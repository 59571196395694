@charset "UTF-8";
/**
 * Clay 3.124.0
 *
 * SPDX-FileCopyrightText: © 2020 Liferay, Inc. <https://liferay.com>
 * SPDX-FileCopyrightText: © 2020 Contributors to the project Clay <https://github.com/liferay/clay/graphs/contributors>
 *
 * SPDX-License-Identifier: BSD-3-Clause
 */
/**
 * Bootstrap v4.4.1
 *
 * SPDX-FileCopyrightText: © 2019 Twitter, Inc. <https://twitter.com>
 * SPDX-FileCopyrightText: © 2019 The Bootstrap Authors <https://getbootstrap.com/>
 *
 * SPDX-License-Identifier: LicenseRef-MIT-Bootstrap
 */
/* SPDX-SnippetBegin
 * SPDX-License-Identifier: MIT
 * SPDX-SnippetCopyrightText: © 2016 Nicolas Gallagher and Jonathan Neal <https://github.com/necolas/normalize.css>
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  display: block;
}

body {
  background-color: #fff;
  color: #272833;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  -ms-overflow-style: scrollbar;
  text-align: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

p {
  margin-bottom: 1rem;
  margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  text-decoration: underline;
  text-decoration: underline dotted;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-bottom: 1rem;
  margin-top: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  border-radius: 1px;
  color: #0b5fff;
  text-decoration: none;
  text-underline-offset: 0.23em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  a {
    transition: none;
  }
}
.c-prefers-reduced-motion a {
  transition: none;
}

a:hover, a.hover {
  color: #0041be;
  text-decoration: underline;
}
a.focus, a:focus-visible, .c-prefers-focus a:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

pre {
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  color: inherit;
  display: block;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* SPDX-SnippetEnd */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

h1,
.h1 {
  font-size: calc(1rem * 2.5);
}
h2,
.h2 {
  font-size: calc(1rem * 2);
}
h3,
.h3 {
  font-size: calc(1rem * 1.75);
}
h4,
.h4 {
  font-size: calc(1rem * 1.5);
}
h5,
.h5 {
  font-size: calc(1rem * 1.25);
}
h6,
.h6 {
  font-size: 1rem;
}
.lead {
  font-size: calc(1rem * 1.25);
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.0625rem 0 0 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  background-color: #fcf8e3;
  display: inline;
  line-height: normal;
  position: relative;
  white-space: pre-wrap;
}
mark.clay-dark,
.clay-dark.mark,
.clay-dark mark,
.clay-dark .mark {
  background-color: #828e9a;
  color: #fff;
}
.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-inline {
  list-style: none;
  padding-left: 0;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  font-size: calc(1rem * 1.25);
  margin-bottom: 1rem;
}

.blockquote-footer {
  color: #6c757d;
  display: block;
  font-size: 80%;
}
.blockquote-footer::before {
  content: "— ";
}

.reference-mark {
  display: inline-block;
  font-size: 0.75rem;
  position: relative;
  vertical-align: super;
}
.reference-mark.lexicon-icon {
  vertical-align: super;
}

.c-kbd-group {
  font-size: 0.875rem;
}
.c-kbd-group > .c-kbd {
  font-size: inherit;
}

.c-kbd {
  background-color: transparent;
  border-radius: 2px;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: inherit;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.875rem;
  font-weight: 500;
  height: 1.5rem;
  line-height: 1.375rem;
  min-width: 1.5rem;
  padding-bottom: 0;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  padding-top: 0;
  text-align: center;
  text-transform: capitalize;
}

.c-kbd > .c-kbd {
  border-width: 0px;
  font-size: inherit;
  font-weight: inherit;
  height: auto;
  line-height: inherit;
  min-width: 0;
  padding: 0;
}
.c-kbd > .c-kbd[class*=c-kbd-] {
  border-width: inherit;
  height: inherit;
  min-width: inherit;
  padding: inherit;
}
.c-kbd > .c-kbd[class*=c-kbd-]:first-child {
  margin-left: -0.3125rem;
}
.c-kbd > .c-kbd[class*=c-kbd-]:last-child {
  margin-right: -0.3125rem;
}
.c-kbd > .c-kbd.c-kbd-monospaced {
  padding: 0;
}
.c-kbd > .c-kbd-separator {
  font-weight: 400;
}

.c-kbd-monospaced {
  padding: 0;
}

.c-kbd-inline {
  border-width: 0px;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  min-width: 0;
  padding: 0;
}
.c-kbd-inline .c-kbd-separator {
  font-weight: inherit;
}

.c-kbd-sm,
.c-kbd.c-kbd-sm {
  font-size: 0.75rem;
}

.c-kbd-group-sm {
  font-size: 0.75rem;
}
.c-kbd-lg,
.c-kbd.c-kbd-lg {
  font-size: 1rem;
}

.c-kbd-group-lg {
  font-size: 1rem;
}
.c-kbd-group-light {
  color: #6b6c7e;
}

.c-kbd-light {
  background-color: #fff;
  border-color: #ced4da;
  color: #6b6c7e;
}

.c-kbd-group-dark {
  color: #fff;
}

.c-kbd-dark {
  background-color: #828e9a;
  border-color: #828e9a;
  color: #fff;
}

code {
  color: #e83e8c;
  font-size: 87.5%;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  background-color: #272833;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
}
kbd kbd {
  box-shadow: none;
  font-size: 100%;
  font-weight: 700;
  padding: 0;
}

pre {
  color: #272833;
  display: block;
  font-size: 87.5%;
}
pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.img-thumbnail {
  background-color: #fff;
  border: 0.0625rem solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  height: auto;
  max-width: 100%;
  padding: 0.25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel-inner::after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.c-prefers-reduced-motion .carousel-item {
  transition: none;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
  z-index: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
  transition: opacity 0s 0.6s;
  z-index: 0;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.c-prefers-reduced-motion .carousel-fade .active.carousel-item-left,
.c-prefers-reduced-motion .carousel-fade .active.carousel-item-right {
  transition: none;
}

.carousel-control-prev,
.carousel-control-next {
  align-items: center;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.15s ease;
  width: 15%;
  z-index: 1;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.c-prefers-reduced-motion .carousel-control-prev,
.c-prefers-reduced-motion .carousel-control-next {
  transition: none;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background: no-repeat 50%/100% 100%;
  display: inline-block;
  height: 20px;
  width: 20px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20fill='%23fff'%20width='8'%20height='8'%20viewBox='0%200%208%208'%3E%3Cpath%20d='M5.25%200l-4%204%204%204%201.5-1.5L4.25%204l2.5-2.5L5.25%200z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20fill='%23fff'%20width='8'%20height='8'%20viewBox='0%200%208%208'%3E%3Cpath%20d='M2.75%200l-1.5%201.5L3.75%204l-2.5%202.5L2.75%208l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  position: absolute;
  right: 0;
  z-index: 15;
}
.carousel-indicators li {
  background-clip: padding-box;
  background-color: #fff;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 1 auto;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.5;
  text-indent: -999px;
  transition: opacity 0.6s ease;
  width: 30px;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.c-prefers-reduced-motion .carousel-indicators li {
  transition: none;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.jumbotron {
  background-color: #f1f2f5;
  border-radius: 0.3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  animation: spinner-border 0.75s linear infinite;
  border-color: currentColor transparent currentColor currentColor;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  height: 2rem;
  vertical-align: text-bottom;
  width: 2rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border {
    animation: none;
  }
}
.c-prefers-reduced-motion .spinner-border {
  animation: none;
}

.spinner-border-sm {
  border-width: 0.2em;
  height: 1rem;
  width: 1rem;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  animation: spinner-grow 0.75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2rem;
  opacity: 0;
  vertical-align: text-bottom;
  width: 2rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-grow {
    animation: none;
  }
}
.c-prefers-reduced-motion .spinner-grow {
  animation: none;
}

.spinner-grow-sm {
  height: 1rem;
  width: 1rem;
}

.toast {
  backdrop-filter: blur(10px);
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  max-width: 350px;
  opacity: 0;
  overflow: hidden;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: #6c757d;
  display: flex;
  padding: 0.25rem 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.lexicon-icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  margin-top: -3px;
  vertical-align: middle;
  width: 1em;
}

.lexicon-icon-sm {
  font-size: 0.5rem;
}

.lexicon-icon-lg {
  font-size: 2rem;
}

.lexicon-icon-xl {
  font-size: 8rem;
}

.order-arrow-down-active .order-arrow-arrow-down {
  fill: rgba(0, 0, 0, 0.9);
}

.order-arrow-up-active .order-arrow-arrow-up {
  fill: rgba(0, 0, 0, 0.9);
}

a.collapse-icon,
button.collapse-icon,
.collapse-icon[tabindex] {
  padding-right: 2.28125rem;
}
a.collapse-icon .c-inner,
button.collapse-icon .c-inner,
.collapse-icon[tabindex] .c-inner {
  margin-right: -2.28125rem;
}

.collapse-icon-closed .lexicon-icon,
.collapse-icon-open .lexicon-icon {
  display: block;
}

.collapse-icon .collapse-icon-closed,
.collapse-icon .collapse-icon-open {
  height: 1em;
  position: absolute;
  right: 0.9375rem;
  top: calc(0.5rem + 0.0625rem + (((0.9375em * 1.5) - 1em) * 0.5));
  width: 1em;
}
.collapse-icon .collapse-icon-closed .lexicon-icon,
.collapse-icon .collapse-icon-open .lexicon-icon {
  margin-top: 0;
}
.collapse-icon .collapse-icon-closed {
  display: none;
}
.collapse-icon .collapse-icon-open {
  display: inline-block;
}

.collapsed .collapse-icon-closed {
  display: inline-block;
}
.collapsed .collapse-icon-open {
  display: none;
}

.collapse-icon.collapse-icon-middle .collapse-icon-closed,
.collapse-icon.collapse-icon-middle .collapse-icon-open {
  margin-top: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.aspect-ratio {
  display: block;
  overflow: hidden;
  position: relative;
  padding-bottom: calc(1 / 1 * 100%);
}

.aspect-ratio-item {
  left: 0;
  position: absolute;
  word-wrap: break-word;
}

.sticker-img, .aspect-ratio-item-fluid {
  max-width: 100%;
  position: absolute;
  word-wrap: break-word;
}

.aspect-ratio-item-vertical-fluid {
  max-height: 100%;
  position: absolute;
  word-wrap: break-word;
}

.aspect-ratio-item-flush {
  max-width: none;
  position: absolute;
  width: 100.6%;
}

.aspect-ratio-item-vertical-flush {
  height: 100.6%;
  max-height: none;
  position: absolute;
}

.aspect-ratio-item-top-left {
  position: absolute;
  bottom: auto;
  left: 0;
  right: auto;
  top: 0;
}

.aspect-ratio-item-top-center {
  position: absolute;
  bottom: auto;
  left: 50%;
  right: auto;
  top: 0;
  transform: translateX(-50%);
}

.aspect-ratio-item-top-right {
  position: absolute;
  bottom: auto;
  left: auto;
  right: 0;
  top: 0;
}

.aspect-ratio-item-right-middle {
  position: absolute;
  bottom: auto;
  left: auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.aspect-ratio-item-bottom-right {
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  top: auto;
}

.aspect-ratio-item-bottom-center {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.aspect-ratio-item-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  right: auto;
  top: auto;
}

.aspect-ratio-item-left-middle {
  position: absolute;
  bottom: auto;
  left: 0;
  right: auto;
  top: 50%;
  transform: translateY(-50%);
}

.sticker-img, .aspect-ratio-item-center-middle {
  position: absolute;
  bottom: auto;
  left: 50%;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
}

.aspect-ratio-3-to-2 {
  padding-bottom: calc(2 / 3 * 100%);
}

.aspect-ratio-4-to-3 {
  padding-bottom: calc(3 / 4 * 100%);
}

.aspect-ratio-8-to-3 {
  padding-bottom: calc(3 / 8 * 100%);
}

.aspect-ratio-8-to-5 {
  padding-bottom: calc(5 / 8 * 100%);
}

.aspect-ratio-16-to-9 {
  padding-bottom: calc(9 / 16 * 100%);
}

.aspect-ratio-bg-contain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.aspect-ratio-bg-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.aspect-ratio-bg-center {
  background-position: center;
  background-repeat: no-repeat;
}

.btn {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: var(--btn-border-width, 1px);
  border-radius: var(--btn-border-radius, 4px);
  box-shadow: var(--btn-box-shadow, none);
  color: #272833;
  cursor: pointer;
  display: inline-block;
  font-size: var(--btn-font-size, 1rem);
  font-weight: var(--btn-font-weight, 600);
  letter-spacing: inherit;
  line-height: var(--btn-line-height, 1.5);
  padding-bottom: var(--btn-padding-y, 0.4375rem);
  padding-left: var(--btn-padding-x, 0.9375rem);
  padding-right: var(--btn-padding-x, 0.9375rem);
  padding-top: var(--btn-padding-y, 0.4375rem);
  text-align: center;
  text-transform: none;
  transition: var(--btn-transition, color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out);
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.c-prefers-reduced-motion .btn {
  transition: none;
}

.btn:hover {
  color: #272833;
  text-decoration: none;
}
.btn.focus, .btn:focus-visible, .c-prefers-focus .btn:focus {
  box-shadow: var(--btn-focus-box-shadow, 0 0 0 0.125rem #fff, 0 0 0 0.25rem #528eff);
  outline: var(--btn-focus-outline, 0);
  outline-offset: var(--btn-focus-outline-offset);
}
.btn:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:active:focus-visible, .c-prefers-focus .btn:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:disabled, .btn.disabled {
  cursor: not-allowed;
  opacity: var(--btn-disabled-opacity, 0.4);
}
.btn:disabled:focus-visible, .c-prefers-focus .btn:disabled:focus, .btn.disabled:focus-visible, .c-prefers-focus .btn.disabled:focus {
  box-shadow: none;
}
.btn:disabled:active, .btn.disabled:active {
  pointer-events: none;
}
.btn[aria-expanded=true], .btn.show {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn .c-inner {
  margin-bottom: calc(var(--btn-padding-y, 0.4375rem) * -1);
  margin-left: calc(var(--btn-padding-x, 0.9375rem) * -1);
  margin-right: calc(var(--btn-padding-x, 0.9375rem) * -1);
  margin-top: calc(var(--btn-padding-y, 0.4375rem) * -1);
}
.btn .inline-item {
  line-height: 1;
  margin-top: -3px;
}
.btn .inline-item .lexicon-icon {
  margin-top: 0;
}
.btn .btn-section {
  display: block;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1;
}
fieldset:disabled a.btn {
  cursor: not-allowed;
  opacity: var(--btn-disabled-opacity, 0.4);
}
fieldset:disabled a.btn:focus {
  box-shadow: none;
}
fieldset:disabled a.btn:active {
  pointer-events: none;
}

.sidebar-sm .btn.btn-xs, .btn-translucent, .btn-xs {
  font-size: 0.75rem;
  line-height: 1.5;
  padding-bottom: 0.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
}
.sidebar-sm .btn.btn-xs .c-inner, .btn-translucent .c-inner, .btn-xs .c-inner {
  margin-bottom: -0.125rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.125rem;
}
.sidebar-sm .btn.btn-xs .lexicon-icon, .btn-translucent .lexicon-icon, .btn-xs .lexicon-icon {
  font-size: inherit;
}
.sidebar-sm .btn.btn-xs .inline-item, .btn-translucent .inline-item, .btn-xs .inline-item {
  font-size: inherit;
  margin-top: -0.16em;
}
.sidebar-sm .btn.btn-xs .inline-item-before, .btn-translucent .inline-item-before, .btn-xs .inline-item-before {
  margin-right: 0.25rem;
}
.sidebar-sm .btn.btn-xs .inline-item-after, .btn-translucent .inline-item-after, .btn-xs .inline-item-after {
  margin-left: 0.25rem;
}
.sidebar-sm .btn.btn-xs .btn-section, .btn-translucent .btn-section, .btn-xs .btn-section {
  font-size: 0.375rem;
}
.btn-group-lg > .btn,
.btn-group-lg .btn-group > .btn, .btn-lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.btn-group-lg > .btn .c-inner,
.btn-group-lg .btn-group > .btn .c-inner, .btn-lg .c-inner {
  margin-bottom: -0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
}
.btn-group-lg > .btn .btn-section,
.btn-group-lg .btn-group > .btn .btn-section, .btn-lg .btn-section {
  font-size: 0.8125rem;
}
.sidebar-sm .btn, .form-group-sm .btn, .btn-group-sm > .btn,
.btn-group-sm .btn-group > .btn, .btn-sm {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.sidebar-sm .btn .c-inner, .form-group-sm .btn .c-inner, .btn-group-sm > .btn .c-inner,
.btn-group-sm .btn-group > .btn .c-inner, .btn-sm .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}
.sidebar-sm .btn .btn-section, .form-group-sm .btn .btn-section, .btn-group-sm > .btn .btn-section,
.btn-group-sm .btn-group > .btn .btn-section, .btn-sm .btn-section {
  font-size: 0.5625rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.menubar-toggler, .btn-unstyled {
  background-color: rgba(0, 0, 0, 0.001);
  border-width: 0;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  max-width: 100%;
  padding: 0;
  text-align: left;
  text-transform: inherit;
  vertical-align: baseline;
}
.menubar-toggler .c-inner, .btn-unstyled .c-inner {
  margin: 0;
  max-width: none;
}
.sidebar-sm .btn.btn-monospaced.btn-xs, .btn-monospaced.btn-xs {
  height: 1.5rem;
  width: 1.5rem;
}
.btn-monospaced {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 2.375rem;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  text-align: center;
  white-space: normal;
  width: 2.375rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.btn-monospaced .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0;
  width: 100%;
}
.btn-monospaced .lexicon-icon {
  margin-top: 0;
}
.btn-monospaced .inline-item {
  margin-top: 0;
}
.btn-group-vertical > .btn-monospaced.btn-lg, .btn-group-lg .btn-monospaced, .btn-monospaced.btn-lg {
  height: 3rem;
  width: 3rem;
}
.sidebar-sm .btn.btn-monospaced, .btn-group-vertical > .btn-monospaced.btn-sm, .btn-group-sm .btn-monospaced, .btn-monospaced.btn-sm {
  height: 1.9375rem;
  width: 1.9375rem;
}
.btn-primary {
  background-color: var(--btn-primary-background-color, #0b5fff);
  background-image: var(--btn-primary-background-image);
  border-color: var(--btn-primary-border-color, transparent);
  box-shadow: var(--btn-primary-box-shadow);
  color: var(--btn-primary-color, #fff);
}
.btn-primary:hover {
  background-color: var(--btn-primary-hover-background-color, #0053f0);
  background-image: var(--btn-primary-hover-background-image);
  border-color: var(--btn-primary-border-color, transparent);
  color: var(--btn-primary-hover-color, #fff);
  box-shadow: var(--btn-primary-hover-box-shadow);
}
.btn-primary.focus, .btn-primary:focus-visible, .c-prefers-focus .btn-primary:focus {
  background-color: var(--btn-primary-focus-background-color, #0053f0);
  background-image: var(--btn-primary-focus-background-image);
  border-color: var(--btn-primary-focus-border-color, transparent);
  box-shadow: var(--btn-primary-focus-box-shadow);
  color: var(--btn-primary-focus-color, #fff);
}
.btn-primary:active {
  background-color: var(--btn-primary-active-background-color, #004ad7);
  background-image: var(--btn-primary-active-background-image);
  border-color: var(--btn-primary-active-border-color, transparent);
  color: var(--btn-primary-active-color, #fff);
  box-shadow: var(--btn-primary-active-box-shadow);
}
.btn-primary:active:focus-visible, .c-prefers-focus .btn-primary:active:focus {
  box-shadow: var(--btn-primary-active-focus-box-shadow);
}
.btn-primary.active {
  background-color: var(--btn-primary-active-background-color, #004ad7);
  background-image: var(--btn-primary-active-background-image);
  border-color: var(--btn-primary-active-border-color, transparent);
  color: var(--btn-primary-active-color, #fff);
  box-shadow: var(--btn-primary-active-box-shadow);
}
.btn-primary:disabled, .btn-primary.disabled {
  background-color: var(--btn-primary-disabled-background-color, #0b5fff);
  background-image: var(--btn-primary-disabled-background-image);
  border-color: var(--btn-primary-disabled-border-color, #0b5fff);
  color: var(--btn-primary-disabled-color, #fff);
  box-shadow: var(--btn-primary-disabled-box-shadow);
}
.btn-primary[aria-expanded=true], .btn-primary.show {
  background-color: var(--btn-primary-active-background-color, #004ad7);
  background-image: var(--btn-primary-active-background-image);
  border-color: var(--btn-primary-active-border-color, transparent);
  color: var(--btn-primary-active-color, #fff);
  box-shadow: var(--btn-primary-active-box-shadow);
}
.btn-secondary {
  background-color: var(--btn-secondary-background-color, #fff);
  border-color: var(--btn-secondary-border-color, #88889a);
  box-shadow: var(--btn-secondary-box-shadow);
  color: var(--btn-secondary-color, #6b6c7e);
}
.btn-secondary:hover {
  background-color: var(--btn-secondary-hover-background-color, #f7f8f9);
  border-color: var(--btn-secondary-hover-border-color, #88889a);
  color: var(--btn-secondary-hover-color, #272833);
  box-shadow: var(--btn-secondary-hover-box-shadow);
}
.btn-secondary.focus, .btn-secondary:focus-visible, .c-prefers-focus .btn-secondary:focus {
  background-color: var(--btn-secondary-focus-background-color, #f7f8f9);
  border-color: var(--btn-secondary-focus-border-color, #88889a);
  box-shadow: var(--btn-secondary-focus-box-shadow);
  color: var(--btn-secondary-focus-color, #272833);
}
.btn-secondary:active {
  background-color: var(--btn-secondary-active-background-color, #f1f2f5);
  border-color: var(--btn-secondary-active-border-color, #88889a);
  color: var(--btn-secondary-active-color, #272833);
  box-shadow: var(--btn-secondary-active-box-shadow);
}
.btn-secondary:active:focus-visible, .c-prefers-focus .btn-secondary:active:focus {
  box-shadow: var(--btn-secondary-active-focus-box-shadow);
}
.btn-secondary.active {
  background-color: var(--btn-secondary-active-background-color, #f1f2f5);
  border-color: var(--btn-secondary-active-border-color, #88889a);
  color: var(--btn-secondary-active-color, #272833);
  box-shadow: var(--btn-secondary-active-box-shadow);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  background-color: var(--btn-secondary-disabled-background-color, #fff);
  border-color: var(--btn-secondary-disabled-border-color, #88889a);
  color: var(--btn-secondary-disabled-color, #6b6c7e);
  box-shadow: var(--btn-secondary-disabled-box-shadow);
}
.btn-secondary[aria-expanded=true], .btn-secondary.show {
  background-color: var(--btn-secondary-active-background-color, #f1f2f5);
  border-color: var(--btn-secondary-active-border-color, #88889a);
  color: var(--btn-secondary-active-color, #272833);
  box-shadow: var(--btn-secondary-active-box-shadow);
}
.btn-success {
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-success:hover {
  background-color: #218838;
  border-color: #1e7e34;
  color: #fff;
}
.btn-success.focus, .btn-success:focus-visible, .c-prefers-focus .btn-success:focus {
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  color: #fff;
}
.btn-success:active {
  background-color: #1e7e34;
  border-color: #1c7430;
  color: #fff;
}
.btn-success:active:focus-visible, .c-prefers-focus .btn-success:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.active {
  background-color: #1e7e34;
  border-color: #1c7430;
  color: #fff;
}
.btn-success:disabled, .btn-success.disabled {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
.btn-success[aria-expanded=true], .btn-success.show {
  background-color: #1e7e34;
  border-color: #1c7430;
  color: #fff;
}
.btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-info:hover {
  background-color: #138496;
  border-color: #117a8b;
  color: #fff;
}
.btn-info.focus, .btn-info:focus-visible, .c-prefers-focus .btn-info:focus {
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  color: #fff;
}
.btn-info:active {
  background-color: #117a8b;
  border-color: #10707f;
  color: #fff;
}
.btn-info:active:focus-visible, .c-prefers-focus .btn-info:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.active {
  background-color: #117a8b;
  border-color: #10707f;
  color: #fff;
}
.btn-info:disabled, .btn-info.disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.btn-info[aria-expanded=true], .btn-info.show {
  background-color: #117a8b;
  border-color: #10707f;
  color: #fff;
}
.btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #272833;
}
.btn-warning:hover {
  background-color: #e0a800;
  border-color: #d39e00;
  color: #272833;
}
.btn-warning.focus, .btn-warning:focus-visible, .c-prefers-focus .btn-warning:focus {
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(223, 170, 14, 0.5);
  color: #272833;
}
.btn-warning:active {
  background-color: #d39e00;
  border-color: #c69500;
  color: #272833;
}
.btn-warning:active:focus-visible, .c-prefers-focus .btn-warning:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(223, 170, 14, 0.5);
}
.btn-warning.active {
  background-color: #d39e00;
  border-color: #c69500;
  color: #272833;
}
.btn-warning:disabled, .btn-warning.disabled {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #272833;
}
.btn-warning[aria-expanded=true], .btn-warning.show {
  background-color: #d39e00;
  border-color: #c69500;
  color: #272833;
}
.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
  color: #fff;
}
.btn-danger.focus, .btn-danger:focus-visible, .c-prefers-focus .btn-danger:focus {
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  color: #fff;
}
.btn-danger:active {
  background-color: #bd2130;
  border-color: #b21f2d;
  color: #fff;
}
.btn-danger:active:focus-visible, .c-prefers-focus .btn-danger:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.active {
  background-color: #bd2130;
  border-color: #b21f2d;
  color: #fff;
}
.btn-danger:disabled, .btn-danger.disabled {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.btn-danger[aria-expanded=true], .btn-danger.show {
  background-color: #bd2130;
  border-color: #b21f2d;
  color: #fff;
}
.btn-light {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #272833;
}
.btn-light:hover {
  background-color: #e1e5e9;
  border-color: #dadfe3;
  color: #272833;
}
.btn-light.focus, .btn-light:focus-visible, .c-prefers-focus .btn-light:focus {
  background-color: #e1e5e9;
  border-color: #dadfe3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  color: #272833;
}
.btn-light:active {
  background-color: #dadfe3;
  border-color: #d3d8de;
  color: #272833;
}
.btn-light:active:focus-visible, .c-prefers-focus .btn-light:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.active {
  background-color: #dadfe3;
  border-color: #d3d8de;
  color: #272833;
}
.btn-light:disabled, .btn-light.disabled {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  color: #272833;
}
.btn-light[aria-expanded=true], .btn-light.show {
  background-color: #dadfe3;
  border-color: #d3d8de;
  color: #272833;
}
.btn-dark {
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #fff;
}
.btn-dark:hover {
  background-color: #23272b;
  border-color: #1d2124;
  color: #fff;
}
.btn-dark.focus, .btn-dark:focus-visible, .c-prefers-focus .btn-dark:focus {
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  color: #fff;
}
.btn-dark:active {
  background-color: #1d2124;
  border-color: #171a1d;
  color: #fff;
}
.btn-dark:active:focus-visible, .c-prefers-focus .btn-dark:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.active {
  background-color: #1d2124;
  border-color: #171a1d;
  color: #fff;
}
.btn-dark:disabled, .btn-dark.disabled {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}
.btn-dark[aria-expanded=true], .btn-dark.show {
  background-color: #1d2124;
  border-color: #171a1d;
  color: #fff;
}
.btn-link {
  border-radius: 1px;
  box-shadow: none;
  color: #0b5fff;
  font-weight: 400;
  text-decoration: none;
}
.btn-link:hover {
  color: #0041be;
  text-decoration: underline;
}
.btn-link.focus, .btn-link:focus-visible, .c-prefers-focus .btn-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  text-decoration: none;
}
.btn-link:active:focus-visible, .c-prefers-focus .btn-link:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.btn-link:disabled, .btn-link.disabled {
  box-shadow: none;
  color: #6c757d;
  text-decoration: none;
}
.btn-translucent {
  border-radius: 50rem;
}
.btn-translucent.btn-primary {
  background-color: rgba(0, 83, 240, 0.04);
  border-color: transparent;
  color: #0053f0;
}
.btn-translucent.btn-primary:hover {
  background-color: rgba(0, 83, 240, 0.06);
  color: #0053f0;
}
.btn-translucent.btn-primary.focus, .btn-translucent.btn-primary:focus-visible, .c-prefers-focus .btn-translucent.btn-primary:focus {
  background-color: rgba(0, 83, 240, 0.06);
  color: #0053f0;
}
.btn-translucent.btn-primary:active {
  background-color: rgba(0, 83, 240, 0.08);
  color: #0053f0;
}
.btn-translucent.btn-primary.active {
  background-color: rgba(0, 83, 240, 0.08);
  color: #0053f0;
}
.btn-translucent.btn-primary[aria-expanded=true], .btn-translucent.btn-primary.show {
  background-color: rgba(0, 83, 240, 0.08);
  color: #0053f0;
}
.btn-translucent.btn-secondary {
  background-color: rgba(130, 142, 154, 0.04);
  border-color: transparent;
  color: #6b6c7e;
}
.btn-translucent.btn-secondary:hover {
  background-color: rgba(130, 142, 154, 0.06);
  color: #6b6c7e;
}
.btn-translucent.btn-secondary.focus, .btn-translucent.btn-secondary:focus-visible, .c-prefers-focus .btn-translucent.btn-secondary:focus {
  background-color: rgba(130, 142, 154, 0.06);
  color: #6b6c7e;
}
.btn-translucent.btn-secondary:active {
  background-color: rgba(130, 142, 154, 0.08);
  color: #6b6c7e;
}
.btn-translucent.btn-secondary.active {
  background-color: rgba(130, 142, 154, 0.08);
  color: #6b6c7e;
}
.btn-translucent.btn-secondary[aria-expanded=true], .btn-translucent.btn-secondary.show {
  background-color: rgba(130, 142, 154, 0.08);
  color: #6b6c7e;
}
.btn-translucent.btn-info, .btn-beta {
  background-color: rgba(19, 132, 150, 0.04);
  border-color: transparent;
  color: #138496;
}
.btn-translucent.btn-info:hover, .btn-beta:hover {
  background-color: rgba(19, 132, 150, 0.06);
  color: #138496;
}
.btn-translucent.btn-info.focus, .btn-translucent.btn-info:focus-visible, .c-prefers-focus .btn-translucent.btn-info:focus, .btn-beta.focus, .btn-beta:focus-visible, .c-prefers-focus .btn-beta:focus {
  background-color: rgba(19, 132, 150, 0.06);
  color: #138496;
}
.btn-translucent.btn-info:active, .btn-beta:active {
  background-color: rgba(19, 132, 150, 0.08);
  color: #138496;
}
.btn-translucent.btn-info.active, .btn-beta.active {
  background-color: rgba(19, 132, 150, 0.08);
  color: #138496;
}
.btn-translucent.btn-info[aria-expanded=true], .btn-translucent.btn-info.show, .btn-beta[aria-expanded=true], .btn-beta.show {
  background-color: rgba(19, 132, 150, 0.08);
  color: #138496;
}
.btn-translucent.btn-success {
  background-color: rgba(33, 136, 56, 0.04);
  border-color: transparent;
  color: #218838;
}
.btn-translucent.btn-success:hover {
  background-color: rgba(33, 136, 56, 0.06);
  color: #218838;
}
.btn-translucent.btn-success.focus, .btn-translucent.btn-success:focus-visible, .c-prefers-focus .btn-translucent.btn-success:focus {
  background-color: rgba(33, 136, 56, 0.06);
  color: #218838;
}
.btn-translucent.btn-success:active {
  background-color: rgba(33, 136, 56, 0.08);
  color: #218838;
}
.btn-translucent.btn-success.active {
  background-color: rgba(33, 136, 56, 0.08);
  color: #218838;
}
.btn-translucent.btn-success[aria-expanded=true], .btn-translucent.btn-success.show {
  background-color: rgba(33, 136, 56, 0.08);
  color: #218838;
}
.btn-translucent.btn-warning {
  background-color: rgba(224, 168, 0, 0.04);
  border-color: transparent;
  color: #e0a800;
}
.btn-translucent.btn-warning:hover {
  background-color: rgba(224, 168, 0, 0.06);
  color: #e0a800;
}
.btn-translucent.btn-warning.focus, .btn-translucent.btn-warning:focus-visible, .c-prefers-focus .btn-translucent.btn-warning:focus {
  background-color: rgba(224, 168, 0, 0.06);
  color: #e0a800;
}
.btn-translucent.btn-warning:active {
  background-color: rgba(224, 168, 0, 0.08);
  color: #e0a800;
}
.btn-translucent.btn-warning.active {
  background-color: rgba(224, 168, 0, 0.08);
  color: #e0a800;
}
.btn-translucent.btn-warning[aria-expanded=true], .btn-translucent.btn-warning.show {
  background-color: rgba(224, 168, 0, 0.08);
  color: #e0a800;
}
.btn-translucent.btn-danger {
  background-color: rgba(200, 35, 51, 0.04);
  border-color: transparent;
  color: #c82333;
}
.btn-translucent.btn-danger:hover {
  background-color: rgba(200, 35, 51, 0.06);
  color: #c82333;
}
.btn-translucent.btn-danger.focus, .btn-translucent.btn-danger:focus-visible, .c-prefers-focus .btn-translucent.btn-danger:focus {
  background-color: rgba(200, 35, 51, 0.06);
  color: #c82333;
}
.btn-translucent.btn-danger:active {
  background-color: rgba(200, 35, 51, 0.08);
  color: #c82333;
}
.btn-translucent.btn-danger.active {
  background-color: rgba(200, 35, 51, 0.08);
  color: #c82333;
}
.btn-translucent.btn-danger[aria-expanded=true], .btn-translucent.btn-danger.show {
  background-color: rgba(200, 35, 51, 0.08);
  color: #c82333;
}
.clay-dark.btn-translucent.btn-primary, .clay-dark .btn-translucent.btn-primary {
  background-color: rgba(179, 205, 255, 0.04);
  border-color: transparent;
  color: #80acff;
}
.clay-dark.btn-translucent.btn-primary:hover, .clay-dark .btn-translucent.btn-primary:hover {
  background-color: rgba(179, 205, 255, 0.06);
  color: #80acff;
}
.focus.clay-dark.btn-translucent.btn-primary, .clay-dark .focus.btn-translucent.btn-primary, .clay-dark.btn-translucent.btn-primary:focus-visible, .clay-dark .btn-translucent.btn-primary:focus-visible, .c-prefers-focus .clay-dark.btn-translucent.btn-primary:focus, .c-prefers-focus .clay-dark .btn-translucent.btn-primary:focus, .clay-dark .c-prefers-focus .btn-translucent.btn-primary:focus {
  background-color: rgba(179, 205, 255, 0.06);
  color: #80acff;
}
.clay-dark.btn-translucent.btn-primary:active, .clay-dark .btn-translucent.btn-primary:active {
  background-color: rgba(179, 205, 255, 0.08);
  color: #80acff;
}
.active.clay-dark.btn-translucent.btn-primary, .clay-dark .active.btn-translucent.btn-primary {
  background-color: rgba(179, 205, 255, 0.08);
  color: #80acff;
}
[aria-expanded=true].clay-dark.btn-translucent.btn-primary, .clay-dark [aria-expanded=true].btn-translucent.btn-primary, .show.clay-dark.btn-translucent.btn-primary, .clay-dark .show.btn-translucent.btn-primary {
  background-color: rgba(179, 205, 255, 0.08);
  color: #80acff;
}
.clay-dark.btn-translucent.btn-info, .clay-dark .btn-translucent.btn-info, .btn-beta-dark {
  background-color: rgba(135, 226, 240, 0.04);
  border-color: transparent;
  color: #5ad7ea;
}
.clay-dark.btn-translucent.btn-info:hover, .clay-dark .btn-translucent.btn-info:hover, .btn-beta-dark:hover {
  background-color: rgba(135, 226, 240, 0.06);
  color: #5ad7ea;
}
.focus.clay-dark.btn-translucent.btn-info, .clay-dark .focus.btn-translucent.btn-info, .focus.btn-beta-dark, .clay-dark.btn-translucent.btn-info:focus-visible, .clay-dark .btn-translucent.btn-info:focus-visible, .btn-beta-dark:focus-visible, .c-prefers-focus .clay-dark.btn-translucent.btn-info:focus, .c-prefers-focus .clay-dark .btn-translucent.btn-info:focus, .clay-dark .c-prefers-focus .btn-translucent.btn-info:focus, .c-prefers-focus .btn-beta-dark:focus {
  background-color: rgba(135, 226, 240, 0.06);
  color: #5ad7ea;
}
.clay-dark.btn-translucent.btn-info:active, .clay-dark .btn-translucent.btn-info:active, .btn-beta-dark:active {
  background-color: rgba(135, 226, 240, 0.08);
  color: #5ad7ea;
}
.active.clay-dark.btn-translucent.btn-info, .clay-dark .active.btn-translucent.btn-info, .active.btn-beta-dark {
  background-color: rgba(135, 226, 240, 0.08);
  color: #5ad7ea;
}
[aria-expanded=true].clay-dark.btn-translucent.btn-info, .clay-dark [aria-expanded=true].btn-translucent.btn-info, [aria-expanded=true].btn-beta-dark, .show.clay-dark.btn-translucent.btn-info, .clay-dark .show.btn-translucent.btn-info, .show.btn-beta-dark {
  background-color: rgba(135, 226, 240, 0.08);
  color: #5ad7ea;
}
.clay-dark.btn-translucent.btn-success, .clay-dark .btn-translucent.btn-success {
  background-color: rgba(146, 229, 165, 0.04);
  border-color: transparent;
  color: #69db83;
}
.clay-dark.btn-translucent.btn-success:hover, .clay-dark .btn-translucent.btn-success:hover {
  background-color: rgba(146, 229, 165, 0.06);
  color: #69db83;
}
.focus.clay-dark.btn-translucent.btn-success, .clay-dark .focus.btn-translucent.btn-success, .clay-dark.btn-translucent.btn-success:focus-visible, .clay-dark .btn-translucent.btn-success:focus-visible, .c-prefers-focus .clay-dark.btn-translucent.btn-success:focus, .c-prefers-focus .clay-dark .btn-translucent.btn-success:focus, .clay-dark .c-prefers-focus .btn-translucent.btn-success:focus {
  background-color: rgba(146, 229, 165, 0.06);
  color: #69db83;
}
.clay-dark.btn-translucent.btn-success:active, .clay-dark .btn-translucent.btn-success:active {
  background-color: rgba(146, 229, 165, 0.08);
  color: #69db83;
}
.active.clay-dark.btn-translucent.btn-success, .clay-dark .active.btn-translucent.btn-success {
  background-color: rgba(146, 229, 165, 0.08);
  color: #69db83;
}
[aria-expanded=true].clay-dark.btn-translucent.btn-success, .clay-dark [aria-expanded=true].btn-translucent.btn-success, .show.clay-dark.btn-translucent.btn-success, .clay-dark .show.btn-translucent.btn-success {
  background-color: rgba(146, 229, 165, 0.08);
  color: #69db83;
}
.clay-dark.btn-translucent.btn-warning, .clay-dark .btn-translucent.btn-warning {
  background-color: rgba(255, 235, 175, 0.04);
  border-color: transparent;
  color: #ffde7c;
}
.clay-dark.btn-translucent.btn-warning:hover, .clay-dark .btn-translucent.btn-warning:hover {
  background-color: rgba(255, 235, 175, 0.06);
  color: #ffde7c;
}
.focus.clay-dark.btn-translucent.btn-warning, .clay-dark .focus.btn-translucent.btn-warning, .clay-dark.btn-translucent.btn-warning:focus-visible, .clay-dark .btn-translucent.btn-warning:focus-visible, .c-prefers-focus .clay-dark.btn-translucent.btn-warning:focus, .c-prefers-focus .clay-dark .btn-translucent.btn-warning:focus, .clay-dark .c-prefers-focus .btn-translucent.btn-warning:focus {
  background-color: rgba(255, 235, 175, 0.06);
  color: #ffde7c;
}
.clay-dark.btn-translucent.btn-warning:active, .clay-dark .btn-translucent.btn-warning:active {
  background-color: rgba(255, 235, 175, 0.08);
  color: #ffde7c;
}
.active.clay-dark.btn-translucent.btn-warning, .clay-dark .active.btn-translucent.btn-warning {
  background-color: rgba(255, 235, 175, 0.08);
  color: #ffde7c;
}
[aria-expanded=true].clay-dark.btn-translucent.btn-warning, .clay-dark [aria-expanded=true].btn-translucent.btn-warning, .show.clay-dark.btn-translucent.btn-warning, .clay-dark .show.btn-translucent.btn-warning {
  background-color: rgba(255, 235, 175, 0.08);
  color: #ffde7c;
}
.clay-dark.btn-translucent.btn-danger, .clay-dark .btn-translucent.btn-danger {
  background-color: rgba(245, 196, 201, 0.04);
  border-color: transparent;
  color: #ed99a1;
}
.clay-dark.btn-translucent.btn-danger:hover, .clay-dark .btn-translucent.btn-danger:hover {
  background-color: rgba(245, 196, 201, 0.06);
  color: #ed99a1;
}
.focus.clay-dark.btn-translucent.btn-danger, .clay-dark .focus.btn-translucent.btn-danger, .clay-dark.btn-translucent.btn-danger:focus-visible, .clay-dark .btn-translucent.btn-danger:focus-visible, .c-prefers-focus .clay-dark.btn-translucent.btn-danger:focus, .c-prefers-focus .clay-dark .btn-translucent.btn-danger:focus, .clay-dark .c-prefers-focus .btn-translucent.btn-danger:focus {
  background-color: rgba(245, 196, 201, 0.06);
  color: #ed99a1;
}
.clay-dark.btn-translucent.btn-danger:active, .clay-dark .btn-translucent.btn-danger:active {
  background-color: rgba(245, 196, 201, 0.08);
  color: #ed99a1;
}
.active.clay-dark.btn-translucent.btn-danger, .clay-dark .active.btn-translucent.btn-danger {
  background-color: rgba(245, 196, 201, 0.08);
  color: #ed99a1;
}
[aria-expanded=true].clay-dark.btn-translucent.btn-danger, .clay-dark [aria-expanded=true].btn-translucent.btn-danger, .show.clay-dark.btn-translucent.btn-danger, .clay-dark .show.btn-translucent.btn-danger {
  background-color: rgba(245, 196, 201, 0.08);
  color: #ed99a1;
}
.btn-outline-primary {
  border-color: #0b5fff;
  color: #0b5fff;
}
.btn-outline-primary:hover {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
.btn-outline-primary.focus, .btn-outline-primary:focus-visible, .c-prefers-focus .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.5);
}
.btn-outline-primary:active {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
.btn-outline-primary:active:focus-visible, .c-prefers-focus .btn-outline-primary:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(11, 95, 255, 0.5);
}
.btn-outline-primary.active {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  background-color: transparent;
  color: #0b5fff;
}
.btn-outline-primary[aria-expanded=true], .btn-outline-primary.show {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
.btn-outline-secondary {
  border-color: #6b6c7e;
  color: #6b6c7e;
}
.btn-outline-secondary:hover {
  background-color: #6b6c7e;
  border-color: #6b6c7e;
  color: #fff;
}
.btn-outline-secondary.focus, .btn-outline-secondary:focus-visible, .c-prefers-focus .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(107, 108, 126, 0.5);
}
.btn-outline-secondary:active {
  background-color: #6b6c7e;
  border-color: #6b6c7e;
  color: #fff;
}
.btn-outline-secondary:active:focus-visible, .c-prefers-focus .btn-outline-secondary:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(107, 108, 126, 0.5);
}
.btn-outline-secondary.active {
  background-color: #6b6c7e;
  border-color: #6b6c7e;
  color: #fff;
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  background-color: transparent;
  color: #6b6c7e;
}
.btn-outline-secondary[aria-expanded=true], .btn-outline-secondary.show {
  background-color: #6b6c7e;
  border-color: #6b6c7e;
  color: #fff;
}
.btn-outline-success {
  border-color: #28a745;
  color: #28a745;
}
.btn-outline-success:hover {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
.btn-outline-success.focus, .btn-outline-success:focus-visible, .c-prefers-focus .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success:active {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
.btn-outline-success:active:focus-visible, .c-prefers-focus .btn-outline-success:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.active {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  background-color: transparent;
  color: #28a745;
}
.btn-outline-success[aria-expanded=true], .btn-outline-success.show {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
.btn-outline-info {
  border-color: #17a2b8;
  color: #17a2b8;
}
.btn-outline-info:hover {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.btn-outline-info.focus, .btn-outline-info:focus-visible, .c-prefers-focus .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info:active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.btn-outline-info:active:focus-visible, .c-prefers-focus .btn-outline-info:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  background-color: transparent;
  color: #17a2b8;
}
.btn-outline-info[aria-expanded=true], .btn-outline-info.show {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.btn-outline-warning {
  border-color: #ffc107;
  color: #ffc107;
}
.btn-outline-warning:hover {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #272833;
}
.btn-outline-warning.focus, .btn-outline-warning:focus-visible, .c-prefers-focus .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:active {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #272833;
}
.btn-outline-warning:active:focus-visible, .c-prefers-focus .btn-outline-warning:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.active {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #272833;
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  background-color: transparent;
  color: #ffc107;
}
.btn-outline-warning[aria-expanded=true], .btn-outline-warning.show {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #272833;
}
.btn-outline-danger {
  border-color: #dc3545;
  color: #dc3545;
}
.btn-outline-danger:hover {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.btn-outline-danger.focus, .btn-outline-danger:focus-visible, .c-prefers-focus .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.btn-outline-danger:active:focus-visible, .c-prefers-focus .btn-outline-danger:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.active {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  background-color: transparent;
  color: #dc3545;
}
.btn-outline-danger[aria-expanded=true], .btn-outline-danger.show {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
.btn-outline-light {
  border-color: #f7f8f9;
  color: #f7f8f9;
}
.btn-outline-light:hover {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  color: #272833;
}
.btn-outline-light.focus, .btn-outline-light:focus-visible, .c-prefers-focus .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}
.btn-outline-light:active {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  color: #272833;
}
.btn-outline-light:active:focus-visible, .c-prefers-focus .btn-outline-light:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}
.btn-outline-light.active {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  color: #272833;
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  background-color: transparent;
  color: #f7f8f9;
}
.btn-outline-light[aria-expanded=true], .btn-outline-light.show {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  color: #272833;
}
.btn-outline-dark {
  border-color: #343a40;
  color: #343a40;
}
.btn-outline-dark:hover {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}
.btn-outline-dark.focus, .btn-outline-dark:focus-visible, .c-prefers-focus .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark:active {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}
.btn-outline-dark:active:focus-visible, .c-prefers-focus .btn-outline-dark:active:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.active {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  background-color: transparent;
  color: #343a40;
}
.btn-outline-dark[aria-expanded=true], .btn-outline-dark.show {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}
.btn-outline-borderless {
  border-color: transparent;
}
.btn-outline-borderless:hover {
  border-color: transparent;
}
.btn-outline-borderless.focus, .btn-outline-borderless:focus-visible, .c-prefers-focus .btn-outline-borderless:focus {
  border-color: transparent;
}
.btn-outline-borderless:disabled, .btn-outline-borderless.disabled {
  border-color: transparent;
}
.btn-outline-borderless:not(:disabled):not(.disabled):active {
  border-color: transparent;
}

.show > .btn-outline-borderless.dropdown-toggle {
  border-color: transparent;
}

.btn .loading-animation {
  font-size: 1em;
  margin-top: -0.1em;
}

.c-horizontal-resizer {
  background-color: transparent;
  bottom: 0;
  cursor: ew-resize;
  margin-right: -0.25rem;
  position: absolute;
  right: 0;
  top: 0;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 0.5rem;
  z-index: 10;
}
.c-horizontal-resizer:hover {
  background-color: #528eff;
}
.c-horizontal-resizer:focus {
  background-color: #528eff;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.c-prefers-reduced-motion .fade {
  transition: none;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.c-prefers-reduced-motion .collapsing {
  transition: none;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(30px * 0.5);
  padding-right: calc(30px * 0.5);
  width: 100%;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-xl, .container-lg, .container-md, .container-sm, .container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(30px * 0.5);
  padding-right: calc(30px * 0.5);
  width: 100%;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 calc(100% / 1);
  max-width: calc(100% / 1);
}

.row-cols-2 > * {
  flex: 0 0 calc(100% / 2);
  max-width: calc(100% / 2);
}

.row-cols-3 > * {
  flex: 0 0 calc(100% / 3);
  max-width: calc(100% / 3);
}

.row-cols-4 > * {
  flex: 0 0 calc(100% / 4);
  max-width: calc(100% / 4);
}

.row-cols-5 > * {
  flex: 0 0 calc(100% / 5);
  max-width: calc(100% / 5);
}

.row-cols-6 > * {
  flex: 0 0 calc(100% / 6);
  max-width: calc(100% / 6);
}

.col-auto {
  flex: 0 0 auto;
  max-width: 100%;
  width: auto;
}

.col-1 {
  flex: 0 0 calc(1 / 12 * 100%);
  max-width: calc(1 / 12 * 100%);
}

.col-2 {
  flex: 0 0 calc(2 / 12 * 100%);
  max-width: calc(2 / 12 * 100%);
}

.col-3 {
  flex: 0 0 calc(3 / 12 * 100%);
  max-width: calc(3 / 12 * 100%);
}

.col-4 {
  flex: 0 0 calc(4 / 12 * 100%);
  max-width: calc(4 / 12 * 100%);
}

.col-5 {
  flex: 0 0 calc(5 / 12 * 100%);
  max-width: calc(5 / 12 * 100%);
}

.col-6 {
  flex: 0 0 calc(6 / 12 * 100%);
  max-width: calc(6 / 12 * 100%);
}

.col-7 {
  flex: 0 0 calc(7 / 12 * 100%);
  max-width: calc(7 / 12 * 100%);
}

.col-8 {
  flex: 0 0 calc(8 / 12 * 100%);
  max-width: calc(8 / 12 * 100%);
}

.col-9 {
  flex: 0 0 calc(9 / 12 * 100%);
  max-width: calc(9 / 12 * 100%);
}

.col-10 {
  flex: 0 0 calc(10 / 12 * 100%);
  max-width: calc(10 / 12 * 100%);
}

.col-11 {
  flex: 0 0 calc(11 / 12 * 100%);
  max-width: calc(11 / 12 * 100%);
}

.col-12 {
  flex: 0 0 calc(12 / 12 * 100%);
  max-width: calc(12 / 12 * 100%);
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: calc(1 / 12 * 100%);
}

.offset-2 {
  margin-left: calc(2 / 12 * 100%);
}

.offset-3 {
  margin-left: calc(3 / 12 * 100%);
}

.offset-4 {
  margin-left: calc(4 / 12 * 100%);
}

.offset-5 {
  margin-left: calc(5 / 12 * 100%);
}

.offset-6 {
  margin-left: calc(6 / 12 * 100%);
}

.offset-7 {
  margin-left: calc(7 / 12 * 100%);
}

.offset-8 {
  margin-left: calc(8 / 12 * 100%);
}

.offset-9 {
  margin-left: calc(9 / 12 * 100%);
}

.offset-10 {
  margin-left: calc(10 / 12 * 100%);
}

.offset-11 {
  margin-left: calc(11 / 12 * 100%);
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 calc(100% / 1);
    max-width: calc(100% / 1);
  }
  .row-cols-sm-2 > * {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
  }
  .row-cols-sm-3 > * {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .row-cols-sm-4 > * {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }
  .row-cols-sm-5 > * {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }
  .row-cols-sm-6 > * {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }
  .col-sm-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 calc(1 / 12 * 100%);
    max-width: calc(1 / 12 * 100%);
  }
  .col-sm-2 {
    flex: 0 0 calc(2 / 12 * 100%);
    max-width: calc(2 / 12 * 100%);
  }
  .col-sm-3 {
    flex: 0 0 calc(3 / 12 * 100%);
    max-width: calc(3 / 12 * 100%);
  }
  .col-sm-4 {
    flex: 0 0 calc(4 / 12 * 100%);
    max-width: calc(4 / 12 * 100%);
  }
  .col-sm-5 {
    flex: 0 0 calc(5 / 12 * 100%);
    max-width: calc(5 / 12 * 100%);
  }
  .col-sm-6 {
    flex: 0 0 calc(6 / 12 * 100%);
    max-width: calc(6 / 12 * 100%);
  }
  .col-sm-7 {
    flex: 0 0 calc(7 / 12 * 100%);
    max-width: calc(7 / 12 * 100%);
  }
  .col-sm-8 {
    flex: 0 0 calc(8 / 12 * 100%);
    max-width: calc(8 / 12 * 100%);
  }
  .col-sm-9 {
    flex: 0 0 calc(9 / 12 * 100%);
    max-width: calc(9 / 12 * 100%);
  }
  .col-sm-10 {
    flex: 0 0 calc(10 / 12 * 100%);
    max-width: calc(10 / 12 * 100%);
  }
  .col-sm-11 {
    flex: 0 0 calc(11 / 12 * 100%);
    max-width: calc(11 / 12 * 100%);
  }
  .col-sm-12 {
    flex: 0 0 calc(12 / 12 * 100%);
    max-width: calc(12 / 12 * 100%);
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: calc(0 / 12 * 100%);
  }
  .offset-sm-1 {
    margin-left: calc(1 / 12 * 100%);
  }
  .offset-sm-2 {
    margin-left: calc(2 / 12 * 100%);
  }
  .offset-sm-3 {
    margin-left: calc(3 / 12 * 100%);
  }
  .offset-sm-4 {
    margin-left: calc(4 / 12 * 100%);
  }
  .offset-sm-5 {
    margin-left: calc(5 / 12 * 100%);
  }
  .offset-sm-6 {
    margin-left: calc(6 / 12 * 100%);
  }
  .offset-sm-7 {
    margin-left: calc(7 / 12 * 100%);
  }
  .offset-sm-8 {
    margin-left: calc(8 / 12 * 100%);
  }
  .offset-sm-9 {
    margin-left: calc(9 / 12 * 100%);
  }
  .offset-sm-10 {
    margin-left: calc(10 / 12 * 100%);
  }
  .offset-sm-11 {
    margin-left: calc(11 / 12 * 100%);
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 calc(100% / 1);
    max-width: calc(100% / 1);
  }
  .row-cols-md-2 > * {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
  }
  .row-cols-md-3 > * {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .row-cols-md-4 > * {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }
  .row-cols-md-5 > * {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }
  .row-cols-md-6 > * {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }
  .col-md-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 calc(1 / 12 * 100%);
    max-width: calc(1 / 12 * 100%);
  }
  .col-md-2 {
    flex: 0 0 calc(2 / 12 * 100%);
    max-width: calc(2 / 12 * 100%);
  }
  .col-md-3 {
    flex: 0 0 calc(3 / 12 * 100%);
    max-width: calc(3 / 12 * 100%);
  }
  .col-md-4 {
    flex: 0 0 calc(4 / 12 * 100%);
    max-width: calc(4 / 12 * 100%);
  }
  .col-md-5 {
    flex: 0 0 calc(5 / 12 * 100%);
    max-width: calc(5 / 12 * 100%);
  }
  .col-md-6 {
    flex: 0 0 calc(6 / 12 * 100%);
    max-width: calc(6 / 12 * 100%);
  }
  .col-md-7 {
    flex: 0 0 calc(7 / 12 * 100%);
    max-width: calc(7 / 12 * 100%);
  }
  .col-md-8 {
    flex: 0 0 calc(8 / 12 * 100%);
    max-width: calc(8 / 12 * 100%);
  }
  .col-md-9 {
    flex: 0 0 calc(9 / 12 * 100%);
    max-width: calc(9 / 12 * 100%);
  }
  .col-md-10 {
    flex: 0 0 calc(10 / 12 * 100%);
    max-width: calc(10 / 12 * 100%);
  }
  .col-md-11 {
    flex: 0 0 calc(11 / 12 * 100%);
    max-width: calc(11 / 12 * 100%);
  }
  .col-md-12 {
    flex: 0 0 calc(12 / 12 * 100%);
    max-width: calc(12 / 12 * 100%);
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: calc(0 / 12 * 100%);
  }
  .offset-md-1 {
    margin-left: calc(1 / 12 * 100%);
  }
  .offset-md-2 {
    margin-left: calc(2 / 12 * 100%);
  }
  .offset-md-3 {
    margin-left: calc(3 / 12 * 100%);
  }
  .offset-md-4 {
    margin-left: calc(4 / 12 * 100%);
  }
  .offset-md-5 {
    margin-left: calc(5 / 12 * 100%);
  }
  .offset-md-6 {
    margin-left: calc(6 / 12 * 100%);
  }
  .offset-md-7 {
    margin-left: calc(7 / 12 * 100%);
  }
  .offset-md-8 {
    margin-left: calc(8 / 12 * 100%);
  }
  .offset-md-9 {
    margin-left: calc(9 / 12 * 100%);
  }
  .offset-md-10 {
    margin-left: calc(10 / 12 * 100%);
  }
  .offset-md-11 {
    margin-left: calc(11 / 12 * 100%);
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 calc(100% / 1);
    max-width: calc(100% / 1);
  }
  .row-cols-lg-2 > * {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
  }
  .row-cols-lg-3 > * {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .row-cols-lg-4 > * {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }
  .row-cols-lg-5 > * {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }
  .row-cols-lg-6 > * {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }
  .col-lg-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 calc(1 / 12 * 100%);
    max-width: calc(1 / 12 * 100%);
  }
  .col-lg-2 {
    flex: 0 0 calc(2 / 12 * 100%);
    max-width: calc(2 / 12 * 100%);
  }
  .col-lg-3 {
    flex: 0 0 calc(3 / 12 * 100%);
    max-width: calc(3 / 12 * 100%);
  }
  .col-lg-4 {
    flex: 0 0 calc(4 / 12 * 100%);
    max-width: calc(4 / 12 * 100%);
  }
  .col-lg-5 {
    flex: 0 0 calc(5 / 12 * 100%);
    max-width: calc(5 / 12 * 100%);
  }
  .col-lg-6 {
    flex: 0 0 calc(6 / 12 * 100%);
    max-width: calc(6 / 12 * 100%);
  }
  .col-lg-7 {
    flex: 0 0 calc(7 / 12 * 100%);
    max-width: calc(7 / 12 * 100%);
  }
  .col-lg-8 {
    flex: 0 0 calc(8 / 12 * 100%);
    max-width: calc(8 / 12 * 100%);
  }
  .col-lg-9 {
    flex: 0 0 calc(9 / 12 * 100%);
    max-width: calc(9 / 12 * 100%);
  }
  .col-lg-10 {
    flex: 0 0 calc(10 / 12 * 100%);
    max-width: calc(10 / 12 * 100%);
  }
  .col-lg-11 {
    flex: 0 0 calc(11 / 12 * 100%);
    max-width: calc(11 / 12 * 100%);
  }
  .col-lg-12 {
    flex: 0 0 calc(12 / 12 * 100%);
    max-width: calc(12 / 12 * 100%);
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: calc(0 / 12 * 100%);
  }
  .offset-lg-1 {
    margin-left: calc(1 / 12 * 100%);
  }
  .offset-lg-2 {
    margin-left: calc(2 / 12 * 100%);
  }
  .offset-lg-3 {
    margin-left: calc(3 / 12 * 100%);
  }
  .offset-lg-4 {
    margin-left: calc(4 / 12 * 100%);
  }
  .offset-lg-5 {
    margin-left: calc(5 / 12 * 100%);
  }
  .offset-lg-6 {
    margin-left: calc(6 / 12 * 100%);
  }
  .offset-lg-7 {
    margin-left: calc(7 / 12 * 100%);
  }
  .offset-lg-8 {
    margin-left: calc(8 / 12 * 100%);
  }
  .offset-lg-9 {
    margin-left: calc(9 / 12 * 100%);
  }
  .offset-lg-10 {
    margin-left: calc(10 / 12 * 100%);
  }
  .offset-lg-11 {
    margin-left: calc(11 / 12 * 100%);
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 calc(100% / 1);
    max-width: calc(100% / 1);
  }
  .row-cols-xl-2 > * {
    flex: 0 0 calc(100% / 2);
    max-width: calc(100% / 2);
  }
  .row-cols-xl-3 > * {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
  }
  .row-cols-xl-4 > * {
    flex: 0 0 calc(100% / 4);
    max-width: calc(100% / 4);
  }
  .row-cols-xl-5 > * {
    flex: 0 0 calc(100% / 5);
    max-width: calc(100% / 5);
  }
  .row-cols-xl-6 > * {
    flex: 0 0 calc(100% / 6);
    max-width: calc(100% / 6);
  }
  .col-xl-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 calc(1 / 12 * 100%);
    max-width: calc(1 / 12 * 100%);
  }
  .col-xl-2 {
    flex: 0 0 calc(2 / 12 * 100%);
    max-width: calc(2 / 12 * 100%);
  }
  .col-xl-3 {
    flex: 0 0 calc(3 / 12 * 100%);
    max-width: calc(3 / 12 * 100%);
  }
  .col-xl-4 {
    flex: 0 0 calc(4 / 12 * 100%);
    max-width: calc(4 / 12 * 100%);
  }
  .col-xl-5 {
    flex: 0 0 calc(5 / 12 * 100%);
    max-width: calc(5 / 12 * 100%);
  }
  .col-xl-6 {
    flex: 0 0 calc(6 / 12 * 100%);
    max-width: calc(6 / 12 * 100%);
  }
  .col-xl-7 {
    flex: 0 0 calc(7 / 12 * 100%);
    max-width: calc(7 / 12 * 100%);
  }
  .col-xl-8 {
    flex: 0 0 calc(8 / 12 * 100%);
    max-width: calc(8 / 12 * 100%);
  }
  .col-xl-9 {
    flex: 0 0 calc(9 / 12 * 100%);
    max-width: calc(9 / 12 * 100%);
  }
  .col-xl-10 {
    flex: 0 0 calc(10 / 12 * 100%);
    max-width: calc(10 / 12 * 100%);
  }
  .col-xl-11 {
    flex: 0 0 calc(11 / 12 * 100%);
    max-width: calc(11 / 12 * 100%);
  }
  .col-xl-12 {
    flex: 0 0 calc(12 / 12 * 100%);
    max-width: calc(12 / 12 * 100%);
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: calc(0 / 12 * 100%);
  }
  .offset-xl-1 {
    margin-left: calc(1 / 12 * 100%);
  }
  .offset-xl-2 {
    margin-left: calc(2 / 12 * 100%);
  }
  .offset-xl-3 {
    margin-left: calc(3 / 12 * 100%);
  }
  .offset-xl-4 {
    margin-left: calc(4 / 12 * 100%);
  }
  .offset-xl-5 {
    margin-left: calc(5 / 12 * 100%);
  }
  .offset-xl-6 {
    margin-left: calc(6 / 12 * 100%);
  }
  .offset-xl-7 {
    margin-left: calc(7 / 12 * 100%);
  }
  .offset-xl-8 {
    margin-left: calc(8 / 12 * 100%);
  }
  .offset-xl-9 {
    margin-left: calc(9 / 12 * 100%);
  }
  .offset-xl-10 {
    margin-left: calc(10 / 12 * 100%);
  }
  .offset-xl-11 {
    margin-left: calc(11 / 12 * 100%);
  }
}
.container-fluid-max-sm {
  max-width: 540px;
}
.container-fluid-max-md {
  max-width: 720px;
}
.container-fluid-max-lg {
  max-width: 960px;
}
.container-fluid-max-xl {
  max-width: 1140px;
}

.container-no-gutters {
  padding-left: 0;
  padding-right: 0;
}
.container-no-gutters > .row {
  margin-left: 0;
  margin-right: 0;
}
.container-no-gutters > .row > .col,
.container-no-gutters > .row > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 575.98px) {
  .container-no-gutters-sm-down {
    padding-left: 0;
    padding-right: 0;
  }
  .container-no-gutters-sm-down > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container-no-gutters-sm-down > .row > .col,
  .container-no-gutters-sm-down > .row > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 767.98px) {
  .container-no-gutters-md-down {
    padding-left: 0;
    padding-right: 0;
  }
  .container-no-gutters-md-down > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container-no-gutters-md-down > .row > .col,
  .container-no-gutters-md-down > .row > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 991.98px) {
  .container-no-gutters-lg-down {
    padding-left: 0;
    padding-right: 0;
  }
  .container-no-gutters-lg-down > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container-no-gutters-lg-down > .row > .col,
  .container-no-gutters-lg-down > .row > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 1199.98px) {
  .container-no-gutters-xl-down {
    padding-left: 0;
    padding-right: 0;
  }
  .container-no-gutters-xl-down > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container-no-gutters-xl-down > .row > .col,
  .container-no-gutters-xl-down > .row > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}

.card-page {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.container .card-page, .container-fluid .card-page {
  margin-left: -15px;
  margin-right: -15px;
}

.container-form-lg {
  padding-bottom: 3rem;
  padding-top: 3rem;
}
@media (max-width: 991.98px) {
  .container-form-lg {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

.container-view {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.alert, .portlet-msg-alert,
.portlet-msg-error,
.portlet-msg-help,
.portlet-msg-info,
.portlet-msg-progress,
.portlet-msg-success {
  border-color: transparent;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem;
  display: block;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  position: relative;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.alert .alert-btn, .portlet-msg-alert .alert-btn,
.portlet-msg-error .alert-btn,
.portlet-msg-help .alert-btn,
.portlet-msg-info .alert-btn,
.portlet-msg-progress .alert-btn,
.portlet-msg-success .alert-btn {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.alert .alert-btn .c-inner, .portlet-msg-alert .alert-btn .c-inner,
.portlet-msg-error .alert-btn .c-inner,
.portlet-msg-help .alert-btn .c-inner,
.portlet-msg-info .alert-btn .c-inner,
.portlet-msg-progress .alert-btn .c-inner,
.portlet-msg-success .alert-btn .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}
.alert .btn-group, .portlet-msg-alert .btn-group,
.portlet-msg-error .btn-group,
.portlet-msg-help .btn-group,
.portlet-msg-info .btn-group,
.portlet-msg-progress .btn-group,
.portlet-msg-success .btn-group {
  margin-bottom: -0.125rem;
  margin-top: -0.125rem;
}
.alert .btn-group-item, .portlet-msg-alert .btn-group-item,
.portlet-msg-error .btn-group-item,
.portlet-msg-help .btn-group-item,
.portlet-msg-info .btn-group-item,
.portlet-msg-progress .btn-group-item,
.portlet-msg-success .btn-group-item {
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
}
.alert .close, .portlet-msg-alert .close,
.portlet-msg-error .close,
.portlet-msg-help .close,
.portlet-msg-info .close,
.portlet-msg-progress .close,
.portlet-msg-success .close {
  color: inherit;
  font-size: 0.875rem;
  height: 2rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  width: 2rem;
}
.alert .close:hover, .portlet-msg-alert .close:hover,
.portlet-msg-error .close:hover,
.portlet-msg-help .close:hover,
.portlet-msg-info .close:hover,
.portlet-msg-progress .close:hover,
.portlet-msg-success .close:hover {
  color: inherit;
}
.alert .close .c-inner, .portlet-msg-alert .close .c-inner,
.portlet-msg-error .close .c-inner,
.portlet-msg-help .close .c-inner,
.portlet-msg-info .close .c-inner,
.portlet-msg-progress .close .c-inner,
.portlet-msg-success .close .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.alert .lead, .portlet-msg-alert .lead,
.portlet-msg-error .lead,
.portlet-msg-help .lead,
.portlet-msg-info .lead,
.portlet-msg-progress .lead,
.portlet-msg-success .lead {
  margin-right: 0.3125rem;
}
.alert-heading {
  color: inherit;
}

.alert-footer {
  margin-top: 1.375rem;
}

.alert-link,
.alert-link.btn-unstyled {
  font-weight: 700;
}
.alert-link:hover, .alert-link.hover,
.alert-link.btn-unstyled:hover,
.alert-link.btn-unstyled.hover {
  text-decoration: underline;
}
.alert-link.focus, .alert-link:focus-visible, .c-prefers-focus .alert-link:focus,
.alert-link.btn-unstyled.focus,
.alert-link.btn-unstyled:focus-visible,
.c-prefers-focus .alert-link.btn-unstyled:focus {
  text-decoration: underline;
}
.alert-indicator {
  font-size: 1.25rem;
  line-height: 1;
  vertical-align: 8%;
}
.alert-indicator + .lead {
  margin-left: 0.3125rem;
}

.alert-dismissible {
  padding-right: 2.5rem;
}

.alert-fluid {
  border-radius: 0;
  border-width: 0 0 1px 0;
  margin-bottom: 0;
  padding: 0;
}
.alert-fluid.alert-dismissible .container,
.alert-fluid.alert-dismissible .container-fluid {
  padding-right: calc( 				2.5rem + 15px 			);
  position: relative;
}
.alert-fluid .close {
  right: calc(15px + 0.25rem);
}
.alert-fluid .container,
.alert-fluid .container-fluid {
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
}
.alert-container {
  height: 0;
  position: relative;
}

.alert-notifications .alert, .alert-notifications .portlet-msg-alert,
.alert-notifications .portlet-msg-error,
.alert-notifications .portlet-msg-help,
.alert-notifications .portlet-msg-info,
.alert-notifications .portlet-msg-progress,
.alert-notifications .portlet-msg-success {
  clear: both;
  float: left;
  margin-bottom: 1rem;
  max-width: 22.5rem;
}
.alert-notifications .alert:last-child, .alert-notifications .portlet-msg-alert:last-child,
.alert-notifications .portlet-msg-error:last-child,
.alert-notifications .portlet-msg-help:last-child,
.alert-notifications .portlet-msg-info:last-child,
.alert-notifications .portlet-msg-progress:last-child,
.alert-notifications .portlet-msg-success:last-child {
  margin-bottom: 0;
}
.alert-notifications .alert-fluid {
  padding-bottom: 0;
  padding-top: 0;
}

.alert-notifications-absolute {
  height: 0;
  position: absolute;
  right: 0.5rem;
  top: 4.75rem;
  z-index: 5000;
}

.alert-notifications-fixed {
  bottom: 1.25rem;
  left: 1.25rem;
  position: fixed;
  z-index: 5000;
}

.alert-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}
.alert-list .close {
  top: 50%;
  transform: translateY(-50%);
}

.modal-body .alert .close, .modal-body .portlet-msg-alert .close,
.modal-body .portlet-msg-error .close,
.modal-body .portlet-msg-help .close,
.modal-body .portlet-msg-info .close,
.modal-body .portlet-msg-progress .close,
.modal-body .portlet-msg-success .close {
  right: 1rem;
}
.modal-body .alert-list .alert-fluid:last-child {
  margin-bottom: -1px;
}

.autofit-row.alert-autofit-row {
  align-items: baseline;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: auto;
}
.autofit-row.alert-autofit-row > .autofit-col {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.alert-feedback.alert, .alert-feedback.portlet-msg-alert,
.alert-feedback.portlet-msg-error,
.alert-feedback.portlet-msg-help,
.alert-feedback.portlet-msg-info,
.alert-feedback.portlet-msg-progress,
.alert-feedback.portlet-msg-success {
  background-color: transparent;
  border-width: 0;
  margin-bottom: 0.25rem;
  padding: 0;
}
.alert-feedback.alert .alert-autofit-row, .alert-feedback.portlet-msg-alert .alert-autofit-row,
.alert-feedback.portlet-msg-error .alert-autofit-row,
.alert-feedback.portlet-msg-help .alert-autofit-row,
.alert-feedback.portlet-msg-info .alert-autofit-row,
.alert-feedback.portlet-msg-progress .alert-autofit-row,
.alert-feedback.portlet-msg-success .alert-autofit-row,
.alert-feedback.alert .autofit-row,
.alert-feedback.portlet-msg-alert .autofit-row,
.alert-feedback.portlet-msg-error .autofit-row,
.alert-feedback.portlet-msg-help .autofit-row,
.alert-feedback.portlet-msg-info .autofit-row,
.alert-feedback.portlet-msg-progress .autofit-row,
.alert-feedback.portlet-msg-success .autofit-row {
  display: inline;
  margin-left: 0;
  margin-right: 0;
}
.alert-feedback.alert .alert-autofit-row > .autofit-col, .alert-feedback.portlet-msg-alert .alert-autofit-row > .autofit-col,
.alert-feedback.portlet-msg-error .alert-autofit-row > .autofit-col,
.alert-feedback.portlet-msg-help .alert-autofit-row > .autofit-col,
.alert-feedback.portlet-msg-info .alert-autofit-row > .autofit-col,
.alert-feedback.portlet-msg-progress .alert-autofit-row > .autofit-col,
.alert-feedback.portlet-msg-success .alert-autofit-row > .autofit-col,
.alert-feedback.alert .autofit-row > .autofit-col,
.alert-feedback.portlet-msg-alert .autofit-row > .autofit-col,
.alert-feedback.portlet-msg-error .autofit-row > .autofit-col,
.alert-feedback.portlet-msg-help .autofit-row > .autofit-col,
.alert-feedback.portlet-msg-info .autofit-row > .autofit-col,
.alert-feedback.portlet-msg-progress .autofit-row > .autofit-col,
.alert-feedback.portlet-msg-success .autofit-row > .autofit-col {
  display: inline;
  padding-left: 0;
  padding-right: 0.25em;
}
.alert-feedback.alert .alert-autofit-row > .autofit-col > .autofit-section, .alert-feedback.portlet-msg-alert .alert-autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-error .alert-autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-help .alert-autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-info .alert-autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-progress .alert-autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-success .alert-autofit-row > .autofit-col > .autofit-section,
.alert-feedback.alert .autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-alert .autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-error .autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-help .autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-info .autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-progress .autofit-row > .autofit-col > .autofit-section,
.alert-feedback.portlet-msg-success .autofit-row > .autofit-col > .autofit-section {
  display: inline;
}
.alert-feedback.alert .close, .alert-feedback.portlet-msg-alert .close,
.alert-feedback.portlet-msg-error .close,
.alert-feedback.portlet-msg-help .close,
.alert-feedback.portlet-msg-info .close,
.alert-feedback.portlet-msg-progress .close,
.alert-feedback.portlet-msg-success .close {
  font-size: inherit;
  height: 1rem;
  position: relative;
  right: auto;
  top: 0.125em;
  width: 1rem;
}
.alert-feedback.alert .lead, .alert-feedback.portlet-msg-alert .lead,
.alert-feedback.portlet-msg-error .lead,
.alert-feedback.portlet-msg-help .lead,
.alert-feedback.portlet-msg-info .lead,
.alert-feedback.portlet-msg-progress .lead,
.alert-feedback.portlet-msg-success .lead {
  margin-right: 0;
}
.alert-feedback.alert .lead + .component-text, .alert-feedback.portlet-msg-alert .lead + .component-text,
.alert-feedback.portlet-msg-error .lead + .component-text,
.alert-feedback.portlet-msg-help .lead + .component-text,
.alert-feedback.portlet-msg-info .lead + .component-text,
.alert-feedback.portlet-msg-progress .lead + .component-text,
.alert-feedback.portlet-msg-success .lead + .component-text {
  margin-left: 0.25em;
}

.alert-inline.alert, .alert-inline.portlet-msg-alert,
.alert-inline.portlet-msg-error,
.alert-inline.portlet-msg-help,
.alert-inline.portlet-msg-info,
.alert-inline.portlet-msg-progress,
.alert-inline.portlet-msg-success {
  display: inline-block;
  max-width: 100%;
  width: auto;
}
.alert-inline.alert.alert-dismissible, .alert-inline.alert-dismissible.portlet-msg-alert,
.alert-inline.alert-dismissible.portlet-msg-error,
.alert-inline.alert-dismissible.portlet-msg-help,
.alert-inline.alert-dismissible.portlet-msg-info,
.alert-inline.alert-dismissible.portlet-msg-progress,
.alert-inline.alert-dismissible.portlet-msg-success {
  padding-right: 3.5rem;
}
.alert-inline.alert.alert-dismissible.alert-fluid, .alert-inline.alert-dismissible.alert-fluid.portlet-msg-alert,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-error,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-help,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-info,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-progress,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-success {
  padding-right: 0;
}
.alert-inline.alert.alert-dismissible.alert-fluid > .container, .alert-inline.alert-dismissible.alert-fluid.portlet-msg-alert > .container,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-error > .container,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-help > .container,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-info > .container,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-progress > .container,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-success > .container,
.alert-inline.alert.alert-dismissible.alert-fluid > .container-fluid,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-alert > .container-fluid,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-error > .container-fluid,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-help > .container-fluid,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-info > .container-fluid,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-progress > .container-fluid,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-success > .container-fluid {
  padding-right: 3.5rem;
}
.alert-inline.alert.alert-dismissible.alert-fluid > .container > .alert-autofit-row, .alert-inline.alert-dismissible.alert-fluid.portlet-msg-alert > .container > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-error > .container > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-help > .container > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-info > .container > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-progress > .container > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-success > .container > .alert-autofit-row,
.alert-inline.alert.alert-dismissible.alert-fluid > .container-fluid > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-alert > .container-fluid > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-error > .container-fluid > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-help > .container-fluid > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-info > .container-fluid > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-progress > .container-fluid > .alert-autofit-row,
.alert-inline.alert-dismissible.alert-fluid.portlet-msg-success > .container-fluid > .alert-autofit-row {
  align-items: center;
  margin-bottom: calc(-0.75rem - 1px);
  margin-top: calc(-0.75rem - 1px);
  padding-bottom: 0.5625rem;
  padding-top: 0.5625rem;
  min-height: 3.5rem;
}
.alert-inline.alert > .alert-autofit-row, .alert-inline.portlet-msg-alert > .alert-autofit-row,
.alert-inline.portlet-msg-error > .alert-autofit-row,
.alert-inline.portlet-msg-help > .alert-autofit-row,
.alert-inline.portlet-msg-info > .alert-autofit-row,
.alert-inline.portlet-msg-progress > .alert-autofit-row,
.alert-inline.portlet-msg-success > .alert-autofit-row {
  align-items: center;
  margin-bottom: calc(-0.75rem - 1px);
  margin-top: calc(-0.75rem - 1px);
  padding-bottom: 0.5625rem;
  padding-top: 0.5625rem;
  min-height: 3.5rem;
}
.alert-inline.alert > .alert-autofit-row .btn-group, .alert-inline.portlet-msg-alert > .alert-autofit-row .btn-group,
.alert-inline.portlet-msg-error > .alert-autofit-row .btn-group,
.alert-inline.portlet-msg-help > .alert-autofit-row .btn-group,
.alert-inline.portlet-msg-info > .alert-autofit-row .btn-group,
.alert-inline.portlet-msg-progress > .alert-autofit-row .btn-group,
.alert-inline.portlet-msg-success > .alert-autofit-row .btn-group {
  margin-right: 2rem;
}
.alert-inline.alert .close, .alert-inline.portlet-msg-alert .close,
.alert-inline.portlet-msg-error .close,
.alert-inline.portlet-msg-help .close,
.alert-inline.portlet-msg-info .close,
.alert-inline.portlet-msg-progress .close,
.alert-inline.portlet-msg-success .close {
  margin-top: -1rem;
  top: 50%;
}
.alert-autofit-stacked.alert > .alert-autofit-row, .alert-autofit-stacked.portlet-msg-alert > .alert-autofit-row,
.alert-autofit-stacked.portlet-msg-error > .alert-autofit-row,
.alert-autofit-stacked.portlet-msg-help > .alert-autofit-row,
.alert-autofit-stacked.portlet-msg-info > .alert-autofit-row,
.alert-autofit-stacked.portlet-msg-progress > .alert-autofit-row,
.alert-autofit-stacked.portlet-msg-success > .alert-autofit-row {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
  margin-top: 0;
  min-height: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.alert-autofit-stacked.alert > .alert-autofit-row > .autofit-col, .alert-autofit-stacked.portlet-msg-alert > .alert-autofit-row > .autofit-col,
.alert-autofit-stacked.portlet-msg-error > .alert-autofit-row > .autofit-col,
.alert-autofit-stacked.portlet-msg-help > .alert-autofit-row > .autofit-col,
.alert-autofit-stacked.portlet-msg-info > .alert-autofit-row > .autofit-col,
.alert-autofit-stacked.portlet-msg-progress > .alert-autofit-row > .autofit-col,
.alert-autofit-stacked.portlet-msg-success > .alert-autofit-row > .autofit-col {
  width: 100%;
}
.alert-autofit-stacked.alert > .alert-autofit-row .btn-group, .alert-autofit-stacked.portlet-msg-alert > .alert-autofit-row .btn-group,
.alert-autofit-stacked.portlet-msg-error > .alert-autofit-row .btn-group,
.alert-autofit-stacked.portlet-msg-help > .alert-autofit-row .btn-group,
.alert-autofit-stacked.portlet-msg-info > .alert-autofit-row .btn-group,
.alert-autofit-stacked.portlet-msg-progress > .alert-autofit-row .btn-group,
.alert-autofit-stacked.portlet-msg-success > .alert-autofit-row .btn-group {
  margin-right: 0;
  margin-top: 0.875rem;
}
.alert-autofit-stacked.alert .close, .alert-autofit-stacked.portlet-msg-alert .close,
.alert-autofit-stacked.portlet-msg-error .close,
.alert-autofit-stacked.portlet-msg-help .close,
.alert-autofit-stacked.portlet-msg-info .close,
.alert-autofit-stacked.portlet-msg-progress .close,
.alert-autofit-stacked.portlet-msg-success .close {
  margin-top: 0;
  top: 0.75rem;
}
@media (max-width: 767.98px) {
  .alert-autofit-stacked-sm-down.alert > .alert-autofit-row, .alert-autofit-stacked-sm-down.portlet-msg-alert > .alert-autofit-row,
  .alert-autofit-stacked-sm-down.portlet-msg-error > .alert-autofit-row,
  .alert-autofit-stacked-sm-down.portlet-msg-help > .alert-autofit-row,
  .alert-autofit-stacked-sm-down.portlet-msg-info > .alert-autofit-row,
  .alert-autofit-stacked-sm-down.portlet-msg-progress > .alert-autofit-row,
  .alert-autofit-stacked-sm-down.portlet-msg-success > .alert-autofit-row {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
    min-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width: 767.98px) {
  .alert-autofit-stacked-sm-down.alert > .alert-autofit-row > .autofit-col, .alert-autofit-stacked-sm-down.portlet-msg-alert > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-sm-down.portlet-msg-error > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-sm-down.portlet-msg-help > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-sm-down.portlet-msg-info > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-sm-down.portlet-msg-progress > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-sm-down.portlet-msg-success > .alert-autofit-row > .autofit-col {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .alert-autofit-stacked-sm-down.alert > .alert-autofit-row .btn-group, .alert-autofit-stacked-sm-down.portlet-msg-alert > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-sm-down.portlet-msg-error > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-sm-down.portlet-msg-help > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-sm-down.portlet-msg-info > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-sm-down.portlet-msg-progress > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-sm-down.portlet-msg-success > .alert-autofit-row .btn-group {
    margin-right: 0;
    margin-top: 0.875rem;
  }
}
@media (max-width: 767.98px) {
  .alert-autofit-stacked-sm-down.alert .close, .alert-autofit-stacked-sm-down.portlet-msg-alert .close,
  .alert-autofit-stacked-sm-down.portlet-msg-error .close,
  .alert-autofit-stacked-sm-down.portlet-msg-help .close,
  .alert-autofit-stacked-sm-down.portlet-msg-info .close,
  .alert-autofit-stacked-sm-down.portlet-msg-progress .close,
  .alert-autofit-stacked-sm-down.portlet-msg-success .close {
    margin-top: 0;
    top: 0.75rem;
  }
}

@media (max-width: 575.98px) {
  .alert-autofit-stacked-xs-down.alert > .alert-autofit-row, .alert-autofit-stacked-xs-down.portlet-msg-alert > .alert-autofit-row,
  .alert-autofit-stacked-xs-down.portlet-msg-error > .alert-autofit-row,
  .alert-autofit-stacked-xs-down.portlet-msg-help > .alert-autofit-row,
  .alert-autofit-stacked-xs-down.portlet-msg-info > .alert-autofit-row,
  .alert-autofit-stacked-xs-down.portlet-msg-progress > .alert-autofit-row,
  .alert-autofit-stacked-xs-down.portlet-msg-success > .alert-autofit-row {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
    min-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  .alert-autofit-stacked-xs-down.alert > .alert-autofit-row > .autofit-col, .alert-autofit-stacked-xs-down.portlet-msg-alert > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-xs-down.portlet-msg-error > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-xs-down.portlet-msg-help > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-xs-down.portlet-msg-info > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-xs-down.portlet-msg-progress > .alert-autofit-row > .autofit-col,
  .alert-autofit-stacked-xs-down.portlet-msg-success > .alert-autofit-row > .autofit-col {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .alert-autofit-stacked-xs-down.alert > .alert-autofit-row .btn-group, .alert-autofit-stacked-xs-down.portlet-msg-alert > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-xs-down.portlet-msg-error > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-xs-down.portlet-msg-help > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-xs-down.portlet-msg-info > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-xs-down.portlet-msg-progress > .alert-autofit-row .btn-group,
  .alert-autofit-stacked-xs-down.portlet-msg-success > .alert-autofit-row .btn-group {
    margin-right: 0;
    margin-top: 0.875rem;
  }
}
@media (max-width: 575.98px) {
  .alert-autofit-stacked-xs-down.alert .close, .alert-autofit-stacked-xs-down.portlet-msg-alert .close,
  .alert-autofit-stacked-xs-down.portlet-msg-error .close,
  .alert-autofit-stacked-xs-down.portlet-msg-help .close,
  .alert-autofit-stacked-xs-down.portlet-msg-info .close,
  .alert-autofit-stacked-xs-down.portlet-msg-progress .close,
  .alert-autofit-stacked-xs-down.portlet-msg-success .close {
    margin-top: 0;
    top: 0.75rem;
  }
}

.alert-indicator-start.alert, .alert-indicator-start.portlet-msg-alert,
.alert-indicator-start.portlet-msg-error,
.alert-indicator-start.portlet-msg-help,
.alert-indicator-start.portlet-msg-info,
.alert-indicator-start.portlet-msg-progress,
.alert-indicator-start.portlet-msg-success {
  padding-left: calc( 				1.25rem + 1.25rem + 0.5rem 			);
}
.alert-indicator-start.alert.alert-fluid, .alert-indicator-start.alert-fluid.portlet-msg-alert,
.alert-indicator-start.alert-fluid.portlet-msg-error,
.alert-indicator-start.alert-fluid.portlet-msg-help,
.alert-indicator-start.alert-fluid.portlet-msg-info,
.alert-indicator-start.alert-fluid.portlet-msg-progress,
.alert-indicator-start.alert-fluid.portlet-msg-success {
  padding-left: 0;
}
.alert-indicator-start.alert.alert-fluid > .container, .alert-indicator-start.alert-fluid.portlet-msg-alert > .container,
.alert-indicator-start.alert-fluid.portlet-msg-error > .container,
.alert-indicator-start.alert-fluid.portlet-msg-help > .container,
.alert-indicator-start.alert-fluid.portlet-msg-info > .container,
.alert-indicator-start.alert-fluid.portlet-msg-progress > .container,
.alert-indicator-start.alert-fluid.portlet-msg-success > .container,
.alert-indicator-start.alert.alert-fluid > .container-fluid,
.alert-indicator-start.alert-fluid.portlet-msg-alert > .container-fluid,
.alert-indicator-start.alert-fluid.portlet-msg-error > .container-fluid,
.alert-indicator-start.alert-fluid.portlet-msg-help > .container-fluid,
.alert-indicator-start.alert-fluid.portlet-msg-info > .container-fluid,
.alert-indicator-start.alert-fluid.portlet-msg-progress > .container-fluid,
.alert-indicator-start.alert-fluid.portlet-msg-success > .container-fluid {
  padding-left: calc( 						1.25rem + 1.25rem + 0.5rem 					);
}
.alert-indicator-start.alert.alert-feedback, .alert-indicator-start.alert-feedback.portlet-msg-alert,
.alert-indicator-start.alert-feedback.portlet-msg-error,
.alert-indicator-start.alert-feedback.portlet-msg-help,
.alert-indicator-start.alert-feedback.portlet-msg-info,
.alert-indicator-start.alert-feedback.portlet-msg-progress,
.alert-indicator-start.alert-feedback.portlet-msg-success {
  padding-left: calc( 					1.25rem + 0.5rem 				);
}
.alert-indicator-start.alert .alert-indicator, .alert-indicator-start.portlet-msg-alert .alert-indicator,
.alert-indicator-start.portlet-msg-error .alert-indicator,
.alert-indicator-start.portlet-msg-help .alert-indicator,
.alert-indicator-start.portlet-msg-info .alert-indicator,
.alert-indicator-start.portlet-msg-progress .alert-indicator,
.alert-indicator-start.portlet-msg-success .alert-indicator {
  line-height: 1;
  margin-left: calc( 					-1em - 0.5rem 				);
  margin-top: 4px;
  position: absolute;
}
.alert-indicator-start.alert .alert-indicator + .lead, .alert-indicator-start.portlet-msg-alert .alert-indicator + .lead,
.alert-indicator-start.portlet-msg-error .alert-indicator + .lead,
.alert-indicator-start.portlet-msg-help .alert-indicator + .lead,
.alert-indicator-start.portlet-msg-info .alert-indicator + .lead,
.alert-indicator-start.portlet-msg-progress .alert-indicator + .lead,
.alert-indicator-start.portlet-msg-success .alert-indicator + .lead {
  margin-left: 0;
}
.alert-indicator-start.alert .alert-autofit-row .alert-indicator:only-child, .alert-indicator-start.portlet-msg-alert .alert-autofit-row .alert-indicator:only-child,
.alert-indicator-start.portlet-msg-error .alert-autofit-row .alert-indicator:only-child,
.alert-indicator-start.portlet-msg-help .alert-autofit-row .alert-indicator:only-child,
.alert-indicator-start.portlet-msg-info .alert-autofit-row .alert-indicator:only-child,
.alert-indicator-start.portlet-msg-progress .alert-autofit-row .alert-indicator:only-child,
.alert-indicator-start.portlet-msg-success .alert-autofit-row .alert-indicator:only-child {
  margin-right: 0;
}

.alert-primary {
  background-color: #cedfff;
  border-color: #bbd2ff;
  color: #063185;
}
.alert-primary hr {
  border-top-color: #a2c1ff;
}
.alert-primary .alert-btn {
  background-color: #fff;
  border-color: #0b5fff;
  color: #0b5fff;
}
.alert-primary .alert-btn:hover {
  background-color: #0b5fff;
  color: #fff;
}
.alert-primary .alert-btn:active {
  background-color: #0053f1;
  border-color: #0053f1;
}
.alert-primary .alert-btn.active {
  background-color: #0053f1;
  border-color: #0053f1;
}
.alert-primary .alert-btn[aria-expanded=true], .alert-primary .alert-btn.show {
  background-color: #0053f1;
  border-color: #0053f1;
}
.alert-primary .alert-link {
  color: #041f54;
}
.alert-secondary {
  background-color: #e1e2e5;
  border-color: #d6d6db;
  color: #383842;
}
.alert-secondary .alert-btn {
  background-color: #fff;
  border-color: #6b6c7e;
  color: #6b6c7e;
}
.alert-secondary .alert-btn:hover {
  background-color: #6b6c7e;
  color: #fff;
}
.alert-secondary .alert-btn:active {
  background-color: #5f6070;
  border-color: #5f6070;
}
.alert-secondary .alert-btn.active {
  background-color: #5f6070;
  border-color: #5f6070;
}
.alert-secondary .alert-btn[aria-expanded=true], .alert-secondary .alert-btn.show {
  background-color: #5f6070;
  border-color: #5f6070;
}
.alert-secondary .alert-link {
  color: #212126;
}
.alert-success, .portlet-msg-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
.alert-success .alert-btn, .portlet-msg-success .alert-btn {
  background-color: #fff;
  border-color: #28a745;
  color: #28a745;
}
.alert-success .alert-btn:hover, .portlet-msg-success .alert-btn:hover {
  background-color: #28a745;
  color: #fff;
}
.alert-success .alert-btn:active, .portlet-msg-success .alert-btn:active {
  background-color: #23923d;
  border-color: #23923d;
}
.alert-success .alert-btn.active, .portlet-msg-success .alert-btn.active {
  background-color: #23923d;
  border-color: #23923d;
}
.alert-success .alert-btn[aria-expanded=true], .portlet-msg-success .alert-btn[aria-expanded=true], .alert-success .alert-btn.show, .portlet-msg-success .alert-btn.show {
  background-color: #23923d;
  border-color: #23923d;
}
.alert-success .alert-link, .portlet-msg-success .alert-link {
  color: #0b2e13;
}
.alert-info, .portlet-msg-help,
.portlet-msg-info,
.portlet-msg-progress {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}
.alert-info .alert-btn, .portlet-msg-help .alert-btn,
.portlet-msg-info .alert-btn,
.portlet-msg-progress .alert-btn {
  background-color: #fff;
  border-color: #17a2b8;
  color: #17a2b8;
}
.alert-info .alert-btn:hover, .portlet-msg-help .alert-btn:hover,
.portlet-msg-info .alert-btn:hover,
.portlet-msg-progress .alert-btn:hover {
  background-color: #17a2b8;
  color: #fff;
}
.alert-info .alert-btn:active, .portlet-msg-help .alert-btn:active,
.portlet-msg-info .alert-btn:active,
.portlet-msg-progress .alert-btn:active {
  background-color: #148ea1;
  border-color: #148ea1;
}
.alert-info .alert-btn.active, .portlet-msg-help .alert-btn.active,
.portlet-msg-info .alert-btn.active,
.portlet-msg-progress .alert-btn.active {
  background-color: #148ea1;
  border-color: #148ea1;
}
.alert-info .alert-btn[aria-expanded=true], .portlet-msg-help .alert-btn[aria-expanded=true],
.portlet-msg-info .alert-btn[aria-expanded=true],
.portlet-msg-progress .alert-btn[aria-expanded=true], .alert-info .alert-btn.show, .portlet-msg-help .alert-btn.show,
.portlet-msg-info .alert-btn.show,
.portlet-msg-progress .alert-btn.show {
  background-color: #148ea1;
  border-color: #148ea1;
}
.alert-warning, .portlet-msg-alert {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}
.alert-warning .alert-btn, .portlet-msg-alert .alert-btn {
  background-color: #fff;
  border-color: #ffc107;
  color: #ffc107;
}
.alert-warning .alert-btn:hover, .portlet-msg-alert .alert-btn:hover {
  background-color: #ffc107;
  color: #272833;
}
.alert-warning .alert-btn:active, .portlet-msg-alert .alert-btn:active {
  background-color: #edb100;
  border-color: #edb100;
}
.alert-warning .alert-btn.active, .portlet-msg-alert .alert-btn.active {
  background-color: #edb100;
  border-color: #edb100;
}
.alert-warning .alert-btn[aria-expanded=true], .portlet-msg-alert .alert-btn[aria-expanded=true], .alert-warning .alert-btn.show, .portlet-msg-alert .alert-btn.show {
  background-color: #edb100;
  border-color: #edb100;
}
.alert-danger, .portlet-msg-error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
.alert-danger .alert-btn, .portlet-msg-error .alert-btn {
  background-color: #fff;
  border-color: #dc3545;
  color: #dc3545;
}
.alert-danger .alert-btn:hover, .portlet-msg-error .alert-btn:hover {
  background-color: #dc3545;
  color: #fff;
}
.alert-danger .alert-btn:active, .portlet-msg-error .alert-btn:active {
  background-color: #d32535;
  border-color: #d32535;
}
.alert-danger .alert-btn.active, .portlet-msg-error .alert-btn.active {
  background-color: #d32535;
  border-color: #d32535;
}
.alert-danger .alert-btn[aria-expanded=true], .portlet-msg-error .alert-btn[aria-expanded=true], .alert-danger .alert-btn.show, .portlet-msg-error .alert-btn.show {
  background-color: #d32535;
  border-color: #d32535;
}
.alert-light {
  background-color: #fdfefe;
  border-color: #fdfdfd;
  color: #808181;
}
.alert-light .alert-btn {
  background-color: #fff;
  border-color: #6b6c7e;
  color: #6b6c7e;
}
.alert-light .alert-btn:hover {
  background-color: #6b6c7e;
  color: #fff;
}
.alert-light .alert-btn:active {
  background-color: #5f6070;
  border-color: #5f6070;
}
.alert-light .alert-btn.active {
  background-color: #5f6070;
  border-color: #5f6070;
}
.alert-light .alert-btn[aria-expanded=true], .alert-light .alert-btn.show {
  background-color: #5f6070;
  border-color: #5f6070;
}
.alert-dark {
  background-color: #d6d8d9;
  border-color: #c6c8ca;
  color: #1b1e21;
}
.alert-dark .alert-btn {
  background-color: #fff;
  border-color: #343a40;
  color: #343a40;
}
.alert-dark .alert-btn:hover {
  background-color: #343a40;
  color: #fff;
}
.alert-dark .alert-btn:active {
  background-color: #292d32;
  border-color: #292d32;
}
.alert-dark .alert-btn.active {
  background-color: #292d32;
  border-color: #292d32;
}
.alert-dark .alert-btn[aria-expanded=true], .alert-dark .alert-btn.show {
  background-color: #292d32;
  border-color: #292d32;
}
.badge {
  border-color: transparent;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  display: inline-flex;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
  margin-top: 0.125rem;
  max-width: 100%;
  padding-bottom: 0.25em;
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.25em;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
.c-prefers-reduced-motion .badge {
  transition: none;
}

.badge:empty {
  display: none;
}
.badge[href]:hover, .badge[href].hover, .badge[type]:hover, .badge[type].hover {
  text-decoration: none;
}
.badge[href].focus, .badge[href]:focus-visible, .c-prefers-focus .badge[href]:focus, .badge[type].focus, .badge[type]:focus-visible, .c-prefers-focus .badge[type]:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.badge a {
  color: #fff;
  text-decoration: underline;
}
.badge a:hover, .badge a.hover {
  text-decoration: none;
}
.badge a.focus, .badge a:focus-visible, .c-prefers-focus .badge a:focus {
  text-decoration: none;
}
.badge > .c-inner {
  margin-bottom: -0.25em;
  margin-left: -0.4em;
  margin-right: -0.4em;
  margin-top: -0.25em;
  max-width: none;
}
.badge .inline-item a {
  align-items: center;
  display: inline-flex;
  margin-top: 0;
}
.badge .inline-item .btn-unstyled {
  color: inherit;
  display: inline-flex;
  margin-top: 0;
  max-width: none;
}
.badge .inline-item .close {
  color: inherit;
  display: inline-flex;
  float: none;
  font-size: inherit;
  margin-top: 0;
  opacity: 1;
}
.badge .inline-item a .lexicon-icon,
.badge .inline-item .btn-unstyled .lexicon-icon,
.badge .inline-item .close .lexicon-icon {
  margin-top: 0;
}
.badge .inline-item .lexicon-icon {
  height: 0.875em;
  margin-top: 0;
  width: 0.875em;
}

.badge-item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  min-height: 0;
  position: relative;
  text-align: left;
}
.badge-item a {
  display: inline-flex;
}
.badge-item .btn-unstyled {
  color: inherit;
  display: inline-flex;
}
.badge-item .close {
  border-radius: 1px;
  color: inherit;
  display: inline-flex;
  font-size: inherit;
  height: auto;
  opacity: 1;
  width: auto;
}
.badge-item .close:hover {
  color: inherit;
  opacity: 1;
}
.badge-item .close.focus, .badge-item .close:focus-visible, .c-prefers-focus .badge-item .close:focus {
  opacity: 1;
}

.badge-item .lexicon-icon {
  height: 0.875em;
  margin-top: 0;
  width: 0.875em;
}

.badge-item-expand {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0.375rem;
  word-wrap: break-word;
}
.badge-item-expand a {
  flex-direction: column;
}

.badge-item-before {
  margin-right: 0.5em;
}

.badge-item-after {
  margin-left: 0.5em;
}

.btn .badge {
  margin-right: 0;
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: 0.6em;
  padding-right: 0.6em;
}
.badge-primary {
  background-color: #0b5fff;
  color: #fff;
}
[href].badge-primary:hover, [href].hover.badge-primary, [type].badge-primary:hover, [type].hover.badge-primary {
  background-color: #004ad7;
  color: #fff;
}
[href].focus.badge-primary, [href].badge-primary:focus-visible, .c-prefers-focus [href].badge-primary:focus, [type].focus.badge-primary, [type].badge-primary:focus-visible, .c-prefers-focus [type].badge-primary:focus {
  background-color: #004ad7;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.5);
  color: #fff;
}
.badge-secondary {
  background-color: #6b6c7e;
  color: #fff;
}
[href].badge-secondary:hover, [href].hover.badge-secondary, [type].badge-secondary:hover, [type].hover.badge-secondary {
  background-color: #545462;
  color: #fff;
}
[href].focus.badge-secondary, [href].badge-secondary:focus-visible, .c-prefers-focus [href].badge-secondary:focus, [type].focus.badge-secondary, [type].badge-secondary:focus-visible, .c-prefers-focus [type].badge-secondary:focus {
  background-color: #545462;
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(107, 108, 126, 0.5);
}
.badge-success {
  background-color: #28a745;
  color: #fff;
}
[href].badge-success:hover, [href].hover.badge-success, [type].badge-success:hover, [type].hover.badge-success {
  background-color: #1e7e34;
  color: #fff;
}
[href].focus.badge-success, [href].badge-success:focus-visible, .c-prefers-focus [href].badge-success:focus, [type].focus.badge-success, [type].badge-success:focus-visible, .c-prefers-focus [type].badge-success:focus {
  background-color: #1e7e34;
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  background-color: #17a2b8;
  color: #fff;
}
[href].badge-info:hover, [href].hover.badge-info, [type].badge-info:hover, [type].hover.badge-info {
  background-color: #117a8b;
  color: #fff;
}
[href].focus.badge-info, [href].badge-info:focus-visible, .c-prefers-focus [href].badge-info:focus, [type].focus.badge-info, [type].badge-info:focus-visible, .c-prefers-focus [type].badge-info:focus {
  background-color: #117a8b;
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  background-color: #ffc107;
  color: #272833;
}
[href].badge-warning:hover, [href].hover.badge-warning, [type].badge-warning:hover, [type].hover.badge-warning {
  background-color: #d39e00;
  color: #272833;
}
[href].focus.badge-warning, [href].badge-warning:focus-visible, .c-prefers-focus [href].badge-warning:focus, [type].focus.badge-warning, [type].badge-warning:focus-visible, .c-prefers-focus [type].badge-warning:focus {
  background-color: #d39e00;
  color: #272833;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  background-color: #dc3545;
  color: #fff;
}
[href].badge-danger:hover, [href].hover.badge-danger, [type].badge-danger:hover, [type].hover.badge-danger {
  background-color: #bd2130;
  color: #fff;
}
[href].focus.badge-danger, [href].badge-danger:focus-visible, .c-prefers-focus [href].badge-danger:focus, [type].focus.badge-danger, [type].badge-danger:focus-visible, .c-prefers-focus [type].badge-danger:focus {
  background-color: #bd2130;
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
  background-color: #f7f8f9;
  color: #272833;
}
[href].badge-light:hover, [href].hover.badge-light, [type].badge-light:hover, [type].hover.badge-light {
  background-color: #dadfe3;
  color: #272833;
}
[href].focus.badge-light, [href].badge-light:focus-visible, .c-prefers-focus [href].badge-light:focus, [type].focus.badge-light, [type].badge-light:focus-visible, .c-prefers-focus [type].badge-light:focus {
  background-color: #dadfe3;
  color: #272833;
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}
.badge-dark {
  background-color: #343a40;
  color: #fff;
}
[href].badge-dark:hover, [href].hover.badge-dark, [type].badge-dark:hover, [type].hover.badge-dark {
  background-color: #1d2124;
  color: #fff;
}
[href].focus.badge-dark, [href].badge-dark:focus-visible, .c-prefers-focus [href].badge-dark:focus, [type].focus.badge-dark, [type].badge-dark:focus-visible, .c-prefers-focus [type].badge-dark:focus {
  background-color: #1d2124;
  color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.badge-translucent.badge-primary {
  background-color: rgba(0, 83, 240, 0.04);
  border-color: transparent;
  color: #0053f0;
}
.badge-translucent.badge-info, .badge-beta {
  background-color: rgba(19, 132, 150, 0.04);
  border-color: transparent;
  color: #138496;
}
.badge-translucent.badge-success {
  background-color: rgba(33, 136, 56, 0.04);
  border-color: transparent;
  color: #218838;
}
.badge-translucent.badge-warning {
  background-color: rgba(224, 168, 0, 0.04);
  border-color: transparent;
  color: #e0a800;
}
.badge-translucent.badge-danger {
  background-color: rgba(200, 35, 51, 0.04);
  border-color: transparent;
  color: #c82333;
}
.clay-dark.badge-translucent.badge-primary, .clay-dark .badge-translucent.badge-primary {
  background-color: rgba(179, 205, 255, 0.04);
  border-color: transparent;
  color: #80acff;
}
.clay-dark.badge-translucent.badge-info, .clay-dark .badge-translucent.badge-info, .badge-beta-dark {
  background-color: rgba(135, 226, 240, 0.04);
  border-color: transparent;
  color: #5ad7ea;
}
.clay-dark.badge-translucent.badge-success, .clay-dark .badge-translucent.badge-success {
  background-color: rgba(146, 229, 165, 0.04);
  border-color: transparent;
  color: #69db83;
}
.clay-dark.badge-translucent.badge-warning, .clay-dark .badge-translucent.badge-warning {
  background-color: rgba(255, 235, 175, 0.04);
  border-color: transparent;
  color: #ffde7c;
}
.clay-dark.badge-translucent.badge-danger, .clay-dark .badge-translucent.badge-danger {
  background-color: rgba(245, 196, 201, 0.04);
  border-color: transparent;
  color: #ed99a1;
}
.breadcrumb {
  background-color: #f1f2f5;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
}

.breadcrumb-link {
  color: #0b5fff;
  display: block;
  text-decoration: none;
}
.breadcrumb-link:hover, .breadcrumb-link.hover {
  color: #0041be;
  text-decoration: underline;
}
.breadcrumb-link.focus, .breadcrumb-link:focus-visible, .c-prefers-focus .breadcrumb-link:focus {
  color: #0041be;
  text-decoration: underline;
}
.breadcrumb-toggle {
  color: #0b5fff;
}
.breadcrumb-item {
  margin-right: 0.5em;
  position: relative;
}
.breadcrumb-item.active,
.breadcrumb-item .active {
  color: #6c757d;
}
.breadcrumb-item.active .breadcrumb-link,
.breadcrumb-item .active .breadcrumb-link {
  color: inherit;
}
.breadcrumb-item > span {
  text-transform: inherit;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 1em;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #6c757d;
  display: block;
  float: left;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline'%20d='M375.2%20239.2%20173.3%2037c-23.6-23-59.9%2011.9-36%2035.1l183%20183.9-182.9%20183.8c-24%2023.5%2012.5%2058.2%2036.1%2035.2l201.7-202.1c10.2-10.1%209.3-24.4%200-33.7z'%20fill='%236c757d'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  height: 0.75em;
  left: 0;
  margin-top: calc(-0.75em / 2);
  padding: 0;
  position: absolute;
  top: 50%;
  width: 0.75em;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item .dropdown-toggle {
  text-decoration: none;
}
.breadcrumb-item .dropdown-toggle:hover {
  text-decoration: none;
}
.breadcrumb-item .dropdown-toggle.focus, .breadcrumb-item .dropdown-toggle:focus-visible, .c-prefers-focus .breadcrumb-item .dropdown-toggle:focus {
  text-decoration: none;
}
.breadcrumb-text-truncate {
  display: inline-block;
  max-width: 17.875rem;
  overflow: hidden;
  text-decoration: inherit;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus {
  z-index: 3;
}
.btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.0625rem;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group {
  flex-wrap: wrap;
}

.btn-group-spaced {
  align-items: center;
  column-gap: 0.5rem;
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
}

.btn-group-nowrap {
  flex-wrap: nowrap;
}

.btn-group-item {
  align-items: center;
  display: inline-flex;
  margin-right: 0.5rem;
}

.btn-group-item:last-child,
.btn-group-item-last {
  margin-right: 0;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
}
.btn-toolbar .btn-group:not(:last-child),
.btn-toolbar .input-group:not(:last-child) {
  margin-right: 0.5rem;
}
.btn-toolbar .input-group {
  width: auto;
}

.dropdown-toggle-split {
  padding-left: calc(0.75rem * 0.75);
  padding-right: calc(0.75rem * 0.75);
}

.btn-sm + .dropdown-toggle-split {
  padding-right: calc(0.5rem * 0.75);
  padding-left: calc(0.5rem * 0.75);
}

.btn-lg + .dropdown-toggle-split {
  padding-right: calc(1rem * 0.75);
  padding-left: calc(1rem * 0.75);
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.0625rem;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-vertical > .btn-monospaced {
  width: 2.375rem;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.c-empty-state-animation {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 5rem auto 1.5rem;
  max-width: 340px;
  text-align: center;
}

.c-empty-state-image {
  margin-bottom: -0.5rem;
  max-width: 250px;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.c-empty-state-aspect-ratio {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.c-empty-state-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 2.5rem;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.c-empty-state-text {
  color: #6b6c7e;
  margin-bottom: 0;
  margin-top: 0.5rem;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.c-empty-state-footer {
  margin-top: 1rem;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.c-empty-state-sm.c-empty-state-animation {
  margin: 2.5rem auto 2rem;
  max-width: 268px;
}
.c-empty-state-sm .c-empty-state-image {
  max-width: 120px;
}
.c-empty-state-sm .c-empty-state-title {
  font-size: 1rem;
  line-height: 1.5;
}
.c-empty-state-sm .c-empty-state-text {
  font-size: 0.875rem;
}
.label {
  border-color: #272833;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.0625rem;
  display: inline-flex;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
  margin-top: 0.125rem;
  max-width: 100%;
  padding-bottom: 0.1875rem;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
  padding-top: 0.1875rem;
  white-space: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.label[href], .label[type], .label[tabindex] {
  cursor: pointer;
  text-decoration: none;
}
.label a,
.label .btn-unstyled {
  color: inherit;
  display: inline-block;
  text-decoration: underline;
}
.label a:hover, .label a.hover,
.label .btn-unstyled:hover,
.label .btn-unstyled.hover {
  color: inherit;
  text-decoration: none;
}
.label a.focus, .label a:focus-visible, .c-prefers-focus .label a:focus,
.label .btn-unstyled.focus,
.label .btn-unstyled:focus-visible,
.c-prefers-focus .label .btn-unstyled:focus {
  color: inherit;
  text-decoration: none;
}
.label .sticker {
  border-radius: 100px;
  height: 0.875em;
  line-height: 0.875em;
  width: 0.875em;
}
.label .sticker-overlay {
  border-radius: 100px;
}
.label > .c-inner {
  margin-bottom: -0.1875rem;
  margin-left: -0.4375rem;
  margin-right: -0.4375rem;
  margin-top: -0.1875rem;
  max-width: none;
}
.label .inline-item a {
  align-items: center;
  display: inline-flex;
  margin-top: 0;
}
.label .inline-item .btn-unstyled {
  color: inherit;
  display: inline-flex;
  margin-top: 0;
  max-width: none;
}
.label .inline-item .close {
  color: inherit;
  display: inline-flex;
  float: none;
  font-size: inherit;
  margin-top: 0;
  opacity: 1;
}
.label .inline-item a .lexicon-icon,
.label .inline-item .btn-unstyled .lexicon-icon,
.label .inline-item .close .lexicon-icon {
  margin-top: 0;
}
.label .inline-item .lexicon-icon {
  margin-top: 0;
}

.label-item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  min-height: 0;
  position: relative;
}
.label-item a {
  display: inline-flex;
}
.label-item .btn-unstyled {
  color: inherit;
  display: inline-flex;
}
.label-item .close {
  border-radius: 1px;
  color: inherit;
  display: inline-flex;
  font-size: 1rem;
  height: auto;
  margin-bottom: -2px;
  margin-top: -2px;
  opacity: 1;
  width: auto;
}
.label-item .close:hover {
  color: inherit;
  opacity: 1;
}
.label-item .close.focus, .label-item .close:focus-visible, .c-prefers-focus .label-item .close:focus {
  opacity: 1;
}

.label-item .close:disabled, .label-item .close.disabled {
  opacity: 0.65;
}
.label-item .lexicon-icon {
  margin-top: 0;
}
.label-item .text-truncate-inline {
  display: inline-flex;
}

.label-item-expand {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.label-item-expand a {
  flex-direction: column;
}
.label-item-before {
  margin-right: 0.5em;
}

.label-item-after {
  margin-left: 0.5em;
}

.label-lg {
  font-size: 0.875rem;
  height: auto;
  padding-bottom: 0.375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.375rem;
  text-transform: none;
}
.label-lg > .c-inner {
  margin-bottom: -0.375rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.375rem;
}

.label-primary {
  background-color: #fff;
  border-color: #0b5fff;
  color: #0b5fff;
}
[href].label-primary:hover, [href].hover.label-primary, [type].label-primary:hover, [type].hover.label-primary, [tabindex].label-primary:hover, [tabindex].hover.label-primary {
  border-color: #004ad7;
  color: #004ad7;
}
[href].focus.label-primary, [href].label-primary:focus-visible, .c-prefers-focus [href].label-primary:focus, [type].focus.label-primary, [type].label-primary:focus-visible, .c-prefers-focus [type].label-primary:focus, [tabindex].focus.label-primary, [tabindex].label-primary:focus-visible, .c-prefers-focus [tabindex].label-primary:focus {
  color: #004ad7;
}
.label-primary a:hover, .label-primary a.hover,
.label-primary .btn-unstyled:hover,
.label-primary .btn-unstyled.hover {
  color: #004ad7;
}
.label-primary .close:hover {
  color: #004ad7;
}
.label-primary .close.focus, .label-primary .close:focus-visible, .c-prefers-focus .label-primary .close:focus {
  color: #004ad7;
}

.label-secondary {
  background-color: #fff;
  border-color: #6b6c7e;
  color: #6b6c7e;
}
[href].label-secondary:hover, [href].hover.label-secondary, [type].label-secondary:hover, [type].hover.label-secondary, [tabindex].label-secondary:hover, [tabindex].hover.label-secondary {
  border-color: #545462;
  color: #545462;
}
[href].focus.label-secondary, [href].label-secondary:focus-visible, .c-prefers-focus [href].label-secondary:focus, [type].focus.label-secondary, [type].label-secondary:focus-visible, .c-prefers-focus [type].label-secondary:focus, [tabindex].focus.label-secondary, [tabindex].label-secondary:focus-visible, .c-prefers-focus [tabindex].label-secondary:focus {
  color: #545462;
}
.label-secondary a:hover, .label-secondary a.hover,
.label-secondary .btn-unstyled:hover,
.label-secondary .btn-unstyled.hover {
  color: #545462;
}
.label-secondary .close:hover {
  color: #545462;
}
.label-secondary .close.focus, .label-secondary .close:focus-visible, .c-prefers-focus .label-secondary .close:focus {
  color: #545462;
}

.label-success {
  background-color: #fff;
  border-color: #28a745;
  color: #28a745;
}
[href].label-success:hover, [href].hover.label-success, [type].label-success:hover, [type].hover.label-success, [tabindex].label-success:hover, [tabindex].hover.label-success {
  border-color: #1e7e34;
  color: #1e7e34;
}
[href].focus.label-success, [href].label-success:focus-visible, .c-prefers-focus [href].label-success:focus, [type].focus.label-success, [type].label-success:focus-visible, .c-prefers-focus [type].label-success:focus, [tabindex].focus.label-success, [tabindex].label-success:focus-visible, .c-prefers-focus [tabindex].label-success:focus {
  color: #1e7e34;
}
.label-success a:hover, .label-success a.hover,
.label-success .btn-unstyled:hover,
.label-success .btn-unstyled.hover {
  color: #1e7e34;
}
.label-success .close:hover {
  color: #1e7e34;
}
.label-success .close.focus, .label-success .close:focus-visible, .c-prefers-focus .label-success .close:focus {
  color: #1e7e34;
}

.label-info {
  background-color: #fff;
  border-color: #17a2b8;
  color: #17a2b8;
}
[href].label-info:hover, [href].hover.label-info, [type].label-info:hover, [type].hover.label-info, [tabindex].label-info:hover, [tabindex].hover.label-info {
  border-color: #117a8b;
  color: #117a8b;
}
[href].focus.label-info, [href].label-info:focus-visible, .c-prefers-focus [href].label-info:focus, [type].focus.label-info, [type].label-info:focus-visible, .c-prefers-focus [type].label-info:focus, [tabindex].focus.label-info, [tabindex].label-info:focus-visible, .c-prefers-focus [tabindex].label-info:focus {
  color: #117a8b;
}
.label-info a:hover, .label-info a.hover,
.label-info .btn-unstyled:hover,
.label-info .btn-unstyled.hover {
  color: #117a8b;
}
.label-info .close:hover {
  color: #117a8b;
}
.label-info .close.focus, .label-info .close:focus-visible, .c-prefers-focus .label-info .close:focus {
  color: #117a8b;
}

.label-warning {
  background-color: #fff;
  border-color: #ffc107;
  color: #ffc107;
}
[href].label-warning:hover, [href].hover.label-warning, [type].label-warning:hover, [type].hover.label-warning, [tabindex].label-warning:hover, [tabindex].hover.label-warning {
  border-color: #d39e00;
  color: #d39e00;
}
[href].focus.label-warning, [href].label-warning:focus-visible, .c-prefers-focus [href].label-warning:focus, [type].focus.label-warning, [type].label-warning:focus-visible, .c-prefers-focus [type].label-warning:focus, [tabindex].focus.label-warning, [tabindex].label-warning:focus-visible, .c-prefers-focus [tabindex].label-warning:focus {
  color: #d39e00;
}
.label-warning a:hover, .label-warning a.hover,
.label-warning .btn-unstyled:hover,
.label-warning .btn-unstyled.hover {
  color: #d39e00;
}
.label-warning .close:hover {
  color: #d39e00;
}
.label-warning .close.focus, .label-warning .close:focus-visible, .c-prefers-focus .label-warning .close:focus {
  color: #d39e00;
}

.label-danger {
  background-color: #fff;
  border-color: #dc3545;
  color: #dc3545;
}
[href].label-danger:hover, [href].hover.label-danger, [type].label-danger:hover, [type].hover.label-danger, [tabindex].label-danger:hover, [tabindex].hover.label-danger {
  border-color: #bd2130;
  color: #bd2130;
}
[href].focus.label-danger, [href].label-danger:focus-visible, .c-prefers-focus [href].label-danger:focus, [type].focus.label-danger, [type].label-danger:focus-visible, .c-prefers-focus [type].label-danger:focus, [tabindex].focus.label-danger, [tabindex].label-danger:focus-visible, .c-prefers-focus [tabindex].label-danger:focus {
  color: #bd2130;
}
.label-danger a:hover, .label-danger a.hover,
.label-danger .btn-unstyled:hover,
.label-danger .btn-unstyled.hover {
  color: #bd2130;
}
.label-danger .close:hover {
  color: #bd2130;
}
.label-danger .close.focus, .label-danger .close:focus-visible, .c-prefers-focus .label-danger .close:focus {
  color: #bd2130;
}

.label-dark {
  background-color: #fff;
  border-color: #343a40;
  color: #343a40;
}
[href].label-dark:hover, [href].hover.label-dark, [type].label-dark:hover, [type].hover.label-dark, [tabindex].label-dark:hover, [tabindex].hover.label-dark {
  border-color: #1d2124;
  color: #1d2124;
}
[href].focus.label-dark, [href].label-dark:focus-visible, .c-prefers-focus [href].label-dark:focus, [type].focus.label-dark, [type].label-dark:focus-visible, .c-prefers-focus [type].label-dark:focus, [tabindex].focus.label-dark, [tabindex].label-dark:focus-visible, .c-prefers-focus [tabindex].label-dark:focus {
  color: #1d2124;
}
.label-dark a:hover, .label-dark a.hover,
.label-dark .btn-unstyled:hover,
.label-dark .btn-unstyled.hover {
  color: #1d2124;
}
.label-dark .close:hover {
  color: #1d2124;
}
.label-dark .close.focus, .label-dark .close:focus-visible, .c-prefers-focus .label-dark .close:focus {
  color: #1d2124;
}

.label-light {
  background-color: #343a40;
  border-color: #f7f8f9;
  color: #f7f8f9;
}
[href].label-light:hover, [href].hover.label-light, [type].label-light:hover, [type].hover.label-light, [tabindex].label-light:hover, [tabindex].hover.label-light {
  border-color: #dadfe3;
  color: #dadfe3;
}
[href].focus.label-light, [href].label-light:focus-visible, .c-prefers-focus [href].label-light:focus, [type].focus.label-light, [type].label-light:focus-visible, .c-prefers-focus [type].label-light:focus, [tabindex].focus.label-light, [tabindex].label-light:focus-visible, .c-prefers-focus [tabindex].label-light:focus {
  color: #dadfe3;
}
.label-light a:hover, .label-light a.hover,
.label-light .btn-unstyled:hover,
.label-light .btn-unstyled.hover {
  color: #dadfe3;
}
.label-light .close:hover {
  color: #dadfe3;
}
.label-light .close.focus, .label-light .close:focus-visible, .c-prefers-focus .label-light .close:focus {
  color: #dadfe3;
}

.label-inverse-primary {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
[href].label-inverse-primary:hover, [href].hover.label-inverse-primary, [type].label-inverse-primary:hover, [type].hover.label-inverse-primary, [tabindex].label-inverse-primary:hover, [tabindex].hover.label-inverse-primary {
  background-color: #004ad7;
  border-color: #004ad7;
  color: #fff;
}
.label-inverse-primary a:hover, .label-inverse-primary a.hover,
.label-inverse-primary .btn-unstyled:hover,
.label-inverse-primary .btn-unstyled.hover {
  color: #e6e6e6;
}
.label-inverse-primary .close:hover {
  color: #e6e6e6;
}
.label-inverse-secondary {
  background-color: #6b6c7e;
  border-color: #6b6c7e;
  color: #fff;
}
[href].label-inverse-secondary:hover, [href].hover.label-inverse-secondary, [type].label-inverse-secondary:hover, [type].hover.label-inverse-secondary, [tabindex].label-inverse-secondary:hover, [tabindex].hover.label-inverse-secondary {
  background-color: #545462;
  border-color: #545462;
  color: #fff;
}
.label-inverse-secondary a:hover, .label-inverse-secondary a.hover,
.label-inverse-secondary .btn-unstyled:hover,
.label-inverse-secondary .btn-unstyled.hover {
  color: #e6e6e6;
}
.label-inverse-secondary .close:hover {
  color: #e6e6e6;
}
.label-inverse-success {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
[href].label-inverse-success:hover, [href].hover.label-inverse-success, [type].label-inverse-success:hover, [type].hover.label-inverse-success, [tabindex].label-inverse-success:hover, [tabindex].hover.label-inverse-success {
  background-color: #1e7e34;
  border-color: #1e7e34;
  color: #fff;
}
.label-inverse-success a:hover, .label-inverse-success a.hover,
.label-inverse-success .btn-unstyled:hover,
.label-inverse-success .btn-unstyled.hover {
  color: #e6e6e6;
}
.label-inverse-success .close:hover {
  color: #e6e6e6;
}
.label-inverse-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
[href].label-inverse-info:hover, [href].hover.label-inverse-info, [type].label-inverse-info:hover, [type].hover.label-inverse-info, [tabindex].label-inverse-info:hover, [tabindex].hover.label-inverse-info {
  background-color: #117a8b;
  border-color: #117a8b;
  color: #fff;
}
.label-inverse-info a:hover, .label-inverse-info a.hover,
.label-inverse-info .btn-unstyled:hover,
.label-inverse-info .btn-unstyled.hover {
  color: #e6e6e6;
}
.label-inverse-info .close:hover {
  color: #e6e6e6;
}
.label-inverse-warning {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #272833;
}
[href].label-inverse-warning:hover, [href].hover.label-inverse-warning, [type].label-inverse-warning:hover, [type].hover.label-inverse-warning, [tabindex].label-inverse-warning:hover, [tabindex].hover.label-inverse-warning {
  background-color: #d39e00;
  border-color: #d39e00;
  color: #272833;
}
.label-inverse-warning a:hover, .label-inverse-warning a.hover,
.label-inverse-warning .btn-unstyled:hover,
.label-inverse-warning .btn-unstyled.hover {
  color: #53556d;
}
.label-inverse-warning .close:hover {
  color: #53556d;
}
.label-inverse-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
}
[href].label-inverse-danger:hover, [href].hover.label-inverse-danger, [type].label-inverse-danger:hover, [type].hover.label-inverse-danger, [tabindex].label-inverse-danger:hover, [tabindex].hover.label-inverse-danger {
  background-color: #bd2130;
  border-color: #bd2130;
  color: #fff;
}
.label-inverse-danger a:hover, .label-inverse-danger a.hover,
.label-inverse-danger .btn-unstyled:hover,
.label-inverse-danger .btn-unstyled.hover {
  color: #e6e6e6;
}
.label-inverse-danger .close:hover {
  color: #e6e6e6;
}
.label-inverse-light {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
  color: #272833;
}
[href].label-inverse-light:hover, [href].hover.label-inverse-light, [type].label-inverse-light:hover, [type].hover.label-inverse-light, [tabindex].label-inverse-light:hover, [tabindex].hover.label-inverse-light {
  background-color: #dadfe3;
  border-color: #dadfe3;
  color: #272833;
}
.label-inverse-light a:hover, .label-inverse-light a.hover,
.label-inverse-light .btn-unstyled:hover,
.label-inverse-light .btn-unstyled.hover {
  color: #3d3f50;
}
.label-inverse-light .close:hover {
  color: #3d3f50;
}
.label-inverse-dark {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
}
[href].label-inverse-dark:hover, [href].hover.label-inverse-dark, [type].label-inverse-dark:hover, [type].hover.label-inverse-dark, [tabindex].label-inverse-dark:hover, [tabindex].hover.label-inverse-dark {
  background-color: #1d2124;
  border-color: #1d2124;
  color: #fff;
}
.label-inverse-dark a:hover, .label-inverse-dark a.hover,
.label-inverse-dark .btn-unstyled:hover,
.label-inverse-dark .btn-unstyled.hover {
  color: #e6e6e6;
}
.label-inverse-dark .close:hover {
  color: #e6e6e6;
}
.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.sticker {
  align-items: center;
  border-radius: 0.25rem;
  color: #272833;
  height: 2rem;
  line-height: 2rem;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 2rem;
}
.sticker > .inline-item {
  justify-content: center;
}
.sticker > .inline-item .lexicon-icon {
  margin-top: 0;
}
.sticker .lexicon-icon {
  margin-top: 0;
}
.sticker-overlay {
  align-items: center;
  border-radius: inherit;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.sticker-bottom-left {
  bottom: 15px;
  left: 15px;
  position: absolute;
  right: auto;
  top: auto;
}

.sticker-bottom-right {
  bottom: 15px;
  left: auto;
  position: absolute;
  right: 15px;
  top: auto;
}

.sticker-top-left {
  left: 15px;
  position: absolute;
  top: 15px;
}

.sticker-top-right {
  left: auto;
  position: absolute;
  right: 15px;
  top: 15px;
}

.sticker-outside {
  left: -1rem;
  top: -1rem;
}
.sticker-outside.sticker-bottom-left {
  bottom: -1rem;
  top: auto;
}
.sticker-outside.sticker-bottom-right {
  bottom: -1rem;
  left: auto;
  right: -1rem;
  top: auto;
}
.sticker-outside.sticker-top-right {
  left: auto;
  right: -1rem;
}

.sticker-user-icon {
  background-color: #fff;
  border-radius: 5000px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.125);
}
.sticker-sm {
  font-size: 0.75rem;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 1.5rem;
}
.sticker-outside.sticker-sm {
  left: -0.75rem;
  top: -0.75rem;
}
.sticker-outside.sticker-bottom-left.sticker-sm {
  bottom: -0.75rem;
  top: auto;
}
.sticker-outside.sticker-bottom-right.sticker-sm {
  bottom: -0.75rem;
  left: auto;
  right: -0.75rem;
  top: auto;
}
.sticker-outside.sticker-top-right.sticker-sm {
  left: auto;
  right: -0.75rem;
}

.sticker-lg {
  font-size: 1.125rem;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}
.sticker-outside.sticker-lg {
  left: -1.25rem;
  top: -1.25rem;
}
.sticker-outside.sticker-bottom-left.sticker-lg {
  bottom: -1.25rem;
  top: auto;
}
.sticker-outside.sticker-bottom-right.sticker-lg {
  bottom: -1.25rem;
  left: auto;
  right: -1.25rem;
  top: auto;
}
.sticker-outside.sticker-top-right.sticker-lg {
  left: auto;
  right: -1.25rem;
}

.sticker-xl {
  font-size: 1.25rem;
  height: 3rem;
  line-height: 3rem;
  width: 3rem;
}
.sticker-outside.sticker-xl {
  left: -1.5rem;
  top: -1.5rem;
}
.sticker-outside.sticker-bottom-left.sticker-xl {
  bottom: -1.5rem;
  top: auto;
}
.sticker-outside.sticker-bottom-right.sticker-xl {
  bottom: -1.5rem;
  left: auto;
  right: -1.5rem;
  top: auto;
}
.sticker-outside.sticker-top-right.sticker-xl {
  left: auto;
  right: -1.5rem;
}

.sticker-primary {
  background-color: #0b5fff;
  color: #fff;
}
.sticker-secondary {
  background-color: #6b6c7e;
  color: #fff;
}
.sticker-success {
  background-color: #28a745;
  color: #fff;
}
.sticker-info {
  background-color: #17a2b8;
  color: #fff;
}
.sticker-warning {
  background-color: #ffc107;
  color: #272833;
}
.sticker-danger {
  background-color: #dc3545;
  color: #fff;
}
.sticker-light {
  background-color: #f7f8f9;
  color: #272833;
}
.sticker-dark {
  background-color: #343a40;
  color: #fff;
}
.sticker-circle {
  border-radius: 5000px;
}
.card,
.card-horizontal {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.0625rem;
  display: block;
  margin-bottom: 1.5rem;
  min-width: 0;
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.card .aspect-ratio .label,
.card-horizontal .aspect-ratio .label {
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.5rem;
}
.card .aspect-ratio-item-top-left,
.card-horizontal .aspect-ratio-item-top-left {
  left: 1rem;
  top: 1rem;
}
.card .aspect-ratio-item-top-center,
.card-horizontal .aspect-ratio-item-top-center {
  top: 1rem;
}
.card .aspect-ratio-item-top-right,
.card-horizontal .aspect-ratio-item-top-right {
  right: 1rem;
  top: 1rem;
}
.card .aspect-ratio-item-right-middle,
.card-horizontal .aspect-ratio-item-right-middle {
  right: 1rem;
}
.card .aspect-ratio-item-bottom-right,
.card-horizontal .aspect-ratio-item-bottom-right {
  bottom: 1rem;
  right: 1rem;
}
.card .aspect-ratio-item-bottom-center,
.card-horizontal .aspect-ratio-item-bottom-center {
  bottom: 1rem;
}
.card .aspect-ratio-item-bottom-left,
.card-horizontal .aspect-ratio-item-bottom-left {
  bottom: 1rem;
  left: 1rem;
}
.card > hr,
.card-horizontal > hr {
  margin-left: 0;
  margin-right: 0;
}
.card .autofit-col:first-child,
.card-horizontal .autofit-col:first-child {
  border-bottom-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-top-left-radius: calc(
	0.25rem - 0.0625rem
);
}
.card .autofit-col:last-child,
.card-horizontal .autofit-col:last-child {
  border-bottom-right-radius: calc(
	0.25rem - 0.0625rem
);
  border-top-right-radius: calc(
	0.25rem - 0.0625rem
);
}
.card > .list-group:first-child .list-group-item:first-child,
.card-horizontal > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child,
.card-horizontal > .list-group:last-child .list-group-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
}

.card-section-header {
  color: #6c757d;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 2.5;
  margin-bottom: 1.5rem;
  padding: 0 15px;
  text-transform: uppercase;
}

.card-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}
.card-subtitle {
  color: #6c757d;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}
.card-subtitle[href],
.card-subtitle a[href] {
  color: #6c757d;
}
.card-subtitle[href]:hover, .card-subtitle[href].hover,
.card-subtitle a[href]:hover,
.card-subtitle a[href].hover {
  color: #494f54;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link {
  color: #0b5fff;
}
.card-link:hover, .card-link.hover {
  color: #0041be;
  text-decoration: none;
}
.card-link.btn-unstyled {
  white-space: normal;
}
.card-link + .card-link {
  margin-left: 0;
}

.card-divider {
  background-color: rgba(0, 0, 0, 0.125);
  height: 1px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
  padding: 0.75rem 1.25rem;
}
.card-header:first-child {
  border-radius: calc( 	0.25rem - 0.0625rem ) calc( 	0.25rem - 0.0625rem ) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -0.75rem;
  margin-left: calc(-1.25rem / 2);
  margin-right: calc(-1.25rem / 2);
}

.card-header-pills {
  margin-left: calc(-1.25rem / 2);
  margin-right: calc(-1.25rem / 2);
}

.card-footer {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
}
.card-footer:last-child {
  border-radius: 0 0 calc( 	0.25rem - 0.0625rem ) calc( 	0.25rem - 0.0625rem );
}
.card-img-overlay {
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-top-right-radius: calc(
	0.25rem - 0.0625rem
);
}

.card-img,
.card-img-bottom {
  border-bottom-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-bottom-right-radius: calc(
	0.25rem - 0.0625rem
);
}

.card-row {
  display: flex;
  width: 100%;
}
.card-row .autofit-col {
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}
.card-row .autofit-col-expand {
  min-width: 25px;
}

.card-row .autofit-col-gutters {
  padding-left: 10px;
  padding-right: 10px;
}
.card-row .autofit-col-gutters .card-divider {
  margin-left: -10px;
  margin-right: -10px;
}

.justify-content-center .autofit-col {
  justify-content: center;
}
.justify-content-start .autofit-col {
  justify-content: flex-start;
}
.justify-content-end .autofit-col {
  justify-content: flex-end;
}
.text-center .autofit-col {
  text-align: center;
}
.text-left .autofit-col {
  text-align: left;
}
.text-right .autofit-col {
  text-align: right;
}

.card-item-first {
  border-top-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-top-right-radius: calc(
	0.25rem - 0.0625rem
);
  width: 100%;
}

.autofit-col:first-child .card-item-first {
  border-radius: calc(
	0.25rem - 0.0625rem
) 0 0 calc(
	0.25rem - 0.0625rem
);
}

.card-item-last {
  border-bottom-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-bottom-right-radius: calc(
	0.25rem - 0.0625rem
);
  width: 100%;
}

.autofit-col:last-child .card-item-last {
  border-radius: 0 calc(
	0.25rem - 0.0625rem
) calc(
	0.25rem - 0.0625rem
) 0;
}

.rounded .card-header,
.rounded .card-item-first {
  border-top-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-top-right-radius: calc(
	0.25rem - 0.0625rem
);
}
.rounded .card-footer,
.rounded .card-item-last {
  border-bottom-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-bottom-right-radius: calc(
	0.25rem - 0.0625rem
);
}
.rounded .autofit-col:first-child {
  border-bottom-left-radius: calc(
	0.25rem - 0.0625rem
);
  border-top-left-radius: calc(
	0.25rem - 0.0625rem
);
}
.rounded .autofit-col:first-child .card-item-first {
  border-radius: calc(
	0.25rem - 0.0625rem
) 0 0 calc(
	0.25rem - 0.0625rem
);
}
.rounded .autofit-col:last-child {
  border-bottom-right-radius: calc(
	0.25rem - 0.0625rem
);
  border-top-right-radius: calc(
	0.25rem - 0.0625rem
);
}
.rounded .autofit-col:last-child .card-item-last {
  border-radius: 0 calc(
	0.25rem - 0.0625rem
) calc(
	0.25rem - 0.0625rem
) 0;
}

.rounded-0 .card-header,
.rounded-0 .card-item-first {
  border-top-left-radius: calc(
	0px - 0.0625rem
);
  border-top-right-radius: calc(
	0px - 0.0625rem
);
}
.rounded-0 .card-footer,
.rounded-0 .card-item-last {
  border-bottom-left-radius: calc(
	0px - 0.0625rem
);
  border-bottom-right-radius: calc(
	0px - 0.0625rem
);
}
.rounded-0 .autofit-col:first-child {
  border-bottom-left-radius: calc(
	0px - 0.0625rem
);
  border-top-left-radius: calc(
	0px - 0.0625rem
);
}
.rounded-0 .autofit-col:first-child .card-item-first {
  border-radius: calc(
	0px - 0.0625rem
) 0 0 calc(
	0px - 0.0625rem
);
}
.rounded-0 .autofit-col:last-child {
  border-bottom-right-radius: calc(
	0px - 0.0625rem
);
  border-top-right-radius: calc(
	0px - 0.0625rem
);
}
.rounded-0 .autofit-col:last-child .card-item-last {
  border-radius: 0 calc(
	0px - 0.0625rem
) calc(
	0px - 0.0625rem
) 0;
}

.rounded-circle .card-header,
.rounded-circle .card-item-first {
  border-top-left-radius: calc(
	5000px - 0.0625rem
);
  border-top-right-radius: calc(
	5000px - 0.0625rem
);
}
.rounded-circle .card-footer,
.rounded-circle .card-item-last {
  border-bottom-left-radius: calc(
	5000px - 0.0625rem
);
  border-bottom-right-radius: calc(
	5000px - 0.0625rem
);
}
.rounded-circle .autofit-col:first-child {
  border-bottom-left-radius: calc(
	5000px - 0.0625rem
);
  border-top-left-radius: calc(
	5000px - 0.0625rem
);
}
.rounded-circle .autofit-col:first-child .card-item-first {
  border-radius: calc(
	5000px - 0.0625rem
) 0 0 calc(
	5000px - 0.0625rem
);
}
.rounded-circle .autofit-col:last-child {
  border-bottom-right-radius: calc(
	5000px - 0.0625rem
);
  border-top-right-radius: calc(
	5000px - 0.0625rem
);
}
.rounded-circle .autofit-col:last-child .card-item-last {
  border-radius: 0 calc(
	5000px - 0.0625rem
) calc(
	5000px - 0.0625rem
) 0;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -0.0625rem;
}

.form-check-card {
  margin-bottom: 1.5rem;
  margin-top: 0;
  padding-left: 0;
}
.form-check-card:hover .card {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-check-card.active .card {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-check-card.checked .card {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-check-card .card {
  margin-bottom: 0;
}
.form-check-card .form-check-input {
  margin-left: 0;
  margin-top: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
}
.form-check-card .form-check-label {
  color: #272833;
  display: inline;
  font-weight: 400;
  padding-left: 0;
  position: static;
}
.form-check-card .custom-control {
  display: inline;
  margin-right: 0;
  position: static;
}
.form-check-card .custom-control label {
  font-weight: 400;
  padding-left: 0;
}
.form-check-card .custom-control .custom-control-label {
  opacity: 0;
  position: absolute;
  z-index: 1;
}
.form-check-card .custom-control .custom-control-label::before {
  top: 0;
}
.form-check-card .custom-control .custom-control-label::after {
  top: 0;
}
.form-check-card .custom-control .custom-control-input {
  z-index: 2;
}
.form-check-card .custom-control .custom-control-input:checked ~ .card {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-check-card .custom-control .custom-control-input:checked ~ .card {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-check-card .form-check-input:checked ~ .card {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-check-bottom-left .card-horizontal > .card-body,
.form-check-middle-left .card-horizontal > .card-body,
.form-check-top-left .card-horizontal > .card-body {
  padding-left: 40px;
}

.form-check-bottom-right .card-horizontal > .card-body,
.form-check-middle-right .card-horizontal > .card-body,
.form-check-top-right .card-horizontal > .card-body {
  padding-right: 40px;
}

.form-check-bottom-left .form-check-input {
  bottom: 1rem;
  left: 1rem;
  opacity: 1;
  top: auto;
  transform: none;
}
.form-check-bottom-left .custom-control .custom-control-label {
  bottom: 1rem;
  left: 1rem;
  opacity: 1;
  top: auto;
  transform: none;
}
.form-check-bottom-left .custom-control .custom-control-input {
  bottom: 1rem;
  left: 1rem;
  top: auto;
  transform: none;
}
.form-check-bottom-right .form-check-input {
  bottom: 1rem;
  left: auto;
  opacity: 1;
  right: 1rem;
  top: auto;
  transform: none;
}
.form-check-bottom-right .custom-control .custom-control-label {
  bottom: 1rem;
  left: auto;
  opacity: 1;
  right: 1rem;
  top: auto;
  transform: none;
}
.form-check-bottom-right .custom-control .custom-control-input {
  bottom: 1rem;
  left: auto;
  right: 1rem;
  top: auto;
  transform: none;
}
.form-check-middle-left .form-check-input {
  left: 1rem;
  margin-top: 0;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-middle-left .custom-control .custom-control-label {
  left: 1rem;
  margin-top: 0;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-middle-left .custom-control .custom-control-input {
  left: 1rem;
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-middle-right .form-check-input {
  left: auto;
  margin-top: 0;
  opacity: 1;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-middle-right .custom-control .custom-control-label {
  left: auto;
  margin-top: 0;
  opacity: 1;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-middle-right .custom-control .custom-control-input {
  left: auto;
  margin-top: 0;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.form-check-top-left .form-check-input {
  left: 1rem;
  opacity: 1;
  top: 1rem;
  transform: none;
}
.form-check-top-left .custom-control .custom-control-label {
  left: 1rem;
  opacity: 1;
  top: 1rem;
  transform: none;
}
.form-check-top-left .custom-control .custom-control-input {
  left: 1rem;
  top: 1rem;
  transform: none;
}
.form-check-top-right .form-check-input {
  left: auto;
  opacity: 1;
  right: 1rem;
  top: 1rem;
  transform: none;
}
.form-check-top-right .custom-control .custom-control-label {
  left: auto;
  opacity: 1;
  right: 1rem;
  top: 1rem;
  transform: none;
}
.form-check-top-right .custom-control .custom-control-input {
  left: auto;
  right: 1rem;
  top: 1rem;
  transform: none;
}
.card-page.card-page-equal-height .card-page-item,
.card-page.card-page-equal-height .card-page-item-asset,
.card-page.card-page-equal-height .card-page-item-directory {
  display: flex;
  flex-direction: column;
}
.card-page.card-page-equal-height .form-check-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.card-page.card-page-equal-height .card {
  flex-grow: 1;
}

.card-page-item-header {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.card-page-item-directory {
  display: block;
  flex-grow: 1;
  min-width: 193px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
@media (min-width: 0) {
  .card-page-item-directory {
    min-width: 193px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 576px) {
  .card-page-item-directory {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .card-page-item-directory {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
}
@media (min-width: 992px) {
  .card-page-item-directory {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.card-page-item-asset {
  display: block;
  flex-grow: 1;
  min-width: 193px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
@media (min-width: 0) {
  .card-page-item-asset {
    min-width: 193px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 576px) {
  .card-page-item-asset {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .card-page-item-asset {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
}
@media (min-width: 992px) {
  .card-page-item-asset {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.card-page-item-user {
  display: block;
  flex-basis: 50%;
  flex-grow: 1;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}
@media (min-width: 0) {
  .card-page-item-user {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 576px) {
  .card-page-item-user {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    min-width: 200px;
  }
}
@media (min-width: 992px) {
  .card-page-item-user {
    flex-basis: 20%;
    max-width: 20%;
  }
}

.card-interactive {
  cursor: pointer;
  outline: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .card-interactive {
    transition: none;
  }
}
.c-prefers-reduced-motion .card-interactive {
  transition: none;
}

.card-interactive:hover, .card-interactive.hover {
  background-color: #f7f8f9;
  text-decoration: none;
}
.card-interactive.focus, .card-interactive:focus-visible, .c-prefers-focus .card-interactive:focus {
  border-color: #8bb3ff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.card-interactive:active, .card-interactive.active {
  background-color: #f1f2f5;
}
.card-interactive::after {
  border-radius: 0 0 0.25rem 0.25rem;
  bottom: -0.0625rem;
  content: "";
  height: 0;
  left: -0.0625rem;
  position: absolute;
  right: -0.0625rem;
  transition: height 0.15s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .card-interactive::after {
    transition: none;
  }
}
.c-prefers-reduced-motion .card-interactive::after {
  transition: none;
}

.card-interactive:hover::after, .card-interactive.hover::after {
  height: 4px;
}
.card-interactive:focus::after, .card-interactive.focus::after {
  height: 4px;
}
.card-interactive:active::after, .card-interactive.active::after {
  height: 4px;
}
.card-interactive .card-body {
  display: block;
}
.card-interactive label {
  cursor: pointer;
}

.card-interactive-primary.focus, .card-interactive-primary:focus-visible, .c-prefers-focus .card-interactive-primary:focus {
  background-color: #f7f8f9;
}
.card-interactive-primary:active, .card-interactive-primary.active {
  background-color: #f1f2f5;
}
.card-interactive-primary:hover::after, .card-interactive-primary.hover::after {
  background-color: #0b5fff;
}
.card-interactive-primary:focus::after, .card-interactive-primary.focus::after {
  background-color: #0b5fff;
}
.card-interactive-primary:active::after, .card-interactive-primary.active::after {
  background-color: #0b5fff;
}
.card-interactive-secondary {
  color: #272833;
}
.card-interactive-secondary:hover, .card-interactive-secondary.hover {
  background-color: #fff;
  border-color: #8bb3ff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  color: #272833;
}
.card-interactive-secondary:active, .card-interactive-secondary.active {
  background-color: #fff;
}
.card-type-asset .aspect-ratio {
  border-color: rgba(0, 0, 0, 0.125);
  border-style: solid;
  border-width: 0 0 0.0625rem 0;
  padding-bottom: calc(9 / 16 * 100%);
}
.card-type-asset .aspect-ratio .custom-control label,
.card-type-asset .aspect-ratio .form-check-label {
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.card-type-asset .card-row {
  align-items: flex-start;
}
.card-type-asset .card-type-asset-icon {
  width: 22.225%;
}
.card-type-asset .card-type-asset-icon .inline-item {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.card-type-asset .card-type-asset-icon > .lexicon-icon {
  height: 100%;
  width: 100%;
}
.card-type-asset .card-type-asset-icon > .sticker {
  border-radius: 50%;
  display: block;
  font-size: 2vw;
  padding-bottom: 100%;
  width: 100%;
}
.card-type-asset .dropdown-action {
  margin-right: -0.5rem;
  margin-top: -0.1875rem;
}
.file-card .card-type-asset-icon {
  color: #6c757d;
}
.product-card .aspect-ratio {
  background-color: #fff;
  background-image: linear-gradient(0deg, #ebebeb, #ebebeb);
}
.product-card .card-body {
  text-align: center;
}
.product-card .card-title {
  font-size: 1rem;
}
.product-card .card-subtitle {
  font-size: 0.75rem;
}
.product-card .card-text {
  font-size: 1.125rem;
  font-weight: 500;
}
.user-card .card-type-asset-icon {
  max-width: 80px;
  min-width: 48px;
  width: 30%;
}
.user-card .card-type-asset-icon > .lexicon-icon {
  height: auto;
  width: 50%;
}
.card-type-directory .sticker {
  font-size: 1.125rem;
}
.card-type-template {
  color: #272833;
}
.card-type-template:hover, .card-type-template.hover {
  color: #272833;
}
.card-type-template .aspect-ratio {
  border-width: 0px;
  color: #6c757d;
  text-align: center;
  padding-bottom: calc(9 / 16 * 100%);
}
.card-type-template .aspect-ratio .lexicon-icon {
  height: auto;
  width: 28%;
}
.card-type-template .aspect-ratio-item {
  width: 100.6%;
}
.card-type-template .card-title {
  display: block;
  margin-bottom: 0.5rem;
}
.card-type-template .card-text {
  display: block;
}
.template-card .card-body {
  padding-top: 0;
  text-align: center;
}
.template-card-horizontal {
  color: #6c757d;
}
.template-card-horizontal:hover, .template-card-horizontal.hover {
  color: #6c757d;
}
.template-card-horizontal .sticker {
  font-size: 1.25rem;
}
.template-card-horizontal .card-row {
  margin-left: -4px;
  margin-right: -4px;
  width: auto;
}
.template-card-horizontal .card-row .autofit-col {
  padding-left: 4px;
  padding-right: 4px;
}
.template-card-horizontal .card-title {
  color: inherit;
  margin-bottom: 0;
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-header {
  color: #6c757d;
  display: block;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.dropdown-header:first-child {
  margin-top: 0;
}

.dropdown-subheader {
  color: #6c757d;
  font-size: 0.75rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.5rem;
  text-transform: uppercase;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.dropdown-subheader:first-child {
  margin-top: 0;
}

.dropdown-caption {
  color: #6c757d;
  font-size: 0.875rem;
  padding: 0.25rem 1.5rem;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.dropdown-item {
  background-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  clear: both;
  color: #272833;
  cursor: pointer;
  display: block;
  font-weight: 400;
  overflow: hidden;
  padding-bottom: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.25rem;
  position: relative;
  text-align: inherit;
  transition: none;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.dropdown-item:hover, .dropdown-item.hover {
  background-color: #f7f8f9;
  color: #1c1d25;
  text-decoration: none;
}
.dropdown-item.focus, .dropdown-item:focus-visible, .c-prefers-focus .dropdown-item:focus {
  background-color: #f7f8f9;
  color: #1c1d25;
  text-decoration: none;
}
.dropdown-item:active {
  background-color: #0b5fff;
  color: #fff;
  text-decoration: none;
}
.dropdown-item:active label {
  color: #fff;
}
.dropdown-item:active .form-check-label {
  color: #fff;
}
.dropdown-item.active {
  background-color: #0b5fff;
  color: #fff;
  text-decoration: none;
}
.dropdown-item.active label {
  color: #fff;
}
.dropdown-item.active .form-check-label {
  color: #fff;
}
.dropdown-item:disabled, .dropdown-item.disabled {
  background-color: transparent;
  color: #6c757d;
  cursor: not-allowed;
  opacity: 1;
  outline: 0;
}
.dropdown-item:disabled label,
.dropdown-item:disabled .form-check-label, .dropdown-item.disabled label,
.dropdown-item.disabled .form-check-label {
  color: #6c757d;
}
.dropdown-item:disabled .c-kbd-inline, .dropdown-item.disabled .c-kbd-inline {
  color: #6c757d;
}
.dropdown-item:disabled:active, .dropdown-item.disabled:active {
  pointer-events: none;
}
.dropdown-item .c-inner {
  flex-grow: 1;
  margin-bottom: -0.25rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-top: -0.25rem;
  width: auto;
}
.dropdown-item.autofit-row {
  padding-left: calc(1.5rem - 0.25rem);
  padding-right: calc(1.5rem - 0.25rem);
}
.dropdown-item.autofit-row > .autofit-col {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.dropdown-item .autofit-row {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  width: auto;
}
.dropdown-item .autofit-row > .autofit-col {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.dropdown-item .c-kbd-inline {
  line-height: calc(1rem * 1.5);
}
.dropdown-item .form-check-label {
  font-weight: 400;
}
.dropdown-item .custom-control-label {
  font-weight: 400;
}
.dropdown-item .form-check {
  margin-bottom: 0;
}
.dropdown-item .custom-control {
  margin-bottom: 0;
  margin-right: 0;
  min-height: 1rem;
}

.dropdown-item-text {
  color: #272833;
  display: block;
  font-weight: 400;
  padding-bottom: 0.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.25rem;
}

.dropdown-section {
  padding: 0.25rem 1.5rem;
}
.dropdown-section .form-group + .form-group {
  margin-top: 0.5rem;
}
.dropdown-section .custom-control {
  margin-bottom: 0;
}
.dropdown-footer {
  box-shadow: -1px -2px 3px -3px rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1.5rem 0;
  position: relative;
}

.dropdown-menu {
  background-clip: padding-box;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.0625rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  color: #272833;
  display: none;
  float: left;
  font-size: 1rem;
  left: 0;
  list-style: none;
  margin: 0.125rem 0 0;
  max-height: 500px;
  max-width: 260px;
  min-width: 10rem;
  overflow: auto;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000;
}
@media (max-width: 991.98px) {
  .dropdown-menu {
    max-height: 295px;
    max-width: 230px;
  }
}
.dropdown-menu.show {
  display: block;
}
.dropdown-menu .alert, .dropdown-menu .portlet-msg-alert,
.dropdown-menu .portlet-msg-error,
.dropdown-menu .portlet-msg-help,
.dropdown-menu .portlet-msg-info,
.dropdown-menu .portlet-msg-progress,
.dropdown-menu .portlet-msg-success {
  line-height: normal;
  margin: 0.5rem;
  padding: 0.5rem 1.5rem;
}
.dropdown-menu .alert:first-child, .dropdown-menu .portlet-msg-alert:first-child,
.dropdown-menu .portlet-msg-error:first-child,
.dropdown-menu .portlet-msg-help:first-child,
.dropdown-menu .portlet-msg-info:first-child,
.dropdown-menu .portlet-msg-progress:first-child,
.dropdown-menu .portlet-msg-success:first-child {
  margin-top: 0;
}
.dropdown-menu .alert:last-child, .dropdown-menu .portlet-msg-alert:last-child,
.dropdown-menu .portlet-msg-error:last-child,
.dropdown-menu .portlet-msg-help:last-child,
.dropdown-menu .portlet-msg-info:last-child,
.dropdown-menu .portlet-msg-progress:last-child,
.dropdown-menu .portlet-msg-success:last-child {
  margin-bottom: 0;
}
.dropdown-menu .alert-fluid {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-menu .alert-fluid:first-child {
  margin-top: -0.5rem;
}
.dropdown-menu .alert-fluid:last-child {
  margin-bottom: -0.5rem;
}
.dropdown-menu .form-group {
  margin-bottom: 0;
}
.dropdown-menu .inline-scroller {
  max-height: 200px;
}
@media (max-width: 991.98px) {
  .dropdown-menu .inline-scroller {
    max-height: none;
  }
}
.dropdown-menu > .list-unstyled {
  margin-bottom: 0;
}

.dropdown-menu-select.dropdown-menu .dropdown-header {
  padding-bottom: 0.375rem;
  padding-left: 1.75rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.dropdown-menu-select.dropdown-menu .dropdown-subheader {
  padding-bottom: 0.4375rem;
  padding-left: 1.75rem;
  padding-right: 0.5rem;
  padding-top: 0.4375rem;
}
.dropdown-menu-select.dropdown-menu .dropdown-section {
  padding-left: 1.75rem;
  padding-right: 0.5rem;
}
.dropdown-menu-select.dropdown-menu .dropdown-item {
  padding-bottom: 0.375rem;
  padding-left: 1.75rem;
  padding-right: 0.5rem;
  padding-top: 0.3125rem;
}
.dropdown-menu-select.dropdown-menu .dropdown-item .c-inner {
  flex-grow: 1;
  margin-bottom: -0.375rem;
  margin-left: -1.75rem;
  margin-right: -0.5rem;
  margin-top: -0.3125rem;
  width: auto;
}
.dropdown-menu-select.dropdown-menu .dropdown-item.autofit-row {
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll {
  font-size: 1rem;
  height: 2rem;
  padding: 0;
  position: absolute;
  text-align: center;
  z-index: 1;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll:hover, .dropdown-menu-select.dropdown-menu .dropdown-item-scroll.hover {
  background-color: #f7f8f9;
  background-image: none;
  color: #1c1d25;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll.focus, .dropdown-menu-select.dropdown-menu .dropdown-item-scroll:focus-visible, .c-prefers-focus .dropdown-menu-select.dropdown-menu .dropdown-item-scroll:focus {
  background-color: #f7f8f9;
  background-image: none;
  color: #1c1d25;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll:active {
  background-color: #0b5fff;
  background-image: none;
  color: #fff;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll:active label {
  color: #fff;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll:active .form-check-label {
  color: #fff;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll.active {
  background-color: #0b5fff;
  background-image: none;
  color: #fff;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll.active label {
  color: #fff;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll.active .form-check-label {
  color: #fff;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll:disabled, .dropdown-menu-select.dropdown-menu .dropdown-item-scroll.disabled {
  background-color: transparent;
  background-image: none;
  color: #6c757d;
  cursor: not-allowed;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll:disabled label,
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll:disabled .form-check-label, .dropdown-menu-select.dropdown-menu .dropdown-item-scroll.disabled label,
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll.disabled .form-check-label {
  color: #6c757d;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll .c-inner {
  flex-grow: 1;
  width: auto;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll-down {
  background-image: linear-gradient(to top, rgb(255, 255, 255) 84%, rgba(255, 255, 255, 0) 100%);
  bottom: 0.5rem;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll-down .c-inner {
  flex-grow: 1;
  width: auto;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll-up {
  background-image: linear-gradient(to bottom, rgb(255, 255, 255) 84%, rgba(255, 255, 255, 0) 100%);
  top: 0.5rem;
}
.dropdown-menu-select.dropdown-menu .dropdown-item-scroll-up .c-inner {
  flex-grow: 1;
  width: auto;
}
.dropdown-menu-select.dropdown-menu .dropdown-divider {
  margin: 0.3125rem 0;
}
.dropdown-menu-select.dropdown-menu.dropdown-menu-indicator-start .dropdown-item-indicator-start {
  left: 0.5rem;
  top: 0.5rem;
}
.dropdown-menu-select.dropdown-menu.dropdown-menu-indicator-end .dropdown-item-indicator-end {
  right: 0.5rem;
  top: 0.5rem;
}
.dropdown-menu-select.dropdown-menu-height-lg .inline-scroller {
  max-height: 432px;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }
  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }
  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }
  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}
.dropup .dropdown-menu {
  bottom: 100%;
  margin-bottom: 0.125rem;
  margin-top: 0;
  top: auto;
}
.dropright .dropdown-menu {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto;
  top: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  left: auto;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100%;
  top: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  border-top: 1px solid #f1f2f5;
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
}

.dropdown-action {
  display: inline-block;
  vertical-align: middle;
}
.dropdown-action > .dropdown-toggle {
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  font-size: inherit;
  font-weight: inherit;
  height: 1.9375rem;
  justify-content: center;
  line-height: inherit;
  padding: 0;
  text-transform: inherit;
  vertical-align: baseline;
  width: 1.9375rem;
}
.dropdown-action > .dropdown-toggle:disabled, .dropdown-action > .dropdown-toggle.disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.dropdown-action > .dropdown-toggle .lexicon-icon {
  margin-top: 0;
}
.dropdown-action > .dropdown-toggle .inline-item .lexicon-icon {
  margin-top: 0;
}
.dropdown-menu-indicator-start .dropdown-item-indicator {
  height: 1rem;
  left: 1.5rem;
  position: absolute;
  top: 0.25rem;
  width: 1rem;
}
.dropdown-menu-indicator-start .dropdown-item-indicator-text-start {
  padding-left: 0;
}
.dropdown-menu-indicator-start .dropdown-header,
.dropdown-menu-indicator-start .dropdown-subheader,
.dropdown-menu-indicator-start .dropdown-caption,
.dropdown-menu-indicator-start .dropdown-item {
  padding-left: calc(
			1.5rem + 1rem + 1rem
		);
}
.dropdown-menu-indicator-start .dropdown-item .c-inner {
  margin-left: calc((
							1.5rem +
								1rem +
								1rem
						) * -1);
}

.dropdown-item-indicator-start {
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 1rem;
  justify-content: center;
  left: 1.5rem;
  position: absolute;
  right: auto;
  top: calc( 				0.25rem - (( 								1rem - (1em * 1.5) 							) / 2) 			);
  width: 1rem;
}
.dropdown-item-indicator-start .lexicon-icon {
  margin-top: 0;
}

.dropdown-item-indicator-text-start {
  color: inherit;
  display: block;
  padding-left: 2rem;
  text-decoration: inherit;
  width: 100%;
}
.dropdown-item-indicator-text-start:hover, .dropdown-item-indicator-text-start.hover {
  color: inherit;
  text-decoration: inherit;
}
.dropdown-item-indicator-text-start.focus, .dropdown-item-indicator-text-start:focus-visible, .c-prefers-focus .dropdown-item-indicator-text-start:focus {
  color: inherit;
  text-decoration: inherit;
}
.dropdown-item-indicator-text-start > .c-inner {
  margin-left: -2rem;
}
.dropdown-menu-indicator-end .dropdown-item-indicator {
  position: absolute;
  right: 1.5rem;
  top: 0.25rem;
}
.dropdown-menu-indicator-end .dropdown-item-indicator-text-end {
  padding-right: 0;
}
.dropdown-menu-indicator-end .dropdown-item {
  padding-right: calc(
			1.5rem + 1rem + 1rem
		);
}
.dropdown-menu-indicator-end .dropdown-item .c-inner {
  margin-right: calc((
							1.5rem +
								1rem +
								1rem
						) * -1);
}

.dropdown-item-indicator-end {
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 1rem;
  justify-content: center;
  left: auto;
  position: absolute;
  right: 1.5rem;
  top: calc( 				0.25rem - (( 								1rem - (1em * 1.5) 							) / 2) 			);
  width: 1rem;
}
.dropdown-item-indicator-end .lexicon-icon {
  margin-top: 0;
}

.dropdown-item-indicator-text-end {
  color: inherit;
  display: block;
  padding-right: 2rem;
  text-decoration: inherit;
  width: 100%;
}
.dropdown-item-indicator-text-end:hover, .dropdown-item-indicator-text-end.hover {
  color: inherit;
  text-decoration: inherit;
}
.dropdown-item-indicator-text-end.focus, .dropdown-item-indicator-text-end:focus-visible, .c-prefers-focus .dropdown-item-indicator-text-end:focus {
  color: inherit;
  text-decoration: inherit;
}
.dropdown-item-indicator-text-end > .c-inner {
  margin-right: -2rem;
}
.dropdown-menu-top {
  bottom: 100% !important;
  left: 0 !important;
  margin-top: 0;
  margin-bottom: 0.125rem;
  right: auto !important;
  top: auto !important;
  transform: none !important;
  will-change: auto !important;
}
.dropdown-menu-top-right {
  bottom: 100% !important;
  left: auto !important;
  margin-top: 0;
  margin-bottom: 0.125rem;
  right: 0 !important;
  top: auto !important;
  transform: none !important;
  will-change: auto !important;
}
.dropdown-menu-top-center {
  bottom: 100% !important;
  left: 50% !important;
  margin-top: 0;
  margin-bottom: 0.125rem;
  right: auto !important;
  top: auto !important;
  transform: translateX(-50%) !important;
  will-change: auto !important;
}
.dropdown-menu-center {
  bottom: auto !important;
  left: 50% !important;
  right: auto !important;
  top: 100% !important;
  transform: translateX(-50%) !important;
  will-change: auto !important;
}
.dropdown-menu-left-side {
  bottom: auto !important;
  left: auto !important;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100% !important;
  top: 0 !important;
  transform: none !important;
  will-change: auto !important;
}
.dropdown-menu-left-side-bottom {
  bottom: 0 !important;
  left: auto !important;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100% !important;
  top: auto !important;
  transform: none !important;
  will-change: auto !important;
}
.dropdown-menu-left-side-middle {
  bottom: auto !important;
  left: auto !important;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100% !important;
  top: 50% !important;
  transform: translate(0, -50%) !important;
  will-change: auto !important;
}
.dropdown-menu-right-side {
  bottom: auto !important;
  left: 100% !important;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto !important;
  top: 0 !important;
  transform: none !important;
  will-change: auto !important;
}
.dropdown-menu-right-side-bottom {
  bottom: 0 !important;
  left: 100% !important;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto !important;
  top: auto !important;
  transform: none !important;
  will-change: auto !important;
}
.dropdown-menu-right-side-middle {
  bottom: auto !important;
  left: 100% !important;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto !important;
  top: 50% !important;
  transform: translate(0, -50%) !important;
  will-change: auto !important;
}
.dropdown-full .dropdown-menu,
.dropdown-wide .dropdown-menu {
  max-width: none;
  width: 100%;
}
@media (min-width: 768px) {
  .dropdown-full .dropdown-menu,
  .dropdown-wide .dropdown-menu {
    max-height: none;
    width: 100%;
  }
}

.dropdown-full .dropdown-header ~ .dropdown-header,
.dropdown-wide .dropdown-header ~ .dropdown-header {
  margin-top: 20px;
}
.dropdown-full .dropdown-menu > .row,
.dropdown-wide .dropdown-menu > .row {
  margin-left: 0;
  margin-right: 0;
  min-width: 500px;
}

@media (min-width: 992px) {
  .dropdown-wide .dropdown-menu {
    min-width: 500px;
  }
}

.dropdown-menu-width-shrink {
  min-width: 0;
}
.dropdown-menu-width-full {
  left: 12px !important;
  right: 12px !important;
  max-width: none;
  min-width: 0;
  width: calc(100% - 24px);
}

.dropdown-menu-width-sm {
  max-width: none;
  min-width: 0;
  width: 500px;
}
@media (max-width: 767.98px) {
  .dropdown-menu-width-sm {
    left: 12px !important;
    right: 12px !important;
    width: calc(100% - 24px);
  }
}

.dropdown-menu-height-auto {
  height: auto;
  max-height: none;
  min-height: 0;
}

@media (min-width: 768px) {
  .dropdown-full .autocomplete-dropdown-menu,
  .dropdown-full .dropdown-menu-autocomplete {
    max-height: calc(9rem + 2px);
  }
}

.autocomplete-dropdown-menu,
.dropdown-menu-autocomplete {
  max-height: calc(9rem + 2px);
  max-width: none;
  width: 100%;
}
.nav-item.dropdown-full {
  position: static;
}

@media (max-width: 991.98px) {
  .nav-item.dropdown-wide {
    position: static;
  }
}

@media (max-width: 767.98px) {
  .navbar-nav .dropdown-menu-center {
    -ms-transform: none;
    transform: none;
  }
}

.navbar-right .dropdown-menu-center {
  left: 50%;
  right: auto;
}

.drilldown-inner {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  transition: height 0.25s ease;
}
@media (prefers-reduced-motion: reduce) {
  .drilldown-inner {
    transition: none;
  }
}
.c-prefers-reduced-motion .drilldown-inner {
  transition: none;
}

.drilldown-item {
  display: none;
  flex-shrink: 0;
  height: 0;
  width: 100%;
}

.drilldown-item-inner {
  display: flex;
  flex-direction: column;
}

.drilldown-transition {
  transition: all 0.25s ease;
}
@media (prefers-reduced-motion: reduce) {
  .drilldown-transition {
    transition: none;
  }
}
.c-prefers-reduced-motion .drilldown-transition {
  transition: none;
}

.drilldown-item.transitioning {
  display: block;
}

.drilldown-current {
  display: block;
  height: auto;
}

.drilldown-next-active,
.drilldown-prev-initial {
  transform: translateX(-100%);
}

.drilldown-prev-active {
  transform: translateX(0%);
}

.drilldown.dropdown-menu {
  flex-direction: column;
  max-height: none;
  max-width: none;
  min-width: 0;
  overflow: hidden;
  width: 260px;
}
.drilldown.dropdown-menu.show {
  display: flex;
}
.drilldown.dropdown-menu .drilldown-inner {
  min-height: 266px;
}
.drilldown.dropdown-menu .inline-scroller {
  max-height: none;
}

.drilldown.dropdown-menu-indicator-start .dropdown-item,
.drilldown .dropdown-menu-indicator-start .dropdown-item {
  padding-left: 3rem;
}
.drilldown.dropdown-menu-indicator-start .dropdown-item .dropdown-item-indicator-text-start,
.drilldown .dropdown-menu-indicator-start .dropdown-item .dropdown-item-indicator-text-start {
  padding-left: 0;
}
.drilldown.dropdown-menu-indicator-start .dropdown-item .dropdown-item-indicator-text-end,
.drilldown .dropdown-menu-indicator-start .dropdown-item .dropdown-item-indicator-text-end {
  margin-left: -3rem;
}

.drilldown.dropdown-menu-indicator-end .dropdown-item,
.drilldown .dropdown-menu-indicator-end .dropdown-item {
  padding-right: 3rem;
}
.drilldown.dropdown-menu-indicator-end .dropdown-item .dropdown-item-indicator-text-start,
.drilldown .dropdown-menu-indicator-end .dropdown-item .dropdown-item-indicator-text-start {
  margin-right: -3rem;
}
.drilldown.dropdown-menu-indicator-end .dropdown-item .dropdown-item-indicator-text-end,
.drilldown .dropdown-menu-indicator-end .dropdown-item .dropdown-item-indicator-text-end {
  padding-right: 0;
}

.drilldown .dropdown-header {
  color: #6b6c7e;
  font-weight: 500;
  padding-bottom: 0.59375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.59375rem;
  text-transform: uppercase;
}
.drilldown .dropdown-footer {
  padding: 1rem 1rem 0.625rem;
}
.drilldown .dropdown-divider {
  margin: 0;
}
.drilldown .dropdown-item {
  padding-bottom: 0.59375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.59375rem;
}
.drilldown .dropdown-item .c-inner {
  flex-grow: 1;
  margin-bottom: -0.59375rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.59375rem;
  width: auto;
}
.drilldown .dropdown-item-indicator-start {
  height: 1.5rem;
  left: 0.5rem;
  top: calc( 				0.59375rem - (( 								1.5rem - (1em * 1.5) 							) / 2) 			);
  transition: none;
  width: 1.5rem;
}
.drilldown .dropdown-item-indicator-text-start {
  padding-left: 2rem;
}
.drilldown .dropdown-item-indicator-end {
  height: 1.5rem;
  right: 0.5rem;
  top: calc( 				0.59375rem - (( 								1.5rem - (1em * 1.5) 							) / 2) 			);
  transition: none;
  width: 1.5rem;
}
.drilldown .dropdown-item-indicator-text-end {
  margin-bottom: -0.59375rem;
  margin-left: -1rem;
  margin-top: -0.59375rem;
  padding-bottom: inherit;
  padding-left: inherit;
  padding-right: 2rem;
  padding-top: inherit;
  width: auto;
}

.drilldown-height-md.dropdown-menu .drilldown-inner, .drilldown-height-sm.dropdown-menu .drilldown-inner {
  min-height: 0;
}
.drilldown-height-md.dropdown-menu .drilldown-item, .drilldown-height-sm.dropdown-menu .drilldown-item {
  height: 100%;
}
.drilldown-height-md.dropdown-menu .drilldown-item-inner, .drilldown-height-sm.dropdown-menu .drilldown-item-inner {
  height: 100%;
}

.drilldown-height-sm.dropdown-menu {
  height: 272px;
}

.drilldown-height-md.dropdown-menu {
  height: 344px;
}

[type=checkbox] {
  cursor: pointer;
  height: 14px;
  width: 14px;
}
[type=checkbox]:disabled {
  cursor: not-allowed;
}

[type=radio] {
  cursor: pointer;
  height: 15px;
  width: 14px;
}
[type=radio]:disabled {
  cursor: not-allowed;
}

label {
  margin-bottom: 0.5rem;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
label[for] {
  cursor: pointer;
}
label + .form-text {
  margin-bottom: 0.5rem;
  margin-top: 0;
}
label .reference-mark {
  color: #ffc107;
}

.form-control-label {
  display: inline;
  margin-bottom: 0;
}

.form-control-label-text {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0.5rem;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

fieldset[disabled] label,
label.disabled {
  color: #6c757d;
  cursor: not-allowed;
  opacity: 1;
}

fieldset[disabled] label .form-control {
  font-weight: normal;
  opacity: 1;
}

.form-control {
  background-color: #fff;
  border-color: #ced4da;
  border-style: solid;
  border-width: 0.0625rem;
  border-bottom-width: 0.0625rem;
  border-left-width: 0.0625rem;
  border-right-width: 0.0625rem;
  border-top-width: 0.0625rem;
  border-radius: 0.25rem;
  box-shadow: [inset 0 1px 1px rgba(0, 0, 0, 0.075)];
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: 2.375rem;
  letter-spacing: inherit;
  line-height: 1.5;
  min-width: 0;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.c-prefers-reduced-motion .form-control {
  transition: none;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control.focus, .form-control:focus-visible, .c-prefers-focus .form-control:focus {
  background-color: #fff;
  border-color: #8bb3ff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  color: #495057;
  outline: 0;
}
.form-control:focus-within:has(input:focus) {
  background-color: #fff;
  border-color: #8bb3ff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  color: #495057;
}
.form-control:disabled, .form-control.disabled {
  background-color: #f1f2f5;
  cursor: not-allowed;
  opacity: 1;
}
.form-control:not([type=range]) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.form-control::-ms-clear, .form-control::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}
.form-control[contenteditable] p {
  margin-bottom: 0;
}
.form-control .label {
  border-width: 0.0625rem;
  height: auto;
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  min-height: 1.25rem;
}
fieldset[disabled] select.form-control[multiple],
fieldset[disabled] .form-control {
  background-color: #f1f2f5;
  cursor: not-allowed;
  opacity: 1;
}

.form-control-plaintext {
  background-clip: border-box;
  background-color: transparent;
  border-bottom-width: 0.0625rem;
  border-color: transparent;
  border-left-width: 0.0625rem;
  border-right-width: 0.0625rem;
  border-style: solid;
  border-top-width: 0.0625rem;
  color: #272833;
  display: block;
  font-size: 1rem;
  height: 2.375rem;
  letter-spacing: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  min-width: 0;
  padding-bottom: 0.375rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.375rem;
  text-overflow: ellipsis;
  width: 100%;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-hidden {
  left: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

div.form-control {
  height: auto;
  min-height: 2.375rem;
}
div.form-control-lg {
  min-height: 3rem;
}
div.form-control-sm {
  min-height: 1.9375rem;
}

.form-control-tag-group {
  align-items: center;
  color: #6b6c7e;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.form-control-tag-group .autofit-row {
  align-items: center;
  flex-grow: 1;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: auto;
}
.form-control-tag-group .autofit-col {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.form-control-tag-group .autofit-col .form-control-inset {
  width: auto;
}
.form-control-tag-group .input-group-item {
  align-items: center;
}
.form-control-tag-group .inline-item {
  height: 1.5rem;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
}
.form-control-tag-group .btn {
  height: 1.5rem;
  line-height: 1;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
}
.form-control-tag-group .btn .c-inner {
  margin-bottom: 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 0;
}
.form-control-tag-group .btn-monospaced {
  height: 1.5rem;
  line-height: 1;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  padding-left: 0;
  padding-right: 0;
  width: 1.5rem;
}
.form-control-tag-group .btn-monospaced .c-inner {
  margin-left: 0;
  margin-right: 0;
}
.form-control-tag-group .component-action {
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  width: 2rem;
}
.form-control-inset {
  background-color: transparent;
  border-width: 0px;
  color: #495057;
  flex-grow: 1;
  margin-bottom: 0.125rem;
  margin-left: 0.25rem;
  margin-top: 0.125rem;
  min-height: 1.5rem;
  padding: 0;
  width: 50px;
}
.form-control-inset.focus, .form-control-inset:focus-visible, .c-prefers-focus .form-control-inset:focus {
  outline: 0;
}
.form-control-inset:disabled, .form-control-inset.disabled {
  background-color: #f1f2f5;
  cursor: not-allowed;
  opacity: 1;
}
.form-control-inset::-ms-clear, .form-control-inset::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}

select.form-control {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23495057' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 8px 10px;
  cursor: pointer;
  padding-right: 1.75rem;
  color: #495057;
}
select.form-control:disabled > option, select.form-control.disabled > option {
  cursor: not-allowed;
}
select.form-control option {
  cursor: pointer;
}
select.form-control::-ms-expand {
  display: none;
}
select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  background-color: transparent;
  color: inherit;
}

.form-control-select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23495057' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 8px 10px;
  padding-right: 1.75rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-control-select:hover {
  color: inherit;
  text-decoration: none;
}
select.form-control[size] {
  background-image: none;
  height: auto;
  margin-left: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  scrollbar-width: thin;
  color: #495057;
}
select.form-control[size].focus, select.form-control[size]:focus-visible, .c-prefers-focus select.form-control[size]:focus {
  background-image: none;
}
select.form-control[size] option {
  padding: 0.25rem;
}
select.form-control[multiple] {
  background-image: none;
  height: auto;
  margin-left: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  scrollbar-width: thin;
  color: #495057;
}
select.form-control[multiple].focus, select.form-control[multiple]:focus-visible, .c-prefers-focus select.form-control[multiple]:focus {
  background-image: none;
}
select.form-control[multiple] option {
  padding: 0.25rem;
}
textarea.form-control,
textarea.form-control-plaintext,
.form-control.form-control-textarea {
  height: 150px;
  resize: vertical;
}
.form-control-file {
  cursor: pointer;
  display: block;
  width: 100%;
}
.form-control-file::-webkit-file-upload-button {
  cursor: pointer;
}

.form-control[type=range] {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}
.form-control[type=range]:focus {
  box-shadow: none;
}
.form-control[type=range]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-control[type=range]::-webkit-slider-thumb {
  border-radius: 100px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control[type=range]::-webkit-slider-thumb {
    transition: none;
  }
}
.c-prefers-reduced-motion .form-control[type=range]::-webkit-slider-thumb {
  transition: none;
}

.form-control-range {
  display: block;
  width: 100%;
}

.form-check {
  display: block;
  padding-left: 0;
  position: relative;
}

.form-check-label {
  cursor: pointer;
  display: inline;
  margin-bottom: 0;
  position: relative;
}

.form-check-input {
  margin-left: 0;
  margin-top: 0;
  position: static;
}
.form-check-input[disabled], .form-check-input:disabled {
  cursor: not-allowed;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input[disabled] + .form-check-label-text, .form-check-input:disabled ~ .form-check-label,
.form-check-input:disabled + .form-check-label-text {
  color: #6c757d;
  cursor: not-allowed;
}

.form-check-label-text {
  margin-left: -0.1875rem;
  padding-left: 0.5rem;
}

.form-check-inline {
  align-items: center;
  display: inline-flex;
  margin-right: 0.75rem;
  padding-left: 0;
}
.form-check-inline .form-check-input {
  margin-left: 0;
  margin-right: auto;
  margin-top: 0;
  position: static;
}

.form-control-file:disabled {
  cursor: not-allowed;
}
.form-control-file:disabled::-webkit-file-upload-button {
  cursor: not-allowed;
}

.form-control[readonly] {
  background-color: #f1f2f5;
  opacity: 1;
}
.form-control-plaintext[readonly] {
  border-radius: 0.25rem;
  outline: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control-plaintext[readonly] {
    transition: none;
  }
}
.c-prefers-reduced-motion .form-control-plaintext[readonly] {
  transition: none;
}

.form-control-plaintext[readonly].focus, .form-control-plaintext[readonly]:focus-visible, .c-prefers-focus .form-control-plaintext[readonly]:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-control-lg {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: 3rem;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
textarea.form-control-lg,
.form-control-lg.form-control-textarea {
  height: 190px;
}
.sidebar-sm .form-control, .input-group-sm .form-control, .form-group-sm .form-control,
.form-group-sm .form-control-plaintext, .form-control-sm {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: 1.9375rem;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.sidebar-sm select.form-control,
.sidebar-sm .form-control-select, .form-group-sm select.form-control, .form-control-select.form-control-sm {
  height: 1.9375rem;
  padding-right: 2em;
}
.form-group-sm textarea.form-control,
.form-group-sm .form-control.form-control-textarea, textarea.form-control-sm,
.form-control-sm.form-control-textarea {
  height: 120px;
}
.sidebar-sm .form-control-tag-group, .form-group-sm .input-group .form-control-tag-group, .input-group-sm .form-control-tag-group, .form-group-sm .form-control-tag-group.input-group, .form-control-tag-group.input-group-sm, .form-control-tag-group-sm.form-control {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: auto;
  line-height: 1.5;
  min-height: 1.9375rem;
  padding-bottom: 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0;
}
.sidebar-sm .form-control-tag-group .inline-item, .form-group-sm .input-group .form-control-tag-group .inline-item, .input-group-sm .form-control-tag-group .inline-item, .form-group-sm .form-control-tag-group.input-group .inline-item, .form-control-tag-group.input-group-sm .inline-item, .form-control-tag-group-sm.form-control .inline-item {
  margin-bottom: 0;
  margin-top: 0;
}
.sidebar-sm .form-control-tag-group .label, .form-group-sm .input-group .form-control-tag-group .label, .input-group-sm .form-control-tag-group .label, .form-group-sm .form-control-tag-group.input-group .label, .form-control-tag-group.input-group-sm .label, .form-control-tag-group-sm.form-control .label {
  margin-bottom: 0.1875rem;
  margin-right: 0.25rem;
  margin-top: 0.1875rem;
}
.sidebar-sm .form-control-tag-group .form-control-inset, .form-group-sm .input-group .form-control-tag-group .form-control-inset, .input-group-sm .form-control-tag-group .form-control-inset, .form-group-sm .form-control-tag-group.input-group .form-control-inset, .form-control-tag-group.input-group-sm .form-control-inset, .form-control-tag-group-sm.form-control .form-control-inset {
  margin-bottom: 0.125rem;
  margin-left: 0.25rem;
  margin-top: 0.1875rem;
}
.form-group {
  margin-bottom: 1rem;
}

.col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: 0.4375rem;
  padding-top: 0.4375rem;
}

.col-form-label-lg {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-bottom: 0.5625rem;
  padding-top: 0.5625rem;
}

.col-form-label-sm {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    flex: 0 0 auto;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 0;
    width: auto;
  }
  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-left: 0;
    margin-right: 0.25rem;
    margin-top: 0;
    position: relative;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.form-group-autofit {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .form-group-autofit {
    flex-direction: row;
    width: 100%;
  }
}
.form-group-autofit label {
  align-self: flex-start;
}
.form-group-autofit .form-group-item {
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 25px;
  position: relative;
  width: 100%;
}
.form-group-autofit .form-group-item:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .form-group-autofit > .form-group-item:not(:last-child) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}
.form-group-autofit .form-group-item-shrink {
  flex-shrink: 0;
  max-width: 100%;
  width: auto;
}

@media (max-width: 575.98px) {
  .form-group-autofit .form-group-item-label:not(:last-child) {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .form-group-item-label {
    justify-content: center;
    min-height: 2.375rem;
  }
  .form-group-item-label > label {
    margin-bottom: 0;
    max-width: 12.5rem;
  }
}

@media (min-width: 576px) {
  .form-group-item-label-spacer {
    margin-top: 2rem;
  }
}

.form-group {
  position: relative;
}

.form-group-sm {
  margin-bottom: 1rem;
}
.form-group-sm label {
  margin-bottom: 0.25rem;
}
.form-group-sm div.form-control,
.form-group-sm .form-control[contenteditable] {
  height: auto;
  min-height: 1.9375rem;
}
.form-group-sm select[multiple],
.form-group-sm .form-control[size] {
  height: auto;
}
.form-group-sm .form-feedback-item ~ .form-feedback-item,
.form-group-sm .form-feedback-item ~ .form-text,
.form-group-sm .form-text ~ .form-feedback-item,
.form-group-sm .form-text ~ .form-text {
  margin-top: 0;
}
@media (min-width: 576px) {
  .form-group-sm .form-group-item-label {
    min-height: 1.9375rem;
  }
  .form-group-sm .form-group-item-label > label {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .form-group-sm .form-group-item-label-spacer {
    margin-top: 1.75rem;
  }
}

.c-link {
  text-decoration: none;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .c-link {
    transition: none;
  }
}
.c-prefers-reduced-motion .c-link {
  transition: none;
}

.c-link:hover, .c-link.hover {
  text-decoration: none;
}
.c-link.focus, .c-link:focus-visible, .c-prefers-focus .c-link:focus {
  border-radius: 1px;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.c-link.text-secondary {
  color: #272833 !important;
}
.c-link.text-secondary:hover, .c-link.text-secondary.hover {
  color: #000 !important;
}
.c-link.text-secondary.focus, .c-link.text-secondary:focus-visible, .c-prefers-focus .c-link.text-secondary:focus {
  color: #000 !important;
}
.c-link.text-tertiary {
  color: #272833 !important;
}
.c-link.text-tertiary:hover, .c-link.text-tertiary.hover {
  color: #000 !important;
  text-decoration: underline;
}
.component-link {
  color: #6c757d;
}
.component-link:hover, .component-link.hover {
  color: #494f54;
}
.single-link {
  font-weight: 500;
}

.link-primary {
  color: #0b5fff;
}
.link-primary:hover, .link-primary.hover {
  color: #0041be;
}
.link-secondary {
  color: #6b6c7e;
}
.link-secondary:hover, .link-secondary.hover {
  color: #484955;
}
button.link-outline {
  cursor: pointer;
}

.link-outline {
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.0625rem;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .link-outline {
    transition: none;
  }
}
.c-prefers-reduced-motion .link-outline {
  transition: none;
}

.link-outline:hover, .link-outline.hover {
  text-decoration: none;
}
.link-outline.focus, .link-outline:focus-visible, .c-prefers-focus .link-outline:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.link-outline:disabled, .link-outline.disabled {
  box-shadow: none;
}
.link-outline:disabled:active, .link-outline.disabled:active {
  pointer-events: none;
}
.link-outline > .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}
.link-outline .lexicon-icon {
  margin-top: 0;
}

.link-outline-primary {
  border-color: #0b5fff;
  color: #0b5fff;
}
.link-outline-primary:hover, .link-outline-primary.hover {
  background-color: #0b5fff;
  color: #fff;
}
.link-outline-primary:active {
  background-color: #0b5fff;
  color: #fff;
}
.link-outline-primary.active {
  background-color: #0b5fff;
  color: #fff;
}
.link-outline-primary:disabled, .link-outline-primary.disabled {
  background-color: transparent;
  color: #0b5fff;
  cursor: not-allowed;
  opacity: 0.65;
}
.link-outline-primary[aria-expanded=true], .link-outline-primary.show {
  background-color: #0b5fff;
  color: #fff;
}
.link-outline-secondary {
  border-color: #6b6c7e;
  color: #6b6c7e;
}
.link-outline-secondary:hover, .link-outline-secondary.hover {
  background-color: #6b6c7e;
  color: #fff;
}
.link-outline-secondary:active {
  background-color: #6b6c7e;
  color: #fff;
}
.link-outline-secondary.active {
  background-color: #6b6c7e;
  color: #fff;
}
.link-outline-secondary:disabled, .link-outline-secondary.disabled {
  background-color: transparent;
  color: #6b6c7e;
  cursor: not-allowed;
  opacity: 0.65;
}
.link-outline-secondary[aria-expanded=true], .link-outline-secondary.show {
  background-color: #6b6c7e;
  color: #fff;
}
.link-outline-borderless {
  border-color: transparent;
}

.link-monospaced {
  align-items: center;
  display: inline-flex;
  height: 1.9375rem;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  vertical-align: middle;
  width: 1.9375rem;
}
.link-monospaced > .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.link-monospaced .lexicon-icon {
  margin-top: 0;
}

.component-title {
  color: #272833;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: calc(( 						1.9375rem - (1em * 1.2) 					) / 2);
  margin-top: calc(( 						1.9375rem - (1em * 1.2) 					) / 2);
}
.component-title[href],
.component-title a[href] {
  color: #272833;
}
.component-title[href]:hover, .component-title[href].hover,
.component-title a[href]:hover,
.component-title a[href].hover {
  color: #060608;
}
.component-subtitle {
  color: #6c757d;
  margin-bottom: 0;
}
.component-subtitle[href],
.component-subtitle a[href] {
  color: #6c757d;
}
.component-subtitle[href]:hover, .component-subtitle[href].hover,
.component-subtitle a[href]:hover,
.component-subtitle a[href].hover {
  color: #494f54;
}
.component-action {
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.25rem;
  border-width: 0px;
  color: #6b6c7e;
  display: inline-flex;
  height: 1.9375rem;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
  width: 1.9375rem;
}
@media (prefers-reduced-motion: reduce) {
  .component-action {
    transition: none;
  }
}
.c-prefers-reduced-motion .component-action {
  transition: none;
}

.component-action:hover, .component-action.hover {
  background-color: #6b6c7e;
  color: #fff;
}
.component-action.focus, .component-action:focus-visible, .c-prefers-focus .component-action:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.component-action:active {
  background-color: #6b6c7e;
  color: #fff;
}
.component-action.active {
  background-color: #6b6c7e;
  color: #fff;
}
.component-action:disabled, .component-action.disabled {
  background-color: transparent;
  box-shadow: none;
  color: #6b6c7e;
  cursor: not-allowed;
  opacity: 0.65;
}
.component-action:disabled:active, .component-action.disabled:active {
  pointer-events: none;
}
.component-action[aria-expanded=true], .component-action.show {
  background-color: #6b6c7e;
  color: #fff;
}
.component-action .lexicon-icon {
  margin-top: 0;
}

.component-icon {
  align-items: center;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}
.component-icon .lexicon-icon {
  margin-top: 0;
}

.clay-range {
  padding-bottom: 0.1px;
}
.clay-range .input-group {
  align-items: center;
}
.clay-range .input-group-item {
  flex-direction: column;
}
.clay-range .input-group-text {
  background-color: transparent;
  border-width: 0px;
  color: #272833;
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 1.5rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.clay-range.disabled .clay-range-title {
  color: #6c757d;
}
.clay-range.disabled .input-group-text {
  color: #6c757d;
}

.clay-range-progress-none .clay-range-input .clay-range-progress {
  visibility: hidden;
}
.clay-range-progress-none .clay-range-input .clay-range-thumb {
  visibility: hidden;
}
.clay-range-progress-none .clay-range-input .form-control-range {
  content: "";
}
.clay-range-progress-none .clay-range-input .form-control-range::-moz-range-thumb {
  visibility: visible;
}
.clay-range-progress-none .clay-range-input .form-control-range::-moz-range-track {
  -moz-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-range-progress-none .clay-range-input .form-control-range::-ms-fill-lower {
  background-color: transparent;
}
.clay-range-progress-none .clay-range-input .form-control-range::-ms-fill-upper {
  background-color: transparent;
}
.clay-range-progress-none .clay-range-input .form-control-range::-ms-thumb {
  visibility: visible;
}
.clay-range-progress-none .clay-range-input .form-control-range::-ms-tooltip {
  display: none;
}
.clay-range-progress-none .clay-range-input .form-control-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  color: transparent;
  height: 100%;
}
.clay-range-progress-none .clay-range-input .form-control-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-range-progress-none .clay-range-input .form-control-range::-webkit-slider-thumb {
  visibility: visible;
}
.clay-range-progress-none .clay-range-input .form-control-range:hover ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-range-progress-none .clay-range-input .form-control-range.focus ~ .clay-range-progress .tooltip, .clay-range-progress-none .clay-range-input .form-control-range:focus-visible ~ .clay-range-progress .tooltip, .c-prefers-focus .clay-range-progress-none .clay-range-input .form-control-range:focus ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-range-title {
  display: block;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.5rem;
  text-align: center;
}
.clay-range-input {
  display: block;
  padding-bottom: 0.1px;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.clay-range-input .tooltip {
  margin-left: 0.8125rem;
  transition: opacity 0.15s linear;
  visibility: hidden;
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .clay-range-input .tooltip {
    transition: none;
  }
}
.c-prefers-reduced-motion .clay-range-input .tooltip {
  transition: none;
}

.clay-range-input .tooltip-inner {
  padding: 0.25rem 0.5rem;
}
.clay-range-input .tooltip-arrow {
  height: 0.375rem;
  width: 0.375rem;
}
.clay-range-input .clay-tooltip-bottom {
  margin-top: 0.25rem;
  padding-top: 0.1875rem;
  top: 100%;
  transform: translateX(-50%);
}
.clay-range-input .clay-tooltip-bottom .tooltip-arrow {
  margin-left: -0.25rem;
}
.clay-range-input .clay-tooltip-top {
  bottom: 100%;
  margin-bottom: 0.25rem;
  padding-bottom: 0.1875rem;
  transform: translateX(-50%);
}
.clay-range-input .clay-tooltip-top .tooltip-arrow {
  margin-left: -0.25rem;
}
.clay-range-input .clay-range-track {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #dee2e6;
  border-radius: 100px;
  height: 0.25rem;
  margin-top: -0.125rem;
  position: absolute;
  top: 50%;
  width: 100%;
}
.clay-range-input .clay-range-track::-ms-expand {
  display: none;
}
.clay-range-input .clay-range-progress {
  background-color: #0b5fff;
  border-radius: 100px 0 0 100px;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 0.25rem;
  margin-top: -0.125rem;
}
.clay-range-input .clay-range-thumb {
  background-color: #fff;
  border-radius: 100px;
  border-width: 0px;
  box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
  height: 1.5rem;
  margin-top: -0.75rem;
  position: absolute;
  right: -0.75rem;
  top: 50%;
  width: 1.5rem;
}
.clay-range-input .form-control-range {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 2.375rem;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  content: "1.5rem";
}
.clay-range-input .form-control-range::-ms-expand {
  display: none;
}
.clay-range-input .form-control-range::-moz-focus-outer {
  border-width: 0;
}
.clay-range-input .form-control-range::-moz-range-thumb {
  visibility: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 100px;
  border-width: 0px;
  box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
  height: 1.5rem;
  margin-top: -0.75rem;
  position: relative;
  top: 50%;
  width: 1.5rem;
}
.clay-range-input .form-control-range::-moz-range-thumb::-ms-expand {
  display: none;
}
.clay-range-input .form-control-range::-moz-range-track {
  -moz-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-range-input .form-control-range::-ms-fill-lower {
  background-color: transparent;
}
.clay-range-input .form-control-range::-ms-fill-upper {
  background-color: transparent;
}
.clay-range-input .form-control-range::-ms-thumb {
  visibility: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.clay-range-input .form-control-range::-ms-thumb::-ms-expand {
  display: none;
}
.clay-range-input .form-control-range::-ms-tooltip {
  display: none;
}
.clay-range-input .form-control-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  color: transparent;
  height: 100%;
}
.clay-range-input .form-control-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-range-input .form-control-range::-webkit-slider-thumb {
  visibility: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 100px;
  border-width: 0px;
  box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
  height: 1.5rem;
  margin-top: -0.75rem;
  position: relative;
  top: 50%;
  width: 1.5rem;
}
.clay-range-input .form-control-range::-webkit-slider-thumb::-ms-expand {
  display: none;
}
.clay-range-input .form-control-range:hover {
  cursor: pointer;
}
.clay-range-input .form-control-range:hover ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-range-input .form-control-range.focus, .clay-range-input .form-control-range:focus-visible, .c-prefers-focus .clay-range-input .form-control-range:focus {
  outline: 0;
}
.clay-range-input .form-control-range.focus ~ .clay-range-progress .clay-range-thumb, .clay-range-input .form-control-range:focus-visible ~ .clay-range-progress .clay-range-thumb, .c-prefers-focus .clay-range-input .form-control-range:focus ~ .clay-range-progress .clay-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-range-input .form-control-range.focus ~ .clay-range-progress .tooltip, .clay-range-input .form-control-range:focus-visible ~ .clay-range-progress .tooltip, .c-prefers-focus .clay-range-input .form-control-range:focus ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-range-input .form-control-range.focus::-moz-range-thumb, .clay-range-input .form-control-range:focus-visible::-moz-range-thumb, .c-prefers-focus .clay-range-input .form-control-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-range-input .form-control-range.focus::-ms-thumb, .clay-range-input .form-control-range:focus-visible::-ms-thumb, .c-prefers-focus .clay-range-input .form-control-range:focus::-ms-thumb {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-range-input .form-control-range.focus::-webkit-slider-thumb, .clay-range-input .form-control-range:focus-visible::-webkit-slider-thumb, .c-prefers-focus .clay-range-input .form-control-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}

.clay-range-input .form-control-range:disabled {
  color: #6c757d;
  cursor: not-allowed;
}
.clay-range-input .form-control-range:disabled ~ .clay-range-track {
  background-color: #f1f2f5;
}
.clay-range-input .form-control-range:disabled ~ .clay-range-progress {
  background-color: #b3cdff;
}
.clay-range-input[data-label-min], .clay-range-input[data-label-max] {
  margin-bottom: 1rem;
}
.clay-range-input[data-label-min]::before {
  content: '\FEFF' attr(data-label-min);
}
.clay-range-input[data-label-max]::after {
  content: '\FEFF' attr(data-label-max);
  right: 0;
}
.clay-range-input::after, .clay-range-input::before {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1;
  margin-top: 2.5rem;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1.5rem;
}

.clay-range-align-items-start .input-group {
  align-items: flex-start;
}

.clay-range-align-items-end .input-group {
  align-items: flex-end;
}

.clay-reorder {
  padding: 2px;
  position: relative;
  z-index: 0;
}
.clay-reorder .form-control-inset {
  margin-bottom: 0;
  margin-top: 0;
  overflow: auto;
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
  width: 100%;
}
.clay-reorder .form-control-inset.focus, .clay-reorder .form-control-inset:focus-visible, .c-prefers-focus .clay-reorder .form-control-inset:focus {
  background-color: transparent;
  box-shadow: none;
}
.clay-reorder .form-control-inset:focus + .clay-reorder-underlay, .clay-reorder .form-control-inset.focus + .clay-reorder-underlay {
  background-color: #fff;
  border-color: #8bb3ff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-reorder .form-control-inset + .clay-reorder-underlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.clay-reorder-footer {
  padding: 0.5rem;
}

.clay-reorder-footer-invisible.clay-reorder-footer *,
.clay-reorder-footer-invisible .clay-reorder-footer * {
  visibility: hidden;
}

.clay-reorder-footer-center.clay-reorder-footer,
.clay-reorder-footer-center .clay-reorder-footer {
  text-align: center;
}

.clay-reorder-footer-end.clay-reorder-footer,
.clay-reorder-footer-end .clay-reorder-footer {
  text-align: right;
}

.clay-color > .input-group-item > .input-group-inset-item-before {
  color: #6c757d;
  font-size: inherit;
  padding-left: 0.75rem;
  padding-right: 0.5rem;
}
.clay-color > .input-group-item > .input-group-text {
  background-color: #fff;
  border-color: #ced4da;
  padding-left: 0;
  padding-right: 0;
}

.clay-color-dropdown-menu {
  max-height: 500px;
  max-width: none;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  width: 272px;
}
.clay-color-dropdown-menu .component-action:hover {
  background-color: transparent;
  color: #000;
}
.clay-color-dropdown-menu .component-action.focus, .clay-color-dropdown-menu .component-action:focus-visible, .c-prefers-focus .clay-color-dropdown-menu .component-action:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  color: #000;
}
.clay-color-dropdown-menu .component-action:active:focus-visible, .c-prefers-focus .clay-color-dropdown-menu .component-action:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-color-dropdown-menu .form-control {
  font-size: 0.875rem;
  height: 2rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
}
.clay-color-dropdown-menu .form-group {
  margin-bottom: 1rem;
}
.clay-color-dropdown-menu .input-group .input-group-inset-item-before {
  color: #6c757d;
  font-size: 0.875rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.clay-color-btn {
  border-radius: 2px;
  height: 1.5rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  width: 1.5rem;
}
.clay-color-btn:active {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-color-btn.active {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-color-btn[aria-expanded=true], .clay-color-btn.show {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-color-btn .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.clay-color-btn-bordered {
  border-color: #dee2e6;
}
.clay-color-pointer {
  background-color: transparent;
  border-radius: 100px;
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  height: 0.875rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  position: absolute;
  transition: box-shadow 0.15s ease-in-out;
  width: 0.875rem;
}
@media (prefers-reduced-motion: reduce) {
  .clay-color-pointer {
    transition: none;
  }
}
.c-prefers-reduced-motion .clay-color-pointer {
  transition: none;
}

.clay-color-pointer.focus, .clay-color-pointer:focus-visible, .c-prefers-focus .clay-color-pointer:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.clay-color-pointer:active:focus-visible, .c-prefers-focus .clay-color-pointer:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.clay-color-pointer .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.clay-color-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: -0.25rem;
}
.clay-color-header .component-title {
  color: #6c757d;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  max-width: calc(100% - 2rem);
}
.clay-color-footer {
  margin-bottom: 1rem;
}

.clay-color-swatch {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 0.5rem;
}
.clay-color-swatch + .clay-color-swatch {
  margin-top: 0;
}

.clay-color-swatch-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 1rem;
  width: 16.66667%;
}

.clay-color-map-group {
  display: flex;
  margin-top: 0.5rem;
}

.clay-color-map {
  flex-shrink: 0;
  height: 128px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  position: relative;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 144px;
}

.clay-color-map-hsb {
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)), linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.clay-color-map-values {
  flex-grow: 1;
  flex-shrink: 1;
  width: 1%;
}
.clay-color-map-values .form-control {
  padding-left: 0;
  padding-right: 10%;
  text-align: right;
}
.clay-color-map-values .input-group .input-group-inset-item-before {
  font-weight: 500;
  padding-left: 10%;
  padding-right: 0;
  min-width: 1.125rem;
}
.clay-color-range {
  border-radius: 100px;
  height: 0.5rem;
  margin-bottom: 1.25rem;
  margin-top: 0.25rem;
  position: relative;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.clay-color-range-hue {
  background-image: linear-gradient(270deg, #fc0d1b 0%, #fc22d6 18.23%, #1824fb 34.25%, #2bf6fd 50.28%, #2bfd2e 67.58%, #fcfd37 81.22%, #fc121b 100%);
}

.clay-color-range-pointer {
  margin-top: -7px;
  top: 50%;
}
.clay-color-slider .clay-range-input {
  border-radius: 100px;
}
.clay-color-slider .clay-range-input .clay-range-track {
  border-radius: inherit;
  height: 0.5rem;
  margin-top: -0.25rem;
}
.clay-color-slider .clay-range-input .clay-range-progress {
  background-color: transparent;
  border-radius: inherit;
  height: 0.5rem;
  margin-top: -0.25rem;
  width: 100%;
}
.clay-color-slider .clay-range-input .clay-range-thumb {
  background-color: currentColor;
  border-width: 0;
  box-shadow: 0 0 0 0.125rem #fff;
  height: 0.625rem;
  margin-top: -0.3125rem;
  transition: box-shadow 0.15s ease-in-out;
  visibility: hidden;
  width: 0.625rem;
}
@media (prefers-reduced-motion: reduce) {
  .clay-color-slider .clay-range-input .clay-range-thumb {
    transition: none;
  }
}
.c-prefers-reduced-motion .clay-color-slider .clay-range-input .clay-range-thumb {
  transition: none;
}

.clay-color-slider .clay-range-input .form-control-range {
  background-color: inherit;
  border-radius: inherit;
  color: inherit;
  height: 0.5rem;
  content: "0.625rem";
}
.clay-color-slider .clay-range-input .form-control-range::-moz-range-thumb {
  height: 0.625rem;
  visibility: visible;
  width: 0.625rem;
  background-color: currentColor;
  border-width: 0;
  box-shadow: 0 0 0 0.125rem #fff;
  margin-top: -0.3125rem;
}
.clay-color-slider .clay-range-input .form-control-range::-moz-range-track {
  -moz-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-color-slider .clay-range-input .form-control-range::-ms-fill-lower {
  background-color: transparent;
}
.clay-color-slider .clay-range-input .form-control-range::-ms-fill-upper {
  background-color: transparent;
}
.clay-color-slider .clay-range-input .form-control-range::-ms-thumb {
  visibility: visible;
}
.clay-color-slider .clay-range-input .form-control-range::-ms-tooltip {
  display: none;
}
.clay-color-slider .clay-range-input .form-control-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  color: transparent;
  height: 100%;
}
.clay-color-slider .clay-range-input .form-control-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.clay-color-slider .clay-range-input .form-control-range::-webkit-slider-runnable-track::-ms-expand {
  display: none;
}
.clay-color-slider .clay-range-input .form-control-range::-webkit-slider-thumb {
  visibility: visible;
  background-color: currentColor;
  border-width: 0;
  box-shadow: 0 0 0 0.125rem #fff;
  height: 0.625rem;
  margin-top: -0.3125rem;
  width: 0.625rem;
}
.clay-color-slider .clay-range-input .form-control-range:hover ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-color-slider .clay-range-input .form-control-range.focus ~ .clay-range-progress .clay-range-thumb, .clay-color-slider .clay-range-input .form-control-range:focus-visible ~ .clay-range-progress .clay-range-thumb, .c-prefers-focus .clay-color-slider .clay-range-input .form-control-range:focus ~ .clay-range-progress .clay-range-thumb {
  box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #80acff;
}
.clay-color-slider .clay-range-input .form-control-range.focus ~ .clay-range-progress .tooltip, .clay-color-slider .clay-range-input .form-control-range:focus-visible ~ .clay-range-progress .tooltip, .c-prefers-focus .clay-color-slider .clay-range-input .form-control-range:focus ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-color-slider .clay-range-input .form-control-range.focus::-moz-range-thumb, .clay-color-slider .clay-range-input .form-control-range:focus-visible::-moz-range-thumb, .c-prefers-focus .clay-color-slider .clay-range-input .form-control-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #80acff;
}
.clay-color-slider .clay-range-input .form-control-range.focus::-ms-thumb, .clay-color-slider .clay-range-input .form-control-range:focus-visible::-ms-thumb, .c-prefers-focus .clay-color-slider .clay-range-input .form-control-range:focus::-ms-thumb {
  box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #80acff;
}
.clay-color-slider .clay-range-input .form-control-range.focus::-webkit-slider-thumb, .clay-color-slider .clay-range-input .form-control-range:focus-visible::-webkit-slider-thumb, .c-prefers-focus .clay-color-slider .clay-range-input .form-control-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #80acff;
}

.clay-color-slider-hue .clay-range-input {
  color: #26affd;
}
.clay-color-slider-hue .clay-range-input .clay-range-track {
  background-image: linear-gradient(270deg, #fc0d1b 0%, #fc22d6 18.23%, #1824fb 34.25%, #2bf6fd 50.28%, #2bfd2e 67.58%, #fcfd37 81.22%, #fc121b 100%);
}
.clay-color-slider-hue .clay-range-input .form-control-range {
  content: "";
}
.clay-color-slider-hue .clay-range-input .form-control-range::-moz-range-track {
  -moz-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-color-slider-hue .clay-range-input .form-control-range::-ms-fill-lower {
  background-color: transparent;
}
.clay-color-slider-hue .clay-range-input .form-control-range::-ms-fill-upper {
  background-color: transparent;
}
.clay-color-slider-hue .clay-range-input .form-control-range::-ms-tooltip {
  display: none;
}
.clay-color-slider-hue .clay-range-input .form-control-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  color: transparent;
  height: 100%;
}
.clay-color-slider-hue .clay-range-input .form-control-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-color-slider-hue .clay-range-input .form-control-range:hover ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-color-slider-hue .clay-range-input .form-control-range.focus ~ .clay-range-progress .tooltip, .clay-color-slider-hue .clay-range-input .form-control-range:focus-visible ~ .clay-range-progress .tooltip, .c-prefers-focus .clay-color-slider-hue .clay-range-input .form-control-range:focus ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-color-slider-alpha .clay-range-input {
  color: #000;
}
.clay-color-slider-alpha .clay-range-input .clay-range-track {
  background-color: #fff;
  background-image: linear-gradient(45deg, #e7e7ed 25%, transparent 25%), linear-gradient(-45deg, #e7e7ed 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e7e7ed 75%), linear-gradient(-45deg, transparent 75%, #e7e7ed 75%);
  background-position: 0 0, 0 4px, 4px -4px, -4px 0px;
  background-size: 8px 8px;
}
.clay-color-slider-alpha .clay-range-input .form-control-range {
  background-image: linear-gradient(90deg, transparent 0%, currentcolor 100%);
  color: inherit;
  content: "";
}
.clay-color-slider-alpha .clay-range-input .form-control-range::-moz-range-track {
  -moz-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-color-slider-alpha .clay-range-input .form-control-range::-ms-fill-lower {
  background-color: transparent;
}
.clay-color-slider-alpha .clay-range-input .form-control-range::-ms-fill-upper {
  background-color: transparent;
}
.clay-color-slider-alpha .clay-range-input .form-control-range::-ms-tooltip {
  display: none;
}
.clay-color-slider-alpha .clay-range-input .form-control-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  color: transparent;
  height: 100%;
}
.clay-color-slider-alpha .clay-range-input .form-control-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 100%;
}
.clay-color-slider-alpha .clay-range-input .form-control-range:hover ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-color-slider-alpha .clay-range-input .form-control-range.focus ~ .clay-range-progress .tooltip, .clay-color-slider-alpha .clay-range-input .form-control-range:focus-visible ~ .clay-range-progress .tooltip, .c-prefers-focus .clay-color-slider-alpha .clay-range-input .form-control-range:focus ~ .clay-range-progress .tooltip {
  visibility: visible;
  opacity: 1;
}
.clay-color-form-group {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}
.clay-color-form-group .clay-range {
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 1rem;
  width: 144px;
}
.clay-color-form-group .form-control {
  padding-left: 0;
  padding-right: 10%;
  text-align: right;
}
.clay-color-form-group .input-group .input-group-inset-item-before {
  font-weight: 500;
  padding-left: 10%;
  padding-right: 0;
  min-width: 1.125rem;
}

.sidebar-sm .clay-color.input-group > .input-group-item > .input-group-inset-item-before, .form-group-sm .clay-color.input-group > .input-group-item > .input-group-inset-item-before, .clay-color.input-group-sm > .input-group-item > .input-group-inset-item-before {
  padding-left: 0.5rem;
}

.sidebar-sm .clay-color.input-group > .input-group-item > .input-group-text, .form-group-sm .clay-color.input-group > .input-group-item > .input-group-text, .clay-color.input-group-sm > .input-group-item > .input-group-text {
  padding: 0;
}

.sidebar-sm .clay-color.input-group > .input-group-item > .input-group-text > .clay-color-btn, .form-group-sm .clay-color.input-group > .input-group-item > .input-group-text > .clay-color-btn, .clay-color.input-group-sm > .input-group-item > .input-group-text > .clay-color-btn {
  border-radius: 2px;
  height: 1.25rem;
  padding: 0;
  width: 1.25rem;
}
.form-file {
  border-radius: 0.25rem;
  display: flex;
  position: relative;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-file {
    transition: none;
  }
}
.c-prefers-reduced-motion .form-file {
  transition: none;
}

.form-file:focus-within:has(input:focus) {
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
  z-index: 1;
}
.form-file-input {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 10;
}
.form-file-input::-webkit-file-upload-button {
  cursor: pointer;
}
.form-file-input:focus + .input-group {
  border-radius: 1px;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.form-file-input:disabled {
  cursor: not-allowed;
}
.form-file-input:disabled::-webkit-file-upload-button {
  cursor: not-allowed;
}

.custom-control {
  display: block;
  min-height: 1.5rem;
  position: relative;
  text-align: left;
}
.custom-control:only-child {
  margin-bottom: 0;
}
.custom-control label {
  cursor: pointer;
  display: inline;
  font-size: 1rem;
  margin-bottom: 0;
}

.custom-control-label {
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0;
  position: static;
  vertical-align: top;
}
.custom-control-label::before {
  background-color: #fff;
  border-color: #adb5bd;
  border-style: solid;
  border-width: 0.0625rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  content: "";
  display: block;
  float: left;
  font-size: 1rem;
  height: 1rem;
  left: 0;
  position: relative;
  top: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before {
    transition: none;
  }
}
.c-prefers-reduced-motion .custom-control-label::before {
  transition: none;
}

.custom-control-label::after {
  background: no-repeat 50%/50% 50%;
  content: "";
  display: block;
  height: 1rem;
  left: 0;
  position: absolute;
  top: 0.25rem;
  width: 1rem;
}

label.custom-control-label {
  font-size: 1rem;
}

.custom-control-label-text {
  padding-left: 0.5rem;
}
.custom-control-primary .custom-control-label-text {
  font-weight: 500;
}
.custom-control-input {
  cursor: pointer;
  height: 1rem;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0.25rem;
  width: 1rem;
  z-index: 1;
}
.custom-control-input:focus-visible ~ .custom-control-label::before, .c-prefers-focus .custom-control-input:focus ~ .custom-control-label::before {
  border-color: #8bb3ff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #0d60ff;
  border-color: #0d60ff;
  box-shadow: none;
  color: #fff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
  cursor: not-allowed;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f1f2f5;
  border-color: #ced4da;
  box-shadow: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0b5fff;
  border-color: #0b5fff;
  box-shadow: none;
  color: #fff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0b5fff;
  border-color: #0b5fff;
  box-shadow: none;
  color: #fff;
}
.custom-control-input:checked[disabled] ~ .custom-control-label::before, .custom-control-input:checked:disabled ~ .custom-control-label::before {
  background-color: #f1f2f5;
  box-shadow: none;
}
.custom-control-input:checked[readonly] ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #adb5bd;
}
.custom-checkbox .custom-control-input ~ .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='8'%20height='8'%20viewBox='0%200%208%208'%3E%3Cpath%20fill='%23fff'%20d='M6.564.75l-3.59%203.612-1.538-1.55L0%204.26l2.974%202.99L8%202.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='8'%20height='8'%20viewBox='0%200%208%208'%3E%3Cpath%20fill='%23fff'%20d='M6.564.75l-3.59%203.612-1.538-1.55L0%204.26l2.974%202.99L8%202.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:checked[disabled] ~ .custom-control-label::before, .custom-checkbox .custom-control-input:checked:disabled ~ .custom-control-label::before {
  background-color: rgba(11, 95, 255, 0.5);
}
.custom-checkbox .custom-control-input:checked[readonly] ~ .custom-control-label::before {
  background-color: #fff;
}
.custom-checkbox .custom-control-input:checked[readonly] ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='8'%20height='8'%20viewBox='0%200%208%208'%3E%3Cpath%20fill='%236c757d'%20d='M6.564.75l-3.59%203.612-1.538-1.55L0%204.26l2.974%202.99L8%202.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #0b5fff;
  border-color: #0b5fff;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='4'%20height='4'%20viewBox='0%200%204%204'%3E%3Cpath%20stroke='%23fff'%20d='M0%202h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate[disabled] ~ .custom-control-label::before, .custom-checkbox .custom-control-input:indeterminate:disabled ~ .custom-control-label::before {
  background-color: rgba(11, 95, 255, 0.5);
}
.custom-checkbox .custom-control-input:indeterminate[readonly] ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #adb5bd;
}
.custom-checkbox .custom-control-input:indeterminate[readonly] ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='4'%20height='4'%20viewBox='0%200%204%204'%3E%3Cpath%20stroke='%23343a40'%20d='M0%202h4'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input ~ .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='12'%20height='12'%20viewBox='-4%20-4%208%208'%3E%3Ccircle%20r='3'%20fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='12'%20height='12'%20viewBox='-4%20-4%208%208'%3E%3Ccircle%20r='3'%20fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:checked[disabled] ~ .custom-control-label::before, .custom-radio .custom-control-input:checked:disabled ~ .custom-control-label::before {
  background-color: rgba(11, 95, 255, 0.5);
}
.custom-control-outside label {
  display: block;
  padding-left: calc( 					1rem + 0.5rem 				);
}
.custom-control-outside .custom-control-label-text {
  padding-left: 0;
}
.custom-control-outside .custom-control-input ~ .custom-control-label::before {
  position: absolute;
}
.custom-control-inline {
  display: inline-flex;
}
.custom-control-inline + .custom-control-inline {
  margin-left: 1rem;
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  border-radius: 0.5rem;
  left: -2.25rem;
  pointer-events: all;
  width: 1.75rem;
}
.custom-switch .custom-control-label::after {
  background-color: #adb5bd;
  border-radius: 0.5rem;
  height: 0.75rem;
  left: -2.125rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  top: calc((
									1rem *
										1.5 -
										1rem
								) * 0.5 + (0.0625rem * 2));
  width: 0.75rem;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.c-prefers-reduced-motion .custom-switch .custom-control-label::after {
  transition: none;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(11, 95, 255, 0.5);
}

.custom-select {
  -webkit-appearance: none;
  appearance: none;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='4'%20height='5'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23343a40'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  color: #495057;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  height: 2.375rem;
  line-height: 1.5;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  vertical-align: middle;
  width: 100%;
}
.custom-select:focus {
  border-color: #8bb3ff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem #8bb3ff;
  outline: 0;
}
.custom-select:focus::-ms-value {
  background-color: #fff;
  color: #495057;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  background-image: none;
  height: auto;
  padding-right: 0.75rem;
}
.custom-select:disabled {
  background-color: #f1f2f5;
  color: #6c757d;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  font-size: 0.875rem;
  height: 1.9375rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
}

.custom-select-lg {
  font-size: 1.25rem;
  height: 3rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.custom-file {
  display: inline-block;
  height: 2.375rem;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  height: 2.375rem;
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 2;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #8bb3ff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #f1f2f5;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: '\FEFF Browse';
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: '\FEFF' attr(data-browse);
}

.custom-file-label {
  background-color: #fff;
  border: 0.0625rem solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #495057;
  font-weight: 400;
  height: 2.375rem;
  left: 0;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.custom-file-label::after {
  background-color: #f1f2f5;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
  bottom: 0;
  color: #495057;
  content: '\FEFF Browse';
  display: block;
  height: 36px;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.custom-range {
  appearance: none;
  background-color: transparent;
  height: 1.4rem;
  padding: 0;
  width: 100%;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #0b5fff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  height: 1rem;
  margin-top: -0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.c-prefers-reduced-motion .custom-range::-webkit-slider-thumb {
  transition: none;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #bed4ff;
}
.custom-range::-webkit-slider-runnable-track {
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  background-color: #dee2e6;
  border-color: transparent;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.custom-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #0b5fff;
  border-radius: 1rem;
  border: 0;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  height: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.c-prefers-reduced-motion .custom-range::-moz-range-thumb {
  transition: none;
}

.custom-range::-moz-range-thumb:active {
  background-color: #bed4ff;
}
.custom-range::-moz-range-track {
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.custom-range::-ms-thumb {
  appearance: none;
  background-color: #0b5fff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  height: 1rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-top: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.c-prefers-reduced-motion .custom-range::-ms-thumb {
  transition: none;
}

.custom-range::-ms-thumb:active {
  background-color: #bed4ff;
}
.custom-range::-ms-track {
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  border-radius: 1rem;
  background-color: #dee2e6;
  margin-right: 15px;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.c-prefers-reduced-motion .custom-file-label,
.c-prefers-reduced-motion .custom-select {
  transition: none;
}

.clay-time .btn {
  align-items: center;
  display: inline-flex;
  font-size: 0.75rem;
  height: 1.5rem;
  justify-content: center;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  width: 1.5rem;
}
.clay-time .btn .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.clay-time .btn .lexicon-icon {
  margin-top: 0;
}
.clay-time .form-control {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
}
.clay-time .form-control-inset {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  text-align: center;
  width: 1.25rem;
}
.clay-time .form-control-inset::-moz-selection, .clay-time .form-control-inset::selection {
  background-color: transparent;
}
.clay-time .form-control-inset.focus, .clay-time .form-control-inset:focus-visible, .c-prefers-focus .clay-time .form-control-inset:focus {
  background-color: #b3d8fd;
}
.clay-time .input-group-text {
  background-color: transparent;
  border-color: transparent;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.clay-time .clay-time-ampm {
  font-weight: 500;
  margin-left: 0.25rem;
  text-transform: uppercase;
  width: 1.5rem;
}
.clay-time-edit {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.clay-time-edit:first-child {
  margin-left: -0.5rem;
}
.clay-time-edit:last-child {
  margin-right: -0.5rem;
}

.clay-time-action-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.clay-time-action-group-item {
  align-items: center;
  display: flex;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.clay-time-inner-spin {
  border-color: #595a69;
  border-radius: 8px;
  border-style: solid;
  border-width: 0.0625rem;
}
.clay-time-inner-spin .btn {
  align-items: center;
  border-radius: 8px;
  border-width: 0px;
  display: inline-flex;
  font-size: 8px;
  height: auto;
  justify-content: center;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  width: auto;
}
.clay-time-inner-spin .btn .c-inner {
  margin-bottom: -2px;
  margin-left: -2px;
  margin-right: -2px;
  margin-top: -2px;
}
.date-picker .input-group-item {
  margin-left: 0.125rem;
}
.date-picker .input-group-text {
  background-color: transparent;
  border-color: transparent;
  min-width: 2rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
@media (max-width: 575.98px) {
  .date-picker .input-group-text {
    font-size: inherit;
    height: 1.9375rem;
    min-width: 1.5rem;
  }
}
@media (max-width: 575.98px) {
  .date-picker .clay-time .form-control {
    border-radius: 0.2rem;
    font-size: 0.875rem;
    height: 1.9375rem;
    line-height: 1.5;
    min-height: 1.9375rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
  }
}

.date-picker-dropdown-menu {
  max-height: none;
  max-width: 370px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  width: 100%;
}
@media (max-width: 575.98px) {
  .date-picker-dropdown-menu {
    font-size: 0.75rem;
    margin: 0;
    max-height: 255px;
    max-width: 264px;
  }
}

.date-picker-nav {
  display: flex;
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}
.date-picker-nav .nav-btn {
  color: #6c757d;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .date-picker-nav .nav-btn {
    transition: none;
  }
}
.c-prefers-reduced-motion .date-picker-nav .nav-btn {
  transition: none;
}

.date-picker-nav .nav-btn:hover {
  background-color: #f1f2f5;
}
.date-picker-nav .nav-btn.focus, .date-picker-nav .nav-btn:focus-visible, .c-prefers-focus .date-picker-nav .nav-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.date-picker-nav .nav-btn:active:focus-visible, .c-prefers-focus .date-picker-nav .nav-btn:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.date-picker-nav .nav-btn:disabled, .date-picker-nav .nav-btn.disabled {
  background-color: transparent;
  box-shadow: none;
  color: #6c757d;
  opacity: 0.65;
}
.date-picker-nav .nav-btn-monospaced {
  margin-bottom: 0;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  margin-top: 0;
}
@media (max-width: 575.98px) {
  .date-picker-nav .nav-btn-monospaced {
    font-size: 0.75rem;
    height: 1.5rem;
    max-height: 1.5rem;
    max-width: 1.5rem;
    min-width: 1.5rem;
  }
}
.date-picker-nav select.form-control {
  color: #495057;
}
@media (max-width: 575.98px) {
  .date-picker-nav select.form-control {
    font-size: 0.75rem;
    height: 1.5rem;
    color: #495057;
  }
}
.date-picker-nav .form-control-select {
  color: #495057;
}
@media (max-width: 575.98px) {
  .date-picker-nav .form-control-select {
    font-size: 0.75rem;
    height: 1.5rem;
    color: #495057;
  }
}
.date-picker-nav-item {
  align-items: center;
  display: flex;
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.date-picker-nav-item-expand {
  flex-grow: 1;
}

.date-picker-nav-controls {
  display: flex;
  justify-content: flex-end;
}

.date-picker-calendar {
  float: left;
  min-width: 100%;
}

.date-picker-calendar-header {
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}
@media (max-width: 575.98px) {
  .date-picker-calendar-header {
    padding-bottom: 0.25rem;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    padding-top: 0.5rem;
  }
}

.date-picker-calendar-body {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 575.98px) {
  .date-picker-calendar-body {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    padding-bottom: 0.25rem;
  }
}

.date-picker-calendar-footer {
  border-color: #ced4da;
  border-style: solid;
  border-width: 1px 0 0 0;
  padding-bottom: 0.5rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-top: 0.5rem;
}
@media (max-width: 575.98px) {
  .date-picker-calendar-footer {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    padding-top: 0.4375rem;
  }
}

.date-picker-calendar-item {
  align-items: center;
  background-color: transparent;
  border-width: 0px;
  display: inline-flex;
  flex-shrink: 0;
  font-weight: 500;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 2rem;
}
@media (prefers-reduced-motion: reduce) {
  .date-picker-calendar-item {
    transition: none;
  }
}
.c-prefers-reduced-motion .date-picker-calendar-item {
  transition: none;
}

.date-picker-calendar-item:disabled, .date-picker-calendar-item.disabled {
  cursor: not-allowed;
}
.date-picker-calendar-item .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
@media (max-width: 575.98px) {
  .date-picker-calendar-item {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.date-picker-row {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 0;
}
@media (max-width: 575.98px) {
  .date-picker-row {
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }
}

.date-picker-col {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
.date-picker-col.c-selected {
  background-image: linear-gradient(#f0f5ff, #f0f5ff);
  background-repeat: no-repeat;
}
.date-picker-col.c-selected:first-child {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.date-picker-col.c-selected:last-child {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.date-picker-col.c-selected-start {
  background-position: right top;
  background-size: 50% 100%;
}
.date-picker-col.c-selected-end {
  background-position: left top;
  background-size: 50% 100%;
}

.date-picker-days-row {
  margin-bottom: 1rem;
  margin-top: 0;
}
@media (max-width: 575.98px) {
  .date-picker-days-row {
    margin-bottom: 0.25rem;
  }
}

.date-picker-day {
  height: 1.3125rem;
}
.date-picker-date {
  border-radius: 100px;
  color: #6c757d;
  cursor: pointer;
  position: relative;
}
.date-picker-date:hover {
  background-color: #f1f2f5;
}
.date-picker-date.focus, .date-picker-date:focus-visible, .c-prefers-focus .date-picker-date:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.date-picker-date:active {
  background-color: #0b5fff;
  color: #fff;
}
.date-picker-date:active:focus-visible, .c-prefers-focus .date-picker-date:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.date-picker-date.active {
  background-color: #0b5fff;
  color: #fff;
}
.date-picker-date:disabled, .date-picker-date.disabled {
  background-color: transparent;
  box-shadow: none;
  opacity: 0.65;
}
.date-picker-date[aria-expanded=true], .date-picker-date.show {
  background-color: #0b5fff;
  color: #fff;
}
.previous-month-date {
  opacity: 0.65;
}
.previous-month-date.focus, .previous-month-date:focus-visible, .c-prefers-focus .previous-month-date:focus {
  opacity: 1;
}
.previous-month-date:active {
  background-color: #80acff;
  color: #f0f5ff;
}
.previous-month-date.active {
  background-color: #80acff;
  color: #f0f5ff;
}
.previous-month-date[aria-expanded=true], .previous-month-date.show {
  background-color: #80acff;
  color: #f0f5ff;
}
.next-month-date {
  opacity: 0.65;
}
.next-month-date.focus, .next-month-date:focus-visible, .c-prefers-focus .next-month-date:focus {
  opacity: 1;
}
.next-month-date:active {
  background-color: #80acff;
  color: #f0f5ff;
}
.next-month-date.active {
  background-color: #80acff;
  color: #f0f5ff;
}
.next-month-date[aria-expanded=true], .next-month-date.show {
  background-color: #80acff;
  color: #f0f5ff;
}
.clay-dual-listbox {
  display: flex;
  flex-direction: row;
}
.clay-dual-listbox .clay-reorder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.clay-dual-listbox label {
  margin-bottom: 1rem;
}

.clay-dual-listbox-item {
  display: flex;
  flex-direction: column;
  margin-right: 0.25rem;
}
@media (min-width: 576px) {
  .clay-dual-listbox-item {
    margin-right: 1.5rem;
  }
}
.clay-dual-listbox-item:last-child {
  margin-right: 0;
}

.clay-dual-listbox-item-expand {
  flex-grow: 1;
  width: 3rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.clay-dual-listbox-actions {
  align-self: center;
  margin-top: calc(21px + 1rem);
}

.valid-feedback {
  color: #28a745;
  display: none;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}

.valid-tooltip {
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
  border-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  color: #dc3545;
  display: none;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
}

.invalid-tooltip {
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
  border-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-feedback-group {
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.form-feedback-item {
  font-size: 80%;
  margin-top: 0.25rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.form-feedback-indicator {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.form-feedback-indicator:first-child {
  margin-left: 0;
}

.form-text {
  color: #6c757d;
  display: block;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.was-validated .form-control:valid,
.was-validated .form-control.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus,
.was-validated .form-control.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:invalid,
.was-validated .form-control.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus,
.was-validated .form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.has-error .custom-control-label,
.has-error .form-check-label {
  color: #dc3545;
}
.has-error .form-control {
  border-color: #dc3545;
}
.has-error .form-control.focus, .has-error .form-control:focus-visible, .c-prefers-focus .has-error .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.has-error .form-feedback-item {
  color: #dc3545;
}
.has-error select.form-control {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23dc3545' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  color: #495057;
}
.has-error select.form-control[size] {
  background-image: none;
  color: #495057;
}
.has-error select.form-control[multiple] {
  background-image: none;
  color: #495057;
}
.has-error .input-group-item.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.has-error .input-group-item.focus .input-group-inset {
  border-color: #dc3545;
}
.has-error .input-group-item.focus .input-group-inset ~ .input-group-inset-item {
  border-color: #dc3545;
}
.has-error .input-group-item .input-group-inset ~ .input-group-inset-item {
  border-color: #dc3545;
}
.has-error .input-group-item .input-group-inset:focus {
  box-shadow: none;
}
.has-error .input-group-item .input-group-inset:focus ~ .input-group-inset-item {
  border-color: #dc3545;
}

.has-warning .custom-control-label,
.has-warning .form-check-label {
  color: #ffc107;
}
.has-warning .form-control {
  border-color: #ffc107;
}
.has-warning .form-control.focus, .has-warning .form-control:focus-visible, .c-prefers-focus .has-warning .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
.has-warning .form-feedback-item {
  color: #ffc107;
}
.has-warning select.form-control {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23ffc107' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  color: #495057;
}
.has-warning select.form-control[size] {
  background-image: none;
  color: #495057;
}
.has-warning select.form-control[multiple] {
  background-image: none;
  color: #495057;
}
.has-warning .input-group-item.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25);
}
.has-warning .input-group-item.focus .input-group-inset {
  border-color: #ffc107;
}
.has-warning .input-group-item.focus .input-group-inset ~ .input-group-inset-item {
  border-color: #ffc107;
}
.has-warning .input-group-item .input-group-inset ~ .input-group-inset-item {
  border-color: #ffc107;
}
.has-warning .input-group-item .input-group-inset:focus {
  box-shadow: none;
}
.has-warning .input-group-item .input-group-inset:focus ~ .input-group-inset-item {
  border-color: #ffc107;
}

.has-success .custom-control-label,
.has-success .form-check-label {
  color: #28a745;
}
.has-success .form-control {
  border-color: #28a745;
}
.has-success .form-control.focus, .has-success .form-control:focus-visible, .c-prefers-focus .has-success .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.has-success .form-feedback-item {
  color: #28a745;
}
.has-success select.form-control {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%2328a745' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  color: #495057;
}
.has-success select.form-control[size] {
  background-image: none;
  color: #495057;
}
.has-success select.form-control[multiple] {
  background-image: none;
  color: #495057;
}
.has-success .input-group-item.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.has-success .input-group-item.focus .input-group-inset {
  border-color: #28a745;
}
.has-success .input-group-item.focus .input-group-inset ~ .input-group-inset-item {
  border-color: #28a745;
}
.has-success .input-group-item .input-group-inset ~ .input-group-inset-item {
  border-color: #28a745;
}
.has-success .input-group-item .input-group-inset:focus {
  box-shadow: none;
}
.has-success .input-group-item .input-group-inset:focus ~ .input-group-inset-item {
  border-color: #28a745;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  flex: 1 1 0%;
  margin-bottom: 0;
  min-width: 0;
  position: relative;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -0.0625rem;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group > .custom-file {
  align-items: center;
  display: flex;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group-lg > .custom-select {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: 3rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
}

.input-group-sm > .custom-select {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: 1.9375rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group-prepend > .btn + .btn,
.input-group-prepend > .btn + .input-group-text,
.input-group-prepend > .input-group-text + .input-group-text,
.input-group-prepend > .input-group-text + .btn,
.input-group-append > .btn + .btn,
.input-group-append > .btn + .input-group-text,
.input-group-append > .input-group-text + .input-group-text,
.input-group-append > .input-group-text + .btn {
  margin-left: -0.0625rem;
}
.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 1;
}
.input-group-prepend .btn:hover,
.input-group-append .btn:hover {
  z-index: 3;
}
.input-group-prepend .btn,
.input-group-prepend .form-control,
.input-group-append .btn,
.input-group-append .form-control {
  position: relative;
}
.input-group-prepend .btn:focus,
.input-group-prepend .form-control:focus,
.input-group-append .btn:focus,
.input-group-append .form-control:focus {
  z-index: 4;
}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.input-group .btn-unstyled {
  color: inherit;
}
.input-group-item {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: 0.5rem;
  width: 1%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.input-group-item::after {
  border-radius: inherit;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group-item::after {
    transition: none;
  }
}
.c-prefers-reduced-motion .input-group-item::after {
  transition: none;
}

.input-group-item.focus {
  border-radius: 0.25rem;
}
.input-group-item.focus::after {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.input-group-item.focus.input-group-prepend {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;
}
.input-group-item.focus.input-group-append {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group-item.focus > .form-control {
  background-color: #fff;
  border-color: #8bb3ff;
}
.input-group-item.focus > .input-group-inset-item {
  background-color: #fff;
  border-color: #8bb3ff;
}
.input-group-item:first-child {
  margin-left: 0;
}
.input-group-item > .btn {
  align-self: flex-start;
}
.input-group-item > .dropdown {
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;
}
.input-group-item-shrink {
  flex-grow: 0;
  width: auto;
}
.input-group-item-focusable {
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group-item-focusable {
    transition: none;
  }
}
.c-prefers-reduced-motion .input-group-item-focusable {
  transition: none;
}

.input-group-item-focusable:focus-within:has(input:focus) {
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
  z-index: 1;
}
.input-group-item-focusable:focus-within:has(input:focus).input-group-prepend {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-item-focusable:focus-within:has(input:focus).input-group-append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-text {
  align-items: center;
  background-color: #f1f2f5;
  border-bottom-width: 0.0625rem;
  border-color: #ced4da;
  border-left-width: 0.0625rem;
  border-radius: 0.25rem;
  border-right-width: 0.0625rem;
  border-style: solid;
  border-top-width: 0.0625rem;
  color: #495057;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  height: 2.375rem;
  justify-content: center;
  line-height: 1.5;
  margin-bottom: 0;
  min-width: 2.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  text-align: center;
  white-space: nowrap;
}
.input-group-text label {
  color: #495057;
}
.input-group-text .custom-control {
  margin-bottom: 0;
}
.input-group-text .form-check {
  margin-bottom: 0;
}
.input-group-text .form-check input[type=radio],
.input-group-text .form-check input[type=checkbox] {
  margin-top: 0;
}
.input-group-text .lexicon-icon {
  margin-top: 0;
}
.input-group-text-secondary {
  background-color: #6b6c7e;
  border-color: #6b6c7e;
  border-width: 0.0625rem;
  color: #fff;
  z-index: 2;
}
.input-group-text-secondary label {
  color: #fff;
}
@media (max-width: 575.98px) {
  .input-group-stacked-sm-down > .input-group-item {
    margin-bottom: 0.5rem;
    margin-left: 0;
    width: 100%;
  }
  .input-group-stacked-sm-down > .input-group-item-shrink {
    margin-right: 0.5rem;
    width: auto;
  }
}

.input-group-lg > .input-group-item > .btn {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.input-group-lg > .input-group-item > .btn .c-inner {
  margin-bottom: -0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
}
.input-group-lg > .input-group-item > .btn .btn-section {
  font-size: 0.8125rem;
}
.input-group-lg > .input-group-item > .btn-monospaced {
  height: 3rem;
  line-height: 1;
  width: 3rem;
}
.input-group-lg > .input-group-item > .form-control {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: auto;
  line-height: 1.5;
  min-height: 3rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.input-group-lg > .input-group-item > .form-file .btn {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: 3rem;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.input-group-lg > .input-group-item > .form-file .btn .c-inner {
  margin-bottom: -0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
}
.input-group-lg > .input-group-item > textarea.form-control,
.input-group-lg > .input-group-item > .form-control-textarea {
  height: 190px;
}
.input-group-lg > .input-group-item > .form-control-plaintext {
  font-size: 1.25rem;
  height: 3rem;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.input-group-lg > .input-group-item > .input-group-text {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: 3rem;
  min-width: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.input-group-lg > .input-group-item > .input-group-inset-item > .form-file .btn {
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: 3rem;
  line-height: 1.5;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.input-group-lg > .input-group-item > .input-group-inset-item > .form-file .btn .c-inner {
  margin-bottom: -0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
}
.input-group-lg > .input-group-item .form-control-inset {
  margin-bottom: 0;
  margin-top: 0;
}
.sidebar-sm .input-group > .input-group-item > .btn, .form-group-sm .input-group > .input-group-item > .btn, .input-group-sm > .input-group-item > .btn {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.sidebar-sm .input-group > .input-group-item > .btn .c-inner, .form-group-sm .input-group > .input-group-item > .btn .c-inner, .input-group-sm > .input-group-item > .btn .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}
.sidebar-sm .input-group > .input-group-item > .btn .btn-section, .form-group-sm .input-group > .input-group-item > .btn .btn-section, .input-group-sm > .input-group-item > .btn .btn-section {
  font-size: 0.5625rem;
}
.sidebar-sm .input-group > .input-group-item > .btn-monospaced, .form-group-sm .input-group > .input-group-item > .btn-monospaced, .input-group-sm > .input-group-item > .btn-monospaced {
  height: 1.9375rem;
  line-height: 1;
  width: 1.9375rem;
}
.sidebar-sm .input-group > .input-group-item > .form-file .btn, .form-group-sm .input-group > .input-group-item > .form-file .btn, .input-group-sm > .input-group-item > .form-file .btn {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: 1.9375rem;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.sidebar-sm .input-group > .input-group-item > .form-file .btn .c-inner, .form-group-sm .input-group > .input-group-item > .form-file .btn .c-inner, .input-group-sm > .input-group-item > .form-file .btn .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}
.sidebar-sm .input-group > .input-group-item > textarea.form-control, .form-group-sm .input-group > .input-group-item > textarea.form-control, .input-group-sm > .input-group-item > textarea.form-control,
.sidebar-sm .input-group > .input-group-item > .form-control-textarea,
.form-group-sm .input-group > .input-group-item > .form-control-textarea,
.input-group-sm > .input-group-item > .form-control-textarea {
  height: 120px;
}
.sidebar-sm .input-group > .input-group-item > .form-control-plaintext, .form-group-sm .input-group > .input-group-item > .form-control-plaintext, .input-group-sm > .input-group-item > .form-control-plaintext {
  font-size: 0.875rem;
  height: 1.9375rem;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.sidebar-sm .input-group > .input-group-item > .input-group-text, .form-group-sm .input-group > .input-group-item > .input-group-text, .input-group-sm > .input-group-item > .input-group-text {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: 1.9375rem;
  min-width: 1.9375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.sidebar-sm .input-group > .input-group-item > .input-group-inset-item > .btn, .form-group-sm .input-group > .input-group-item > .input-group-inset-item > .btn, .input-group-sm > .input-group-item > .input-group-inset-item > .btn {
  line-height: 1;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
}
.sidebar-sm .input-group > .input-group-item > .input-group-inset-item > .btn .c-inner, .form-group-sm .input-group > .input-group-item > .input-group-inset-item > .btn .c-inner, .input-group-sm > .input-group-item > .input-group-inset-item > .btn .c-inner {
  margin-bottom: 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 0;
}
.sidebar-sm .input-group > .input-group-item > .input-group-inset-item > .btn-monospaced, .form-group-sm .input-group > .input-group-item > .input-group-inset-item > .btn-monospaced, .input-group-sm > .input-group-item > .input-group-inset-item > .btn-monospaced {
  height: 1.5rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  width: 1.5rem;
}
.sidebar-sm .input-group > .input-group-item > .input-group-inset-item > .form-file .btn, .form-group-sm .input-group > .input-group-item > .input-group-inset-item > .form-file .btn, .input-group-sm > .input-group-item > .input-group-inset-item > .form-file .btn {
  border-radius: 0.2rem;
  font-size: 0.875rem;
  height: 1.9375rem;
  line-height: 1.5;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.sidebar-sm .input-group > .input-group-item > .input-group-inset-item > .form-file .btn .c-inner, .form-group-sm .input-group > .input-group-item > .input-group-inset-item > .form-file .btn .c-inner, .input-group-sm > .input-group-item > .input-group-inset-item > .form-file .btn .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}
.input-group-inset {
  flex-grow: 1;
  order: 5;
  width: 1%;
}
.input-group-inset.focus, .input-group-inset:focus-visible, .c-prefers-focus .input-group-inset:focus {
  box-shadow: none;
}
.input-group-inset.focus ~ .input-group-inset-item, .input-group-inset:focus-visible ~ .input-group-inset-item, .c-prefers-focus .input-group-inset:focus ~ .input-group-inset-item {
  background-color: #fff;
  border-color: #8bb3ff;
}

.input-group-inset:disabled ~ .input-group-inset-item, .input-group-inset.disabled ~ .input-group-inset-item {
  background-color: #f1f2f5;
}
.input-group-inset ~ .form-feedback-group {
  order: 13;
}

.input-group-inset[readonly] ~ .input-group-inset-item {
  background-color: #f1f2f5;
}
.input-group .input-group-inset-item {
  align-items: center;
  background-color: #fff;
  border-bottom-width: 0.0625rem;
  border-color: #ced4da;
  border-left-width: 0.0625rem;
  border-right-width: 0.0625rem;
  border-style: solid;
  border-top-width: 0.0625rem;
  display: flex;
  margin-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group .input-group-inset-item {
    transition: none;
  }
}
.c-prefers-reduced-motion .input-group .input-group-inset-item {
  transition: none;
}

.input-group .input-group-inset-item .btn {
  align-items: center;
  display: flex;
  height: 75%;
  justify-content: center;
  line-height: 1;
  margin-left: 0.1875rem;
  margin-right: 0.1875rem;
  padding-bottom: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
}
.input-group .input-group-inset-item .btn .c-inner {
  margin-bottom: 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 0;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.input-group .input-group-inset-item .btn .lexicon-icon {
  margin-top: 0;
}
.input-group .input-group-inset-item .btn .c-inner .lexicon-icon {
  margin-top: 0;
}
.input-group .input-group-inset-item .btn-monospaced {
  font-size: 1rem;
  height: 2rem;
  margin-bottom: -0.0625rem;
  margin-top: -0.0625rem;
  padding: 0;
  width: 2rem;
}
.input-group .input-group-inset-item .form-file {
  height: 75%;
}
.input-group .input-group-inset-item .form-file .btn {
  height: 100%;
}
.input-group .input-group-item .input-group-inset-before.form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-left-width: 0;
  padding-left: 0;
}
.input-group .input-group-inset-item-before {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-right-width: 0;
  color: #495057;
  order: 3;
}
.input-group .input-group-append > .input-group-inset-item-before {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group .input-group-item .input-group-inset-after.form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right-width: 0;
  padding-right: 0;
}
.input-group .input-group-inset-item-after {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-left-width: 0;
  color: #495057;
  order: 9;
}
.input-group-prepend .input-group .input-group-inset-item-after {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;
}

.input-group > .input-group-item.input-group-prepend {
  align-items: stretch;
  display: flex;
  margin-right: -0.0625rem;
}
.input-group > .input-group-item.input-group-prepend:not(:last-child) > .btn,
.input-group > .input-group-item.input-group-prepend:not(:last-child) > .form-control,
.input-group > .input-group-item.input-group-prepend:not(:last-child) > .input-group-text {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group > .input-group-item.input-group-prepend:not(:last-child) > .btn + .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group > .input-group-item.input-group-prepend + .input-group-prepend {
  margin-left: 0;
}
.input-group > .input-group-item.input-group-prepend + .input-group-prepend > .btn,
.input-group > .input-group-item.input-group-prepend + .input-group-prepend > .form-control,
.input-group > .input-group-item.input-group-prepend + .input-group-prepend > .input-group-text {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group > .input-group-item.input-group-append {
  align-items: stretch;
  display: flex;
  margin-right: -0.0625rem;
}
.input-group > .input-group-item.input-group-append:first-child > .btn,
.input-group > .input-group-item.input-group-append:first-child > .form-control,
.input-group > .input-group-item.input-group-append:first-child > .input-group-text {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group > .input-group-item.input-group-append:not(:first-child) > .btn,
.input-group > .input-group-item.input-group-append:not(:first-child) > .form-control,
.input-group > .input-group-item.input-group-append:not(:first-child) > .form-file .btn,
.input-group > .input-group-item.input-group-append:not(:first-child) > .input-group-text {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.input-group > .input-group-item.input-group-append + .input-group-append, .input-group > .input-group-item.input-group-prepend + .input-group-append {
  margin-left: 0;
}

.input-group-password .form-control[type=text] ~ .input-group-inset-item .input-password-label {
  display: none;
}
.input-group-password .form-control[type=password] ~ .input-group-inset-item .input-text-label {
  display: none;
}

.list-group-item-action {
  color: #495057;
  text-align: inherit;
  width: 100%;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  background-color: #f7f8f9;
  color: #495057;
  text-decoration: none;
  z-index: 1;
}
.list-group-item-action:active {
  background-color: #f1f2f5;
  color: #272833;
}

.list-group {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding-left: 0;
}
.list-group-bordered .list-group-item-flex {
  padding: 0;
}
.list-group-bordered .list-group-item-flex.active .autofit-col {
  border-color: #0b5fff;
}
.list-group-bordered .autofit-col {
  border-left: 0.0625rem solid rgba(0, 0, 0, 0.125);
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.125);
  margin-left: -0.0625rem;
  padding: 0.75rem 1.25rem;
}
.list-group-bordered .autofit-col:first-child, .list-group-bordered .autofit-col:last-child {
  border-left-width: 0;
  border-right-width: 0;
  margin-left: 0;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-left-width: 0;
  border-top-width: 0.0625rem;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  border-left-width: 0.0625rem;
  margin-left: -0.0625rem;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 0.0625rem;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    border-left-width: 0.0625rem;
    margin-left: -0.0625rem;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 0.0625rem;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    border-left-width: 0.0625rem;
    margin-left: -0.0625rem;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 0.0625rem;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    border-left-width: 0.0625rem;
    margin-left: -0.0625rem;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: 0.0625rem;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    border-left-width: 0.0625rem;
    margin-left: -0.0625rem;
  }
}
.list-group-header {
  align-items: center;
  background-color: #fff;
  border: 0.0625rem solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: space-between;
  margin-bottom: -0.0625rem;
  padding: 0.75rem 1.25rem;
}
.list-group-header:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-header:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.list-group-header-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}
.list-group-title {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: -0.25rem;
}
.list-group-title[href],
.list-group-title a[href] {
  color: #272833;
  max-width: 100%;
}
.list-group-title[href]:hover, .list-group-title[href].hover,
.list-group-title a[href]:hover,
.list-group-title a[href].hover {
  color: #060608;
}
.list-group-subtitle {
  color: #6c757d;
  margin-bottom: 0;
}
.list-group-subtitle[href],
.list-group-subtitle a[href] {
  color: #6c757d;
}
.list-group-subtitle[href]:hover, .list-group-subtitle[href].hover,
.list-group-subtitle a[href]:hover,
.list-group-subtitle a[href].hover {
  color: #494f54;
}
.list-group-text {
  color: #272833;
  margin-bottom: 0;
}
.list-group-text[href],
.list-group-text a[href] {
  color: #272833;
}
.list-group-text[href]:hover, .list-group-text[href].hover,
.list-group-text a[href]:hover,
.list-group-text a[href].hover {
  color: #060608;
}
.list-group-subtext {
  color: #6c757d;
  margin-bottom: 0;
}
.list-group-subtext[href],
.list-group-subtext a[href] {
  color: #6c757d;
}
.list-group-subtext[href]:hover, .list-group-subtext[href].hover,
.list-group-subtext a[href]:hover,
.list-group-subtext a[href].hover {
  color: #494f54;
}
.show-dropdown-action-on-active .list-group-item.active .dropdown-action .dropdown-menu {
  background-color: #0b5fff;
}
.show-dropdown-action-on-active .list-group-item.active .dropdown-action .dropdown-item {
  color: #fff;
}

.list-group-item {
  background-color: #fff;
  border: 0.0625rem solid rgba(0, 0, 0, 0.125);
  display: block;
  padding: 0.75rem 1.25rem;
  position: relative;
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.list-group-item.active {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
  z-index: 2;
}
.list-group-item.active:focus, .list-group-item.active.focus {
  z-index: 2;
}
.list-group-item.active .dropdown-action .dropdown-toggle,
.list-group-item.active .list-group-link {
  color: #fff;
}
.list-group-item.active .list-group-title,
.list-group-item.active a.list-group-title,
.list-group-item.active .list-group-title a {
  color: #fff;
}
.list-group-item.active .list-group-text,
.list-group-item.active a.list-group-text,
.list-group-item.active .list-group-text a {
  color: #fff;
}
.list-group-item.active .list-group-subtext,
.list-group-item.active a.list-group-subtext,
.list-group-item.active .list-group-subtext a {
  color: #fff;
}
.list-group-item.active .list-group-link {
  color: #fff;
}
.list-group-item.disabled, .list-group-item:disabled {
  background-color: #fff;
  color: #6c757d;
  pointer-events: none;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  border-top-width: 0.0625rem;
  margin-top: -0.0625rem;
}

.list-group-item-flex {
  display: flex;
  margin-bottom: -0.0625rem;
  padding-left: calc(1.25rem * 0.5);
  padding-right: calc(1.25rem * 0.5);
}
.list-group-item-flex:last-child {
  margin-bottom: 0;
}
.list-group-item-flex + .list-group-item-flex {
  border-top-width: 0.0625rem;
}
.list-group-item-flex + .list-group-item-flex.active {
  margin-top: 0;
}
.list-group-item-flex .autofit-col {
  justify-content: flex-start;
  padding-left: calc(1.25rem * 0.5);
  padding-right: calc(1.25rem * 0.5);
}
.list-group-item-flex .autofit-col .custom-control:only-child {
  margin-bottom: 0;
  margin-top: 0.0625rem;
}
.list-group-item-flex .autofit-col .custom-control:only-child .custom-control-input,
.list-group-item-flex .autofit-col .custom-control:only-child .custom-control-label::before,
.list-group-item-flex .autofit-col .custom-control:only-child .custom-control-label::after {
  margin-top: 0.1875rem;
}
.list-group-item-flex .autofit-col .form-check:only-child {
  margin-bottom: 0;
  margin-top: 0.0625rem;
  padding-top: 0.1875rem;
}
.list-group-item-flex .autofit-col .sticker:only-child {
  margin-top: 0.0625rem;
}
.list-group-item-flex .autofit-col .list-group-title:only-child {
  align-items: center;
  display: flex;
  min-height: 32px;
  margin-top: 0;
}
.list-group-item-flex .autofit-col .list-group-title:only-child::after {
  content: "";
  display: block;
  min-height: inherit;
}
.list-group-item-flex .autofit-col .dropdown-action {
  margin-top: 0.0625rem;
}
.list-group-item-flex .autofit-col .quick-action-item {
  margin-top: 0.0625rem;
}

.list-group-item-action .c-inner {
  margin: -0.75rem -1.25rem;
  width: auto;
}

.list-group-item-action:focus {
  z-index: 1;
}

.sidebar-list-group .list-group-item, .list-group-flush .list-group-item {
  border-left-width: 0;
  border-radius: 0;
  border-right-width: 0;
}
.sidebar-list-group .list-group-item:first-child, .list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.sidebar-list-group .list-group-item:last-child .list-group-item:last-child, .list-group-flush .list-group-item:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}
.sidebar-list-group .list-group-item > .autofit-col:first-child, .list-group-flush .list-group-item > .autofit-col:first-child {
  padding-left: 0;
}
.sidebar-list-group .list-group-item > .autofit-col:last-child, .list-group-flush .list-group-item > .autofit-col:last-child {
  padding-right: 0;
}

.list-group-item-primary {
  background-color: #bbd2ff;
  color: #063185;
}
.list-group-item-primary.list-group-item-action:hover {
  background-color: #a2c1ff;
  color: #063185;
}
.list-group-item-primary.list-group-item-action.active {
  background-color: #063185;
  border-color: #063185;
  color: #fff;
}

.list-group-item-secondary {
  background-color: #d6d6db;
  color: #383842;
}
.list-group-item-secondary.list-group-item-action:hover {
  background-color: #c8c8cf;
  color: #383842;
}
.list-group-item-secondary.list-group-item-action.active {
  background-color: #383842;
  border-color: #383842;
  color: #fff;
}

.list-group-item-success {
  background-color: #c3e6cb;
  color: #155724;
}
.list-group-item-success.list-group-item-action:hover {
  background-color: #b1dfbb;
  color: #155724;
}
.list-group-item-success.list-group-item-action.active {
  background-color: #155724;
  border-color: #155724;
  color: #fff;
}

.list-group-item-info {
  background-color: #bee5eb;
  color: #0c5460;
}
.list-group-item-info.list-group-item-action:hover {
  background-color: #abdde5;
  color: #0c5460;
}
.list-group-item-info.list-group-item-action.active {
  background-color: #0c5460;
  border-color: #0c5460;
  color: #fff;
}

.list-group-item-warning {
  background-color: #ffeeba;
  color: #856404;
}
.list-group-item-warning.list-group-item-action:hover {
  background-color: #ffe8a1;
  color: #856404;
}
.list-group-item-warning.list-group-item-action.active {
  background-color: #856404;
  border-color: #856404;
  color: #fff;
}

.list-group-item-danger {
  background-color: #f5c6cb;
  color: #721c24;
}
.list-group-item-danger.list-group-item-action:hover {
  background-color: #f1b0b7;
  color: #721c24;
}
.list-group-item-danger.list-group-item-action.active {
  background-color: #721c24;
  border-color: #721c24;
  color: #fff;
}

.list-group-item-light {
  background-color: #fdfdfd;
  color: #808181;
}
.list-group-item-light.list-group-item-action:hover {
  background-color: #f0f0f0;
  color: #808181;
}
.list-group-item-light.list-group-item-action.active {
  background-color: #808181;
  border-color: #808181;
  color: #fff;
}

.list-group-item-dark {
  background-color: #c6c8ca;
  color: #1b1e21;
}
.list-group-item-dark.list-group-item-action:hover {
  background-color: #b9bbbe;
  color: #1b1e21;
}
.list-group-item-dark.list-group-item-action.active {
  background-color: #1b1e21;
  border-color: #1b1e21;
  color: #fff;
}

.show-quick-actions-on-hover .list-group-item:not(.active):hover .quick-action-menu {
  display: flex;
}
.show-quick-actions-on-hover .focus:not(.active) .quick-action-menu {
  display: flex;
}

.list-group .quick-action-menu {
  align-items: flex-start;
  background-color: #fff;
  margin-bottom: -0.75rem;
  margin-top: -0.75rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.list-group-bordered .quick-action-menu {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.list-group-item-flex.active .quick-action-menu {
  background-color: #0b5fff;
}
.list-group-item-flex.active .quick-action-item {
  color: #fff;
}

.list-group-notification.list-group-bordered .quick-action-menu {
  margin-bottom: 0.0625rem;
  margin-top: 0.0625rem;
}
.list-group-notification .list-group-item {
  border-width: 0;
}
.list-group-notification .list-group-item-flex.active {
  border-top-width: 0;
}
.list-group-notification .list-group-item-primary {
  background-color: #fff;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  box-shadow: inset 0.5rem 0 #bbd2ff, inset -0.0625rem 0 rgba(0, 0, 0, 0.125), inset 0 0.0625rem rgba(0, 0, 0, 0.125), inset 0 -0.0625rem rgba(0, 0, 0, 0.125);
}
.list-group-notification .list-group-item-primary.active {
  background-color: #0b5fff;
  box-shadow: inset 0.5rem 0 #bbd2ff, inset -0.0625rem 0 #0b5fff, inset 0 0.0625rem #0b5fff, inset 0 -0.0625rem #0b5fff;
}
.list-group-notification .quick-action-menu {
  margin-bottom: -0.6875rem;
  margin-top: -0.6875rem;
}
.list-group-notification .autofit-col .quick-action-item {
  margin-top: 0rem;
}

.list-group-sm .list-group-item {
  padding-bottom: 0.40625rem;
  padding-top: 0.40625rem;
}
.list-group-sm .quick-action-menu {
  margin-bottom: -0.40625rem;
  margin-top: -0.40625rem;
  padding-bottom: 0.40625rem;
  padding-top: 0.40625rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}
@media (max-width: 767.98px) {
  .modal .close {
    margin-right: -0.5rem;
  }
  .modal .modal-header {
    height: 3rem;
    padding: 0.5rem 1rem;
  }
  .modal .modal-title {
    font-size: 1.125rem;
  }
  .modal .modal-body {
    padding: 0.5rem 1rem;
  }
  .modal .modal-body-iframe {
    padding: 0;
  }
  .modal .modal-footer {
    padding: 0.5rem;
  }
}

.modal-dialog {
  margin: 0.5rem;
  position: relative;
  pointer-events: none;
  width: auto;
}
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.c-prefers-reduced-motion .modal.fade .modal-dialog {
  transition: none;
}

.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
    max-width: 500px;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered .modal-dialog,
  .modal-dialog-centered.modal-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered .modal-dialog::before,
  .modal-dialog-centered.modal-dialog::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered .modal-dialog,
.modal-dialog-centered.modal-dialog {
  align-items: center;
  display: flex;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered .modal-dialog::before,
.modal-dialog-centered.modal-dialog::before {
  content: "";
  display: block;
  height: calc(100vh - 1rem);
}
.modal-dialog-centered .modal-dialog.modal-dialog-scrollable,
.modal-dialog-centered.modal-dialog.modal-dialog-scrollable {
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.modal-dialog-centered .modal-dialog.modal-dialog-scrollable .modal-content,
.modal-dialog-centered.modal-dialog.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered .modal-dialog.modal-dialog-scrollable::before,
.modal-dialog-centered.modal-dialog.modal-dialog-scrollable::before {
  content: none;
}

.modal-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 0.3rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 0.5rem * 2);
  outline: 0;
  overflow: hidden;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-header {
  align-items: center;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 0.0625rem 0;
  display: flex;
  flex-shrink: 0;
  height: 4rem;
  justify-content: space-between;
  padding: 1rem 1rem;
  padding-bottom: 0;
  padding-top: 0;
  z-index: 1;
}

.modal-body {
  border-top: 0.0625rem solid #dee2e6;
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: -0.0625rem;
  overflow: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding: 1rem;
  position: relative;
}
.modal-body.inline-scroller {
  max-height: none;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  padding: 1rem;
}

.modal-body-flush {
  padding: 0;
}

.modal-footer {
  align-items: center;
  border-bottom-left-radius: calc( 	0.3rem - 0.0625rem );
  border-bottom-right-radius: calc( 	0.3rem - 0.0625rem );
  border-top: 0.0625rem solid #dee2e6;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
}
.modal-footer > * {
  margin: 0 calc(0.5rem * 0.5);
}

.modal-item-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-width: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
}
.modal-item-group:first-child, .modal-item-group.modal-item-group-first {
  padding-left: 0;
}
.modal-item-group:last-child, .modal-item-group.modal-item-group-last {
  padding-right: 0;
}
.modal-item-group:only-child, .modal-item-group.modal-item-group-only {
  padding-left: 0;
  padding-right: 0;
}

.modal-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
  min-height: 0;
  min-width: 3.125rem;
  padding: 0;
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.modal-item-shrink {
  flex-grow: 0;
}

.modal-item-last {
  margin-left: auto;
}

.modal-footer > .modal-item-last {
  margin-left: auto;
  margin-right: 0;
}

.modal-title {
  flex-grow: 1;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal-title[tabindex="-1"] {
  outline: 0;
}

.modal-title-indicator {
  display: inline-block;
  font-size: 0.875rem;
  margin-right: 0.5rem;
  margin-top: -0.2em;
  vertical-align: middle;
}

.modal-subtitle {
  display: inline-block;
}
.modal-subtitle-divider {
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.close + .modal-title {
  margin-left: 0.3125rem;
}

.modal-title + .close {
  margin-right: 0.3125rem;
}

.modal-body-iframe {
  height: 160px;
  padding: 0;
}
.modal-body-iframe iframe {
  border-width: 0;
  height: 100%;
  position: absolute;
  width: 100%;
}

.modal-full-screen {
  bottom: 45px;
  left: 45px;
  margin: 0;
  max-width: none;
  position: absolute;
  right: 45px;
  top: 45px;
}
.modal-full-screen .modal-content {
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  border-width: 0.0625rem;
  height: 100%;
}
.modal-full-screen .modal-body {
  overflow: auto;
}

@media (max-width: 767.98px) {
  .modal-full-screen-sm-down {
    bottom: 0;
    left: 0;
    margin: 0;
    max-width: none;
    position: absolute;
    right: 0;
    top: 0;
  }
  .modal-full-screen-sm-down .modal-body {
    overflow: auto;
  }
  .modal-full-screen-sm-down .modal-content {
    border-radius: 0;
    border-width: 0;
    height: 100%;
  }
}

.modal-height-sm .modal-content {
  height: 250px;
}

.modal-height-md .modal-content {
  height: 450px;
}

.modal-height-lg .modal-content {
  height: 650px;
}

.modal-height-xl .modal-content {
  height: 800px;
}

.modal-height-full .modal-dialog {
  height: 100%;
  margin: 0;
}
@media (min-width: 576px) {
  .modal-height-full .modal-dialog {
    bottom: 0;
    height: 100%;
    left: 50%;
    margin-bottom: 0;
    margin-left: calc(-500px / 2);
    margin-right: 0;
    margin-top: 0;
    top: 0;
  }
}
@media (min-width: 576px) {
  .modal-height-full .modal-dialog.modal-sm {
    margin-left: calc(-300px / 2);
  }
}
@media (min-width: 992px) {
  .modal-height-full .modal-dialog.modal-lg {
    margin-left: calc(-800px / 2);
  }
}
@media (min-width: 992px) {
  .modal-height-full .modal-dialog.modal-xl {
    margin-left: calc(-800px / 2);
  }
}
@media (min-width: 1200px) {
  .modal-height-full .modal-dialog.modal-xl {
    margin-left: calc(-1140px / 2);
  }
}
.modal-height-full .modal-body {
  overflow: auto;
}
.modal-height-full .modal-content {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  top: 0.5rem;
}
@media (min-width: 576px) {
  .modal-height-full .modal-content {
    bottom: 1.75rem;
    left: auto;
    right: auto;
    top: 1.75rem;
  }
}

.modal-success .modal-header {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}
.modal-success .modal-header .close {
  color: #155724;
}
.modal-success .modal-header .close:hover {
  color: inherit;
}
.modal-success .modal-header .close:focus {
  color: inherit;
}
.modal-success .modal-header .close:disabled {
  color: inherit;
}
.modal-info .modal-header {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  color: #0c5460;
}
.modal-info .modal-header .close {
  color: #0c5460;
}
.modal-info .modal-header .close:hover {
  color: inherit;
}
.modal-info .modal-header .close:focus {
  color: inherit;
}
.modal-info .modal-header .close:disabled {
  color: inherit;
}
.modal-warning .modal-header {
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
}
.modal-warning .modal-header .close {
  color: #856404;
}
.modal-warning .modal-header .close:hover {
  color: inherit;
}
.modal-warning .modal-header .close:focus {
  color: inherit;
}
.modal-warning .modal-header .close:disabled {
  color: inherit;
}
.modal-danger .modal-header {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
.modal-danger .modal-header .close {
  color: #721c24;
}
.modal-danger .modal-header .close:hover {
  color: inherit;
}
.modal-danger .modal-header .close:focus {
  color: inherit;
}
.modal-danger .modal-header .close:disabled {
  color: inherit;
}
.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

.multi-step-nav {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 15px;
  padding: 0 15px;
  position: relative;
}

.multi-step-indicator-label-bottom .multi-step-item {
  padding-bottom: calc(
			1rem * 1.5
		);
}
.multi-step-indicator-label-bottom .multi-step-indicator-label {
  top: 2rem;
}

.multi-step-indicator-label-top .multi-step-item {
  padding-top: calc(
			1rem * 1.5
		);
}
.multi-step-indicator-label-top .multi-step-indicator-label {
  bottom: calc(2rem + 0.25rem);
}

.multi-step-title-center .multi-step-title {
  margin-left: calc(2rem * 0.5);
  max-width: 100px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transform: translateX(-50%);
  white-space: nowrap;
  width: auto;
}
.multi-step-item {
  margin-bottom: 10px;
  position: relative;
}
.multi-step-item.active .multi-step-icon {
  background-color: #0b5fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  color: #fff;
}
.multi-step-item.active .multi-step-divider {
  background-color: #f1f2f5;
}
.multi-step-item.active.complete .multi-step-icon {
  background-color: #0b5fff;
  color: #fff;
}
.multi-step-item.complete .dropdown .multi-step-icon, .multi-step-item.complete .dropdown .multi-step-icon:active {
  background-image: none;
}
.multi-step-item.complete .multi-step-icon {
  background-color: #6c757d;
  color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline'%20d='M192.9%20429.5c-8.3%200-16.4-3.3-22.3-9.2L44.5%20294.1C15%20263.2%2062.7%20222%2089.1%20249.5L191.5%20352l230-258.9c27.2-30.5%2074.3%2011.5%2047.1%2041.9L216.4%20418.9c-5.8%206.5-14%2010.3-22.6%2010.6h-.9z'%20fill='%23fff'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
}
.multi-step-item.complete .multi-step-icon[data-multi-step-icon]:before {
  content: none;
}
.multi-step-item.complete .multi-step-icon:active {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline'%20d='M192.9%20429.5c-8.3%200-16.4-3.3-22.3-9.2L44.5%20294.1C15%20263.2%2062.7%20222%2089.1%20249.5L191.5%20352l230-258.9c27.2-30.5%2074.3%2011.5%2047.1%2041.9L216.4%20418.9c-5.8%206.5-14%2010.3-22.6%2010.6h-.9z'%20fill='%23fff'/%3E%3C/svg%3E");
}
.multi-step-item.complete .multi-step-divider {
  background-color: #6c757d;
}
.multi-step-item.error .multi-step-icon {
  background-color: #dc3545;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3E%3Cpath%20class='lexicon-icon-outline'%20d='M300.4%20256%20467%2089.4c29.6-29.6-14.8-74.1-44.4-44.4L256%20211.6%2089.4%2045C59.8%2015.3%2015.3%2059.8%2045%2089.4L211.6%20256%2045%20422.6c-29.7%2029.7%2014.7%2074.1%2044.4%2044.4L256%20300.4%20422.6%20467c29.7%2029.7%2074.1-14.7%2044.4-44.4L300.4%20256z'%20fill='%23fff'/%3E%3C/svg%3E");
  color: #fff;
}
.multi-step-item.error .multi-step-icon[data-multi-step-icon]::before {
  content: none;
}
.multi-step-item.error .multi-step-icon .lexicon-icon {
  display: none;
}
.multi-step-item.disabled .multi-step-title {
  color: #ced4da;
}
.multi-step-item.disabled .multi-step-icon {
  background-color: #f7f8f9;
  background-repeat: no-repeat;
  color: #ced4da;
  cursor: not-allowed;
  opacity: 1;
}
.multi-step-item.disabled .multi-step-icon:focus {
  box-shadow: none;
}
.multi-step-item.disabled .multi-step-indicator-label {
  color: #ced4da;
}
.multi-step-item.disabled .multi-step-divider {
  background-color: #f7f8f9;
}

.multi-step-title {
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 0.625rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.multi-step-item-expand {
  flex-grow: 1;
  width: 75px;
}

.multi-step-divider {
  background-color: #f1f2f5;
  height: 0.25rem;
  margin-left: 2rem;
  margin-top: -0.25rem;
  position: relative;
  top: 1.125rem;
}

.multi-step-indicator {
  position: relative;
  width: 2rem;
}
.multi-step-indicator .dropdown-item-indicator,
.multi-step-indicator .dropdown-item-indicator-start,
.multi-step-indicator .dropdown-item-indicator-end {
  display: none;
}
.multi-step-indicator .complete .dropdown-item-indicator,
.multi-step-indicator .complete .dropdown-item-indicator-start,
.multi-step-indicator .complete .dropdown-item-indicator-end {
  display: block;
}
.multi-step-indicator .multi-step-indicator-label {
  font-size: 1rem;
  left: 50%;
  max-width: 100px;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  transform: translateX(-50%);
  white-space: nowrap;
}
.multi-step-icon {
  align-items: center;
  background-color: #f1f2f5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  border-radius: 0.25rem;
  border-width: 0px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  height: 2rem;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  transition: box-shadow 0.15s ease-in-out;
  width: 2rem;
}
@media (prefers-reduced-motion: reduce) {
  .multi-step-icon {
    transition: none;
  }
}
.c-prefers-reduced-motion .multi-step-icon {
  transition: none;
}

.multi-step-icon:hover {
  background-color: #f1f2f5;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}
.multi-step-icon:focus {
  background-color: #f1f2f5;
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  color: rgba(0, 0, 0, 0.7);
  outline: 0;
  text-decoration: none;
}
.multi-step-icon[data-multi-step-icon]::before {
  content: '\FEFF' attr(data-multi-step-icon);
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  width: 2rem;
}
.multi-step-icon .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  position: absolute;
}
.multi-step-icon .btn-link {
  vertical-align: baseline;
}
.multi-step-icon .lexicon-icon {
  height: 1rem;
  margin-top: 0;
  width: 1rem;
}

.multi-step-item-fixed-width.multi-step-item,
.multi-step-item-fixed-width .multi-step-item-expand {
  flex-grow: 0;
  width: 150px;
}

@media (max-width: 767.98px) {
  .multi-step-nav-collapse-sm {
    flex-wrap: nowrap;
  }
  .multi-step-nav-collapse-sm.multi-step-indicator-label-top .multi-step-indicator-label {
    bottom: auto;
    top: 0;
  }
  .multi-step-nav-collapse-sm.multi-step-indicator-label-bottom .multi-step-indicator-label {
    bottom: 0;
    top: auto;
  }
  .multi-step-nav-collapse-sm.multi-step-title-center .multi-step-title {
    margin-left: 0;
    max-width: none;
    transform: none;
  }
  .multi-step-nav-collapse-sm .active.multi-step-item {
    position: static;
  }
  .multi-step-nav-collapse-sm .active .multi-step-title {
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }
  .multi-step-nav-collapse-sm .active .multi-step-indicator {
    position: static;
  }
  .multi-step-nav-collapse-sm .active .multi-step-indicator-label {
    display: block;
    left: 0;
    max-width: none;
    position: absolute;
    right: 0;
    text-align: center;
    transform: none;
    width: auto;
  }
  .multi-step-nav-collapse-sm .multi-step-item {
    margin-bottom: 0;
    min-width: 2rem;
    padding-top: calc(
				1rem + 0.625rem +
					(0.25rem * 0.5)
			);
  }
  .multi-step-nav-collapse-sm .multi-step-title {
    display: none;
  }
  .multi-step-nav-collapse-sm .multi-step-indicator-label {
    display: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  cursor: pointer;
  display: block;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  position: relative;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.nav-link:hover, .nav-link.hover {
  text-decoration: none;
}
.nav-link.focus, .nav-link:focus-visible, .c-prefers-focus .nav-link:focus {
  text-decoration: none;
  z-index: 1;
}
.nav-link:disabled, .nav-link.disabled {
  color: #6c757d;
  cursor: not-allowed;
}
.nav-link:disabled:active, .nav-link.disabled:active {
  pointer-events: none;
}
.nav-link > .c-inner {
  margin-bottom: -0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
}
.nav-link.btn-unstyled {
  width: 100%;
}
.nav-link.btn-unstyled:disabled, .nav-link.btn-unstyled.disabled {
  opacity: 1;
}
.nav-link.btn-unstyled .c-inner {
  width: auto;
}
.nav-btn {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  line-height: 1.5;
  margin: calc(( 			(1.5 * 1rem) + 				(0.5rem * 2) - 2rem 		) * 0.5) 0.25rem;
  min-width: 2rem;
  padding: 0 0.5rem;
  position: relative;
  text-align: center;
  width: auto;
}
.nav-btn.focus, .nav-btn:focus-visible, .c-prefers-focus .nav-btn:focus {
  z-index: 1;
}
.nav-btn:disabled, .nav-btn.disabled {
  opacity: 1;
}
.nav-btn .c-inner {
  margin-bottom: 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.0625rem;
}
.nav-btn .lexicon-icon {
  margin-top: 0;
}
.nav-btn.btn-link {
  margin-left: 0;
  margin-right: 0;
}
.nav-btn-monospaced {
  padding: 0;
}
.nav-btn-monospaced .c-inner {
  margin-left: -0.0625rem;
  margin-right: -0.0625rem;
}
.nav-link-monospaced {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: calc(( 			(1.5 * 1rem) + 				(0.5rem * 2) - 2rem 		) * 0.5) 0.25rem;
  min-width: 2rem;
  padding: 0;
}
.nav-link-monospaced .lexicon-icon {
  margin-top: 0;
}

.nav-item {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.nav-item[class*=col-] {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.nav-divider {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  position: relative;
}
.nav-divider::before {
  background-color: #6c757d;
  content: "";
  display: block;
  height: 1rem;
  left: 0;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 1px;
  z-index: 2;
}
.nav-divider-end {
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
}
.nav-divider-end::after {
  background-color: #6c757d;
  content: "";
  display: block;
  height: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  z-index: 2;
}

.nav-text-truncate {
  display: inline-block;
  margin-bottom: -6px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-toggle .nav-text-truncate {
  max-width: calc(100% - 24px);
}

.nav .nav-form {
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-link {
  text-align: center;
  width: 100%;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-stacked {
  display: block;
}
.nav-stacked .nav-form {
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}
.nav-unstyled {
  flex-wrap: nowrap;
}
.nav-unstyled .nav-link {
  line-height: 2rem;
  padding-bottom: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0;
}
.nav-unstyled .nav-link > .c-inner {
  margin-bottom: 0;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: 0;
}
.nav-unstyled .nav-link-monospaced {
  margin: 0 4px;
}
.nav-unstyled .nav-btn {
  margin: 0 4px;
  padding-bottom: 0;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0;
}
.nav-unstyled .nav-btn .c-inner {
  margin-bottom: 0;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: 0;
}
.nav-nested {
  flex-direction: column;
  flex-wrap: nowrap;
}
.nav-nested .nav > li > a,
.nav-nested .nav > li > .btn {
  padding-left: calc(1rem * 2);
}
.nav-nested .nav > li > a .c-inner,
.nav-nested .nav > li > .btn .c-inner {
  margin-left: calc(1rem * -2);
}
.nav-nested .nav > li > .nav-equal-height-heading {
  padding-left: calc(1rem * 1);
}
.nav-nested .nav .nav > li > a,
.nav-nested .nav .nav > li > .btn {
  padding-left: calc(1rem * 3);
}
.nav-nested .nav .nav > li > a .c-inner,
.nav-nested .nav .nav > li > .btn .c-inner {
  margin-left: calc(1rem * -3);
}
.nav-nested .nav .nav > li > .nav-equal-height-heading {
  padding-left: calc(1rem * 2);
}
.nav-nested .nav .nav .nav > li > a,
.nav-nested .nav .nav .nav > li > .btn {
  padding-left: calc(1rem * 4);
}
.nav-nested .nav .nav .nav > li > a .c-inner,
.nav-nested .nav .nav .nav > li > .btn .c-inner {
  margin-left: calc(1rem * -4);
}
.nav-nested .nav .nav .nav > li > .nav-equal-height-heading {
  padding-left: calc(1rem * 3);
}
.nav-nested .nav .nav .nav .nav > li > a,
.nav-nested .nav .nav .nav .nav > li > .btn {
  padding-left: calc(1rem * 5);
}
.nav-nested .nav .nav .nav .nav > li > a .c-inner,
.nav-nested .nav .nav .nav .nav > li > .btn .c-inner {
  margin-left: calc(1rem * -5);
}
.nav-nested .nav .nav .nav .nav > li > .nav-equal-height-heading {
  padding-left: calc(1rem * 4);
}
.nav-nested .nav .nav .nav .nav .nav > li > a,
.nav-nested .nav .nav .nav .nav .nav > li > .btn {
  padding-left: calc(1rem * 6);
}
.nav-nested .nav .nav .nav .nav .nav > li > a .c-inner,
.nav-nested .nav .nav .nav .nav .nav > li > .btn .c-inner {
  margin-left: calc(1rem * -6);
}
.nav-nested .nav .nav .nav .nav .nav > li > .nav-equal-height-heading {
  padding-left: calc(1rem * 5);
}
.nav-nested .nav .nav .nav .nav .nav .nav > li > a,
.nav-nested .nav .nav .nav .nav .nav .nav > li > .btn {
  padding-left: calc(1rem * 7);
}
.nav-nested .nav .nav .nav .nav .nav .nav > li > a .c-inner,
.nav-nested .nav .nav .nav .nav .nav .nav > li > .btn .c-inner {
  margin-left: calc(1rem * -7);
}
.nav-nested .nav .nav .nav .nav .nav .nav > li > .nav-equal-height-heading {
  padding-left: calc(1rem * 6);
}
.nav-nested .nav .nav .nav .nav .nav .nav .nav > li > a,
.nav-nested .nav .nav .nav .nav .nav .nav .nav > li > .btn {
  padding-left: calc(1rem * 8);
}
.nav-nested .nav .nav .nav .nav .nav .nav .nav > li > a .c-inner,
.nav-nested .nav .nav .nav .nav .nav .nav .nav > li > .btn .c-inner {
  margin-left: calc(1rem * -8);
}
.nav-nested .nav .nav .nav .nav .nav .nav .nav > li > .nav-equal-height-heading {
  padding-left: calc(1rem * 7);
}

.nav-nested-margins {
  flex-direction: column;
  flex-wrap: nowrap;
}
.nav-nested-margins > li .nav > li {
  margin-left: 1rem;
}

.nav-tabs {
  border-bottom: 0.0625rem solid #dee2e6;
}
.nav-tabs .nav-link {
  border-color: transparent;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem 0.25rem 0 0;
  white-space: nowrap;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link.hover {
  border-color: #f1f2f5 #f1f2f5 #dee2e6;
}
.nav-tabs .nav-link.focus, .nav-tabs .nav-link:focus-visible, .c-prefers-focus .nav-tabs .nav-link:focus {
  border-color: #f1f2f5 #f1f2f5 #dee2e6;
}
.nav-tabs .nav-link:active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  color: #495057;
}
.nav-tabs .nav-link.active {
  background-color: #fff;
  color: #495057;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-link:disabled, .nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #6c757d;
}
.nav-tabs .nav-link[aria-expanded=true], .nav-tabs .nav-link.show {
  background-color: #fff;
  color: #495057;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .nav-item {
  margin-bottom: -0.0625rem;
}
.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -0.0625rem;
}
.nav-tabs + .tab-content .tab-pane.active:first-child {
  border-top-left-radius: 0;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.dropdown-item[data-toggle=tab] .dropdown-item-indicator,
.dropdown-item[data-toggle=tab] .dropdown-item-indicator-start,
.dropdown-item[data-toggle=tab] .dropdown-item-indicator-end {
  display: none;
}
.dropdown-item[data-toggle=tab].active .dropdown-item-indicator,
.dropdown-item[data-toggle=tab].active .dropdown-item-indicator-start,
.dropdown-item[data-toggle=tab].active .dropdown-item-indicator-end {
  display: block;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #0b5fff;
  color: #fff;
}

.nav-tabs-light {
  background-color: #f7f8f9;
}
.nav-tabs-light + .tab-content .tab-pane {
  background-color: transparent;
  border-radius: 0;
}

.menubar {
  position: relative;
}

.menubar-toggler {
  display: none;
}

.menubar-primary .nav-item {
  position: relative;
}
.menubar-primary .nav-link {
  border-radius: 0;
  color: #272833;
  line-height: 24px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .menubar-primary .nav-link {
    transition: none;
  }
}
.c-prefers-reduced-motion .menubar-primary .nav-link {
  transition: none;
}

.menubar-primary .nav-link::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .menubar-primary .nav-link::before {
    transition: none;
  }
}
.c-prefers-reduced-motion .menubar-primary .nav-link::before {
  transition: none;
}

.menubar-primary .nav-link:hover, .menubar-primary .nav-link.hover {
  background-color: #f0f5ff;
  color: #272833;
  letter-spacing: 0;
}
.menubar-primary .nav-link:hover::before, .menubar-primary .nav-link.hover::before {
  background: #88889a;
  width: 0.125rem;
}
.menubar-primary .nav-link.focus, .menubar-primary .nav-link:focus-visible, .c-prefers-focus .menubar-primary .nav-link:focus {
  box-shadow: none;
  color: #272833;
  outline: 0;
}
.menubar-primary .nav-link.focus::after, .menubar-primary .nav-link:focus-visible::after, .c-prefers-focus .menubar-primary .nav-link:focus::after {
  bottom: 0;
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
.menubar-primary .nav-link:active {
  color: rgba(0, 0, 0, 0.9);
}
.menubar-primary .nav-link.active {
  font-weight: 500;
  background-color: #f0f5ff;
  color: #272833;
}
.menubar-primary .nav-link.active::before {
  background-color: #0b5fff;
  width: 0.375rem;
}
.menubar-primary .nav-link.active:focus-visible::before, .c-prefers-focus .menubar-primary .nav-link.active:focus::before {
  display: none;
}
.menubar-primary .nav-link:disabled, .menubar-primary .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  font-weight: 400;
  letter-spacing: 0.016rem;
}
.menubar-primary .nav-link:disabled::before, .menubar-primary .nav-link.disabled::before {
  content: none;
}
.menubar-primary .nav-link:disabled::after, .menubar-primary .nav-link.disabled::after {
  content: none;
}
.menubar-primary .nav-link[aria-expanded=true], .menubar-primary .nav-link.show {
  font-weight: 500;
  color: #272833;
}
.menubar-primary .nav-link[aria-expanded=true]::before, .menubar-primary .nav-link.show::before {
  background-color: transparent;
  width: 0;
}
.menubar-primary .nav-link[aria-expanded=true]:hover::before, .menubar-primary .nav-link.show:hover::before {
  background-color: #88889a;
  width: 0.125rem;
}
.menubar-primary .nav-link[aria-expanded=true]:focus-visible::before, .c-prefers-focus .menubar-primary .nav-link[aria-expanded=true]:focus::before, .menubar-primary .nav-link.show:focus-visible::before, .c-prefers-focus .menubar-primary .nav-link.show:focus::before {
  display: none;
}
.menubar-primary .nav-link.collapse-icon {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.menubar-primary .nav-link.collapse-icon .collapse-icon-closed {
  top: calc(22px - 0.5em);
}
.menubar-primary .nav-link.collapse-icon .collapse-icon-open {
  top: calc(22px - 0.5em);
}
.menubar-primary .nav-link .autofit-row {
  align-items: center;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.menubar-primary .nav-link .autofit-row .autofit-col {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.menubar-primary .menubar-actions-1 {
  padding-right: 4rem;
}
.menubar-primary .menubar-action {
  position: absolute;
  top: 0.625rem;
  right: 2rem;
}
.menubar-primary .nav .nav .nav > li > .nav-link {
  margin-left: 1rem;
}

.menubar-vertical-expand-md {
  max-width: 15.625rem;
}
.menubar-vertical-expand-md .menubar-collapse {
  display: block;
}
.menubar-vertical-expand-md .menubar-collapse.collapsing, .menubar-vertical-expand-md .menubar-collapse.show {
  display: block;
}
@media (max-width: 767.98px) {
  .menubar-vertical-expand-md {
    margin-bottom: 1rem;
    max-width: none;
  }
  .menubar-vertical-expand-md .menubar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0.0625rem;
    display: none;
    position: relative;
    top: 100%;
    z-index: 499;
  }
  .menubar-vertical-expand-md .menubar-toggler {
    align-items: center;
    border-color: transparent;
    border-style: solid;
    border-width: 0.0625rem;
    display: inline-flex;
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .menubar-vertical-expand-md .menubar-toggler .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    max-width: none;
  }
  .menubar-vertical-expand-md .menubar-toggler .lexicon-icon {
    margin-top: 0;
  }
  .menubar-vertical-expand-md .nav-nested {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .menubar-vertical-expand-md .nav-nested-margins > li .nav > li {
    margin-left: 0;
  }
  .menubar-vertical-expand-md .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  .menubar-vertical-expand-md .nav-link:hover, .menubar-vertical-expand-md .nav-link.hover {
    color: rgba(0, 0, 0, 0.7);
  }
  .menubar-vertical-expand-md .nav-link:active {
    color: rgba(0, 0, 0, 0.9);
  }
  .menubar-vertical-expand-md .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  .menubar-vertical-expand-md .nav-link:disabled, .menubar-vertical-expand-md .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  .menubar-vertical-expand-md .nav-link[aria-expanded=true], .menubar-vertical-expand-md .nav-link.show {
    color: rgba(0, 0, 0, 0.9);
  }
}
.menubar-vertical-expand-md.menubar-transparent .nav-link {
  color: #6c757d;
}
.menubar-vertical-expand-md.menubar-transparent .nav-link:hover, .menubar-vertical-expand-md.menubar-transparent .nav-link.hover {
  color: #494f54;
}
.menubar-vertical-expand-md.menubar-transparent .nav-link:active {
  color: rgba(0, 0, 0, 0.9);
}
.menubar-vertical-expand-md.menubar-transparent .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.menubar-vertical-expand-md.menubar-transparent .nav-link:disabled, .menubar-vertical-expand-md.menubar-transparent .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 767.98px) {
  .menubar-vertical-expand-md.menubar-transparent .menubar-collapse {
    background-color: #fff;
    border-color: #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
  }
  .menubar-vertical-expand-md.menubar-transparent .menubar-toggler {
    text-decoration: none;
  }
  .menubar-vertical-expand-md.menubar-transparent .nav-link {
    border-radius: 0;
    color: #272833;
  }
  .menubar-vertical-expand-md.menubar-transparent .nav-link:hover, .menubar-vertical-expand-md.menubar-transparent .nav-link.hover {
    background-color: #f7f8f9;
    color: #1c1d25;
  }
  .menubar-vertical-expand-md.menubar-transparent .nav-link:active {
    background-color: #0b5fff;
    color: #fff;
  }
  .menubar-vertical-expand-md.menubar-transparent .nav-link.active {
    font-weight: 500;
    background-color: #0b5fff;
    color: #fff;
  }
  .menubar-vertical-expand-md.menubar-transparent .nav-link:disabled, .menubar-vertical-expand-md.menubar-transparent .nav-link.disabled {
    background-color: transparent;
    color: #6c757d;
  }
}
@media (min-width: 768px) {
  .menubar-vertical-expand-md.menubar-decorated .nav {
    border-left-color: #dee2e6;
    border-left-style: solid;
    border-left-width: 0.125rem;
    display: block;
    padding-left: 0.5rem;
  }
  .menubar-vertical-expand-md.menubar-decorated .nav > .nav-item .nav {
    margin-bottom: 0.25rem;
    margin-left: 1rem;
    margin-top: 0.25rem;
  }
  .menubar-vertical-expand-md.menubar-decorated .nav-link {
    padding-left: 1rem !important;
  }
  .menubar-vertical-expand-md.menubar-decorated .nav-link.active::after {
    background-color: #b3cdff;
    bottom: 0;
    content: "";
    display: block;
    left: -0.625rem;
    position: absolute;
    top: 0;
    width: 0.125rem;
  }
}

.menubar-vertical-expand-lg {
  max-width: 15.625rem;
}
.menubar-vertical-expand-lg .menubar-collapse {
  display: block;
}
.menubar-vertical-expand-lg .menubar-collapse.collapsing, .menubar-vertical-expand-lg .menubar-collapse.show {
  display: block;
}
@media (max-width: 991.98px) {
  .menubar-vertical-expand-lg {
    margin-bottom: 1rem;
    max-width: none;
  }
  .menubar-vertical-expand-lg .menubar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0.0625rem;
    display: none;
    position: relative;
    top: 100%;
    z-index: 499;
  }
  .menubar-vertical-expand-lg .menubar-toggler {
    align-items: center;
    border-color: transparent;
    border-style: solid;
    border-width: 0.0625rem;
    display: inline-flex;
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .menubar-vertical-expand-lg .menubar-toggler .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    max-width: none;
  }
  .menubar-vertical-expand-lg .menubar-toggler .lexicon-icon {
    margin-top: 0;
  }
  .menubar-vertical-expand-lg .nav-nested {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .menubar-vertical-expand-lg .nav-nested-margins > li .nav > li {
    margin-left: 0;
  }
  .menubar-vertical-expand-lg .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  .menubar-vertical-expand-lg .nav-link:hover, .menubar-vertical-expand-lg .nav-link.hover {
    color: rgba(0, 0, 0, 0.7);
  }
  .menubar-vertical-expand-lg .nav-link:active {
    color: rgba(0, 0, 0, 0.9);
  }
  .menubar-vertical-expand-lg .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  .menubar-vertical-expand-lg .nav-link:disabled, .menubar-vertical-expand-lg .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  .menubar-vertical-expand-lg .nav-link[aria-expanded=true], .menubar-vertical-expand-lg .nav-link.show {
    color: rgba(0, 0, 0, 0.9);
  }
}
.menubar-vertical-expand-lg.menubar-transparent .nav-link {
  color: #6c757d;
}
.menubar-vertical-expand-lg.menubar-transparent .nav-link:hover, .menubar-vertical-expand-lg.menubar-transparent .nav-link.hover {
  color: #494f54;
}
.menubar-vertical-expand-lg.menubar-transparent .nav-link:active {
  color: rgba(0, 0, 0, 0.9);
}
.menubar-vertical-expand-lg.menubar-transparent .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.menubar-vertical-expand-lg.menubar-transparent .nav-link:disabled, .menubar-vertical-expand-lg.menubar-transparent .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
@media (max-width: 991.98px) {
  .menubar-vertical-expand-lg.menubar-transparent .menubar-collapse {
    background-color: #fff;
    border-color: #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.3);
  }
  .menubar-vertical-expand-lg.menubar-transparent .menubar-toggler {
    text-decoration: none;
  }
  .menubar-vertical-expand-lg.menubar-transparent .nav-link {
    border-radius: 0;
    color: #272833;
  }
  .menubar-vertical-expand-lg.menubar-transparent .nav-link:hover, .menubar-vertical-expand-lg.menubar-transparent .nav-link.hover {
    background-color: #f7f8f9;
    color: #1c1d25;
  }
  .menubar-vertical-expand-lg.menubar-transparent .nav-link:active {
    background-color: #0b5fff;
    color: #fff;
  }
  .menubar-vertical-expand-lg.menubar-transparent .nav-link.active {
    font-weight: 500;
    background-color: #0b5fff;
    color: #fff;
  }
  .menubar-vertical-expand-lg.menubar-transparent .nav-link:disabled, .menubar-vertical-expand-lg.menubar-transparent .nav-link.disabled {
    background-color: transparent;
    color: #6c757d;
  }
}
@media (min-width: 992px) {
  .menubar-vertical-expand-lg.menubar-decorated .nav {
    border-left-color: #dee2e6;
    border-left-style: solid;
    border-left-width: 0.125rem;
    display: block;
    padding-left: 0.5rem;
  }
  .menubar-vertical-expand-lg.menubar-decorated .nav > .nav-item .nav {
    margin-bottom: 0.25rem;
    margin-left: 1rem;
    margin-top: 0.25rem;
  }
  .menubar-vertical-expand-lg.menubar-decorated .nav-link {
    padding-left: 1rem !important;
  }
  .menubar-vertical-expand-lg.menubar-decorated .nav-link.active::after {
    background-color: #b3cdff;
    bottom: 0;
    content: "";
    display: block;
    left: -0.625rem;
    position: absolute;
    top: 0;
    width: 0.125rem;
  }
}

.navbar {
  align-items: center;
  border-width: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  position: relative;
}
.navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar .container,
.navbar .container-fluid {
  align-items: inherit;
  background-color: inherit;
  display: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
}
.navbar-nowrap {
  flex-wrap: nowrap;
}
.navbar-nowrap .navbar-text {
  min-width: 0;
  white-space: nowrap;
}

.navbar-nav .dropdown-menu-right,
.navbar-form .dropdown-menu-right {
  left: auto;
  right: 0;
}

.navbar-nav {
  display: flex;
  flex-wrap: inherit;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  float: none;
}
.navbar-nav .nav-item,
.navbar-nav .nav-item .dropdown {
  align-items: center;
  display: flex;
  word-wrap: normal;
  max-width: 100%;
}
.navbar-nav .nav-item > .custom-control,
.navbar-nav .nav-item > .form-check {
  margin-bottom: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.navbar-nav .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar-nav-expand {
  flex-grow: 1;
  min-width: 0;
}

.navbar-nav-last {
  margin-left: auto;
}

.nav-item-expand {
  flex-grow: 1;
  min-width: 0;
}

.nav-item-shrink {
  min-width: 0;
}

.navbar-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0;
}

.navbar-text {
  display: inline-block;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}

.navbar-collapse {
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.navbar-collapse .container,
.navbar-collapse .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.navbar-collapse .dropdown-toggle .navbar-text-truncate {
  max-width: calc(100% - 1.5625rem);
}

.navbar-text-truncate {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-decoration: inherit;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}
.navbar-toggler-icon {
  background-size: 100% 100%;
  background: no-repeat center center;
  content: "";
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}

.navbar-toggler {
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  z-index: 525;
}
.navbar-toggler-link {
  align-items: center;
  display: flex;
  border-width: 0;
  line-height: 1.5;
  max-width: 100%;
  padding: calc((1rem * 1.5 + 0.5rem * 2 - calc(
	1.25rem * 1.5
)) * 0.5) 0.5rem;
  position: relative;
}
.navbar-toggler-link .lexicon-icon {
  min-width: 1em;
  margin-left: 3px;
  margin-top: 0;
}

.navbar-brand {
  display: inline-block;
  font-size: 1.25rem;
  line-height: inherit;
  max-width: calc(100% - 72px);
  padding-bottom: calc((1rem * 1.5 + 0.5rem * 2 - calc(
	1.25rem * 1.5
)) * 0.5);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: calc((1rem * 1.5 + 0.5rem * 2 - calc(
	1.25rem * 1.5
)) * 0.5);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-form {
  align-items: center;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.navbar-form > form {
  width: 100%;
}

.navbar-form-autofit {
  flex-basis: 100px;
  flex-grow: 1;
}
.navbar-form-autofit form {
  display: flex;
  width: 100%;
}

.navbar-overlay {
  background-color: inherit;
  flex-wrap: inherit;
}

.navbar-breakpoint-d-block,
.navbar-breakpoint-d-inline-block,
.navbar-breakpoint-d-flex {
  display: none !important;
}

.navbar-expand-sm .nav-item .navbar-text-truncate {
  max-width: 12.5rem;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar-collapse-absolute .navbar-collapse {
    background-color: inherit;
    left: 0;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 500;
  }
  .navbar-expand-sm.navbar-collapse-absolute .navbar-collapse .container,
  .navbar-expand-sm.navbar-collapse-absolute .navbar-collapse .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-expand-sm.navbar-collapse-absolute .navbar-collapse .navbar-nav:last-child,
  .navbar-expand-sm.navbar-collapse-absolute .navbar-collapse .navbar-form:last-child {
    padding-bottom: 0.5rem;
  }
  .navbar-expand-sm .navbar-collapse .nav-item,
  .navbar-expand-sm .navbar-collapse .nav-item .dropdown {
    display: block;
  }
  .navbar-expand-sm .navbar-collapse .navbar-text-truncate {
    max-width: 100%;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav {
    flex-direction: column;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-toggle .navbar-text-truncate {
    max-width: calc(
									100% - 1.5625rem
								);
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item:hover, .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item:focus {
    background-color: transparent;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item.active {
    background-color: transparent;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
    margin: 0;
    max-height: none;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: static;
  }
  .navbar-expand-sm .show-dropdown-on-collapse .dropdown-header,
  .navbar-expand-sm .show-dropdown-on-collapse .dropdown-item {
    padding: 0.5rem 0.5rem;
  }
  .navbar-expand-sm .show-dropdown-on-collapse .dropdown-menu {
    display: block;
  }
  .navbar-expand-sm .show-dropdown-on-collapse .dropdown-toggle {
    display: none;
  }
  .navbar-expand-sm .navbar-breakpoint-down-d-block {
    display: block !important;
  }
  .navbar-expand-sm .navbar-breakpoint-down-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-sm .navbar-breakpoint-down-d-flex {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-breakpoint-down-d-none {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand .navbar-text-truncate {
    max-width: 12.5rem;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    width: auto;
  }
  .navbar-expand-sm .navbar-collapse .dropdown-toggle .navbar-text-truncate {
    max-width: 10.9375rem;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .navbar-breakpoint-d-block {
    display: block !important;
  }
  .navbar-expand-sm .navbar-breakpoint-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-sm .navbar-breakpoint-d-flex {
    display: flex !important;
  }
  .navbar-expand-sm .navbar-breakpoint-d-none {
    display: none !important;
  }
}
.navbar-expand-md .nav-item .navbar-text-truncate {
  max-width: 12.5rem;
}
@media (max-width: 767.98px) {
  .navbar-expand-md.navbar-collapse-absolute .navbar-collapse {
    background-color: inherit;
    left: 0;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 500;
  }
  .navbar-expand-md.navbar-collapse-absolute .navbar-collapse .container,
  .navbar-expand-md.navbar-collapse-absolute .navbar-collapse .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-expand-md.navbar-collapse-absolute .navbar-collapse .navbar-nav:last-child,
  .navbar-expand-md.navbar-collapse-absolute .navbar-collapse .navbar-form:last-child {
    padding-bottom: 0.5rem;
  }
  .navbar-expand-md .navbar-collapse .nav-item,
  .navbar-expand-md .navbar-collapse .nav-item .dropdown {
    display: block;
  }
  .navbar-expand-md .navbar-collapse .navbar-text-truncate {
    max-width: 100%;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav {
    flex-direction: column;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-toggle .navbar-text-truncate {
    max-width: calc(
									100% - 1.5625rem
								);
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:hover, .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:focus {
    background-color: transparent;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.active {
    background-color: transparent;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
    margin: 0;
    max-height: none;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: static;
  }
  .navbar-expand-md .show-dropdown-on-collapse .dropdown-header,
  .navbar-expand-md .show-dropdown-on-collapse .dropdown-item {
    padding: 0.5rem 0.5rem;
  }
  .navbar-expand-md .show-dropdown-on-collapse .dropdown-menu {
    display: block;
  }
  .navbar-expand-md .show-dropdown-on-collapse .dropdown-toggle {
    display: none;
  }
  .navbar-expand-md .navbar-breakpoint-down-d-block {
    display: block !important;
  }
  .navbar-expand-md .navbar-breakpoint-down-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-md .navbar-breakpoint-down-d-flex {
    display: flex !important;
  }
  .navbar-expand-md .navbar-breakpoint-down-d-none {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand .navbar-text-truncate {
    max-width: 12.5rem;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    width: auto;
  }
  .navbar-expand-md .navbar-collapse .dropdown-toggle .navbar-text-truncate {
    max-width: 10.9375rem;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .navbar-breakpoint-d-block {
    display: block !important;
  }
  .navbar-expand-md .navbar-breakpoint-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-md .navbar-breakpoint-d-flex {
    display: flex !important;
  }
  .navbar-expand-md .navbar-breakpoint-d-none {
    display: none !important;
  }
}
.navbar-expand-lg .nav-item .navbar-text-truncate {
  max-width: 12.5rem;
}
@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar-collapse-absolute .navbar-collapse {
    background-color: inherit;
    left: 0;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 500;
  }
  .navbar-expand-lg.navbar-collapse-absolute .navbar-collapse .container,
  .navbar-expand-lg.navbar-collapse-absolute .navbar-collapse .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-expand-lg.navbar-collapse-absolute .navbar-collapse .navbar-nav:last-child,
  .navbar-expand-lg.navbar-collapse-absolute .navbar-collapse .navbar-form:last-child {
    padding-bottom: 0.5rem;
  }
  .navbar-expand-lg .navbar-collapse .nav-item,
  .navbar-expand-lg .navbar-collapse .nav-item .dropdown {
    display: block;
  }
  .navbar-expand-lg .navbar-collapse .navbar-text-truncate {
    max-width: 100%;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    flex-direction: column;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-toggle .navbar-text-truncate {
    max-width: calc(
									100% - 1.5625rem
								);
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item:hover, .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item:focus {
    background-color: transparent;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item.active {
    background-color: transparent;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-menu {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
    margin: 0;
    max-height: none;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: static;
  }
  .navbar-expand-lg .show-dropdown-on-collapse .dropdown-header,
  .navbar-expand-lg .show-dropdown-on-collapse .dropdown-item {
    padding: 0.5rem 0.5rem;
  }
  .navbar-expand-lg .show-dropdown-on-collapse .dropdown-menu {
    display: block;
  }
  .navbar-expand-lg .show-dropdown-on-collapse .dropdown-toggle {
    display: none;
  }
  .navbar-expand-lg .navbar-breakpoint-down-d-block {
    display: block !important;
  }
  .navbar-expand-lg .navbar-breakpoint-down-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-lg .navbar-breakpoint-down-d-flex {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-breakpoint-down-d-none {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand .navbar-text-truncate {
    max-width: 12.5rem;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    width: auto;
  }
  .navbar-expand-lg .navbar-collapse .dropdown-toggle .navbar-text-truncate {
    max-width: 10.9375rem;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .navbar-breakpoint-d-block {
    display: block !important;
  }
  .navbar-expand-lg .navbar-breakpoint-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-lg .navbar-breakpoint-d-flex {
    display: flex !important;
  }
  .navbar-expand-lg .navbar-breakpoint-d-none {
    display: none !important;
  }
}
.navbar-expand-xl .nav-item .navbar-text-truncate {
  max-width: 12.5rem;
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar-collapse-absolute .navbar-collapse {
    background-color: inherit;
    left: 0;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 500;
  }
  .navbar-expand-xl.navbar-collapse-absolute .navbar-collapse .container,
  .navbar-expand-xl.navbar-collapse-absolute .navbar-collapse .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-expand-xl.navbar-collapse-absolute .navbar-collapse .navbar-nav:last-child,
  .navbar-expand-xl.navbar-collapse-absolute .navbar-collapse .navbar-form:last-child {
    padding-bottom: 0.5rem;
  }
  .navbar-expand-xl .navbar-collapse .nav-item,
  .navbar-expand-xl .navbar-collapse .nav-item .dropdown {
    display: block;
  }
  .navbar-expand-xl .navbar-collapse .navbar-text-truncate {
    max-width: 100%;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav {
    flex-direction: column;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-toggle .navbar-text-truncate {
    max-width: calc(
									100% - 1.5625rem
								);
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item:hover, .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item:focus {
    background-color: transparent;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item.active {
    background-color: transparent;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-menu {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
    margin: 0;
    max-height: none;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: static;
  }
  .navbar-expand-xl .show-dropdown-on-collapse .dropdown-header,
  .navbar-expand-xl .show-dropdown-on-collapse .dropdown-item {
    padding: 0.5rem 0.5rem;
  }
  .navbar-expand-xl .show-dropdown-on-collapse .dropdown-menu {
    display: block;
  }
  .navbar-expand-xl .show-dropdown-on-collapse .dropdown-toggle {
    display: none;
  }
  .navbar-expand-xl .navbar-breakpoint-down-d-block {
    display: block !important;
  }
  .navbar-expand-xl .navbar-breakpoint-down-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-xl .navbar-breakpoint-down-d-flex {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-breakpoint-down-d-none {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand .navbar-text-truncate {
    max-width: 12.5rem;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    width: auto;
  }
  .navbar-expand-xl .navbar-collapse .dropdown-toggle .navbar-text-truncate {
    max-width: 10.9375rem;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .navbar-breakpoint-d-block {
    display: block !important;
  }
  .navbar-expand-xl .navbar-breakpoint-d-inline-block {
    display: inline-block !important;
  }
  .navbar-expand-xl .navbar-breakpoint-d-flex {
    display: flex !important;
  }
  .navbar-expand-xl .navbar-breakpoint-d-none {
    display: none !important;
  }
}
.navbar-expand .nav-item .navbar-text-truncate {
  max-width: 12.5rem;
}

@media (max-width: 575.98px) {
  .navbar-overlay-xs-down {
    bottom: 0;
    display: none;
    justify-content: space-between;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 450;
  }
  .navbar-overlay-xs-down.show {
    display: flex;
  }
}

@media (max-width: 767.98px) {
  .navbar-overlay-sm-down {
    bottom: 0;
    display: none;
    justify-content: space-between;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 450;
  }
  .navbar-overlay-sm-down.show {
    display: flex;
  }
}

@media (max-width: 991.98px) {
  .navbar-overlay-md-down {
    bottom: 0;
    display: none;
    justify-content: space-between;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 450;
  }
  .navbar-overlay-md-down.show {
    display: flex;
  }
}

@media (max-width: 1199.98px) {
  .navbar-overlay-lg-down {
    bottom: 0;
    display: none;
    justify-content: space-between;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 450;
  }
  .navbar-overlay-lg-down.show {
    display: flex;
  }
}

.navbar-overlay-up {
  bottom: 0;
  display: none;
  justify-content: space-between;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 450;
}
.navbar-overlay-up.show {
  display: flex;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.navbar-underline .navbar-toggler-link::after {
  background-color: #0b5fff;
  bottom: -0.5rem;
  content: "";
  display: block;
  height: 0.1875rem;
  left: 0;
  position: absolute;
  right: 0;
  width: auto;
}
@media (min-width: 768px) {
  .navbar-underline.navbar-expand-md.navbar-underline .navbar-nav .nav-link.active::after {
    background-color: #0b5fff;
    bottom: -0.5rem;
    content: "";
    display: block;
    height: 0.1875rem;
    left: 0;
    position: absolute;
    right: 0;
    width: auto;
  }
  .navbar-underline.navbar-expand-md.navbar-underline .navbar-nav .nav-link[aria-expanded=true]::after, .navbar-underline.navbar-expand-md.navbar-underline .navbar-nav .nav-link.show::after {
    background-color: #0b5fff;
    bottom: -0.5rem;
    content: "";
    display: block;
    height: 0.1875rem;
    left: 0;
    position: absolute;
    right: 0;
    width: auto;
  }
}
.application-bar {
  flex-wrap: nowrap;
  border-width: 0px 0px 0px 0px;
  font-size: 1rem;
  padding: 0px 0;
}
.application-bar .navbar-toggler {
  font-size: 1.25rem;
  height: calc(3rem * 0.66667);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
}
.application-bar .navbar-toggler .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.25rem;
}
.application-bar .navbar-toggler-link {
  font-size: 1rem;
  height: auto;
  line-height: 1.5;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: calc((
						3rem -
							0px -
							0px -
							0px *
							2 -
							1rem *
							1.5
					) * 0.5);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: calc((
						3rem -
							0px -
							0px -
							0px *
							2 -
							1rem *
							1.5
					) * 0.5);
}
.application-bar .navbar-toggler-link .c-inner {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.application-bar .navbar-brand {
  font-size: 1.25rem;
  margin-right: 0;
  padding-bottom: calc((
							3rem -
								0px -
								0px -
								0px *
								2 -
								1.25rem *
								1.5
						) * 0.5);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: calc((
							3rem -
								0px -
								0px -
								0px *
								2 -
								1.25rem *
								1.5
						) * 0.5);
}
.application-bar .navbar-brand .c-inner {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.application-bar .navbar-nav .nav-btn {
  font-size: 1rem;
  margin-bottom: calc((
						3rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: calc((
						3rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
}
.application-bar .navbar-nav .nav-btn .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: -0.25rem;
}
.application-bar .navbar-nav .nav-btn-monospaced {
  font-size: 1rem;
  padding: 0;
}
.application-bar .navbar-nav .nav-btn-monospaced .c-inner {
  margin: 0;
}
.application-bar .navbar-nav .nav-item > .custom-control,
.application-bar .navbar-nav .nav-item > .form-check {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.application-bar .navbar-nav .nav-link,
.application-bar .navbar-nav .navbar-text {
  margin-bottom: calc((
						(
								3rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: calc((
						(
								3rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
  padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
}
.application-bar .navbar-nav .nav-link .c-inner,
.application-bar .navbar-nav .navbar-text .c-inner {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.application-bar .navbar-nav .nav-link-monospaced {
  font-size: 1rem;
  margin-bottom: calc((
						3rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: calc((
						3rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  padding: 0;
}
.application-bar .navbar-nav .nav-link-monospaced .c-inner {
  margin: 0;
}
@media (max-width: 575.98px) {
  .application-bar.navbar-expand-sm.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0px 0px;
    left: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .application-bar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .application-bar.navbar-expand-sm .navbar-form {
    height: calc(
											3rem - 0px -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .application-bar.navbar-expand-sm .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 576px) {
  .application-bar.navbar-expand-sm .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.25rem;
    padding-bottom: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-sm .navbar-brand .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-sm .navbar-form {
    height: calc(
												3.5rem -
													0px -
													0px
											);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .application-bar.navbar-expand-sm .navbar-form > .container,
  .application-bar.navbar-expand-sm .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .application-bar.navbar-expand-sm .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-sm .nav-btn .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-sm .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .application-bar.navbar-expand-sm .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .application-bar.navbar-expand-sm .nav-item > .custom-control,
  .application-bar.navbar-expand-sm .nav-item > .form-check {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .application-bar.navbar-expand-sm .nav-link,
  .application-bar.navbar-expand-sm .navbar-text {
    margin-bottom: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-sm .nav-link .c-inner,
  .application-bar.navbar-expand-sm .navbar-text .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-sm .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .application-bar.navbar-expand-sm .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .application-bar.navbar-expand-md.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0px 0px;
    left: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .application-bar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .application-bar.navbar-expand-md .navbar-form {
    height: calc(
											3rem - 0px -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .application-bar.navbar-expand-md .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .application-bar.navbar-expand-md .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.25rem;
    padding-bottom: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-md .navbar-brand .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-md .navbar-form {
    height: calc(
												3.5rem -
													0px -
													0px
											);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .application-bar.navbar-expand-md .navbar-form > .container,
  .application-bar.navbar-expand-md .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .application-bar.navbar-expand-md .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-md .nav-btn .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-md .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .application-bar.navbar-expand-md .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .application-bar.navbar-expand-md .nav-item > .custom-control,
  .application-bar.navbar-expand-md .nav-item > .form-check {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .application-bar.navbar-expand-md .nav-link,
  .application-bar.navbar-expand-md .navbar-text {
    margin-bottom: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-md .nav-link .c-inner,
  .application-bar.navbar-expand-md .navbar-text .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-md .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .application-bar.navbar-expand-md .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 991.98px) {
  .application-bar.navbar-expand-lg.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0px 0px;
    left: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .application-bar.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .application-bar.navbar-expand-lg .navbar-form {
    height: calc(
											3rem - 0px -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .application-bar.navbar-expand-lg .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  .application-bar.navbar-expand-lg .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.25rem;
    padding-bottom: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-lg .navbar-brand .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-lg .navbar-form {
    height: calc(
												3.5rem -
													0px -
													0px
											);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .application-bar.navbar-expand-lg .navbar-form > .container,
  .application-bar.navbar-expand-lg .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .application-bar.navbar-expand-lg .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-lg .nav-btn .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-lg .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .application-bar.navbar-expand-lg .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .application-bar.navbar-expand-lg .nav-item > .custom-control,
  .application-bar.navbar-expand-lg .nav-item > .form-check {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .application-bar.navbar-expand-lg .nav-link,
  .application-bar.navbar-expand-lg .navbar-text {
    margin-bottom: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-lg .nav-link .c-inner,
  .application-bar.navbar-expand-lg .navbar-text .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-lg .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .application-bar.navbar-expand-lg .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 1199.98px) {
  .application-bar.navbar-expand-xl.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0px 0px;
    left: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .application-bar.navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .application-bar.navbar-expand-xl .navbar-form {
    height: calc(
											3rem - 0px -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .application-bar.navbar-expand-xl .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 1200px) {
  .application-bar.navbar-expand-xl .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.25rem;
    padding-bottom: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							3.5rem -
								0px -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-xl .navbar-brand .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-xl .navbar-form {
    height: calc(
												3.5rem -
													0px -
													0px
											);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .application-bar.navbar-expand-xl .navbar-form > .container,
  .application-bar.navbar-expand-xl .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .application-bar.navbar-expand-xl .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-xl .nav-btn .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-xl .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .application-bar.navbar-expand-xl .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .application-bar.navbar-expand-xl .nav-item > .custom-control,
  .application-bar.navbar-expand-xl .nav-item > .form-check {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .application-bar.navbar-expand-xl .nav-link,
  .application-bar.navbar-expand-xl .navbar-text {
    margin-bottom: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .application-bar.navbar-expand-xl .nav-link .c-inner,
  .application-bar.navbar-expand-xl .navbar-text .c-inner {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .application-bar.navbar-expand-xl .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: calc((
						3.5rem -
							0px -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .application-bar.navbar-expand-xl .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 575.98px) {
  .application-bar .navbar-overlay-xs-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 767.98px) {
  .application-bar .navbar-overlay-sm-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 991.98px) {
  .application-bar .navbar-overlay-md-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 1199.98px) {
  .application-bar .navbar-overlay-lg-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
.application-bar .navbar-overlay-up {
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0px;
}
.application-bar.navbar-underline .navbar-toggler-link:after {
  bottom: calc((
						0px +
							0px +
							calc((
						(
								3rem -
									0px -
									0px
							) -
							2rem
					) * 0.5)
					) * -1);
  height: 0.125rem;
}
@media (min-width: 576px) {
  .application-bar.navbar-underline.navbar-expand-sm .navbar-nav .nav-link.active:after {
    bottom: calc((0px + 0px + calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.125rem;
  }
}
@media (min-width: 768px) {
  .application-bar.navbar-underline.navbar-expand-md .navbar-nav .nav-link.active:after {
    bottom: calc((0px + 0px + calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.125rem;
  }
}
@media (min-width: 992px) {
  .application-bar.navbar-underline.navbar-expand-lg .navbar-nav .nav-link.active:after {
    bottom: calc((0px + 0px + calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.125rem;
  }
}
@media (min-width: 1200px) {
  .application-bar.navbar-underline.navbar-expand-xl .navbar-nav .nav-link.active:after {
    bottom: calc((0px + 0px + calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.125rem;
  }
}
.application-bar.navbar-underline.navbar-expand .navbar-nav .nav-link.active:after {
  bottom: calc((0px + 0px + calc((
						(
								3.5rem -
									0px -
									0px
							) -
							2rem
					) * 0.5)) * -1);
  height: 0.125rem;
}
.application-bar .container,
.application-bar .container-fluid {
  flex-wrap: nowrap;
}
.application-bar .navbar-nav {
  flex-wrap: nowrap;
}

.application-bar-dark {
  background-color: #343a40;
  color: rgba(255, 255, 255, 0.5);
}
.application-bar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.application-bar-dark .navbar-nav .nav-link:hover, .application-bar-dark .navbar-nav .nav-link.hover {
  color: rgba(255, 255, 255, 0.75);
}
.application-bar-dark .navbar-nav .nav-link:active {
  color: #fff;
}
.application-bar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.application-bar-dark .navbar-nav .nav-link:disabled, .application-bar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
  opacity: 1;
}
.application-bar-dark .navbar-nav .nav-link[aria-expanded=true], .application-bar-dark .navbar-nav .nav-link.show {
  color: #fff;
}
.application-bar-dark .navbar-brand:active {
  color: #fff;
}
.application-bar-dark .navbar-brand.active {
  color: #fff;
}
.application-bar-dark .navbar-brand:disabled, .application-bar-dark .navbar-brand.disabled {
  color: rgba(255, 255, 255, 0.25);
  opacity: 1;
}
.application-bar-dark .navbar-brand[aria-expanded=true], .application-bar-dark .navbar-brand.show {
  color: #fff;
}
.application-bar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
}
.application-bar-dark .navbar-overlay {
  background-color: #343a40;
}
.management-bar {
  border-width: 0px 0px 0.0625rem 0px;
  font-size: 1rem;
  min-height: 4rem;
  padding: 0px 0;
  border-color: transparent;
  border-style: solid;
}
.management-bar .navbar-toggler {
  font-size: 1.25rem;
  height: calc(3rem * 0.66667);
  margin-left: 0.875rem;
  margin-right: 0.875rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
}
.management-bar .navbar-toggler .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.25rem;
}
.management-bar .navbar-toggler-link {
  font-size: 1rem;
  height: auto;
  line-height: 1.5;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							1rem *
							1.5
					) * 0.5);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							1rem *
							1.5
					) * 0.5);
}
.management-bar .navbar-toggler-link .c-inner {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.management-bar .navbar-brand {
  font-size: 1.25rem;
  margin-right: 0;
  padding-bottom: calc((
							3rem -
								0.0625rem -
								0px -
								0px *
								2 -
								1.25rem *
								1.5
						) * 0.5);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: calc((
							3rem -
								0.0625rem -
								0px -
								0px *
								2 -
								1.25rem *
								1.5
						) * 0.5);
}
.management-bar .navbar-brand .c-inner {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.management-bar .navbar-nav .nav-btn {
  font-size: 1rem;
  margin-bottom: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.25rem;
}
.management-bar .navbar-nav .nav-btn .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: -0.25rem;
}
.management-bar .navbar-nav .nav-btn-monospaced {
  font-size: 1rem;
  padding: 0;
}
.management-bar .navbar-nav .nav-btn-monospaced .c-inner {
  margin: 0;
}
.management-bar .navbar-nav .nav-item > .custom-control,
.management-bar .navbar-nav .nav-item > .form-check {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.management-bar .navbar-nav .nav-link,
.management-bar .navbar-nav .navbar-text {
  margin-bottom: calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
  padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
}
.management-bar .navbar-nav .nav-link .c-inner,
.management-bar .navbar-nav .navbar-text .c-inner {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.management-bar .navbar-nav .nav-link-monospaced {
  font-size: 1rem;
  margin-bottom: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  padding: 0;
}
.management-bar .navbar-nav .nav-link-monospaced .c-inner {
  margin: 0;
}
.management-bar .dropdown-menu {
  margin-top: 0;
}
@media (max-width: 575.98px) {
  .management-bar.navbar-expand-sm {
    min-height: 3rem;
  }
  .management-bar.navbar-expand-sm.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .management-bar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .management-bar.navbar-expand-sm .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .management-bar.navbar-expand-sm .navbar-form .form-control {
    height: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 576px) {
  .management-bar.navbar-expand-sm .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.5rem;
    padding-bottom: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-sm .navbar-brand .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-sm .navbar-form {
    height: calc(
												4rem -
													0.0625rem -
													0px
											);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .management-bar.navbar-expand-sm .navbar-form > .container,
  .management-bar.navbar-expand-sm .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .management-bar.navbar-expand-sm .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-sm .nav-btn .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-sm .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .management-bar.navbar-expand-sm .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .management-bar.navbar-expand-sm .nav-item > .custom-control,
  .management-bar.navbar-expand-sm .nav-item > .form-check {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .management-bar.navbar-expand-sm .nav-link,
  .management-bar.navbar-expand-sm .navbar-text {
    margin-bottom: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-sm .nav-link .c-inner,
  .management-bar.navbar-expand-sm .navbar-text .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-sm .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .management-bar.navbar-expand-sm .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .management-bar.navbar-expand-md {
    min-height: 3rem;
  }
  .management-bar.navbar-expand-md.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .management-bar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .management-bar.navbar-expand-md .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .management-bar.navbar-expand-md .navbar-form .form-control {
    height: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .management-bar.navbar-expand-md .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.5rem;
    padding-bottom: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-md .navbar-brand .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-md .navbar-form {
    height: calc(
												4rem -
													0.0625rem -
													0px
											);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .management-bar.navbar-expand-md .navbar-form > .container,
  .management-bar.navbar-expand-md .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .management-bar.navbar-expand-md .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-md .nav-btn .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-md .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .management-bar.navbar-expand-md .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .management-bar.navbar-expand-md .nav-item > .custom-control,
  .management-bar.navbar-expand-md .nav-item > .form-check {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .management-bar.navbar-expand-md .nav-link,
  .management-bar.navbar-expand-md .navbar-text {
    margin-bottom: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-md .nav-link .c-inner,
  .management-bar.navbar-expand-md .navbar-text .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-md .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .management-bar.navbar-expand-md .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 991.98px) {
  .management-bar.navbar-expand-lg {
    min-height: 3rem;
  }
  .management-bar.navbar-expand-lg.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .management-bar.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .management-bar.navbar-expand-lg .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .management-bar.navbar-expand-lg .navbar-form .form-control {
    height: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  .management-bar.navbar-expand-lg .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.5rem;
    padding-bottom: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-lg .navbar-brand .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-lg .navbar-form {
    height: calc(
												4rem -
													0.0625rem -
													0px
											);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .management-bar.navbar-expand-lg .navbar-form > .container,
  .management-bar.navbar-expand-lg .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .management-bar.navbar-expand-lg .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-lg .nav-btn .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-lg .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .management-bar.navbar-expand-lg .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .management-bar.navbar-expand-lg .nav-item > .custom-control,
  .management-bar.navbar-expand-lg .nav-item > .form-check {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .management-bar.navbar-expand-lg .nav-link,
  .management-bar.navbar-expand-lg .navbar-text {
    margin-bottom: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-lg .nav-link .c-inner,
  .management-bar.navbar-expand-lg .navbar-text .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-lg .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .management-bar.navbar-expand-lg .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 1199.98px) {
  .management-bar.navbar-expand-xl {
    min-height: 3rem;
  }
  .management-bar.navbar-expand-xl.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .management-bar.navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .management-bar.navbar-expand-xl .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: calc((
						2rem -
							(1rem * 1.5)
					) * 0.5);
  }
  .management-bar.navbar-expand-xl .navbar-form .form-control {
    height: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 1200px) {
  .management-bar.navbar-expand-xl .navbar-brand {
    font-size: 1.25rem;
    margin-right: 0.5rem;
    padding-bottom: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							4rem -
								0.0625rem -
								0px -
								0px *
								2 -
								(1.25rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-xl .navbar-brand .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-xl .navbar-form {
    height: calc(
												4rem -
													0.0625rem -
													0px
											);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .management-bar.navbar-expand-xl .navbar-form > .container,
  .management-bar.navbar-expand-xl .navbar-form > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .management-bar.navbar-expand-xl .nav-btn {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-xl .nav-btn .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-xl .nav-btn-monospaced {
    font-size: 1rem;
    padding: 0;
  }
  .management-bar.navbar-expand-xl .nav-btn-monospaced .c-inner {
    margin: 0;
  }
  .management-bar.navbar-expand-xl .nav-item > .custom-control,
  .management-bar.navbar-expand-xl .nav-item > .form-check {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .management-bar.navbar-expand-xl .nav-link,
  .management-bar.navbar-expand-xl .navbar-text {
    margin-bottom: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5);
    padding-bottom: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: calc((
							2rem -
								(1rem * 1.5)
						) * 0.5);
  }
  .management-bar.navbar-expand-xl .nav-link .c-inner,
  .management-bar.navbar-expand-xl .navbar-text .c-inner {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .management-bar.navbar-expand-xl .nav-link-monospaced {
    font-size: 1rem;
    margin-bottom: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: calc((
						4rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
    padding: 0;
  }
  .management-bar.navbar-expand-xl .nav-link-monospaced .c-inner {
    margin: 0;
  }
}
@media (max-width: 575.98px) {
  .management-bar .navbar-overlay-xs-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 767.98px) {
  .management-bar .navbar-overlay-sm-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 991.98px) {
  .management-bar .navbar-overlay-md-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 1199.98px) {
  .management-bar .navbar-overlay-lg-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
.management-bar .navbar-overlay-up {
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0px;
}
.management-bar.navbar-underline .navbar-toggler-link:after {
  bottom: calc((
						0.0625rem +
							0px +
							calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5)
					) * -1);
  height: 0.25rem;
}
@media (min-width: 576px) {
  .management-bar.navbar-underline.navbar-expand-sm .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
@media (min-width: 768px) {
  .management-bar.navbar-underline.navbar-expand-md .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
@media (min-width: 992px) {
  .management-bar.navbar-underline.navbar-expand-lg .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .management-bar.navbar-underline.navbar-expand-xl .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
.management-bar.navbar-underline.navbar-expand .navbar-nav .nav-link.active:after {
  bottom: calc((0.0625rem + 0px + calc((
						(
								4rem -
									0.0625rem -
									0px
							) -
							2rem
					) * 0.5)) * -1);
  height: 0.25rem;
}
.management-bar.navbar-nowrap .navbar-text {
  white-space: normal;
  word-wrap: break-word;
}

.management-bar-light {
  background-color: #f7f8f9;
}
.management-bar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.management-bar-light .navbar-nav .nav-link:hover, .management-bar-light .navbar-nav .nav-link.hover {
  color: rgba(0, 0, 0, 0.7);
}
.management-bar-light .navbar-nav .nav-link:active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-light .navbar-nav .nav-link:disabled, .management-bar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.management-bar-light .navbar-nav .nav-link[aria-expanded=true], .management-bar-light .navbar-nav .nav-link.show {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-light .navbar-brand:active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-light .navbar-brand.active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-light .navbar-brand:disabled, .management-bar-light .navbar-brand.disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.management-bar-light .navbar-brand[aria-expanded=true], .management-bar-light .navbar-brand.show {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
}
.management-bar-light .navbar-overlay {
  background-color: #f7f8f9;
}
.management-bar-primary {
  background-color: #f0f5ff;
  border-color: #0b5fff;
  color: rgba(0, 0, 0, 0.5);
}
.management-bar-primary .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.management-bar-primary .navbar-nav .nav-link:hover, .management-bar-primary .navbar-nav .nav-link.hover {
  color: rgba(0, 0, 0, 0.7);
}
.management-bar-primary .navbar-nav .nav-link:active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-primary .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-primary .navbar-nav .nav-link:disabled, .management-bar-primary .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.management-bar-primary .navbar-nav .nav-link[aria-expanded=true], .management-bar-primary .navbar-nav .nav-link.show {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-primary .navbar-brand:active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-primary .navbar-brand.active {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-primary .navbar-brand:disabled, .management-bar-primary .navbar-brand.disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.management-bar-primary .navbar-brand[aria-expanded=true], .management-bar-primary .navbar-brand.show {
  color: rgba(0, 0, 0, 0.9);
}
.management-bar-primary .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
}
.management-bar-primary .navbar-overlay {
  background-color: #f0f5ff;
}
.navigation-bar {
  border-width: 0px 0px 0.0625rem 0px;
  font-size: 1rem;
  padding: 0px 0;
  border-color: transparent;
  border-style: solid;
}
.navigation-bar .navbar-toggler {
  font-size: 1.25rem;
  height: calc(3rem * 0.66667);
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
}
.navigation-bar .navbar-toggler .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.25rem;
}
.navigation-bar .navbar-toggler-link {
  font-size: 1rem;
  height: auto;
  line-height: 1.5;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							1rem *
							1.5
					) * 0.5);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							1rem *
							1.5
					) * 0.5);
}
.navigation-bar .navbar-toggler-link .c-inner {
  margin-left: -1rem;
  margin-right: -1rem;
}
.navigation-bar .navbar-brand {
  font-size: 1.25rem;
  margin-right: 0;
  padding-bottom: calc((
							3rem -
								0.0625rem -
								0px -
								0px *
								2 -
								1.25rem *
								1.5
						) * 0.5);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: calc((
							3rem -
								0.0625rem -
								0px -
								0px *
								2 -
								1.25rem *
								1.5
						) * 0.5);
}
.navigation-bar .navbar-brand .c-inner {
  margin-left: -1rem;
  margin-right: -1rem;
}
.navigation-bar .navbar-nav .nav-btn {
  font-size: 1rem;
  margin-bottom: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}
.navigation-bar .navbar-nav .nav-btn .c-inner {
  margin-bottom: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
}
.navigation-bar .navbar-nav .nav-btn-monospaced {
  padding: 0;
}
.navigation-bar .navbar-nav .nav-btn-monospaced .c-inner {
  margin: 0;
}
.navigation-bar .navbar-nav .nav-item > .custom-control,
.navigation-bar .navbar-nav .nav-item > .form-check {
  margin-left: 1rem;
  margin-right: 1rem;
}
.navigation-bar .navbar-nav .nav-link,
.navigation-bar .navbar-nav .navbar-text {
  margin-bottom: calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5);
  margin-top: calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5);
  padding-bottom: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
}
.navigation-bar .navbar-nav .nav-link .c-inner,
.navigation-bar .navbar-nav .navbar-text .c-inner {
  margin-left: -1rem;
  margin-right: -1rem;
}
.navigation-bar .navbar-nav .nav-link-monospaced {
  margin-bottom: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: calc((
						3rem -
							0.0625rem -
							0px -
							0px *
							2 -
							2rem
					) * 0.5);
  padding: 0;
}
.navigation-bar .navbar-nav .nav-link-monospaced .c-inner {
  margin: 0;
}
.navigation-bar .dropdown-menu {
  margin-top: 0;
}
@media (max-width: 575.98px) {
  .navigation-bar.navbar-expand-sm.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .navigation-bar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .navigation-bar.navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-item {
    padding-bottom: 0.71875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.71875rem;
  }
  .navigation-bar.navbar-expand-sm .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
  }
  .navigation-bar.navbar-expand-sm .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width: 767.98px) {
  .navigation-bar.navbar-expand-md.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .navigation-bar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .navigation-bar.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item {
    padding-bottom: 0.71875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.71875rem;
  }
  .navigation-bar.navbar-expand-md .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
  }
  .navigation-bar.navbar-expand-md .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width: 991.98px) {
  .navigation-bar.navbar-expand-lg.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .navigation-bar.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .navigation-bar.navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-item {
    padding-bottom: 0.71875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.71875rem;
  }
  .navigation-bar.navbar-expand-lg .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
  }
  .navigation-bar.navbar-expand-lg .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width: 1199.98px) {
  .navigation-bar.navbar-expand-xl.navbar-collapse-absolute .navbar-collapse {
    border-color: transparent;
    border-style: solid;
    border-width: 0 0px 0.0625rem 0px;
    left: 0px;
    margin-top: 0.0625rem;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
    right: 0px;
  }
  .navigation-bar.navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .navigation-bar.navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-item {
    padding-bottom: 0.71875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.71875rem;
  }
  .navigation-bar.navbar-expand-xl .navbar-form {
    height: calc(
											3rem - 0.0625rem -
												0px
										);
    padding-bottom: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: calc((
						3rem - 0.0625rem - 0px - 0px * 2 -
							(1rem * 1.5)
					) * 0.5);
  }
  .navigation-bar.navbar-expand-xl .navbar-form .form-control {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  .navigation-bar .navbar-overlay-xs-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 767.98px) {
  .navigation-bar .navbar-overlay-sm-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 991.98px) {
  .navigation-bar .navbar-overlay-md-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
@media (max-width: 1199.98px) {
  .navigation-bar .navbar-overlay-lg-down {
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
}
.navigation-bar .navbar-overlay-up {
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0px;
}
.navigation-bar.navbar-underline .navbar-toggler-link:after {
  bottom: calc((
						0.0625rem +
							0px +
							calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5)
					) * -1);
  height: 0.25rem;
}
@media (min-width: 576px) {
  .navigation-bar.navbar-underline.navbar-expand-sm .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
@media (min-width: 768px) {
  .navigation-bar.navbar-underline.navbar-expand-md .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
@media (min-width: 992px) {
  .navigation-bar.navbar-underline.navbar-expand-lg .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .navigation-bar.navbar-underline.navbar-expand-xl .navbar-nav .nav-link.active:after {
    bottom: calc((0.0625rem + 0px + calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5)) * -1);
    height: 0.25rem;
  }
}
.navigation-bar.navbar-underline.navbar-expand .navbar-nav .nav-link.active:after {
  bottom: calc((0.0625rem + 0px + calc((
						(
								3rem -
									0.0625rem -
									0px
							) -
							3rem - 0.0625rem - 0px - 0px * 2
					) * 0.5)) * -1);
  height: 0.25rem;
}
.navigation-bar .navbar-nav .nav-link {
  border-width: 0;
  font-size: inherit;
}
.navigation-bar .navbar-brand {
  border-width: 0;
  font-size: inherit;
}
.navigation-bar-light {
  background-color: #f7f8f9;
}
.navigation-bar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navigation-bar-light .navbar-nav .nav-link:hover, .navigation-bar-light .navbar-nav .nav-link.hover {
  color: rgba(0, 0, 0, 0.7);
}
.navigation-bar-light .navbar-nav .nav-link:active {
  color: rgba(0, 0, 0, 0.9);
}
.navigation-bar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navigation-bar-light .navbar-nav .nav-link:disabled, .navigation-bar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.navigation-bar-light .navbar-nav .nav-link[aria-expanded=true], .navigation-bar-light .navbar-nav .nav-link.show {
  color: rgba(0, 0, 0, 0.9);
}
.navigation-bar-light .navbar-brand:active {
  color: rgba(0, 0, 0, 0.9);
}
.navigation-bar-light .navbar-brand.active {
  color: rgba(0, 0, 0, 0.9);
}
.navigation-bar-light .navbar-brand:disabled, .navigation-bar-light .navbar-brand.disabled {
  color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.navigation-bar-light .navbar-brand[aria-expanded=true], .navigation-bar-light .navbar-brand.show {
  color: rgba(0, 0, 0, 0.9);
}
.navigation-bar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
}
.navigation-bar-light .navbar-overlay {
  background-color: #f7f8f9;
}
@media (max-width: 767.98px) {
  .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(0, 0, 0, 0.5);
  }
  .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:hover, .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.hover {
    color: rgba(0, 0, 0, 0.7);
  }
  .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:active {
    color: rgba(0, 0, 0, 0.9);
  }
  .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.active {
    color: rgba(0, 0, 0, 0.9);
  }
  .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:disabled, .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.disabled {
    color: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }
  .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item[aria-expanded=true], .navigation-bar-light.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.show {
    color: rgba(0, 0, 0, 0.9);
  }
}
@media (min-width: 768px) {
  .navigation-bar-light.navbar-expand-md.navbar-underline .navbar-nav .nav-link.active::after {
    background-color: #528eff;
  }
  .navigation-bar-light.navbar-expand-md.navbar-underline .navbar-nav .nav-link[aria-expanded=true]::after, .navigation-bar-light.navbar-expand-md.navbar-underline .navbar-nav .nav-link.show::after {
    background-color: #528eff;
  }
}

.navigation-bar-secondary {
  background-color: #6b6c7e;
  color: #fff;
}
.navigation-bar-secondary .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.65);
}
.navigation-bar-secondary .navbar-nav .nav-link:hover, .navigation-bar-secondary .navbar-nav .nav-link.hover {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-nav .nav-link:active {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-nav .nav-link:disabled, .navigation-bar-secondary .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
  opacity: 1;
}
.navigation-bar-secondary .navbar-nav .nav-link[aria-expanded=true], .navigation-bar-secondary .navbar-nav .nav-link.show {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-brand {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-brand:hover, .navigation-bar-secondary .navbar-brand.hover {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-brand:active {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-brand.active {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-brand:disabled, .navigation-bar-secondary .navbar-brand.disabled {
  color: rgba(255, 255, 255, 0.25);
  opacity: 1;
}
.navigation-bar-secondary .navbar-brand[aria-expanded=true], .navigation-bar-secondary .navbar-brand.show {
  color: rgba(255, 255, 255, 0.9);
}
.navigation-bar-secondary .navbar-toggler {
  color: rgba(255, 255, 255, 0.65);
}
.navigation-bar-secondary .navbar-overlay {
  background-color: #6b6c7e;
}
@media (max-width: 767.98px) {
  .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item {
    color: rgba(255, 255, 255, 0.65);
  }
  .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:hover, .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.hover {
    color: rgba(255, 255, 255, 0.9);
  }
  .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:active {
    color: rgba(255, 255, 255, 0.9);
  }
  .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.active {
    color: rgba(255, 255, 255, 0.9);
  }
  .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item:disabled, .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.disabled {
    color: rgba(255, 255, 255, 0.25);
    opacity: 1;
  }
  .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item[aria-expanded=true], .navigation-bar-secondary.navbar-expand-md .navbar-collapse .navbar-nav .dropdown-item.show {
    color: rgba(255, 255, 255, 0.9);
  }
}

.pagination {
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0.5rem;
  padding-left: 0;
}
.pagination-bar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .pagination-bar {
    flex-direction: column;
    justify-content: center;
  }
  .pagination-bar .pagination {
    margin-top: 0.5rem;
  }
}

.page-link {
  align-items: center;
  background-color: #fff;
  border-radius: 0px;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0.0625rem;
  color: #0b5fff;
  cursor: pointer;
  display: inline-flex;
  height: 2.375rem;
  justify-content: center;
  line-height: 1.25;
  margin-left: 0;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  position: relative;
}
.page-link:hover, .page-link.hover {
  background-color: #f1f2f5;
  border-color: #dee2e6;
  color: #0041be;
  text-decoration: none;
  z-index: 2;
}
.page-link.focus, .page-link:focus-visible, .c-prefers-focus .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
  z-index: 4;
}
.page-link:active {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
  z-index: 3;
}
.page-link.active {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
  z-index: 3;
}
.page-link:disabled, .page-link.disabled {
  background-color: #fff;
  border-color: #dee2e6;
  box-shadow: none;
  color: #6c757d;
  cursor: not-allowed;
  opacity: 1;
  pointer-events: auto;
  z-index: 0;
}
.page-link:disabled:active, .page-link.disabled:active {
  pointer-events: none;
}
.page-link[aria-expanded=true], .page-link.show {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
  z-index: 3;
}
.page-link > .c-inner {
  margin-bottom: -0.5rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.5rem;
}
.page-link .lexicon-icon {
  margin-top: 0;
}

.page-item {
  margin-left: -0.5px;
  margin-right: -0.5px;
}
.page-item.active .page-link {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
  z-index: 3;
}
.page-item.disabled .page-link {
  background-color: #fff;
  border-color: #dee2e6;
  box-shadow: none;
  color: #6c757d;
  cursor: not-allowed;
  opacity: 1;
  pointer-events: auto;
  z-index: 0;
}
.page-item.disabled .page-link:active {
  pointer-events: none;
}
.page-item:first-child .page-link,
.page-link-first {
  border-radius: 0.25rem 0 0 0.25rem;
}

.page-item:last-child .page-link,
.page-link-last {
  border-radius: 0 0.25rem 0.25rem 0;
}

.pagination-items-per-page {
  margin-bottom: 0.5rem;
}
.pagination-items-per-page > a,
.pagination-items-per-page > button {
  align-items: center;
  border-color: #dee2e6;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.0625rem;
  color: #0b5fff;
  display: inline-flex;
  height: 2.375rem;
  justify-content: center;
  line-height: 1.25;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  text-decoration: none;
}
.pagination-items-per-page > a:hover, .pagination-items-per-page > a.hover,
.pagination-items-per-page > button:hover,
.pagination-items-per-page > button.hover {
  background-color: #f1f2f5;
  border-color: #dee2e6;
  color: #0041be;
}
.pagination-items-per-page > a.focus, .pagination-items-per-page > a:focus-visible, .c-prefers-focus .pagination-items-per-page > a:focus,
.pagination-items-per-page > button.focus,
.pagination-items-per-page > button:focus-visible,
.c-prefers-focus .pagination-items-per-page > button:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.pagination-items-per-page > a:active,
.pagination-items-per-page > button:active {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
.pagination-items-per-page > a.active,
.pagination-items-per-page > button.active {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
.pagination-items-per-page > a:disabled, .pagination-items-per-page > a.disabled,
.pagination-items-per-page > button:disabled,
.pagination-items-per-page > button.disabled {
  background-color: #fff;
  border-color: #dee2e6;
  box-shadow: none;
  color: #6c757d;
  cursor: not-allowed;
  opacity: 1;
  pointer-events: auto;
}
.pagination-items-per-page > a[aria-expanded=true], .pagination-items-per-page > a.show,
.pagination-items-per-page > button[aria-expanded=true],
.pagination-items-per-page > button.show {
  background-color: #0b5fff;
  border-color: #0b5fff;
  color: #fff;
}
.pagination-items-per-page > a > .c-inner,
.pagination-items-per-page > button > .c-inner {
  margin-bottom: -0.5rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.5rem;
}
.pagination-items-per-page > a .lexicon-icon,
.pagination-items-per-page > button .lexicon-icon {
  margin-left: 0.125rem;
  margin-top: 0.125rem;
}
.pagination-items-per-page.disabled > a,
.pagination-items-per-page.disabled > button {
  background-color: #fff;
  border-color: #dee2e6;
  box-shadow: none;
  color: #6c757d;
  cursor: not-allowed;
  opacity: 1;
  pointer-events: auto;
}
@media (max-width: 767.98px) {
  .pagination-items-per-page + .pagination-results {
    margin-left: auto;
  }
}

.pagination-results {
  border-color: transparent;
  border-style: solid;
  border-width: 0.0625rem;
  line-height: 1.25;
  margin-bottom: 0.5rem;
  margin-right: auto;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.pagination-sm .page-link {
  font-size: 0.875rem;
  height: 1.9375rem;
  line-height: 1;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
}
.pagination-sm .page-link > .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
}
.pagination-sm .page-item:first-child .page-link,
.pagination-sm .page-link-first {
  border-bottom-left-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link,
.pagination-sm .page-link-last {
  border-bottom-right-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.pagination-sm .pagination-items-per-page {
  border-radius: 0.2rem;
}
.pagination-sm .pagination-items-per-page > a,
.pagination-sm .pagination-items-per-page > button {
  font-size: 0.875rem;
  height: 1.9375rem;
  line-height: 1;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
}
.pagination-sm .pagination-items-per-page > a > .c-inner,
.pagination-sm .pagination-items-per-page > button > .c-inner {
  margin-bottom: -0.25rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.25rem;
}
.pagination-sm .pagination-items-per-page + .pagination-results {
  font-size: 0.875rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
}

.pagination-lg .page-link {
  font-size: 1.25rem;
  height: 3.5rem;
  line-height: 1;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
}
.pagination-lg .page-link > .c-inner {
  margin-bottom: -0.75rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-top: -0.75rem;
}
.pagination-lg .page-item:first-child .page-link,
.pagination-lg .page-link-first {
  border-bottom-left-radius: 0.3rem;
  border-top-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link,
.pagination-lg .page-link-last {
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.pagination-lg .pagination-items-per-page {
  border-radius: 0.3rem;
}
.pagination-lg .pagination-items-per-page > a,
.pagination-lg .pagination-items-per-page > button {
  font-size: 1.25rem;
  height: 3.5rem;
  line-height: 1;
  padding: 0.75rem 0.75rem;
}
.pagination-lg .pagination-items-per-page + .pagination-results {
  font-size: 1.25rem;
  line-height: 1;
  padding: 0.75rem 0.75rem;
}

.panel {
  background-color: #fff;
  border-color: transparent;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  margin-bottom: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.panel-header {
  border-bottom: 1px solid transparent;
  border-top-left-radius: calc( 	0.25rem - 1px );
  border-top-right-radius: calc( 	0.25rem - 1px );
  display: block;
  font-size: 1rem;
  line-height: 1.2;
  padding: 0.75rem 1.25rem;
  position: relative;
  width: 100%;
}
.panel-header .c-inner {
  margin: -0.75rem -1.25rem;
  width: auto;
}
.panel-header.collapsed {
  border-bottom-left-radius: calc( 	0.25rem - 1px );
  border-bottom-right-radius: calc( 	0.25rem - 1px );
}
.panel-header.collapse-icon-middle .collapse-icon-closed,
.panel-header.collapse-icon-middle .collapse-icon-open {
  font-size: inherit;
}
.panel-header .collapse-icon-closed,
.panel-header .collapse-icon-open {
  font-size: 0.875rem;
  top: calc(0.75rem + (((1em * 1.2) - 1em) * 0.5));
}

.panel-header-link {
  color: inherit;
  display: block;
  transition: border-color 0.1s ease, border-radius 0.5s ease;
}
@media (prefers-reduced-motion: reduce) {
  .panel-header-link {
    transition: none;
  }
}
.c-prefers-reduced-motion .panel-header-link {
  transition: none;
}

.panel-header-link:hover, .panel-header-link.hover {
  color: inherit;
}
.panel-header-link.focus, .panel-header-link:focus-visible, .c-prefers-focus .panel-header-link:focus {
  z-index: 1;
}
.panel-header-link.panel-header.collapsed {
  border-color: transparent;
  transition: border-color 0.75s ease;
}
@media (prefers-reduced-motion: reduce) {
  .panel-header-link.panel-header.collapsed {
    transition: none;
  }
}
.c-prefers-reduced-motion .panel-header-link.panel-header.collapsed {
  transition: none;
}

.panel-header-link .collapse-icon {
  padding-right: 2.28125rem;
}

.panel-body {
  padding: 0.75rem 1.25rem;
}

.panel-footer {
  border-bottom-left-radius: calc( 	0.25rem - 1px );
  border-bottom-right-radius: calc( 	0.25rem - 1px );
  border-top: 1px solid transparent;
  padding: 0.75rem 1.25rem;
}

.panel-title {
  font-weight: 500;
}
.panel-group {
  border-radius: 0.25rem;
}
.panel-group .panel {
  margin-bottom: -1px;
}
.panel-group .panel:first-child, .panel-group .panel:first-child .panel-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.panel-group .panel:not(:first-child) {
  border-radius: 0;
  margin-top: -1px;
}
.panel-group .panel:not(:first-child) .panel-header {
  border-radius: 0;
}
.panel-group .panel:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.panel-group .panel:last-child .panel-header.collapsed {
  border-bottom-left-radius: calc(
	0.25rem - 1px
);
  border-bottom-right-radius: calc(
	0.25rem - 1px
);
}
.panel-group .panel-header {
  border-bottom-width: 0;
}
.panel-group .panel-header + .panel-collapse > .panel-body {
  border-top: 1px solid transparent;
}
.panel-group .panel-unstyled {
  margin-bottom: 1.5rem;
}
.panel-group-fluid .panel,
.panel-group-flush .panel {
  border-left-width: 0;
  border-right-width: 0;
}
.panel-group-fluid .panel:first-child, .panel-group-fluid .panel:last-child,
.panel-group-flush .panel:first-child,
.panel-group-flush .panel:last-child {
  border-radius: 0;
}
.panel-group-fluid .panel:first-child .panel-header, .panel-group-fluid .panel:first-child .panel-header.collapsed, .panel-group-fluid .panel:last-child .panel-header, .panel-group-fluid .panel:last-child .panel-header.collapsed,
.panel-group-flush .panel:first-child .panel-header,
.panel-group-flush .panel:first-child .panel-header.collapsed,
.panel-group-flush .panel:last-child .panel-header,
.panel-group-flush .panel:last-child .panel-header.collapsed {
  border-radius: 0;
}

.panel-group-fluid-first .panel {
  border-left-width: 0;
  border-right-width: 0;
}
.panel-group-fluid-first .panel:first-child {
  border-top-width: 0;
}
.panel-group-fluid-first .panel:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.panel-group-fluid-last .panel {
  border-left-width: 0;
  border-right-width: 0;
}
.panel-group-fluid-last .panel:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.panel-group-fluid-last .panel:last-child {
  border-bottom-width: 0;
}

.card-body > .panel-group-fluid,
.card-body > .panel-group-fluid-first,
.card-body > .panel-group-fluid-last {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.sheet > .panel-group-fluid {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.sheet > .panel-group-fluid-first {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-top: -1.5rem;
}
.sheet > .panel-group-fluid-last {
  margin-bottom: -0.0625rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.panel-group-flush .panel-header,
.panel-group-flush .panel-header-link {
  padding-left: 0;
  padding-right: 0;
}
.panel-group-flush .panel-header .c-inner,
.panel-group-flush .panel-header-link .c-inner {
  margin-left: 0;
  margin-right: 0;
}
.panel-group-flush .panel-body {
  margin-bottom: 1.5rem;
  padding: 0;
}
.panel-group-flush .collapse-icon {
  padding-right: 1.5rem;
}
.panel-group-flush .collapse-icon .c-inner {
  margin-right: -1.5rem;
}
.panel-group-flush .collapse-icon-closed,
.panel-group-flush .collapse-icon-open {
  right: 0;
}
.panel-group-flush .panel-unstyled {
  margin-bottom: 1.5rem;
}
.panel-group-flush .panel-unstyled .panel-body {
  margin-bottom: 0;
}
.sidebar-sm .panel-group .panel, .panel-group-sm .panel {
  font-size: 0.875rem;
}
.sidebar-sm .panel-group .panel .panel-header, .panel-group-sm .panel .panel-header {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.sidebar-sm .panel-group .panel .panel-header .c-inner, .panel-group-sm .panel .panel-header .c-inner {
  margin-bottom: -0.5rem;
  margin-top: -0.5rem;
}
.sidebar-sm .panel-group .panel .panel-header:not(.collapse-icon-middle) .collapse-icon-closed, .panel-group-sm .panel .panel-header:not(.collapse-icon-middle) .collapse-icon-closed,
.sidebar-sm .panel-group .panel .panel-header:not(.collapse-icon-middle) .collapse-icon-open,
.panel-group-sm .panel .panel-header:not(.collapse-icon-middle) .collapse-icon-open {
  top: 0.6875rem;
}
.sidebar-sm .panel-group .panel .panel-title, .panel-group-sm .panel .panel-title {
  font-size: 0.875rem;
}
.sidebar-sm .panel-group .panel-unstyled, .panel-group-sm .panel-unstyled {
  margin-bottom: 1rem;
}
.panel-block {
  border-color: #ced4da;
}
.panel-block .panel-header {
  font-size: 1.25rem;
  line-height: 1.25;
  padding: 1.15625rem 1.25rem;
}
.panel-block .panel-header.collapse-icon {
  padding-right: 3rem;
}
.panel-block .panel-header:not(.collapse-icon-middle) .collapse-icon-closed,
.panel-block .panel-header:not(.collapse-icon-middle) .collapse-icon-open {
  font-size: inherit;
  top: 1.3125rem;
}
.panel-group .panel-block .panel-header + .panel-collapse > .panel-body {
  border-color: #ced4da;
}
.panel-block .panel-title {
  font-size: inherit;
  text-transform: none;
}
.panel-block .panel-body {
  padding: 0 1.25rem 1.25rem;
}
.panel-block .panel-footer {
  padding: 0 1.25rem 1.25rem;
}
.panel-default {
  border-width: 0px;
}
.panel-default .panel-header {
  border-radius: 0;
  color: #6c757d;
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding-bottom: 0.40625rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.40625rem;
  text-transform: uppercase;
}
.panel-default .panel-header .c-inner {
  margin-bottom: -0.40625rem;
  margin-left: 0;
  margin-right: 0;
  margin-top: -0.40625rem;
}
.panel-default .panel-header.panel-header-link {
  border-bottom: 1px solid #ced4da;
}
.panel-default .panel-header.collapse-icon {
  padding-right: 1.75rem;
}
.panel-default .panel-header:not(.collapse-icon-middle) .collapse-icon-closed,
.panel-default .panel-header:not(.collapse-icon-middle) .collapse-icon-open {
  font-size: inherit;
  right: 0;
  top: 0.5rem;
}
.panel-default .panel-header.collapse-icon-middle .collapse-icon-closed,
.panel-default .panel-header.collapse-icon-middle .collapse-icon-open {
  right: 0;
}
.panel-default .panel-title {
  font-size: inherit;
}
.panel-default .panel-body {
  padding: 1.25rem 0;
}
.panel-default .panel-footer {
  padding: 1.25rem 0;
}
.panel-secondary {
  border-color: rgba(0, 0, 0, 0.125);
}
.panel-secondary .panel-header {
  background-color: #f7f8f9;
  border-color: rgba(0, 0, 0, 0.125);
}
.panel-group .panel-secondary .panel-header + .panel-collapse > .panel-body {
  border-color: rgba(0, 0, 0, 0.125);
}
.panel-secondary .panel-footer {
  background-color: #f7f8f9;
  border-color: rgba(0, 0, 0, 0.125);
}
.panel-unstyled {
  background-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  margin-bottom: 1.5rem;
}
.panel-unstyled .panel-header {
  border-color: #adb5bd;
  border-radius: 0px;
  border-style: solid;
  border-width: 0 0 1px 0;
  padding-left: 0;
}
.panel-unstyled .panel-header .c-inner {
  margin-left: 0;
}
.panel-unstyled .panel-header.collapsed {
  border-color: #adb5bd;
}
.panel-unstyled .panel-header:not(.collapse-icon-middle) .collapse-icon-closed,
.panel-unstyled .panel-header:not(.collapse-icon-middle) .collapse-icon-open {
  right: 0;
}
.panel-unstyled .panel-body {
  padding: 1rem 0 0.1px 0;
}
.panel-unstyled .panel-footer {
  padding: 1rem 0 0.1px 0;
}
.panel-lg.panel-block .panel-header {
  font-size: 1.5rem;
  padding: 1.5rem 1.5rem;
}
.panel-lg.panel-block .panel-header.collapse-icon {
  padding-right: 4.5rem;
}
.panel-lg.panel-block .panel-header:not(.collapse-icon-middle) .collapse-icon-closed,
.panel-lg.panel-block .panel-header:not(.collapse-icon-middle) .collapse-icon-open {
  top: 1.75rem;
}
.panel-lg.panel-block .panel-body {
  padding: 0 1.5rem 1.5rem;
}
.panel-lg.panel-block .panel-footer {
  padding: 0 1.5rem 1.5rem;
}
.panel-sm.panel-block .panel-header {
  font-size: 1rem;
  padding: 0.8125rem 1rem;
}
.panel-sm.panel-block .panel-header.collapse-icon {
  padding-right: 3rem;
}
.panel-sm.panel-block .panel-header:not(.collapse-icon-middle) .collapse-icon-closed,
.panel-sm.panel-block .panel-header:not(.collapse-icon-middle) .collapse-icon-open {
  top: 0.9375rem;
}
.panel-sm.panel-block .panel-body {
  padding: 0 1rem 1rem;
}
.panel-sm.panel-block .panel-footer {
  padding: 0 1rem 1rem;
}
.panel-sm.panel-default .panel-header {
  font-size: 0.75rem;
  line-height: 1;
  padding-bottom: 0.34375rem;
  padding-top: 0.34375rem;
}
.panel-sm.panel-default .panel-header .c-inner {
  margin-bottom: -0.34375rem;
  margin-top: -0.34375rem;
}
.panel-sm.panel-default .panel-header.collapse-icon {
  padding-right: 1.75rem;
}
.panel-sm.panel-default .panel-header:not(.collapse-icon-middle) .collapse-icon-closed,
.panel-sm.panel-default .panel-header:not(.collapse-icon-middle) .collapse-icon-open {
  top: 0.375rem;
}
.popover {
  background-clip: padding-box;
  background-color: #fff;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  left: 0;
  letter-spacing: normal;
  line-height: 1.5;
  max-width: 276px;
  position: absolute;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  top: 0;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  z-index: 1060;
}
.popover .arrow {
  display: block;
  height: 0.5rem;
  margin: 0 0.3rem;
  position: absolute;
  width: 1rem;
}
.popover .arrow::before {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
}
.popover .arrow::after {
  border-color: transparent;
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
}
.popover .inline-scroller {
  max-height: 14.75rem;
}
.popover.focus, .popover:focus-visible, .c-prefers-focus .popover:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.popover-secondary {
  box-shadow: 0 8px 16px 0 rgba(11, 95, 255, 0.16);
}
.popover-secondary .popover-header {
  background-color: transparent;
  border-color: transparent;
  padding-bottom: 0;
}
.popover-secondary .close {
  color: #6c757d;
}
.bs-popover-bottom,
.bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(
			-0.5rem - 0.0625rem
		);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0 0.5rem 0.5rem 0.5rem;
  top: 0;
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^=bottom] > .arrow::after {
  border-bottom-color: #f7f7f7;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  top: 0.0625rem;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^=bottom] .popover-header::before {
  border-bottom: 0.0625rem solid #f7f7f7;
  content: "";
  display: block;
  left: 50%;
  margin-left: calc(-1rem / 2);
  position: absolute;
  top: 0;
  width: 1rem;
}

.bs-popover-left,
.bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^=left] > .arrow {
  height: 1rem;
  margin: 0.3rem 0;
  right: calc(
			-0.5rem - 0.0625rem
		);
  width: 0.5rem;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^=left] > .arrow::before {
  border-left-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0;
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^=left] > .arrow::after {
  border-left-color: #fff;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0.0625rem;
}

.bs-popover-right,
.bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^=right] > .arrow {
  height: 1rem;
  left: calc(
			-0.5rem - 0.0625rem
		);
  margin: 0.3rem 0;
  width: 0.5rem;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^=right] > .arrow::before {
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0;
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^=right] > .arrow::after {
  border-right-color: #fff;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0.0625rem;
}

.bs-popover-top,
.bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(
			-0.5rem - 0.0625rem
		);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^=top] > .arrow::before {
  border-top-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0;
  bottom: 0;
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^=top] > .arrow::after {
  border-top-color: #fff;
  border-width: 0.5rem 0.5rem 0;
  bottom: 0.0625rem;
}

.popover-header {
  background-color: #f7f7f7;
  border-bottom: 0.0625rem solid #ebebeb;
  border-top-left-radius: calc( 	0.3rem - 0.0625rem );
  border-top-right-radius: calc( 	0.3rem - 0.0625rem );
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0.5rem 0.75rem;
}
.popover-header:empty {
  display: none;
}
.popover-body {
  border-bottom-left-radius: calc( 	0.3rem - 0.0625rem );
  border-bottom-right-radius: calc( 	0.3rem - 0.0625rem );
  color: #272833;
  padding: 0.5rem 0.75rem;
}
.popover-width-lg {
  max-width: 421px;
}
.clay-popover-top,
.clay-popover-top-left,
.clay-popover-top-right {
  margin-bottom: 0.5rem;
}
.clay-popover-top .arrow,
.clay-popover-top-left .arrow,
.clay-popover-top-right .arrow {
  left: 50%;
  margin-left: -0.5rem;
  bottom: calc((0.5rem + 0.0625rem) * -1);
  margin: 0;
}
.clay-popover-top .arrow::before,
.clay-popover-top-left .arrow::before,
.clay-popover-top-right .arrow::before {
  border-top-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0;
  bottom: 0;
}
.clay-popover-top .arrow::after,
.clay-popover-top-left .arrow::after,
.clay-popover-top-right .arrow::after {
  border-top-color: #fff;
  border-width: 0.5rem 0.5rem 0;
  bottom: 0.0625rem;
}
.clay-popover-top-left .arrow {
  left: 0.375rem;
  margin: 0;
}
.clay-popover-top-right .arrow {
  left: auto;
  right: 0.375rem;
  margin: 0;
}
.clay-popover-right,
.clay-popover-right-bottom,
.clay-popover-right-top {
  margin-left: 0.5rem;
}
.clay-popover-right .arrow,
.clay-popover-right-bottom .arrow,
.clay-popover-right-top .arrow {
  height: 1rem;
  left: calc((0.5rem + 0.0625rem) * -1);
  margin: -0.25rem 0 0;
  top: 50%;
  width: 0.5rem;
}
.clay-popover-right .arrow::before,
.clay-popover-right-bottom .arrow::before,
.clay-popover-right-top .arrow::before {
  border-right-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0;
}
.clay-popover-right .arrow::after,
.clay-popover-right-bottom .arrow::after,
.clay-popover-right-top .arrow::after {
  border-right-color: #fff;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  left: 0.0625rem;
}
.clay-popover-right-bottom .arrow {
  bottom: 0.375rem;
  margin: 0;
  top: auto;
}
.clay-popover-right-top .arrow {
  margin: 0;
  top: 0.375rem;
}
.clay-popover-right-top .arrow::after {
  border-right-color: #f7f7f7;
}
.clay-popover-bottom,
.clay-popover-bottom-left,
.clay-popover-bottom-right {
  margin-top: 0.5rem;
}
.clay-popover-bottom .arrow,
.clay-popover-bottom-left .arrow,
.clay-popover-bottom-right .arrow {
  left: 50%;
  margin: 0 0 0 -0.5rem;
  top: calc((0.5rem + 0.0625rem) * -1);
}
.clay-popover-bottom .arrow::before,
.clay-popover-bottom-left .arrow::before,
.clay-popover-bottom-right .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0.25);
  border-width: 0 0.5rem 0.5rem 0.5rem;
  top: 0;
}
.clay-popover-bottom .arrow::after,
.clay-popover-bottom-left .arrow::after,
.clay-popover-bottom-right .arrow::after {
  border-bottom-color: #f7f7f7;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  top: 0.0625rem;
}
.clay-popover-bottom-left .arrow {
  left: 0.375rem;
  margin: 0;
}
.clay-popover-bottom-left .arrow::after {
  border-bottom-color: #f7f7f7;
}
.clay-popover-bottom-right .arrow {
  left: auto;
  right: 0.375rem;
}
.clay-popover-bottom-right .arrow::after {
  border-bottom-color: #f7f7f7;
}
.clay-popover-left,
.clay-popover-left-bottom,
.clay-popover-left-top {
  margin-right: 0.5rem;
}
.clay-popover-left .arrow,
.clay-popover-left-bottom .arrow,
.clay-popover-left-top .arrow {
  height: 1rem;
  margin: -0.25rem 0 0;
  right: calc((0.5rem + 0.0625rem) * -1);
  top: 50%;
  width: 0.5rem;
}
.clay-popover-left .arrow::before,
.clay-popover-left-bottom .arrow::before,
.clay-popover-left-top .arrow::before {
  border-left-color: rgba(0, 0, 0, 0.25);
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0;
}
.clay-popover-left .arrow::after,
.clay-popover-left-bottom .arrow::after,
.clay-popover-left-top .arrow::after {
  border-left-color: #fff;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  right: 0.0625rem;
}
.clay-popover-left-bottom .arrow {
  bottom: 0.375rem;
  margin: 0;
  top: auto;
}
.clay-popover-left-top .arrow {
  margin: 0;
  top: 0.375rem;
}
.clay-popover-left-top .arrow::after {
  border-left-color: #f7f7f7;
}
.progress {
  background-color: #f1f2f5;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-grow: 1;
  font-size: calc(1rem * 0.75);
  height: 1rem;
  min-width: 6.25rem;
  overflow: hidden;
}

.progress-bar {
  background-color: #0b5fff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  transition: width 0.6s ease;
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.c-prefers-reduced-motion .progress-bar {
  transition: none;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.c-prefers-reduced-motion .progress-bar-animated {
  animation: none;
}

.progress-lg,
.progress-lg > .progress {
  border-radius: 0.25rem;
  font-size: 1.25rem;
  height: calc(1rem * 2);
  line-height: calc(1rem * 2);
}

.progress-group {
  align-items: center;
  display: flex;
}
.progress-group-addon {
  margin-right: 0.25rem;
  min-width: 2rem;
  text-align: center;
}
.progress-group-addon:first-child {
  padding-left: 0;
}
.progress-group-addon:last-child {
  padding-right: 0;
}

.progress ~ .progress-group-addon {
  margin-left: 0.25rem;
  margin-right: 0;
}

.progress-group-feedback {
  color: #0b5fff;
}

.progress-group-stacked {
  display: block;
}
.progress-group-stacked .progress {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.progress-group-stacked .progress-group-addon {
  margin-left: 0;
  margin-right: 0;
}

.progress-success .progress-bar {
  background-color: #28a745;
}
.progress-success .progress-group-feedback {
  color: #28a745;
}

.progress-info .progress-bar {
  background-color: #17a2b8;
}
.progress-info .progress-group-feedback {
  color: #17a2b8;
}

.progress-warning .progress-bar {
  background-color: #ffc107;
}
.progress-warning .progress-group-feedback {
  color: #ffc107;
}

.progress-danger .progress-bar {
  background-color: #dc3545;
}
.progress-danger .progress-group-feedback {
  color: #dc3545;
}

.quick-action-menu {
  align-items: center;
  bottom: 0;
  display: none;
  left: auto;
  position: absolute;
  right: 100%;
  top: 0;
}

.quick-action-item {
  border-radius: 0.25rem;
  display: flex;
  line-height: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-height: 2rem;
  min-width: 2rem;
}
.quick-action-item:disabled, .quick-action-item.disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.quick-action-item .lexicon-icon {
  margin-top: 0;
}

.sheet {
  background-color: #fff;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0.25rem;
  padding-bottom: 0.0625rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}
.sheet::after {
  content: "";
  display: block;
  margin-top: 1.5rem;
}
.sheet + .sheet {
  margin-top: 3rem;
}
.sheet .component-title {
  color: inherit;
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
  max-width: 100%;
}
.sheet .panel-group,
.sheet .panel-group .panel:last-child .panel-body {
  margin-bottom: 1.5rem;
}
.sheet .panel-group .panel-body {
  margin-bottom: 3rem;
}

.sheet-row {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.sheet-header {
  margin-bottom: 3rem;
}
.sheet-header::after {
  clear: both;
  content: "";
  display: block;
}

.sheet-section {
  margin-bottom: 3rem;
}
.sheet-section > fieldset {
  margin-bottom: -3rem;
}
.sheet-section > .card-page:last-child,
.sheet-section .card-page-last {
  margin-bottom: -1.5rem;
}
.sheet-section::after {
  clear: both;
  content: "";
  display: block;
}

fieldset + .sheet-footer {
  margin-top: 0;
}

.sheet-footer {
  display: flex;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.sheet-footer::after {
  clear: both;
  content: "";
  display: block;
}

@media (max-width: 767.98px) {
  .sheet-footer-btn-block-sm-down {
    display: block;
  }
  .sheet-footer-btn-block-sm-down .btn {
    display: block;
    margin-bottom: 1rem;
    width: 100%;
  }
  .sheet-footer-btn-block-sm-down .btn-group {
    display: block;
  }
  .sheet-footer-btn-block-sm-down .btn-group-item {
    display: block;
    margin-right: 0;
  }
}

.sheet-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.sheet-title {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
}
.sheet-subtitle {
  border-style: solid;
  border-width: 0 0 1px 0;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem;
  position: relative;
  text-transform: uppercase;
  word-wrap: break-word;
}
.sheet-subtitle .c-inner {
  margin-bottom: -0.3125rem;
  margin-top: -0.3125rem;
}
.sheet-subtitle.autofit-row {
  padding-bottom: 0;
}
.sheet-subtitle.autofit-row .autofit-col {
  margin-bottom: 0.3125rem;
}
.sheet-subtitle .collapse-icon-closed,
.sheet-subtitle .collapse-icon-open {
  top: calc(0.3125rem + (((1em * 1.2) - 1em) * 0.5));
}
.sheet-subtitle .component-title,
.sheet-subtitle .heading-text {
  margin-bottom: 0;
  margin-top: auto;
}
.sheet-subtitle a,
.sheet-subtitle .btn {
  text-transform: none;
}

a.sheet-subtitle:hover, a.sheet-subtitle.hover {
  text-decoration: none;
}
.sheet-tertiary-title {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
  word-wrap: break-word;
}
.sheet-tertiary-title .component-title {
  margin-bottom: 0;
}

.sheet-text {
  margin-bottom: 1.5rem;
  word-wrap: break-word;
}

.sheet-multiple-form .sheet-header {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px;
  margin: -1.5rem -1.5rem 1.5rem;
  padding: 1rem 1.5rem;
}
.sheet-multiple-form .sheet-header .sheet-title {
  margin-bottom: 0;
}

.sheet-dataset-content .sheet-header {
  border-width: 0px;
  margin: -1.5rem -1.5rem 1.5rem;
  padding: 1rem 1.5rem;
}
.sheet-dataset-content .sheet-header .sheet-title {
  margin-bottom: 0;
}

.card-page-equal-height .sheet {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.card-page-equal-height .sheet > .autofit-row {
  flex-grow: 1;
}

.card-page-item .sheet {
  margin-bottom: 30px;
}

.container-fluid-1280.sidenav-container {
  padding-left: 15px;
  padding-right: 15px;
}

.sidenav-container {
  position: relative;
}
.sidenav-container > .sidenav-menu-slider {
  visibility: hidden;
  width: 0;
}
.sidenav-container > .sidenav-content {
  left: 0;
}

.sidenav-container.open.sidenav-transition > .sidenav-menu-slider {
  overflow: hidden;
}
.sidenav-container.open > .sidenav-menu-slider {
  overflow: visible;
  visibility: visible;
}

.sidenav-content {
  position: relative;
}
@media (min-width: 768px) {
  .sidenav-content {
    position: static;
  }
  .sidenav-content::after {
    clear: both;
    content: "";
    display: block;
  }
}

.sidenav-menu {
  height: 100%;
  position: relative;
}

.sidenav-menu-slider {
  overflow: hidden;
  position: absolute;
  width: 320px;
  z-index: 10;
}

.sidenav-fixed > .sidenav-menu-slider {
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  z-index: calc(1000 - 25);
}

.sidenav-end > .sidenav-content,
.sidenav-right > .sidenav-content {
  left: auto;
  right: 0;
}
.sidenav-end > .sidenav-menu-slider,
.sidenav-right > .sidenav-menu-slider {
  left: auto;
  right: 0;
}
.sidenav-end > .sidenav-menu-slider .sidenav-menu,
.sidenav-right > .sidenav-menu-slider .sidenav-menu {
  right: 0;
}

.sidenav-js-fouc > .sidenav-menu-slider {
  opacity: 0;
  visibility: hidden;
}

.sidenav-transition .sidenav-content,
.sidenav-transition .sidenav-menu,
.sidenav-transition .sidenav-menu-slider {
  transition: all 0.5s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidenav-transition .sidenav-content,
  .sidenav-transition .sidenav-menu,
  .sidenav-transition .sidenav-menu-slider {
    transition: none;
  }
}
.c-prefers-reduced-motion .sidenav-transition .sidenav-content,
.c-prefers-reduced-motion .sidenav-transition .sidenav-menu,
.c-prefers-reduced-motion .sidenav-transition .sidenav-menu-slider {
  transition: none;
}

.sidenav-transition {
  transition: all 0.5s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidenav-transition {
    transition: none;
  }
}
.c-prefers-reduced-motion .sidenav-transition {
  transition: none;
}

.sidenav-fixed.sidenav-menu-slider {
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 0;
}
.sidenav-fixed.sidenav-menu-slider.open {
  visibility: visible;
  width: 320px;
}
.sidenav-fixed.sidenav-menu-slider .sidenav-menu {
  position: absolute;
}

.sidenav-menu-slider .sidenav-menu {
  width: 320px;
}
.sidenav-menu-slider.sidenav-end, .sidenav-menu-slider.sidenav-right {
  left: auto;
  right: 0;
}
.sidenav-menu-slider.sidenav-end .sidenav-menu, .sidenav-menu-slider.sidenav-right .sidenav-menu {
  right: 0;
}

.sidebar {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.sidebar:focus, .sidebar.focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.sidebar .container-fluid {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sidebar-header {
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}
.sidebar-header .component-title {
  font-size: 1.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.sidebar-header .component-title[href],
.sidebar-header .component-title a[href] {
  color: #272833;
}
.sidebar-header .component-subtitle {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.sidebar-footer {
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.sidebar-body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}
.sidebar-body > .sidebar-section {
  margin-bottom: 2rem;
}
.sidebar-body > .sidebar-section:last-child {
  margin-bottom: 0;
}

.sidebar-section {
  position: relative;
  word-wrap: break-word;
}

.sidebar-list-group {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin-bottom: 0;
  padding-left: 0;
}
.sidebar-list-group .autofit-col {
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
}
.sidebar-list-group .list-group-item {
  background-color: transparent;
  border-color: #dee2e6;
  padding: 0;
}
.sidebar-list-group .sticker {
  font-size: 0.75rem;
  height: 1.5rem;
  line-height: 1.5rem;
  width: 1.5rem;
}
.sidebar-list-group .sticker.sticker-outside {
  left: -0.75rem;
  top: -0.75rem;
}
.sidebar-list-group .sticker.sticker-outside.sticker-bottom-left {
  bottom: -0.75rem;
  top: auto;
}
.sidebar-list-group .sticker.sticker-outside.sticker-bottom-right {
  bottom: -0.75rem;
  left: auto;
  right: -0.75rem;
  top: auto;
}
.sidebar-list-group .sticker.sticker-outside.sticker-top-right {
  left: auto;
  right: -0.75rem;
}

.sidebar-panel {
  margin-bottom: 1rem;
  position: relative;
}

.sidebar-dt {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}
.sidebar-dd {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}
.sidebar-sm {
  font-size: 0.875rem;
}
.sidebar-light {
  background-color: #f7f8f9;
  border-color: #dee2e6;
  border-left-width: 1px;
  color: #272833;
}
.sidebar-light .sidebar-list-group .list-group-title {
  font-size: 1rem;
}
.sidebar-light .sidebar-list-group .list-group-title[href],
.sidebar-light .sidebar-list-group .list-group-title a[href] {
  color: #272833;
}
.sidebar-light .sidebar-panel {
  background-color: #f1f2f5;
}
.sidebar-light .sidebar-dd[href],
.sidebar-light .sidebar-dd a[href] {
  color: #272833;
}
.sidebar-light .component-navigation-bar {
  background-color: #f7f8f9;
  border-color: #dee2e6;
}
.sidebar-light .component-navigation-bar .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.sidebar-light .component-navigation-bar .navbar-nav .nav-link:hover, .sidebar-light .component-navigation-bar .navbar-nav .nav-link.hover {
  color: rgba(0, 0, 0, 0.7);
}
.sidebar-light .component-navigation-bar .navbar-nav .nav-link:active {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-light .component-navigation-bar .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-light .component-navigation-bar .navbar-nav .nav-link:disabled, .sidebar-light .component-navigation-bar .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.sidebar-light .component-navigation-bar .navbar-nav .nav-link[aria-expanded=true], .sidebar-light .component-navigation-bar .navbar-nav .nav-link.show {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-light .component-navigation-bar .navbar-brand:active {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-light .component-navigation-bar .navbar-brand.active {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-light .component-navigation-bar .navbar-brand:disabled, .sidebar-light .component-navigation-bar .navbar-brand.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.sidebar-light .component-navigation-bar .navbar-brand[aria-expanded=true], .sidebar-light .component-navigation-bar .navbar-brand.show {
  color: rgba(0, 0, 0, 0.9);
}
.sidebar-light .component-navigation-bar .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
}
.sidebar-light .component-navigation-bar .navbar-overlay {
  background-color: #f7f8f9;
}
.sidebar-dark {
  background-color: #343a40;
  color: #fff;
}
.sidebar-dark .close {
  color: #a8a9b6;
}
.sidebar-dark .close:hover {
  color: #fff;
}
.sidebar-dark .sidebar-header .component-title {
  color: inherit;
}
.sidebar-dark .sidebar-header .component-title[href],
.sidebar-dark .sidebar-header .component-title a[href] {
  color: inherit;
}
.sidebar-dark .sidebar-header .component-subtitle {
  color: inherit;
}
.sidebar-dark .sidebar-header .component-subtitle[href],
.sidebar-dark .sidebar-header .component-subtitle a[href] {
  color: inherit;
}
.sidebar-dark .nav-nested .nav-link {
  border-radius: 0.25rem;
  color: #a8a9b6;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-dark .nav-nested .nav-link {
    transition: none;
  }
}
.c-prefers-reduced-motion .sidebar-dark .nav-nested .nav-link {
  transition: none;
}

.sidebar-dark .nav-nested .nav-link:hover, .sidebar-dark .nav-nested .nav-link.hover {
  color: #fff;
}
.sidebar-dark .nav-nested .nav-link.focus, .sidebar-dark .nav-nested .nav-link:focus-visible, .c-prefers-focus .sidebar-dark .nav-nested .nav-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.sidebar-dark .nav-nested .nav-link:active {
  color: #fff;
}
.sidebar-dark .nav-nested .nav-link.active {
  color: #fff;
}
.sidebar-dark .nav-nested .nav-link:disabled, .sidebar-dark .nav-nested .nav-link.disabled {
  box-shadow: none;
  color: #a8a9b6;
  opacity: 0.65;
}
.sidebar-dark .nav-nested .nav-link:disabled:active, .sidebar-dark .nav-nested .nav-link.disabled:active {
  pointer-events: none;
}
.sidebar-dark .nav-nested .nav-link[aria-expanded=true], .sidebar-dark .nav-nested .nav-link.show {
  color: #fff;
}
.sidebar-dark-l2 {
  background-color: #828e9a;
  color: #fff;
}
.sidebar-dark-l2 .close {
  color: #a8a9b6;
}
.sidebar-dark-l2 .close:hover {
  color: #fff;
}
.sidebar-dark-l2 .sidebar-header .component-title {
  color: inherit;
}
.sidebar-dark-l2 .sidebar-header .component-title[href],
.sidebar-dark-l2 .sidebar-header .component-title a[href] {
  color: inherit;
}
.sidebar-dark-l2 .sidebar-header .component-subtitle {
  color: inherit;
}
.sidebar-dark-l2 .sidebar-header .component-subtitle[href],
.sidebar-dark-l2 .sidebar-header .component-subtitle a[href] {
  color: inherit;
}
.sidebar-dark-l2 .nav-nested .nav-link {
  border-radius: 0.25rem;
  color: #a8a9b6;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-dark-l2 .nav-nested .nav-link {
    transition: none;
  }
}
.c-prefers-reduced-motion .sidebar-dark-l2 .nav-nested .nav-link {
  transition: none;
}

.sidebar-dark-l2 .nav-nested .nav-link:hover, .sidebar-dark-l2 .nav-nested .nav-link.hover {
  color: #fff;
}
.sidebar-dark-l2 .nav-nested .nav-link.focus, .sidebar-dark-l2 .nav-nested .nav-link:focus-visible, .c-prefers-focus .sidebar-dark-l2 .nav-nested .nav-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.sidebar-dark-l2 .nav-nested .nav-link:active {
  color: #fff;
}
.sidebar-dark-l2 .nav-nested .nav-link.active {
  color: #fff;
}
.sidebar-dark-l2 .nav-nested .nav-link:disabled, .sidebar-dark-l2 .nav-nested .nav-link.disabled {
  box-shadow: none;
  color: #a8a9b6;
  opacity: 0.65;
}
.sidebar-dark-l2 .nav-nested .nav-link:disabled:active, .sidebar-dark-l2 .nav-nested .nav-link.disabled:active {
  pointer-events: none;
}
.sidebar-dark-l2 .nav-nested .nav-link[aria-expanded=true], .sidebar-dark-l2 .nav-nested .nav-link.show {
  color: #fff;
}
.c-slideout-transition-in {
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .c-slideout-transition-in {
    transition: none;
  }
}
.c-prefers-reduced-motion .c-slideout-transition-in {
  transition: none;
}

.c-slideout-transition-out {
  transition: all 0.2s ease-in;
}
@media (prefers-reduced-motion: reduce) {
  .c-slideout-transition-out {
    transition: none;
  }
}
.c-prefers-reduced-motion .c-slideout-transition-out {
  transition: none;
}

.c-slideout-fixed {
  position: fixed;
}

.c-slideout-absolute {
  position: absolute;
}

.c-slideout {
  display: flex;
  overflow: hidden;
  z-index: calc(1000 - 25);
}
.c-slideout.c-slideout-shown {
  overflow: visible;
}
.c-slideout .sidebar {
  display: none;
  flex-shrink: 0;
  overflow: visible;
  position: relative;
  width: 320px;
}
.c-slideout .sidebar.c-slideout-show {
  display: block;
}
.c-slideout .sidebar.c-slideout-transition {
  display: block;
}
.c-slideout .sidebar.c-slideout-transition .c-horizontal-resizer {
  display: none;
}
.c-slideout .tbar-stacked {
  display: none;
  flex-shrink: 0;
  min-width: 40px;
  overflow: auto;
  position: relative;
  z-index: 1;
}
.c-slideout .tbar-stacked.c-slideout-show {
  display: flex;
}
.c-slideout .tbar-stacked.c-slideout-transition {
  display: flex;
}
@media (max-width: 767.98px) {
  .c-slideout .sidebar {
    width: 280px;
  }
}

.c-slideout-start {
  bottom: 0;
  left: 0;
  top: 0;
}
.c-slideout-start.c-slideout-tbar-shown .sidebar {
  left: -320px;
}
.c-slideout-start .sidebar {
  left: -360px;
  width: 320px;
}
.c-slideout-start .sidebar.c-slideout-show {
  left: 0;
}
.c-slideout-start .tbar-stacked {
  left: -40px;
}
.c-slideout-start .tbar-stacked.c-slideout-show {
  left: 0;
}
@media (max-width: 767.98px) {
  .c-slideout-start.c-slideout-tbar-shown .sidebar {
    left: -280px;
  }
  .c-slideout-start .sidebar {
    left: -320px;
    width: 280px;
  }
  .c-slideout-start .tbar-stacked {
    left: -40px;
  }
}

.c-slideout-end {
  bottom: 0;
  right: 0;
  top: 0;
}
.c-slideout-end.c-slideout-tbar-shown .sidebar {
  right: -320px;
}
.c-slideout-end .sidebar {
  right: -360px;
}
.c-slideout-end .sidebar.c-slideout-show {
  right: 0;
}
.c-slideout-end .tbar-stacked {
  right: -40px;
}
.c-slideout-end .tbar-stacked.c-slideout-show {
  right: 0;
}
.c-slideout-end .c-horizontal-resizer {
  left: 0;
  right: auto;
}
@media (max-width: 767.98px) {
  .c-slideout-end.c-slideout-tbar-shown .sidebar {
    right: -280px;
  }
  .c-slideout-end .sidebar {
    right: -320px;
  }
  .c-slideout-end .tbar-stacked {
    right: -40px;
  }
}

.c-slideout-height-full {
  z-index: calc(1030 + 5);
}
table {
  border-collapse: collapse;
}

th {
  height: 20px;
  text-align: left;
}

caption {
  color: #6c757d;
  padding-bottom: 0.5rem 1rem;
  padding-top: 0.5rem 1rem;
  text-align: left;
}

.table-head-title .inline-item-before {
  margin-right: 0.25rem;
}
.table-head-title .inline-item-before + .text-truncate-inline {
  max-width: calc(
	100% - 1em - 0.25rem
);
}
.table-head-title .inline-item-after {
  margin-left: 0.25rem;
}

.table {
  border-spacing: 0;
  color: #272833;
  margin-bottom: 0;
  width: 100%;
}
.table thead {
  background-color: #fff;
}
.table thead th,
.table thead td {
  border-bottom: calc(2 * 0.0625rem) solid #dee2e6;
  border-top-width: 0px;
  vertical-align: middle;
}
.table thead .autofit-col {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.table thead .autofit-col:first-child {
  padding-left: 0;
}
.table thead .autofit-col:last-child {
  padding-right: 0;
}
.table th:first-child,
.table td:first-child,
.table .table-column-start {
  padding-left: 1.25rem;
}
.table th {
  background-clip: padding-box;
  border-top: 0.0625rem solid #dee2e6;
  color: #495057;
  height: 56px;
  padding: 0.5rem 1rem;
  position: relative;
  vertical-align: top;
}
.table td {
  background-clip: padding-box;
  border-bottom-width: 0.0625rem;
  border-color: #dee2e6;
  border-left-width: 0px;
  border-right-width: 0px;
  border-style: solid;
  border-top-width: 0.0625rem;
  height: 56px;
  padding: 0.5rem 1rem;
  position: relative;
  vertical-align: middle;
}
.table tbody + tbody {
  border-top: calc(2 * 0.0625rem) solid #dee2e6;
}
.table caption {
  caption-side: top;
  padding-left: 1rem;
  padding-right: 1rem;
}
.table .table-divider th,
.table .table-divider td {
  background-color: #fff;
  height: 34px;
  line-height: 17px;
  padding: 0.5rem 1rem 0.5rem 1.25rem;
}
.table .table-active {
  background-color: #ececec;
}
.table .table-active .quick-action-menu {
  background-color: #ececec;
}
.table .table-disabled {
  background-color: #fff;
  color: #acacac;
}
.table .table-disabled th,
.table .table-disabled td {
  cursor: not-allowed;
}
.table .table-disabled th a[href],
.table .table-disabled td a[href] {
  color: #acacac;
  pointer-events: none;
}
.table .table-disabled .table-title {
  color: #acacac;
}
.table .table-disabled .table-list-title {
  color: #acacac;
}
.table .autofit-col {
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.table .autofit-col:first-child {
  padding-left: 0;
}
.table .autofit-col:last-child {
  padding-right: 0;
}
.table .component-drag {
  font-size: 0.875rem;
  height: 1rem;
  width: 1rem;
}
.table .component-drag.focus, .table .component-drag:focus-visible, .c-prefers-focus .table .component-drag:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.table .custom-control,
.table .form-check {
  margin-bottom: 0;
}
.table .quick-action-menu {
  align-items: flex-start;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.table-caption-bottom caption {
  caption-side: bottom;
}

.table-lg th,
.table-lg td {
  padding: 1.0625rem;
}
.table-md th,
.table-md td {
  height: 48px;
  padding: 0.375rem 1rem;
}
.table-sm th,
.table-sm td {
  height: 32px;
  padding: 0.25rem 1rem;
}
.table-bordered {
  border: 0.0625rem solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: calc(2 * 0.0625rem);
}
.table-bordered th,
.table-bordered td {
  border: 0.0625rem solid #dee2e6;
}
.table-head-bordered thead th,
.table-head-bordered thead td {
  border-left: 0.0625rem solid #dee2e6;
}
.table-head-bordered thead th:first-child,
.table-head-bordered thead td:first-child {
  border-left-width: 0;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider),
.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider) td,
.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider) th {
  background-color: #f2f2f2;
}
.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider),
.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider) td,
.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider) th {
  background-color: #ececec;
}

.table-hover tbody tr:hover {
  background-color: #ececec;
  color: #272833;
}
.table-hover tbody tr:hover .quick-action-menu {
  background-color: #ececec;
}
.table-hover .table-active:hover .quick-action-menu {
  background-color: #ececec;
}
.table-hover .table-disabled:hover {
  background-color: #fff;
}
tr.table-focus {
  outline: 0;
}
tr.table-focus th,
tr.table-focus td {
  outline: 0;
}
tr.table-focus th::before,
tr.table-focus td::before {
  box-shadow: inset 0 0.2rem 0 0 rgba(11, 95, 255, 0.25), inset 0 -0.2rem 0 0 rgba(11, 95, 255, 0.25);
  content: "";
  display: block;
  position: absolute;
  left: -0.0625rem;
  right: -0.0625rem;
  top: -0.0625rem;
  bottom: -0.0625rem;
  pointer-events: none;
  z-index: 1;
}
tr.table-focus th:first-child::after,
tr.table-focus td:first-child::after {
  box-shadow: inset 0.2rem 0 0 0 rgba(11, 95, 255, 0.25);
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  top: 0.0625rem;
  bottom: 0.0625rem;
  left: -0.0625rem;
  z-index: 1;
  width: 0.25rem;
}
tr.table-focus th:last-child::after,
tr.table-focus td:last-child::after {
  box-shadow: inset -0.2rem 0 0 0 rgba(11, 95, 255, 0.25);
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  top: 0.0625rem;
  bottom: 0.0625rem;
  right: -0.0625rem;
  z-index: 1;
  width: 0.25rem;
}

td.table-focus {
  outline: none;
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}

.table .table-primary,
.table .table-primary > th,
.table .table-primary > td {
  background-color: #bbd2ff;
  border-color: #80acff;
}
.table .table-primary th,
.table .table-primary td,
.table .table-primary thead th,
.table .table-primary tbody + tbody {
  border-color: #80acff;
}

.table-hover .table-primary:hover {
  background-color: #a2c1ff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a2c1ff;
}

.table .table-secondary,
.table .table-secondary > th,
.table .table-secondary > td {
  background-color: #d6d6db;
  border-color: #b2b3bc;
}
.table .table-secondary th,
.table .table-secondary td,
.table .table-secondary thead th,
.table .table-secondary tbody + tbody {
  border-color: #b2b3bc;
}

.table-hover .table-secondary:hover {
  background-color: #c8c8cf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8c8cf;
}

.table .table-success,
.table .table-success > th,
.table .table-success > td {
  background-color: #c3e6cb;
  border-color: #8fd19e;
}
.table .table-success th,
.table .table-success td,
.table .table-success thead th,
.table .table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table .table-info,
.table .table-info > th,
.table .table-info > td {
  background-color: #bee5eb;
  border-color: #86cfda;
}
.table .table-info th,
.table .table-info td,
.table .table-info thead th,
.table .table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table .table-warning,
.table .table-warning > th,
.table .table-warning > td {
  background-color: #ffeeba;
  border-color: #ffdf7e;
}
.table .table-warning th,
.table .table-warning td,
.table .table-warning thead th,
.table .table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table .table-danger,
.table .table-danger > th,
.table .table-danger > td {
  background-color: #f5c6cb;
  border-color: #ed969e;
}
.table .table-danger th,
.table .table-danger td,
.table .table-danger thead th,
.table .table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table .table-light,
.table .table-light > th,
.table .table-light > td {
  background-color: #fdfdfd;
  border-color: #fbfbfc;
}
.table .table-light th,
.table .table-light td,
.table .table-light thead th,
.table .table-light tbody + tbody {
  border-color: #fbfbfc;
}

.table-hover .table-light:hover {
  background-color: #f0f0f0;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f0f0f0;
}

.table .table-dark,
.table .table-dark > th,
.table .table-dark > td {
  background-color: #c6c8ca;
  border-color: #95999c;
}
.table .table-dark .table-title,
.table .table-dark > th .table-title,
.table .table-dark > td .table-title {
  color: #fff;
}
.table .table-dark .table-title[href],
.table .table-dark .table-title a[href],
.table .table-dark > th .table-title[href],
.table .table-dark > th .table-title a[href],
.table .table-dark > td .table-title[href],
.table .table-dark > td .table-title a[href] {
  color: inherit;
}
.table .table-dark th,
.table .table-dark td,
.table .table-dark thead th,
.table .table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table .thead-dark th {
  background-color: #343a40;
  border-color: #454d55;
  color: #fff;
}
.table .thead-light th {
  background-color: #fff;
  border-color: #dee2e6;
  color: #495057;
}

.table-dark {
  background-color: #343a40;
  color: #fff;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border-width: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider),
.table-dark.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider) th,
.table-dark.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider) td {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider),
.table-dark.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider) td,
.table-dark.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider) th {
  background-color: rgba(255, 255, 255, 0.075);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
  color: #fff;
}

.table-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}
.table-title[href],
.table-title a[href] {
  color: #272833;
}
.table-title[href]:hover, .table-title[href].hover,
.table-title a[href]:hover,
.table-title a[href].hover {
  color: #272833;
}
.table-link {
  color: #495057;
}
.table-link:hover, .table-link.hover {
  color: #262a2d;
}
.table-action-link {
  align-items: center;
  border-radius: 0.25rem;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  vertical-align: middle;
  width: 2rem;
}
.table-action-link:hover, .table-action-link.hover {
  text-decoration: none;
}
.table-action-link .lexicon-icon {
  margin-top: 0;
}

.table-responsive-sm {
  margin-bottom: 1.5rem;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%;
  }
}
.table-responsive-md {
  margin-bottom: 1.5rem;
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%;
  }
}
.table-responsive-lg {
  margin-bottom: 1.5rem;
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%;
  }
}
.table-responsive-xl {
  margin-bottom: 1.5rem;
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%;
  }
}
.table-responsive {
  margin-bottom: 1.5rem;
  display: block;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%;
}

.table-list {
  border-collapse: separate;
  border-color: #dee2e6;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0.0625rem 0.0625rem;
  margin-bottom: 0.0625rem;
}
.table-list thead {
  border-top-left-radius: calc(0.25rem - 0.0625rem);
  border-top-right-radius: calc(0.25rem - 0.0625rem);
}
.table-list thead th,
.table-list thead td {
  border-bottom-width: 0;
}
.table-list th,
.table-list td {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0.0625rem 0 0 0;
}
.table-list thead:first-child tr:first-child th,
.table-list thead:first-child tr:first-child td,
.table-list tbody:first-child tr:first-child th,
.table-list tbody:first-child tr:first-child td,
.table-list tfoot:first-child tr:first-child th,
.table-list tfoot:first-child tr:first-child td,
.table-list caption:first-child + thead tr:first-child th,
.table-list caption:first-child + thead tr:first-child td {
  border-top-width: 0;
}
.table-list thead:first-child tr:first-child th:first-child,
.table-list thead:first-child tr:first-child td:first-child,
.table-list tbody:first-child tr:first-child th:first-child,
.table-list tbody:first-child tr:first-child td:first-child,
.table-list tfoot:first-child tr:first-child th:first-child,
.table-list tfoot:first-child tr:first-child td:first-child,
.table-list caption:first-child + thead tr:first-child th:first-child,
.table-list caption:first-child + thead tr:first-child td:first-child {
  border-top-left-radius: calc(0.25rem - 0.0625rem);
}
.table-list thead:first-child tr:first-child th:last-child,
.table-list thead:first-child tr:first-child td:last-child,
.table-list tbody:first-child tr:first-child th:last-child,
.table-list tbody:first-child tr:first-child td:last-child,
.table-list tfoot:first-child tr:first-child th:last-child,
.table-list tfoot:first-child tr:first-child td:last-child,
.table-list caption:first-child + thead tr:first-child th:last-child,
.table-list caption:first-child + thead tr:first-child td:last-child {
  border-top-right-radius: calc(0.25rem - 0.0625rem);
}
.table-list .table-row-start .table-cell-start {
  border-top-left-radius: calc(0.25rem - 0.0625rem);
}
.table-list .table-row-start .table-cell-end {
  border-top-right-radius: calc(0.25rem - 0.0625rem);
}
.table-list thead:last-child tr:last-child th:first-child,
.table-list thead:last-child tr:last-child td:first-child,
.table-list tbody:last-child tr:last-child th:first-child,
.table-list tbody:last-child tr:last-child td:first-child,
.table-list tfoot:last-child tr:last-child th:first-child,
.table-list tfoot:last-child tr:last-child td:first-child {
  border-bottom-left-radius: calc(0.25rem - 0.0625rem);
}
.table-list thead:last-child tr:last-child th:last-child,
.table-list thead:last-child tr:last-child td:last-child,
.table-list tbody:last-child tr:last-child th:last-child,
.table-list tbody:last-child tr:last-child td:last-child,
.table-list tfoot:last-child tr:last-child th:last-child,
.table-list tfoot:last-child tr:last-child td:last-child {
  border-bottom-right-radius: calc(0.25rem - 0.0625rem);
}
.table-list .table-row-end .table-cell-start {
  border-bottom-left-radius: calc(0.25rem - 0.0625rem);
}
.table-list .table-row-end .table-cell-end {
  border-bottom-right-radius: calc(0.25rem - 0.0625rem);
}
.table-list tbody {
  border-bottom-left-radius: calc(0.25rem - 0.0625rem);
  border-bottom-right-radius: calc(0.25rem - 0.0625rem);
}
.table-list tbody th,
.table-list tbody td {
  vertical-align: middle;
}
.table-list tfoot th,
.table-list tfoot td {
  vertical-align: middle;
}
.table-list .table-divider th,
.table-list .table-divider td {
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}
.table-list .table-active {
  background-color: #dadada;
}
.table-list .table-active .quick-action-menu {
  background-color: #dadada;
}
.table-list .table-disabled {
  background-color: #fff;
  color: #acacac;
}
.table-list .table-disabled th a[href],
.table-list .table-disabled td a[href] {
  color: #acacac;
  pointer-events: none;
}
.table-list .table-disabled .table-title {
  color: #acacac;
}
.table-list .table-disabled .table-list-title {
  color: #acacac;
}
.table-list .quick-action-menu {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  top: 0;
}

.table.table-list.table-bordered thead th,
.table.table-list.table-bordered thead td {
  border-bottom-width: 0;
}
.table.table-list.table-bordered th,
.table.table-list.table-bordered td {
  border-left-width: 0.0625rem;
}
.table.table-list.table-bordered th:first-child,
.table.table-list.table-bordered td:first-child,
.table.table-list.table-bordered .table-column-start {
  border-left-width: 0;
}
.table-list.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider),
.table-list.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider) th,
.table-list.table-striped tbody tr:nth-of-type(odd):not(.table-active):not(.table-disabled):not(.table-divider) td {
  background-color: #f2f2f2;
}
.table-list.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider),
.table-list.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider) th,
.table-list.table-striped.table-hover tbody tr:nth-of-type(odd):hover:not(.table-active):not(.table-disabled):not(.table-divider) td {
  background-color: #ececec;
}

.table-list.table-hover tbody tr:hover {
  background-color: #ececec;
}
.table-list.table-hover tbody tr:hover .quick-action-menu {
  background-color: #ececec;
}
.table-list.table-hover .table-active:hover {
  background-color: #dadada;
}
.table-list.table-hover .table-active:hover .quick-action-menu {
  background-color: #ececec;
}
.table-list.table-hover .table-disabled {
  background-color: #fff;
}
.table-list.table-hover .table-disabled:hover {
  background-color: #fff;
}
.table-list-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}
.table-list-title[href],
.table-list-title a[href] {
  color: #272833;
}
.table-list-title[href]:hover, .table-list-title[href].hover,
.table-list-title a[href]:hover,
.table-list-title a[href].hover {
  color: #272833;
}
.table-list-link {
  color: #495057;
}
.table-list-link:hover, .table-list-link.hover {
  color: #262a2d;
}
.table-list-action-link {
  align-items: center;
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  vertical-align: middle;
  width: 2rem;
}
.table-list-action-link:hover, .table-list-action-link.hover {
  text-decoration: none;
}
.table-list-action-link .lexicon-icon {
  margin-top: 0;
}

.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}

.table-heading-nowrap thead td,
.table-heading-nowrap thead th {
  white-space: nowrap;
}

.table-valign-bottom tbody td,
.table-valign-bottom tbody th,
.table-valign-bottom tfoot td,
.table-valign-bottom tfoot th,
.table-valign-bottom thead td,
.table-valign-bottom thead th {
  vertical-align: bottom;
}
.table-valign-bottom tbody td,
.table-valign-bottom tbody th {
  padding-bottom: 1rem;
}
.table-valign-bottom.show-quick-actions-on-hover .quick-action-menu {
  align-items: flex-end;
}

.table-valign-middle tbody td,
.table-valign-middle tbody th,
.table-valign-middle tfoot td,
.table-valign-middle tfoot th,
.table-valign-middle thead td,
.table-valign-middle thead th {
  vertical-align: middle;
}

.table-valign-top tbody td,
.table-valign-top tbody th,
.table-valign-top tfoot td,
.table-valign-top tfoot th,
.table-valign-top thead td,
.table-valign-top thead th {
  vertical-align: top;
}
.table-valign-top tbody td,
.table-valign-top tbody th {
  padding-top: 1rem;
}
.table-valign-top.show-quick-actions-on-hover .quick-action-menu {
  align-items: flex-start;
}

.tbody-valign-bottom tbody td,
.tbody-valign-bottom tbody th {
  padding-bottom: 1rem;
  vertical-align: bottom;
}
.tbody-valign-bottom.show-quick-actions-on-hover .quick-action-menu {
  align-items: flex-end;
}

.tbody-valign-middle tbody td {
  vertical-align: middle;
}

.tbody-valign-top tbody td,
.tbody-valign-top tbody th {
  padding-top: 1rem;
  vertical-align: top;
}
.tbody-valign-top.show-quick-actions-on-hover .quick-action-menu {
  align-items: flex-start;
}

.thead-valign-bottom thead td,
.thead-valign-bottom thead th {
  vertical-align: bottom;
}

.thead-valign-middle thead td,
.thead-valign-middle thead th {
  vertical-align: middle;
}

.thead-valign-top thead td,
.thead-valign-top thead th {
  vertical-align: top;
}

.table-nested-rows .autofit-col {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
  min-width: 1.75rem;
}
.table-nested-rows .autofit-col-checkbox {
  padding-right: 0.625rem;
}
.table-nested-rows .autofit-col-icon {
  padding-right: 0.625rem;
}
.table-nested-rows .component-drag {
  left: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.table-nested-rows .component-toggle {
  font-size: 0.875rem;
  height: 1.5rem;
  width: 1.5rem;
}
.table-nested-rows .component-action.show .collapse-icon-closed {
  display: none;
}
.table-nested-rows .component-action:not(.show) .collapse-icon-open {
  display: none;
}

.table-sort thead th,
.table-sort thead td {
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .table-sort thead th,
  .table-sort thead td {
    transition: none;
  }
}
.c-prefers-reduced-motion .table-sort thead th,
.c-prefers-reduced-motion .table-sort thead td {
  transition: none;
}

.table-sort thead th:hover,
.table-sort thead td:hover {
  background-color: #f0f5ff;
  color: #272833;
}
.table-sort thead th .component-action,
.table-sort thead td .component-action {
  font-size: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
}
.show-quick-actions-on-hover tr:not(.table-active):not(.table-disabled):hover .quick-action-menu {
  display: flex;
}
.show-quick-actions-on-hover .table-focus:not(.table-active):not(.table-disabled) .quick-action-menu {
  display: flex;
}

.table-striped tbody tr:nth-of-type(odd) .quick-action-menu {
  background-color: #f2f2f2;
}
.table-striped tbody .table-active:nth-of-type(odd) .quick-action-menu {
  background-color: #f2f2f2;
}
.table-striped.table-hover tbody tr:nth-of-type(odd):hover .quick-action-menu {
  background-color: #ececec;
}

.table-list.table-striped tbody tr:nth-of-type(odd) .quick-action-menu {
  background-color: #f2f2f2;
}
.table-list.table-striped tbody .table-active:nth-of-type(odd) .quick-action-menu {
  background-color: #dadada;
}
.table-list.table-striped.table-hover tbody tr:nth-of-type(odd):hover .quick-action-menu {
  background-color: #ececec;
}

.table-column-text-start,
.table-cell-text-start {
  text-align: left;
}

.table-column-text-center,
.table-cell-text-center {
  text-align: center;
}

.table-column-text-end,
.table-cell-text-end {
  text-align: right;
}

.table-column,
.table-cell-contract, .table-autofit td,
.table-autofit th {
  display: table-cell;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 1%;
}

.table-cell-expand,
.table-column-expand, .table-autofit .table-cell-expand {
  display: table-cell;
  max-width: 12.5rem;
  min-width: 12.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: auto;
}

.table-cell-expand-small,
.table-column-expand-small, .table-autofit .table-cell-expand-small {
  max-width: 12.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 25%;
}

.table-cell-expand-smaller,
.table-column-expand-smaller, .table-autofit .table-cell-expand-smaller {
  max-width: 12.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 15%;
}

.table-cell-expand-smallest,
.table-column-expand-smallest, .table-autofit .table-cell-expand-smallest {
  max-width: 12.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 10%;
}

.table-cell-minw-50,
.table-column-minw-50 {
  min-width: 50px;
}

.table-cell-minw-75,
.table-column-minw-75 {
  min-width: 75px;
}

.table-cell-minw-100,
.table-column-minw-100 {
  min-width: 100px;
}

.table-cell-minw-150,
.table-column-minw-150 {
  min-width: 150px;
}

.table-cell-minw-200,
.table-column-minw-200 {
  min-width: 200px;
}

.table-cell-minw-250,
.table-column-minw-250 {
  min-width: 250px;
}

.table-cell-minw-300,
.table-column-minw-300 {
  min-width: 300px;
}

.table-cell-minw-350,
.table-column-minw-350 {
  min-width: 350px;
}

.table-cell-minw-400,
.table-column-minw-400 {
  min-width: 400px;
}

.table-cell-ws-normal,
.table-column-ws-normal {
  white-space: normal;
}

.table-cell-ws-nowrap,
.table-column-ws-nowrap {
  white-space: nowrap;
}

.table-img {
  height: auto;
  max-height: 100px;
  max-width: none;
  width: auto;
}

.tbar {
  display: flex;
}
.tbar > .container,
.tbar > .container-fluid {
  display: flex;
}

.tbar-nav {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: nowrap;
  list-style: none;
  margin-bottom: 0;
  min-width: 3.125rem;
  padding-left: 0;
  word-wrap: break-word;
}
.tbar-nav > .tbar-item {
  justify-content: center;
}

.tbar-nav-shrink {
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
}

.tbar-nav-wrap {
  flex-wrap: wrap;
}

.tbar-item {
  max-width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.tbar-item:first-child {
  padding-left: 0;
}
.tbar-item:last-child {
  padding-right: 0;
}

.tbar-item-expand {
  text-align: center;
}

.tbar-link {
  display: inline-block;
}

.tbar-btn-monospaced,
.tbar-link-monospaced {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  text-align: center;
}
.tbar-btn-monospaced .inline-item .lexicon-icon,
.tbar-btn-monospaced .lexicon-icon,
.tbar-link-monospaced .inline-item .lexicon-icon,
.tbar-link-monospaced .lexicon-icon {
  margin-top: 0;
}

@media (max-width: 575.98px) {
  .tbar-inline-xs-down {
    display: block;
  }
  .tbar-inline-xs-down .container,
  .tbar-inline-xs-down .container-fluid {
    display: block;
  }
  .tbar-inline-xs-down .component-title,
  .tbar-inline-xs-down .tbar-nav,
  .tbar-inline-xs-down .tbar-section {
    display: inline;
  }
  .tbar-inline-xs-down .tbar-item {
    padding-left: 0;
    display: inline;
  }
}

@media (max-width: 767.98px) {
  .tbar-inline-sm-down {
    display: block;
  }
  .tbar-inline-sm-down .container,
  .tbar-inline-sm-down .container-fluid {
    display: block;
  }
  .tbar-inline-sm-down .component-title,
  .tbar-inline-sm-down .tbar-nav,
  .tbar-inline-sm-down .tbar-section {
    display: inline;
  }
  .tbar-inline-sm-down .tbar-item {
    padding-left: 0;
    display: inline;
  }
}

@media (max-width: 991.98px) {
  .tbar-inline-md-down {
    display: block;
  }
  .tbar-inline-md-down .container,
  .tbar-inline-md-down .container-fluid {
    display: block;
  }
  .tbar-inline-md-down .component-title,
  .tbar-inline-md-down .tbar-nav,
  .tbar-inline-md-down .tbar-section {
    display: inline;
  }
  .tbar-inline-md-down .tbar-item {
    padding-left: 0;
    display: inline;
  }
}

@media (max-width: 1199.98px) {
  .tbar-inline-lg-down {
    display: block;
  }
  .tbar-inline-lg-down .container,
  .tbar-inline-lg-down .container-fluid {
    display: block;
  }
  .tbar-inline-lg-down .component-title,
  .tbar-inline-lg-down .tbar-nav,
  .tbar-inline-lg-down .tbar-section {
    display: inline;
  }
  .tbar-inline-lg-down .tbar-item {
    padding-left: 0;
    display: inline;
  }
}

.tbar-inline-xl-down {
  display: block;
}
.tbar-inline-xl-down .container,
.tbar-inline-xl-down .container-fluid {
  display: block;
}
.tbar-inline-xl-down .component-title,
.tbar-inline-xl-down .tbar-nav,
.tbar-inline-xl-down .tbar-section {
  display: inline;
}
.tbar-inline-xl-down .tbar-item {
  padding-left: 0;
  display: inline;
}

.component-tbar {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 0.0625rem 0;
  height: 3.5rem;
}
.component-tbar .tbar-label {
  border-width: 0.0625rem;
}
.subnav-tbar {
  font-size: 0.875rem;
}
.subnav-tbar .btn-unstyled {
  color: #0b5fff;
  text-decoration: none;
}
.subnav-tbar .btn-unstyled:hover {
  color: #0041be;
  text-decoration: underline;
}
.subnav-tbar strong {
  font-weight: 500;
}
.subnav-tbar .component-link {
  color: #0b5fff;
  font-weight: 500;
}
.subnav-tbar .component-link:hover, .subnav-tbar .component-link.hover {
  color: #0041be;
}
.subnav-tbar .component-link:disabled, .subnav-tbar .component-link.disabled {
  box-shadow: none;
}
.subnav-tbar .tbar-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.subnav-tbar .tbar-btn {
  height: 1.5rem;
  line-height: 1;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  padding-left: 0;
  padding-right: 0;
}
.subnav-tbar .tbar-btn .c-inner {
  margin-left: 0;
  margin-right: 0;
}
.subnav-tbar .tbar-btn .c-inner {
  margin-left: 0;
  margin-right: 0;
}
.subnav-tbar .tbar-link {
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  padding-bottom: 0.09375rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.09375rem;
}
.subnav-tbar .tbar-link > .c-inner {
  margin-bottom: -0.09375rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: -0.09375rem;
}
.subnav-tbar .tbar-link .c-inner {
  margin-bottom: -0.09375rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  margin-top: -0.09375rem;
}
.subnav-tbar .tbar-btn-monospaced {
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  padding: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}
.subnav-tbar .tbar-btn-monospaced .c-inner {
  margin: -0.25rem;
}
.subnav-tbar .tbar-link-monospaced {
  height: 1.5rem;
  margin-bottom: 0.125rem;
  margin-top: 0.125rem;
  width: 1.5rem;
}
.subnav-tbar .tbar-section {
  text-align: left;
}
.subnav-tbar .component-title {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.45;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  max-width: 100%;
}
.subnav-tbar .component-text {
  display: inline-block;
  line-height: 1.45;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  max-width: 100%;
}
.subnav-tbar .component-label {
  font-weight: 400;
}
.subnav-tbar .tbar-label {
  border-width: 0.0625rem;
}
.subnav-tbar-primary {
  background-color: #b3cdff;
  padding-bottom: 0.625rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.625rem;
}
.subnav-tbar-primary .component-link {
  color: #272833;
}
.subnav-tbar-primary .component-link:hover, .subnav-tbar-primary .component-link.hover {
  color: #272833;
}
.subnav-tbar-primary .component-link:disabled, .subnav-tbar-primary .component-link.disabled {
  color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
  text-decoration: none;
}
.subnav-tbar-primary .tbar-item {
  justify-content: flex-start;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.subnav-tbar-primary .tbar-link-monospaced {
  border-radius: 0px;
  border-width: 0px;
  height: 3rem;
  margin-bottom: -0.625rem;
  margin-top: -0.625rem;
  width: 3rem;
}
.subnav-tbar-primary .component-label .close.focus, .subnav-tbar-primary .component-label .close:focus-visible, .c-prefers-focus .subnav-tbar-primary .component-label .close:focus {
  color: inherit;
}

.subnav-tbar-primary .component-label .close:disabled, .subnav-tbar-primary .component-label .close.disabled {
  color: #6c757d;
  opacity: 0.65;
}
.subnav-tbar-primary .tbar-label {
  font-size: 0.75rem;
  margin-right: 0;
  padding-bottom: 0.3125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.3125rem;
  text-transform: none;
  border-width: 0.0625rem;
}
.subnav-tbar-primary .tbar-label > .c-inner {
  margin-bottom: -0.3125rem;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  margin-top: -0.3125rem;
}
.subnav-tbar-primary.subnav-tbar-disabled {
  background-color: #cfddf8;
  color: #6c757d;
}
.subnav-tbar-primary.subnav-tbar-disabled .component-label {
  border-color: #6c757d;
}
.subnav-tbar-primary.subnav-tbar-disabled .tbar-label {
  border-width: 0.0625rem;
}
.subnav-tbar-light {
  background-color: #f7f8f9;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 0.125rem;
  padding-top: 0.125rem;
}
.subnav-tbar-light .tbar-label {
  border-width: 0.0625rem;
}
.tbar-stacked {
  display: inline-flex;
  height: 100%;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.tbar-stacked .tbar-nav {
  flex-direction: column;
  min-width: 0;
}
.tbar-stacked .tbar-item {
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
}
.tbar-stacked .tbar-divider-before::before {
  background-color: #272833;
  content: "";
  display: block;
  height: 1px;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  width: 2.5rem;
}
.tbar-stacked .tbar-divider-after::after {
  background-color: #272833;
  content: "";
  display: block;
  height: 1px;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  width: 2.5rem;
}
.tbar-stacked .tbar-item-expand {
  flex-shrink: 0;
  min-width: 0;
}
.tbar-stacked .tbar-btn-monospaced {
  border-color: transparent;
  border-radius: 0px;
  border-width: 0px;
  color: inherit;
  height: 2.5rem;
  margin-bottom: 0;
  margin-top: 0;
  overflow: visible;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  position: relative;
  width: 2.5rem;
}
.tbar-stacked .tbar-btn-monospaced.focus, .tbar-stacked .tbar-btn-monospaced:focus-visible, .c-prefers-focus .tbar-stacked .tbar-btn-monospaced:focus {
  box-shadow: inset 0 0 0 0.125rem #528eff, inset 0 0 0 0.25rem #fff;
}
.tbar-stacked .tbar-btn-monospaced:active:focus-visible, .c-prefers-focus .tbar-stacked .tbar-btn-monospaced:active:focus {
  box-shadow: inset 0 0 0 0.125rem #528eff, inset 0 0 0 0.25rem #fff;
}
.tbar-stacked .tbar-btn-monospaced .c-inner {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
.tbar-stacked .tbar-label {
  border-width: 0.0625rem;
}
.tbar-light {
  background-color: #fff;
  box-shadow: inset 1px 0 0 0 #f1f2f5, inset -1px 0 0 0 #f1f2f5;
  color: #6b6c7e;
}
.tbar-light .tbar-divider-before::before {
  background-color: #f1f2f5;
}
.tbar-light .tbar-divider-after::after {
  background-color: #f1f2f5;
}
.tbar-light .tbar-btn-monospaced:hover {
  color: #343a40;
}
.tbar-light .tbar-btn-monospaced.focus, .tbar-light .tbar-btn-monospaced:focus-visible, .c-prefers-focus .tbar-light .tbar-btn-monospaced:focus {
  color: #343a40;
}
.tbar-light .tbar-btn-monospaced:active {
  background-color: #f1f2f5;
  color: #343a40;
}
.tbar-light .tbar-btn-monospaced.active {
  background-color: #f1f2f5;
  color: #343a40;
}
.tbar-light .tbar-btn-monospaced:disabled, .tbar-light .tbar-btn-monospaced.disabled {
  color: inherit;
}
.tbar-light .tbar-btn-monospaced[aria-expanded=true], .tbar-light .tbar-btn-monospaced.show {
  background-color: #f1f2f5;
  color: #343a40;
}
.tbar-light .tbar-label {
  border-width: 0.0625rem;
}
.tbar-dark-l2 {
  background-color: #828e9a;
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.06), inset -1px 0 0 0 rgba(255, 255, 255, 0.06);
  border-color: rgba(255, 255, 255, 0.06);
  color: #adb5bd;
}
.tbar-dark-l2 .tbar-divider-before::before {
  background-color: rgba(255, 255, 255, 0.06);
}
.tbar-dark-l2 .tbar-divider-after::after {
  background-color: rgba(255, 255, 255, 0.06);
}
.tbar-dark-l2 .tbar-btn-monospaced:hover {
  color: #fff;
}
.tbar-dark-l2 .tbar-btn-monospaced.focus, .tbar-dark-l2 .tbar-btn-monospaced:focus-visible, .c-prefers-focus .tbar-dark-l2 .tbar-btn-monospaced:focus {
  color: #fff;
}
.tbar-dark-l2 .tbar-btn-monospaced:active {
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
.tbar-dark-l2 .tbar-btn-monospaced.active {
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
.tbar-dark-l2 .tbar-btn-monospaced:disabled, .tbar-dark-l2 .tbar-btn-monospaced.disabled {
  color: inherit;
}
.tbar-dark-l2 .tbar-btn-monospaced[aria-expanded=true], .tbar-dark-l2 .tbar-btn-monospaced.show {
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
.tbar-dark-l2 .tbar-label {
  border-width: 0.0625rem;
}
.tbar-dark-d1 {
  background-color: #23272b;
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.06), inset -1px 0 0 0 rgba(255, 255, 255, 0.06);
  color: #adb5bd;
}
.tbar-dark-d1 .tbar-divider-before::before {
  background-color: rgba(255, 255, 255, 0.06);
}
.tbar-dark-d1 .tbar-divider-after::after {
  background-color: rgba(255, 255, 255, 0.06);
}
.tbar-dark-d1 .tbar-btn-monospaced:hover {
  color: #fff;
}
.tbar-dark-d1 .tbar-btn-monospaced.focus, .tbar-dark-d1 .tbar-btn-monospaced:focus-visible, .c-prefers-focus .tbar-dark-d1 .tbar-btn-monospaced:focus {
  color: #fff;
}
.tbar-dark-d1 .tbar-btn-monospaced:active {
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
.tbar-dark-d1 .tbar-btn-monospaced.active {
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
.tbar-dark-d1 .tbar-btn-monospaced:disabled, .tbar-dark-d1 .tbar-btn-monospaced.disabled {
  color: inherit;
}
.tbar-dark-d1 .tbar-btn-monospaced[aria-expanded=true], .tbar-dark-d1 .tbar-btn-monospaced.show {
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
.tbar-dark-d1 .tbar-label {
  border-width: 0.0625rem;
}
.timeline {
  list-style: none;
  padding-left: 0;
  padding-left: 25px;
}
.timeline .panel,
.timeline .panel-group {
  margin-bottom: 0;
}

.timeline-icon {
  background-color: #fff;
  border: 2px solid #dee2e6;
  border-radius: 50%;
  display: block;
  height: 10px;
  line-height: 10px;
  width: 10px;
}

.timeline-increment {
  background-color: #fff;
  left: -39px;
  position: absolute;
  text-align: center;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.timeline-increment-text {
  display: block;
  max-width: 65px;
}

.timeline-item-label {
  color: #272833;
}

.timeline-item {
  padding-bottom: 5px;
  padding-left: 40px;
  padding-top: 5px;
  position: relative;
}
.timeline-item:before {
  background-color: #dee2e6;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}
.timeline-item.active .timeline-icon {
  background-color: #0b5fff;
  border-color: #0b5fff;
}
.timeline-item .panel,
.timeline-item .panel-heading {
  position: relative;
}
.timeline-item .panel .timeline-increment {
  margin-left: -1px;
}

.timeline-right {
  padding-left: 0;
  padding-right: 25px;
}
.timeline-right .timeline-item {
  padding-left: 0;
  padding-right: 40px;
}
.timeline-right .timeline-item:before {
  left: auto;
  right: -2px;
}
.timeline-right .timeline-item .panel .timeline-increment {
  margin-left: auto;
  margin-right: -0.0625rem;
}
.timeline-right .timeline-item .timeline-increment {
  left: auto;
  right: -41px;
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

@media (max-width: 575.98px) {
  .timeline-right-xs-only {
    padding-left: 0;
    padding-right: 25px;
  }
  .timeline-right-xs-only .timeline-item {
    padding-left: 0;
    padding-right: 40px;
  }
  .timeline-right-xs-only .timeline-item:before {
    left: auto;
    right: -2px;
  }
  .timeline-right-xs-only .timeline-item .panel .timeline-increment {
    margin-left: auto;
    margin-right: -0.0625rem;
  }
  .timeline-right-xs-only .timeline-item .timeline-increment {
    left: auto;
    right: -41px;
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
  }
}

@media (min-width: 768px) {
  .timeline-center {
    padding-left: 0;
  }
  .timeline-center .timeline-item {
    margin-left: 50%;
    width: 50%;
  }
  .timeline-center .timeline-item .timeline-item-label {
    left: -100%;
    margin-left: -80px;
    position: absolute;
    text-align: right;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
  }
  .timeline-center .timeline-item.timeline-item-reverse {
    padding-left: 0;
    padding-right: 40px;
    margin-left: 0;
  }
  .timeline-center .timeline-item.timeline-item-reverse:before {
    left: auto;
    right: -2px;
  }
  .timeline-center .timeline-item.timeline-item-reverse .panel .timeline-increment {
    margin-left: auto;
    margin-right: -0.0625rem;
  }
  .timeline-center .timeline-item.timeline-item-reverse .timeline-increment {
    left: auto;
    right: -41px;
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
  }
  .timeline-center .timeline-item.timeline-item-reverse .timeline-item-label {
    margin-left: auto;
    margin-right: -80px;
    right: -100%;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .timeline-even .timeline-item:nth-of-type(even),
  .timeline-odd .timeline-item:nth-of-type(odd) {
    padding-left: 0;
    padding-right: 40px;
    margin-left: 0;
  }
  .timeline-even .timeline-item:nth-of-type(even):before,
  .timeline-odd .timeline-item:nth-of-type(odd):before {
    left: auto;
    right: -2px;
  }
  .timeline-even .timeline-item:nth-of-type(even) .panel .timeline-increment,
  .timeline-odd .timeline-item:nth-of-type(odd) .panel .timeline-increment {
    margin-left: auto;
    margin-right: -0.0625rem;
  }
  .timeline-even .timeline-item:nth-of-type(even) .timeline-increment,
  .timeline-odd .timeline-item:nth-of-type(odd) .timeline-increment {
    left: auto;
    right: -41px;
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
  }
  .timeline-even .timeline-item:nth-of-type(even) .timeline-item-label,
  .timeline-odd .timeline-item:nth-of-type(odd) .timeline-item-label {
    margin-left: auto;
    margin-right: -80px;
    right: -100%;
    text-align: left;
  }
}

.timeline-spacing-xl.timeline {
  padding-left: 25px;
}
@media (min-width: 768px) {
  .timeline-spacing-xl.timeline-center {
    padding-left: 0;
  }
  .timeline-spacing-xl.timeline-center .timeline-item .timeline-item-label {
    margin-left: -100px;
  }
  .timeline-spacing-xl.timeline-center .timeline-item.timeline-item-reverse {
    padding-right: 50px;
  }
  .timeline-spacing-xl.timeline-center .timeline-item.timeline-item-reverse .timeline-increment {
    right: -51px;
  }
  .timeline-spacing-xl.timeline-center .timeline-item.timeline-item-reverse .timeline-item-label {
    margin-left: auto;
    margin-right: -100px;
  }
}
@media (min-width: 768px) {
  .timeline-spacing-xl.timeline-even .timeline-item:nth-of-type(even), .timeline-spacing-xl.timeline-odd .timeline-item:nth-of-type(odd) {
    padding-right: 50px;
  }
  .timeline-spacing-xl.timeline-even .timeline-item:nth-of-type(even) .timeline-increment, .timeline-spacing-xl.timeline-odd .timeline-item:nth-of-type(odd) .timeline-increment {
    right: -51px;
  }
  .timeline-spacing-xl.timeline-even .timeline-item:nth-of-type(even) .timeline-item-label, .timeline-spacing-xl.timeline-odd .timeline-item:nth-of-type(odd) .timeline-item-label {
    margin-left: auto;
    margin-right: -100px;
  }
}
.timeline-spacing-xl.timeline-right {
  padding-right: 25px;
}
.timeline-spacing-xl.timeline-right .timeline-item {
  padding-right: 50px;
}
.timeline-spacing-xl.timeline-right .timeline-item .timeline-increment {
  right: -51px;
}
@media (max-width: 575.98px) {
  .timeline-spacing-xl.timeline-right-xs-only {
    padding-left: 0;
    padding-right: 25px;
  }
  .timeline-spacing-xl.timeline-right-xs-only .timeline-item {
    padding-left: 0;
    padding-right: 50px;
  }
  .timeline-spacing-xl.timeline-right-xs-only .timeline-item .timeline-increment {
    left: auto;
    right: -51px;
  }
}
.timeline-spacing-xl .timeline-item {
  padding-bottom: 15px;
  padding-left: 50px;
  padding-top: 15px;
}
.timeline-spacing-xl .timeline-item .timeline-increment {
  left: -49px;
}
.timeline-spacing-xl .timeline-increment-text {
  max-width: 75px;
}

.toggle-switch {
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  position: relative;
}
.toggle-switch.disabled {
  cursor: not-allowed;
}
.toggle-switch.disabled .toggle-switch-label {
  color: #6c757d;
  cursor: not-allowed;
}
.toggle-switch.disabled .toggle-switch-text {
  color: #6c757d;
}
.toggle-switch-check-bar {
  display: inline-flex;
  position: relative;
}

.toggle-switch-bar .toggle-switch-handle {
  display: block;
  min-width: 50px;
  text-transform: uppercase;
}
.toggle-switch-bar .toggle-switch-icon {
  font-size: 0.75rem;
}
.toggle-switch-bar .toggle-switch-icon .lexicon-icon {
  margin-top: -0.2em;
}
.toggle-switch-bar .button-icon {
  font-size: 0.75rem;
}
.toggle-switch-check {
  bottom: 0;
  font-size: 62.5%;
  height: 25px;
  opacity: 0;
  position: absolute;
  width: 50px;
  z-index: 2;
}
.toggle-switch-check ~ .toggle-switch-bar {
  display: inline-flex;
  font-size: 0.75rem;
  height: 25px;
  line-height: 25px;
  position: relative;
  text-indent: 0;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.toggle-switch-check ~ .toggle-switch-bar::before {
  background-color: #f1f2f5;
  border-color: #ced4da;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  content: " ";
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 100ms ease-in, border-color 100ms ease-in, box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in, opacity 100ms ease-in, right 100ms ease-in;
  width: 50px;
}
@media (prefers-reduced-motion: reduce) {
  .toggle-switch-check ~ .toggle-switch-bar::before {
    transition: none;
  }
}
.c-prefers-reduced-motion .toggle-switch-check ~ .toggle-switch-bar::before {
  transition: none;
}

.toggle-switch-check ~ .toggle-switch-bar::after {
  background-color: #fff;
  border-color: #ced4da;
  border-radius: 3px 0 0 3px;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  content: "";
  display: block;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  transition: background-color 100ms ease-in, border-color 100ms ease-in, box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in, opacity 100ms ease-in, right 100ms ease-in;
  width: 25px;
}
@media (prefers-reduced-motion: reduce) {
  .toggle-switch-check ~ .toggle-switch-bar::after {
    transition: none;
  }
}
.c-prefers-reduced-motion .toggle-switch-check ~ .toggle-switch-bar::after {
  transition: none;
}

.toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::before {
  transition: background-color 100ms ease-in, border-color 100ms ease-in, box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in, opacity 100ms ease-in, right 100ms ease-in;
}
@media (prefers-reduced-motion: reduce) {
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::before {
    transition: none;
  }
}
.c-prefers-reduced-motion .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::before {
  transition: none;
}

.toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::after {
  content: '\FEFF' attr(data-label-off);
  margin-left: 58px;
  transition: background-color 100ms ease-in, border-color 100ms ease-in, box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in, opacity 100ms ease-in, right 100ms ease-in;
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::after {
    transition: none;
  }
}
.c-prefers-reduced-motion .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::after {
  transition: none;
}

.toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon {
  color: #495057;
  left: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: 0;
  transition: background-color 100ms ease-in, border-color 100ms ease-in, box-shadow 150ms ease-in-out, color 100ms ease-in, left 100ms ease-in, opacity 100ms ease-in, right 100ms ease-in;
  width: 25px;
  z-index: 1;
}
@media (prefers-reduced-motion: reduce) {
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon {
    transition: none;
  }
}
.c-prefers-reduced-motion .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon {
  transition: none;
}

.toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon-on {
  left: 0;
  opacity: 0;
}
.toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon-off {
  left: 25px;
}
.toggle-switch-check ~ .toggle-switch-bar .button-icon {
  color: #495057;
}
.toggle-switch-check ~ .toggle-switch-bar .button-icon-on {
  opacity: 0;
}
.toggle-switch-check:focus-visible ~ .toggle-switch-bar::before, .c-prefers-focus .toggle-switch-check:focus ~ .toggle-switch-bar::before {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.toggle-switch-check[disabled] ~ .toggle-switch-bar, .toggle-switch-check:disabled ~ .toggle-switch-bar {
  cursor: not-allowed;
  opacity: 0.4;
}
.toggle-switch-check:checked ~ .toggle-switch-bar::before {
  background-color: #0b5fff;
  border-color: #0b5fff;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
}
.toggle-switch-check:checked ~ .toggle-switch-bar::after {
  background-color: #fff;
  border-color: #0b5fff;
  border-radius: 0 3px 3px 0;
  border-style: solid;
  border-width: 1px;
  left: 25px;
}
.toggle-switch-check:checked ~ .toggle-switch-bar .toggle-switch-handle::after {
  content: '\FEFF' attr(data-label-on);
}
.toggle-switch-check:checked ~ .toggle-switch-bar .toggle-switch-icon {
  color: #fff;
}
.toggle-switch-check:checked ~ .toggle-switch-bar .toggle-switch-icon-on {
  opacity: 1;
}
.toggle-switch-check:checked ~ .toggle-switch-bar .toggle-switch-icon-off {
  opacity: 0;
}
.toggle-switch-check:checked ~ .toggle-switch-bar .button-icon {
  color: #0b5fff;
  left: 25px;
}
.toggle-switch-check:checked ~ .toggle-switch-bar .button-icon-on {
  opacity: 1;
}
.toggle-switch-check:checked ~ .toggle-switch-bar .button-icon-off {
  opacity: 0;
}
@media (max-width: 767.98px) {
  .toggle-switch-check {
    height: 25px;
    width: 50px;
  }
  .toggle-switch-check ~ .toggle-switch-bar {
    height: 25px;
    line-height: 25px;
    text-indent: 0;
  }
  .toggle-switch-check ~ .toggle-switch-bar::before {
    width: 50px;
  }
  .toggle-switch-check ~ .toggle-switch-bar::after {
    bottom: 0;
    height: 25px;
    left: 0;
    top: 0;
    width: 25px;
  }
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle {
    min-width: 50px;
  }
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::after {
    margin-left: 58px;
  }
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon {
    font-size: 0.75rem;
    left: 0;
    line-height: 25px;
    top: 0;
    width: 25px;
  }
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon-on {
    left: 0;
  }
  .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon-off {
    left: 25px;
  }
  .toggle-switch-check ~ .toggle-switch-bar .button-icon {
    font-size: 0.75rem;
  }
  .toggle-switch-check:checked ~ .toggle-switch-bar::after {
    left: 25px;
  }
  .toggle-switch-check:checked ~ .toggle-switch-bar .toggle-switch-handle::after {
    margin-left: 58px;
  }
  .toggle-switch-check:checked ~ .toggle-switch-bar .button-icon {
    left: 25px;
  }
}

.toggle-switch-label {
  display: block;
  margin-bottom: 2px;
}

.toggle-switch-text {
  display: block;
  font-size: 0.75rem;
}

.toggle-switch-text-left {
  display: inline-flex;
  line-height: 25px;
  margin-right: 8px;
}
@media (max-width: 767.98px) {
  .toggle-switch-text-left {
    line-height: 25px;
  }
}

.toggle-switch-text-right {
  display: inline-flex;
  line-height: 25px;
  margin-left: 8px;
}
@media (max-width: 767.98px) {
  .toggle-switch-text-right {
    line-height: 25px;
  }
}

.simple-toggle-switch.toggle-switch {
  align-items: center;
  display: inline-flex;
}
.simple-toggle-switch.toggle-switch .toggle-switch-label {
  line-height: 1;
  margin-bottom: 0;
  max-width: calc( 					100% - 58px 				);
}
@media (max-width: 767.98px) {
  .simple-toggle-switch.toggle-switch .toggle-switch-label {
    max-width: calc( 						100% - 58px 					);
  }
}
.simple-toggle-switch.toggle-switch .toggle-switch-check + .toggle-switch-label {
  margin-right: 0.5rem;
}
.simple-toggle-switch.toggle-switch .toggle-switch-label + .toggle-switch-check-bar {
  margin-left: 0.5rem;
}

.simple-toggle-switch-reverse.simple-toggle-switch .toggle-switch-check-bar {
  order: 5;
}
.simple-toggle-switch-reverse.simple-toggle-switch .toggle-switch-check ~ .toggle-switch-bar {
  order: 5;
}
.simple-toggle-switch-reverse.simple-toggle-switch .toggle-switch-label {
  margin-right: 0.5rem;
}
.toggle-switch-sm .simple-toggle-switch .toggle-switch-label, .simple-toggle-switch.toggle-switch-sm .toggle-switch-label {
  max-width: calc(100% - 38px);
}
.toggle-switch-sm .toggle-switch-check {
  height: 16px;
  width: 30px;
}
.toggle-switch-sm .toggle-switch-check ~ .toggle-switch-bar {
  height: 16px;
  line-height: 16px;
}
.toggle-switch-sm .toggle-switch-check ~ .toggle-switch-bar::before {
  width: 30px;
}
.toggle-switch-sm .toggle-switch-check ~ .toggle-switch-bar::after {
  height: 16px;
  width: 16px;
}
.toggle-switch-sm .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle {
  min-width: 30px;
  max-width: 30px;
}
.toggle-switch-sm .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-handle::after {
  margin-left: 38px;
}
.toggle-switch-sm .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon {
  font-size: 0.6875rem;
  height: 16px;
  left: 0;
  line-height: 16px;
  width: 16px;
}
.toggle-switch-sm .toggle-switch-check ~ .toggle-switch-bar .toggle-switch-icon-off {
  left: 14px;
}
.toggle-switch-sm .toggle-switch-check:checked ~ .toggle-switch-bar::after {
  left: 14px;
}
.toggle-switch-sm .toggle-switch-check:checked ~ .toggle-switch-bar .button-icon {
  left: 14px;
}
.toggle-switch-sm .toggle-switch-label {
  font-size: 0.75rem;
  max-width: calc(100% - 38px);
}
.tooltip {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
  margin: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
  z-index: 1070;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  display: block;
  height: 0.4rem;
  position: absolute;
  width: 0.8rem;
}
.tooltip .arrow::before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}
.tooltip-inner {
  background-color: #000;
  border-radius: 0.25rem;
  color: #fff;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  white-space: pre-line;
}

.tooltip-arrow {
  background-color: #000;
  position: absolute;
  transform: rotate(45deg);
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^=top] .arrow::before {
  border-top-color: #000;
  border-width: 0.4rem 0.4rem 0;
  top: 0;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
  height: 0.8rem;
  left: 0;
  width: 0.4rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-right-color: #000;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: 0;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #000;
  border-width: 0 0.4rem 0.4rem;
  bottom: 0;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
  height: 0.8rem;
  right: 0;
  width: 0.4rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-left-color: #000;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  left: 0;
}

.clay-tooltip-bottom,
.clay-tooltip-bottom-left,
.clay-tooltip-bottom-right {
  padding-top: 0.4rem;
}
.clay-tooltip-bottom .arrow,
.clay-tooltip-bottom-left .arrow,
.clay-tooltip-bottom-right .arrow {
  left: 50%;
  margin-left: -0.4rem;
  top: 0;
}
.clay-tooltip-bottom .arrow::before,
.clay-tooltip-bottom-left .arrow::before,
.clay-tooltip-bottom-right .arrow::before {
  border-bottom-color: #000;
  border-width: 0 0.4rem 0.4rem;
  bottom: 0;
}
.clay-tooltip-bottom .tooltip-arrow,
.clay-tooltip-bottom-left .tooltip-arrow,
.clay-tooltip-bottom-right .tooltip-arrow {
  border-bottom-color: transparent;
  border-right-color: transparent;
  left: 50%;
  top: 0;
}
.clay-tooltip-bottom-left .arrow {
  left: 0.25rem;
  margin-left: 0;
}
.clay-tooltip-bottom-right .arrow {
  left: auto;
  margin-left: 0;
  right: 0.25rem;
}
.clay-tooltip-left,
.clay-tooltip-left-bottom,
.clay-tooltip-left-top {
  padding-right: 0.4rem;
}
.clay-tooltip-left .arrow,
.clay-tooltip-left-bottom .arrow,
.clay-tooltip-left-top .arrow {
  height: 0.8rem;
  margin-top: -0.4rem;
  right: 0;
  top: 50%;
  width: 0.4rem;
}
.clay-tooltip-left .arrow::before,
.clay-tooltip-left-bottom .arrow::before,
.clay-tooltip-left-top .arrow::before {
  border-left-color: #000;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  left: 0;
}
.clay-tooltip-left .tooltip-arrow,
.clay-tooltip-left-bottom .tooltip-arrow,
.clay-tooltip-left-top .tooltip-arrow {
  border-bottom-color: transparent;
  border-left-color: transparent;
  right: 0;
  top: 50%;
}
.clay-tooltip-left-bottom .arrow {
  bottom: 0.25rem;
  margin-top: 0;
  top: auto;
}
.clay-tooltip-left-top .arrow {
  margin-top: 0;
  top: 0.25rem;
}
.clay-tooltip-right,
.clay-tooltip-right-bottom,
.clay-tooltip-right-top {
  padding-left: 0.4rem;
}
.clay-tooltip-right .arrow,
.clay-tooltip-right-bottom .arrow,
.clay-tooltip-right-top .arrow {
  height: 0.8rem;
  left: 0;
  margin-top: -0.4rem;
  width: 0.4rem;
  top: 50%;
}
.clay-tooltip-right .arrow::before,
.clay-tooltip-right-bottom .arrow::before,
.clay-tooltip-right-top .arrow::before {
  border-right-color: #000;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  right: 0;
}
.clay-tooltip-right .tooltip-arrow,
.clay-tooltip-right-bottom .tooltip-arrow,
.clay-tooltip-right-top .tooltip-arrow {
  border-right-color: transparent;
  border-top-color: transparent;
  left: 0;
  top: 50%;
}
.clay-tooltip-right-bottom .arrow {
  bottom: 0.25rem;
  margin-top: 0;
  top: auto;
}
.clay-tooltip-right-top .arrow {
  margin-top: 0;
  top: 0.25rem;
}
.clay-tooltip-top,
.clay-tooltip-top-left,
.clay-tooltip-top-right {
  padding-bottom: 0.4rem;
}
.clay-tooltip-top .arrow,
.clay-tooltip-top-left .arrow,
.clay-tooltip-top-right .arrow {
  bottom: 0;
  left: 50%;
  margin-left: -0.4rem;
}
.clay-tooltip-top .arrow::before,
.clay-tooltip-top-left .arrow::before,
.clay-tooltip-top-right .arrow::before {
  border-top-color: #000;
  border-width: 0.4rem 0.4rem 0;
  top: 0;
}
.clay-tooltip-top .tooltip-arrow,
.clay-tooltip-top-left .tooltip-arrow,
.clay-tooltip-top-right .tooltip-arrow {
  border-left-color: transparent;
  border-top-color: transparent;
  bottom: 0;
  left: 50%;
}
.clay-tooltip-top-left .arrow {
  left: 0.25rem;
  margin-left: 0;
}
.clay-tooltip-top-right .arrow {
  left: auto;
  margin-left: 0;
  right: 0.25rem;
}
.treeview {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  list-style: none;
  margin-bottom: 0;
  padding: 2px 0;
}
.treeview .btn {
  color: inherit;
  font-size: 12px;
  line-height: 1;
  padding: 6px 8px;
}
.treeview .btn .c-inner {
  margin: -7px -8px;
}
.treeview .btn-monospaced {
  font-size: inherit;
  height: 24px;
  padding: 0;
  width: 24px;
}
.treeview .btn-monospaced.focus, .treeview .btn-monospaced:focus-visible, .c-prefers-focus .treeview .btn-monospaced:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview .btn-monospaced:active:focus-visible, .c-prefers-focus .treeview .btn-monospaced:active:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview .custom-control {
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 1.5px;
}
.treeview .component-expander {
  font-size: 10px;
}
.treeview .component-expander .lexicon-icon:not(.component-expanded-d-none) {
  display: none;
}
.treeview .component-action {
  font-size: 16px;
  margin-left: 2px;
  margin-right: 2px;
}
.treeview .component-action:hover {
  background-color: transparent;
  color: #6b6c7e;
}
.treeview .component-action.focus, .treeview .component-action:focus-visible, .c-prefers-focus .treeview .component-action:focus {
  color: #6b6c7e;
}
.treeview .component-action:active {
  background-color: transparent;
}
.treeview .component-action.active {
  background-color: transparent;
}
.treeview .component-action[aria-expanded=true], .treeview .component-action.show {
  background-color: transparent;
}
.treeview .component-icon {
  color: #6b6c7e;
  display: inline-block;
  font-size: 16px;
  height: auto;
  margin: 4px;
  vertical-align: middle;
  width: auto;
}
.treeview .component-icon .lexicon-icon {
  display: block;
}
.treeview .component-text {
  line-height: 24px;
  padding-left: 4px;
  -ms-user-select: auto;
  -moz-user-select: auto;
  -webkit-user-select: auto;
  user-select: auto;
}
.treeview.show-component-expander-on-hover:hover .component-expander, .treeview.show-component-expander-on-hover.hover .component-expander {
  opacity: 1;
  transition: opacity ease-in-out 600ms;
}
@media (prefers-reduced-motion: reduce) {
  .treeview.show-component-expander-on-hover:hover .component-expander, .treeview.show-component-expander-on-hover.hover .component-expander {
    transition: none;
  }
}
.c-prefers-reduced-motion .treeview.show-component-expander-on-hover:hover .component-expander, .c-prefers-reduced-motion .treeview.show-component-expander-on-hover.hover .component-expander {
  transition: none;
}

.treeview.show-component-expander-on-hover .treeview-link:focus .component-expander, .treeview.show-component-expander-on-hover .treeview-link.focus .component-expander {
  opacity: 1;
  transition: none;
}
.treeview.show-component-expander-on-hover .component-expander {
  opacity: 0;
  transition: opacity ease-in-out 450ms;
}
@media (prefers-reduced-motion: reduce) {
  .treeview.show-component-expander-on-hover .component-expander {
    transition: none;
  }
}
.c-prefers-reduced-motion .treeview.show-component-expander-on-hover .component-expander {
  transition: none;
}

.treeview .quick-action-item {
  margin: 0 2px;
  min-height: 0;
  min-width: 0;
}
.treeview .autofit-row {
  align-items: center;
}
.treeview.show-quick-actions-on-hover .treeview-link:not(:hover):not(:focus):not(.focus) .quick-action-item {
  display: none;
}
.treeview.show-quick-actions-on-hover .treeview-link:disabled .quick-action-item, .treeview.show-quick-actions-on-hover .treeview-link.disabled .quick-action-item {
  display: none;
}
.treeview.show-quick-actions-on-hover .treeview-link .quick-action-item:disabled, .treeview.show-quick-actions-on-hover .treeview-link .quick-action-item.disabled {
  display: none;
}

.treeview-group {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.treeview-item {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.treeview-dropping-indicator-top {
  background-color: transparent;
  display: block;
  height: 2px;
  margin-top: -2px;
  outline: none;
  width: 100%;
}
.treeview-dropping-indicator-bottom {
  background-color: transparent;
  display: block;
  height: 2px;
  margin-bottom: -2px;
  outline: none;
  width: 100%;
}
.treeview-dropping-indicator-over {
  background-color: #528eff;
}
.treeview-link {
  background-color: transparent;
  cursor: pointer;
  display: block;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  margin-bottom: 2px;
  margin-top: 2px;
  min-width: 100%;
  padding: 0;
  position: relative;
  text-align: left;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.treeview-link:hover, .treeview-link.hover {
  text-decoration: none;
}
.treeview-link.focus, .treeview-link:focus-visible, .c-prefers-focus .treeview-link:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
}
.treeview-link:disabled, .treeview-link.disabled {
  cursor: not-allowed;
}
.treeview-link.treeview-dropping-middle {
  background-color: #f0f5ff;
  border-color: #528eff;
}
.treeview-link.show .component-expander .component-expanded-d-none, .treeview-link[aria-expanded=true] .component-expander .component-expanded-d-none {
  display: none;
}
.treeview-link.show .component-expander .lexicon-icon:not(.component-expanded-d-none), .treeview-link[aria-expanded=true] .component-expander .lexicon-icon:not(.component-expanded-d-none) {
  display: inline-block;
}
.treeview-nested-margins .treeview-group .treeview-item {
  margin-left: 24px;
}

.treeview-item-dragging {
  cursor: not-allowed;
  opacity: 0.4;
}
.treeview-item-dragging .treeview-link {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.treeview-dragging {
  background-color: #fff;
  border-color: #528eff;
  border-radius: 0.2rem;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  padding: 4px 12px;
  text-transform: uppercase;
}

.treeview-light .component-expander {
  color: #6b6c7e;
}
.treeview-light .component-expander:disabled, .treeview-light .component-expander.disabled {
  color: #6b6c7e;
  opacity: 0.5;
}
.treeview-light .component-expander.btn-secondary {
  background-color: #fff;
}
.treeview-light .treeview-link {
  color: #6c757d;
}
.treeview-light .treeview-link:hover, .treeview-light .treeview-link.hover {
  box-shadow: inset 0 0 0 1px #88889a;
}
.treeview-light .treeview-link.focus, .treeview-light .treeview-link:focus-visible, .c-prefers-focus .treeview-light .treeview-link:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-light .treeview-link:active {
  background-color: #f0f5ff;
  box-shadow: inset 0 0 0 1px #0b5fff;
  color: #828e9a;
}
.treeview-light .treeview-link:active:focus-visible, .c-prefers-focus .treeview-light .treeview-link:active:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-light .treeview-link.active {
  background-color: #f0f5ff;
  box-shadow: inset 0 0 0 1px #0b5fff;
  color: #828e9a;
}
.treeview-light .treeview-link.active:focus-visible, .c-prefers-focus .treeview-light .treeview-link.active:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-light .treeview-link:disabled, .treeview-light .treeview-link.disabled {
  background-color: transparent;
  color: rgba(108, 117, 125, 0.5);
}
.treeview-light .treeview-link[aria-expanded=true]:focus-visible, .c-prefers-focus .treeview-light .treeview-link[aria-expanded=true]:focus, .treeview-light .treeview-link.show:focus-visible, .c-prefers-focus .treeview-light .treeview-link.show:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-light .treeview-link.treeview-no-hover:hover, .treeview-light .treeview-link.treeview-no-hover.hover {
  background-color: transparent;
  color: #6b6c7e;
}
.treeview-dark .component-expander {
  color: #a8a9b6;
}
.treeview-dark .component-expander:disabled, .treeview-dark .component-expander.disabled {
  color: #a8a9b6;
  opacity: 0.5;
}
.treeview-dark .treeview-link {
  color: #a8a9b6;
}
.treeview-dark .treeview-link:hover, .treeview-dark .treeview-link.hover {
  box-shadow: inset 0 0 0 1px #88889a;
}
.treeview-dark .treeview-link.focus, .treeview-dark .treeview-link:focus-visible, .c-prefers-focus .treeview-dark .treeview-link:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-dark .treeview-link:active {
  background-color: #828e9a;
  box-shadow: inset 0 0 0 1px #0b5fff;
  color: #f0f5ff;
}
.treeview-dark .treeview-link:active:focus-visible, .c-prefers-focus .treeview-dark .treeview-link:active:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-dark .treeview-link.active {
  background-color: #828e9a;
  box-shadow: inset 0 0 0 1px #0b5fff;
  color: #f0f5ff;
}
.treeview-dark .treeview-link.active:focus-visible, .c-prefers-focus .treeview-dark .treeview-link.active:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-dark .treeview-link:disabled, .treeview-dark .treeview-link.disabled {
  background-color: transparent;
  color: rgba(168, 169, 182, 0.5);
}
.treeview-dark .treeview-link[aria-expanded=true]:focus-visible, .c-prefers-focus .treeview-dark .treeview-link[aria-expanded=true]:focus, .treeview-dark .treeview-link.show:focus-visible, .c-prefers-focus .treeview-dark .treeview-link.show:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}
.treeview-dark .treeview-link.treeview-no-hover:hover, .treeview-dark .treeview-link.treeview-no-hover.hover {
  background-color: transparent;
  color: #a8a9b6;
}
.treeview-dark .component-action {
  color: #a8a9b6;
}
.treeview-dark .component-action:disabled, .treeview-dark .component-action.disabled {
  color: #a8a9b6;
}
.bg-checkered {
  background-image: linear-gradient(45deg, #f1f2f5 25%, transparent 25%), linear-gradient(-45deg, #f1f2f5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #f1f2f5 75%), linear-gradient(-45deg, transparent 75%, #f1f2f5 75%);
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  background-size: 20px 20px;
}

.close {
  align-items: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0.2rem;
  border-width: 0px;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 700;
  height: 2rem;
  justify-content: center;
  line-height: 1;
  opacity: 0.5;
  padding: 0;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 2rem;
}
.close::-ms-expand {
  display: none;
}
@media (prefers-reduced-motion: reduce) {
  .close {
    transition: none;
  }
}
.c-prefers-reduced-motion .close {
  transition: none;
}

.close:hover {
  color: #000;
  opacity: 0.75;
  text-decoration: none;
}
.close.focus, .close:focus-visible, .c-prefers-focus .close:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  outline: 0;
  opacity: 0.75;
}

.close:disabled, .close.disabled {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.25;
  outline: 0;
}
.close .lexicon-icon {
  margin-top: 0;
}

.c-focus-inset:focus:not(:disabled):not(.disabled):not([disabled]), .c-focus-inset.focus:not(:disabled):not(.disabled):not([disabled]) {
  box-shadow: inset 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
  border-color: rgba(11, 95, 255, 0.25);
  outline: 0;
}

.autofit-row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
ul.autofit-row {
  list-style: none;
  padding-left: 0;
}

.autofit-row-center > .autofit-col {
  justify-content: center;
}

.autofit-row-end > .autofit-col {
  justify-content: flex-end;
}

.autofit-padded > .autofit-col {
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}

.autofit-padded-no-gutters-x {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: auto;
}
.autofit-padded-no-gutters-x > .autofit-col {
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}

.autofit-padded-no-gutters-y {
  margin-bottom: -0.25rem;
  margin-top: -0.25rem;
  width: auto;
}
.autofit-padded-no-gutters-y > .autofit-col {
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}

.autofit-padded-no-gutters {
  margin-bottom: -0.25rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: -0.25rem;
  width: auto;
}
.autofit-padded-no-gutters > .autofit-col {
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}

.autofit-padded-no-gutters-sm, .sheet-title .autofit-padded-no-gutters {
  margin: -0.25rem;
  width: auto;
}
.autofit-padded-no-gutters-sm > .autofit-col, .sheet-title .autofit-padded-no-gutters > .autofit-col {
  padding: 0.25rem;
}

.autofit-col, .tbar-item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0;
  position: relative;
}

.autofit-col-shrink {
  flex-shrink: 1;
  min-width: 1rem;
  word-wrap: break-word;
}

.autofit-col-expand, .tbar-item-expand {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 3.125rem;
  word-wrap: break-word;
}

.autofit-section, .tbar-section {
  max-width: 100%;
}

.autofit-float {
  flex-wrap: wrap;
}
.autofit-float > .autofit-col {
  max-width: 100%;
}
.autofit-float > .autofit-col-end {
  margin-left: auto;
}
.autofit-float > .autofit-col-end + .autofit-col-end {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .autofit-float-sm-down {
    flex-wrap: wrap;
  }
  .autofit-float-sm-down > .autofit-col {
    max-width: 100%;
  }
  .autofit-float-sm-down > .autofit-col-end {
    margin-left: auto;
  }
  .autofit-float-sm-down > .autofit-col-end + .autofit-col-end {
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .autofit-float-md-down {
    flex-wrap: wrap;
  }
  .autofit-float-md-down > .autofit-col {
    max-width: 100%;
  }
  .autofit-float-md-down > .autofit-col-end {
    margin-left: auto;
  }
  .autofit-float-md-down > .autofit-col-end + .autofit-col-end {
    margin-left: 0;
  }
}

.autofit-float-end {
  flex-wrap: wrap;
  justify-content: flex-end;
}
.autofit-float-end > .autofit-col {
  max-width: 100%;
}
.autofit-float-end > .autofit-col-end {
  margin-left: auto;
}
.autofit-float-end > .autofit-col-end + .autofit-col-end {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .autofit-float-end-sm-down {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .autofit-float-end-sm-down > .autofit-col {
    max-width: 100%;
  }
  .autofit-float-end-sm-down > .autofit-col-end {
    margin-left: auto;
  }
  .autofit-float-end-sm-down > .autofit-col-end + .autofit-col-end {
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .autofit-float-end-md-down {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .autofit-float-end-md-down > .autofit-col {
    max-width: 100%;
  }
  .autofit-float-end-md-down > .autofit-col-end {
    margin-left: auto;
  }
  .autofit-float-end-md-down > .autofit-col-end + .autofit-col-end {
    margin-left: 0;
  }
}

.c-inner {
  align-items: inherit;
  display: inherit;
  flex-direction: inherit;
  height: inherit;
  justify-content: inherit;
  line-height: inherit;
  max-width: inherit;
  min-width: inherit;
  outline: 0;
  overflow: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  padding-right: inherit;
  padding-top: inherit;
  width: inherit;
}

.heading-start {
  margin-right: 1rem;
}

.heading-end {
  margin-left: 1rem;
}

.heading-text {
  margin-bottom: auto;
  margin-top: auto;
}

.inline-scroller {
  -webkit-overflow-scrolling: touch;
  list-style: none;
  margin: 0;
  max-height: 125px;
  overflow: auto;
  padding: 0;
}
.inline-scroller:focus {
  outline: 0;
}
.inline-scroller:focus-visible {
  box-shadow: 0 0 0 0.2rem rgba(11, 95, 255, 0.25);
}

.inline-item {
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
}
.inline-item .inline-item {
  display: inline;
}
.inline-item .lexicon-icon {
  margin-top: -0.1em;
}
.inline-item-before {
  margin-right: 0.5rem;
}

.inline-item-middle {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.inline-item-middle + .inline-item-middle {
  margin-left: 0;
}

.inline-item-after {
  margin-left: 0.5rem;
}

.page-header {
  background-color: #f1f2f5;
}

.c-gap-0 {
  gap: 0;
}

.c-gapx-0 {
  column-gap: 0;
}

.c-gapy-0 {
  row-gap: 0;
}

.c-gap-1 {
  gap: 0.25rem;
}

.c-gapx-1 {
  column-gap: 0.25rem;
}

.c-gapy-1 {
  row-gap: 0.25rem;
}

.c-gap-2 {
  gap: 0.5rem;
}

.c-gapx-2 {
  column-gap: 0.5rem;
}

.c-gapy-2 {
  row-gap: 0.5rem;
}

.c-gap-3 {
  gap: 1rem;
}

.c-gapx-3 {
  column-gap: 1rem;
}

.c-gapy-3 {
  row-gap: 1rem;
}

.c-gap-4 {
  gap: 1.5rem;
}

.c-gapx-4 {
  column-gap: 1.5rem;
}

.c-gapy-4 {
  row-gap: 1.5rem;
}

.c-gap-5 {
  gap: 3rem;
}

.c-gapx-5 {
  column-gap: 3rem;
}

.c-gapy-5 {
  row-gap: 3rem;
}

@media (min-width: 576px) {
  .c-gap-sm-0 {
    gap: 0;
  }
  .c-gapx-sm-0 {
    column-gap: 0;
  }
  .c-gapy-sm-0 {
    row-gap: 0;
  }
  .c-gap-sm-1 {
    gap: 0.25rem;
  }
  .c-gapx-sm-1 {
    column-gap: 0.25rem;
  }
  .c-gapy-sm-1 {
    row-gap: 0.25rem;
  }
  .c-gap-sm-2 {
    gap: 0.5rem;
  }
  .c-gapx-sm-2 {
    column-gap: 0.5rem;
  }
  .c-gapy-sm-2 {
    row-gap: 0.5rem;
  }
  .c-gap-sm-3 {
    gap: 1rem;
  }
  .c-gapx-sm-3 {
    column-gap: 1rem;
  }
  .c-gapy-sm-3 {
    row-gap: 1rem;
  }
  .c-gap-sm-4 {
    gap: 1.5rem;
  }
  .c-gapx-sm-4 {
    column-gap: 1.5rem;
  }
  .c-gapy-sm-4 {
    row-gap: 1.5rem;
  }
  .c-gap-sm-5 {
    gap: 3rem;
  }
  .c-gapx-sm-5 {
    column-gap: 3rem;
  }
  .c-gapy-sm-5 {
    row-gap: 3rem;
  }
}
@media (min-width: 768px) {
  .c-gap-md-0 {
    gap: 0;
  }
  .c-gapx-md-0 {
    column-gap: 0;
  }
  .c-gapy-md-0 {
    row-gap: 0;
  }
  .c-gap-md-1 {
    gap: 0.25rem;
  }
  .c-gapx-md-1 {
    column-gap: 0.25rem;
  }
  .c-gapy-md-1 {
    row-gap: 0.25rem;
  }
  .c-gap-md-2 {
    gap: 0.5rem;
  }
  .c-gapx-md-2 {
    column-gap: 0.5rem;
  }
  .c-gapy-md-2 {
    row-gap: 0.5rem;
  }
  .c-gap-md-3 {
    gap: 1rem;
  }
  .c-gapx-md-3 {
    column-gap: 1rem;
  }
  .c-gapy-md-3 {
    row-gap: 1rem;
  }
  .c-gap-md-4 {
    gap: 1.5rem;
  }
  .c-gapx-md-4 {
    column-gap: 1.5rem;
  }
  .c-gapy-md-4 {
    row-gap: 1.5rem;
  }
  .c-gap-md-5 {
    gap: 3rem;
  }
  .c-gapx-md-5 {
    column-gap: 3rem;
  }
  .c-gapy-md-5 {
    row-gap: 3rem;
  }
}
@media (min-width: 992px) {
  .c-gap-lg-0 {
    gap: 0;
  }
  .c-gapx-lg-0 {
    column-gap: 0;
  }
  .c-gapy-lg-0 {
    row-gap: 0;
  }
  .c-gap-lg-1 {
    gap: 0.25rem;
  }
  .c-gapx-lg-1 {
    column-gap: 0.25rem;
  }
  .c-gapy-lg-1 {
    row-gap: 0.25rem;
  }
  .c-gap-lg-2 {
    gap: 0.5rem;
  }
  .c-gapx-lg-2 {
    column-gap: 0.5rem;
  }
  .c-gapy-lg-2 {
    row-gap: 0.5rem;
  }
  .c-gap-lg-3 {
    gap: 1rem;
  }
  .c-gapx-lg-3 {
    column-gap: 1rem;
  }
  .c-gapy-lg-3 {
    row-gap: 1rem;
  }
  .c-gap-lg-4 {
    gap: 1.5rem;
  }
  .c-gapx-lg-4 {
    column-gap: 1.5rem;
  }
  .c-gapy-lg-4 {
    row-gap: 1.5rem;
  }
  .c-gap-lg-5 {
    gap: 3rem;
  }
  .c-gapx-lg-5 {
    column-gap: 3rem;
  }
  .c-gapy-lg-5 {
    row-gap: 3rem;
  }
}
@media (min-width: 1200px) {
  .c-gap-xl-0 {
    gap: 0;
  }
  .c-gapx-xl-0 {
    column-gap: 0;
  }
  .c-gapy-xl-0 {
    row-gap: 0;
  }
  .c-gap-xl-1 {
    gap: 0.25rem;
  }
  .c-gapx-xl-1 {
    column-gap: 0.25rem;
  }
  .c-gapy-xl-1 {
    row-gap: 0.25rem;
  }
  .c-gap-xl-2 {
    gap: 0.5rem;
  }
  .c-gapx-xl-2 {
    column-gap: 0.5rem;
  }
  .c-gapy-xl-2 {
    row-gap: 0.5rem;
  }
  .c-gap-xl-3 {
    gap: 1rem;
  }
  .c-gapx-xl-3 {
    column-gap: 1rem;
  }
  .c-gapy-xl-3 {
    row-gap: 1rem;
  }
  .c-gap-xl-4 {
    gap: 1.5rem;
  }
  .c-gapx-xl-4 {
    column-gap: 1.5rem;
  }
  .c-gapy-xl-4 {
    row-gap: 1.5rem;
  }
  .c-gap-xl-5 {
    gap: 3rem;
  }
  .c-gapx-xl-5 {
    column-gap: 3rem;
  }
  .c-gapy-xl-5 {
    row-gap: 3rem;
  }
}
.c-m-0 {
  margin: 0;
}

.c-mt-0,
.c-my-0 {
  margin-top: 0;
}

.c-mr-0,
.c-mx-0 {
  margin-right: 0;
}

.c-mb-0,
.c-my-0 {
  margin-bottom: 0;
}

.c-ml-0,
.c-mx-0 {
  margin-left: 0;
}

.c-m-1 {
  margin: 0.25rem;
}

.c-mt-1,
.c-my-1 {
  margin-top: 0.25rem;
}

.c-mr-1,
.c-mx-1 {
  margin-right: 0.25rem;
}

.c-mb-1,
.c-my-1 {
  margin-bottom: 0.25rem;
}

.c-ml-1,
.c-mx-1 {
  margin-left: 0.25rem;
}

.c-m-2 {
  margin: 0.5rem;
}

.c-mt-2,
.c-my-2 {
  margin-top: 0.5rem;
}

.c-mr-2,
.c-mx-2 {
  margin-right: 0.5rem;
}

.c-mb-2,
.c-my-2 {
  margin-bottom: 0.5rem;
}

.c-ml-2,
.c-mx-2 {
  margin-left: 0.5rem;
}

.c-m-3 {
  margin: 1rem;
}

.c-mt-3,
.c-my-3 {
  margin-top: 1rem;
}

.c-mr-3,
.c-mx-3 {
  margin-right: 1rem;
}

.c-mb-3,
.c-my-3 {
  margin-bottom: 1rem;
}

.c-ml-3,
.c-mx-3 {
  margin-left: 1rem;
}

.c-m-4 {
  margin: 1.5rem;
}

.c-mt-4,
.c-my-4 {
  margin-top: 1.5rem;
}

.c-mr-4,
.c-mx-4 {
  margin-right: 1.5rem;
}

.c-mb-4,
.c-my-4 {
  margin-bottom: 1.5rem;
}

.c-ml-4,
.c-mx-4 {
  margin-left: 1.5rem;
}

.c-m-5 {
  margin: 3rem;
}

.c-mt-5,
.c-my-5 {
  margin-top: 3rem;
}

.c-mr-5,
.c-mx-5 {
  margin-right: 3rem;
}

.c-mb-5,
.c-my-5 {
  margin-bottom: 3rem;
}

.c-ml-5,
.c-mx-5 {
  margin-left: 3rem;
}

.c-m-6 {
  margin: 4.5rem;
}

.c-mt-6,
.c-my-6 {
  margin-top: 4.5rem;
}

.c-mr-6,
.c-mx-6 {
  margin-right: 4.5rem;
}

.c-mb-6,
.c-my-6 {
  margin-bottom: 4.5rem;
}

.c-ml-6,
.c-mx-6 {
  margin-left: 4.5rem;
}

.c-m-7 {
  margin: 6rem;
}

.c-mt-7,
.c-my-7 {
  margin-top: 6rem;
}

.c-mr-7,
.c-mx-7 {
  margin-right: 6rem;
}

.c-mb-7,
.c-my-7 {
  margin-bottom: 6rem;
}

.c-ml-7,
.c-mx-7 {
  margin-left: 6rem;
}

.c-m-8 {
  margin: 7.5rem;
}

.c-mt-8,
.c-my-8 {
  margin-top: 7.5rem;
}

.c-mr-8,
.c-mx-8 {
  margin-right: 7.5rem;
}

.c-mb-8,
.c-my-8 {
  margin-bottom: 7.5rem;
}

.c-ml-8,
.c-mx-8 {
  margin-left: 7.5rem;
}

.c-p-0 {
  padding: 0;
}

.c-pt-0,
.c-py-0 {
  padding-top: 0;
}

.c-pr-0,
.c-px-0 {
  padding-right: 0;
}

.c-pb-0,
.c-py-0 {
  padding-bottom: 0;
}

.c-pl-0,
.c-px-0 {
  padding-left: 0;
}

.c-p-1 {
  padding: 0.25rem;
}

.c-pt-1,
.c-py-1 {
  padding-top: 0.25rem;
}

.c-pr-1,
.c-px-1 {
  padding-right: 0.25rem;
}

.c-pb-1,
.c-py-1 {
  padding-bottom: 0.25rem;
}

.c-pl-1,
.c-px-1 {
  padding-left: 0.25rem;
}

.c-p-2 {
  padding: 0.5rem;
}

.c-pt-2,
.c-py-2 {
  padding-top: 0.5rem;
}

.c-pr-2,
.c-px-2 {
  padding-right: 0.5rem;
}

.c-pb-2,
.c-py-2 {
  padding-bottom: 0.5rem;
}

.c-pl-2,
.c-px-2 {
  padding-left: 0.5rem;
}

.c-p-3 {
  padding: 1rem;
}

.c-pt-3,
.c-py-3 {
  padding-top: 1rem;
}

.c-pr-3,
.c-px-3 {
  padding-right: 1rem;
}

.c-pb-3,
.c-py-3 {
  padding-bottom: 1rem;
}

.c-pl-3,
.c-px-3 {
  padding-left: 1rem;
}

.c-p-4 {
  padding: 1.5rem;
}

.c-pt-4,
.c-py-4 {
  padding-top: 1.5rem;
}

.c-pr-4,
.c-px-4 {
  padding-right: 1.5rem;
}

.c-pb-4,
.c-py-4 {
  padding-bottom: 1.5rem;
}

.c-pl-4,
.c-px-4 {
  padding-left: 1.5rem;
}

.c-p-5 {
  padding: 3rem;
}

.c-pt-5,
.c-py-5 {
  padding-top: 3rem;
}

.c-pr-5,
.c-px-5 {
  padding-right: 3rem;
}

.c-pb-5,
.c-py-5 {
  padding-bottom: 3rem;
}

.c-pl-5,
.c-px-5 {
  padding-left: 3rem;
}

.c-p-6 {
  padding: 4.5rem;
}

.c-pt-6,
.c-py-6 {
  padding-top: 4.5rem;
}

.c-pr-6,
.c-px-6 {
  padding-right: 4.5rem;
}

.c-pb-6,
.c-py-6 {
  padding-bottom: 4.5rem;
}

.c-pl-6,
.c-px-6 {
  padding-left: 4.5rem;
}

.c-p-7 {
  padding: 6rem;
}

.c-pt-7,
.c-py-7 {
  padding-top: 6rem;
}

.c-pr-7,
.c-px-7 {
  padding-right: 6rem;
}

.c-pb-7,
.c-py-7 {
  padding-bottom: 6rem;
}

.c-pl-7,
.c-px-7 {
  padding-left: 6rem;
}

.c-p-8 {
  padding: 7.5rem;
}

.c-pt-8,
.c-py-8 {
  padding-top: 7.5rem;
}

.c-pr-8,
.c-px-8 {
  padding-right: 7.5rem;
}

.c-pb-8,
.c-py-8 {
  padding-bottom: 7.5rem;
}

.c-pl-8,
.c-px-8 {
  padding-left: 7.5rem;
}

.c-m-n1 {
  margin: -0.25rem;
}

.c-mt-n1,
.c-my-n1 {
  margin-top: -0.25rem;
}

.c-mr-n1,
.c-mx-n1 {
  margin-right: -0.25rem;
}

.c-mb-n1,
.c-my-n1 {
  margin-bottom: -0.25rem;
}

.c-ml-n1,
.c-mx-n1 {
  margin-left: -0.25rem;
}

.c-m-n2 {
  margin: -0.5rem;
}

.c-mt-n2,
.c-my-n2 {
  margin-top: -0.5rem;
}

.c-mr-n2,
.c-mx-n2 {
  margin-right: -0.5rem;
}

.c-mb-n2,
.c-my-n2 {
  margin-bottom: -0.5rem;
}

.c-ml-n2,
.c-mx-n2 {
  margin-left: -0.5rem;
}

.c-m-n3 {
  margin: -1rem;
}

.c-mt-n3,
.c-my-n3 {
  margin-top: -1rem;
}

.c-mr-n3,
.c-mx-n3 {
  margin-right: -1rem;
}

.c-mb-n3,
.c-my-n3 {
  margin-bottom: -1rem;
}

.c-ml-n3,
.c-mx-n3 {
  margin-left: -1rem;
}

.c-m-n4 {
  margin: -1.5rem;
}

.c-mt-n4,
.c-my-n4 {
  margin-top: -1.5rem;
}

.c-mr-n4,
.c-mx-n4 {
  margin-right: -1.5rem;
}

.c-mb-n4,
.c-my-n4 {
  margin-bottom: -1.5rem;
}

.c-ml-n4,
.c-mx-n4 {
  margin-left: -1.5rem;
}

.c-m-n5 {
  margin: -3rem;
}

.c-mt-n5,
.c-my-n5 {
  margin-top: -3rem;
}

.c-mr-n5,
.c-mx-n5 {
  margin-right: -3rem;
}

.c-mb-n5,
.c-my-n5 {
  margin-bottom: -3rem;
}

.c-ml-n5,
.c-mx-n5 {
  margin-left: -3rem;
}

.c-m-n6 {
  margin: -4.5rem;
}

.c-mt-n6,
.c-my-n6 {
  margin-top: -4.5rem;
}

.c-mr-n6,
.c-mx-n6 {
  margin-right: -4.5rem;
}

.c-mb-n6,
.c-my-n6 {
  margin-bottom: -4.5rem;
}

.c-ml-n6,
.c-mx-n6 {
  margin-left: -4.5rem;
}

.c-m-n7 {
  margin: -6rem;
}

.c-mt-n7,
.c-my-n7 {
  margin-top: -6rem;
}

.c-mr-n7,
.c-mx-n7 {
  margin-right: -6rem;
}

.c-mb-n7,
.c-my-n7 {
  margin-bottom: -6rem;
}

.c-ml-n7,
.c-mx-n7 {
  margin-left: -6rem;
}

.c-m-n8 {
  margin: -7.5rem;
}

.c-mt-n8,
.c-my-n8 {
  margin-top: -7.5rem;
}

.c-mr-n8,
.c-mx-n8 {
  margin-right: -7.5rem;
}

.c-mb-n8,
.c-my-n8 {
  margin-bottom: -7.5rem;
}

.c-ml-n8,
.c-mx-n8 {
  margin-left: -7.5rem;
}

.c-m-auto {
  margin: auto;
}

.c-mt-auto,
.c-my-auto {
  margin-top: auto;
}

.c-mr-auto,
.c-mx-auto {
  margin-right: auto;
}

.c-mb-auto,
.c-my-auto {
  margin-bottom: auto;
}

.c-ml-auto,
.c-mx-auto {
  margin-left: auto;
}

@media (min-width: 576px) {
  .c-m-sm-0 {
    margin: 0;
  }
  .c-mt-sm-0,
  .c-my-sm-0 {
    margin-top: 0;
  }
  .c-mr-sm-0,
  .c-mx-sm-0 {
    margin-right: 0;
  }
  .c-mb-sm-0,
  .c-my-sm-0 {
    margin-bottom: 0;
  }
  .c-ml-sm-0,
  .c-mx-sm-0 {
    margin-left: 0;
  }
  .c-m-sm-1 {
    margin: 0.25rem;
  }
  .c-mt-sm-1,
  .c-my-sm-1 {
    margin-top: 0.25rem;
  }
  .c-mr-sm-1,
  .c-mx-sm-1 {
    margin-right: 0.25rem;
  }
  .c-mb-sm-1,
  .c-my-sm-1 {
    margin-bottom: 0.25rem;
  }
  .c-ml-sm-1,
  .c-mx-sm-1 {
    margin-left: 0.25rem;
  }
  .c-m-sm-2 {
    margin: 0.5rem;
  }
  .c-mt-sm-2,
  .c-my-sm-2 {
    margin-top: 0.5rem;
  }
  .c-mr-sm-2,
  .c-mx-sm-2 {
    margin-right: 0.5rem;
  }
  .c-mb-sm-2,
  .c-my-sm-2 {
    margin-bottom: 0.5rem;
  }
  .c-ml-sm-2,
  .c-mx-sm-2 {
    margin-left: 0.5rem;
  }
  .c-m-sm-3 {
    margin: 1rem;
  }
  .c-mt-sm-3,
  .c-my-sm-3 {
    margin-top: 1rem;
  }
  .c-mr-sm-3,
  .c-mx-sm-3 {
    margin-right: 1rem;
  }
  .c-mb-sm-3,
  .c-my-sm-3 {
    margin-bottom: 1rem;
  }
  .c-ml-sm-3,
  .c-mx-sm-3 {
    margin-left: 1rem;
  }
  .c-m-sm-4 {
    margin: 1.5rem;
  }
  .c-mt-sm-4,
  .c-my-sm-4 {
    margin-top: 1.5rem;
  }
  .c-mr-sm-4,
  .c-mx-sm-4 {
    margin-right: 1.5rem;
  }
  .c-mb-sm-4,
  .c-my-sm-4 {
    margin-bottom: 1.5rem;
  }
  .c-ml-sm-4,
  .c-mx-sm-4 {
    margin-left: 1.5rem;
  }
  .c-m-sm-5 {
    margin: 3rem;
  }
  .c-mt-sm-5,
  .c-my-sm-5 {
    margin-top: 3rem;
  }
  .c-mr-sm-5,
  .c-mx-sm-5 {
    margin-right: 3rem;
  }
  .c-mb-sm-5,
  .c-my-sm-5 {
    margin-bottom: 3rem;
  }
  .c-ml-sm-5,
  .c-mx-sm-5 {
    margin-left: 3rem;
  }
  .c-m-sm-6 {
    margin: 4.5rem;
  }
  .c-mt-sm-6,
  .c-my-sm-6 {
    margin-top: 4.5rem;
  }
  .c-mr-sm-6,
  .c-mx-sm-6 {
    margin-right: 4.5rem;
  }
  .c-mb-sm-6,
  .c-my-sm-6 {
    margin-bottom: 4.5rem;
  }
  .c-ml-sm-6,
  .c-mx-sm-6 {
    margin-left: 4.5rem;
  }
  .c-m-sm-7 {
    margin: 6rem;
  }
  .c-mt-sm-7,
  .c-my-sm-7 {
    margin-top: 6rem;
  }
  .c-mr-sm-7,
  .c-mx-sm-7 {
    margin-right: 6rem;
  }
  .c-mb-sm-7,
  .c-my-sm-7 {
    margin-bottom: 6rem;
  }
  .c-ml-sm-7,
  .c-mx-sm-7 {
    margin-left: 6rem;
  }
  .c-m-sm-8 {
    margin: 7.5rem;
  }
  .c-mt-sm-8,
  .c-my-sm-8 {
    margin-top: 7.5rem;
  }
  .c-mr-sm-8,
  .c-mx-sm-8 {
    margin-right: 7.5rem;
  }
  .c-mb-sm-8,
  .c-my-sm-8 {
    margin-bottom: 7.5rem;
  }
  .c-ml-sm-8,
  .c-mx-sm-8 {
    margin-left: 7.5rem;
  }
  .c-p-sm-0 {
    padding: 0;
  }
  .c-pt-sm-0,
  .c-py-sm-0 {
    padding-top: 0;
  }
  .c-pr-sm-0,
  .c-px-sm-0 {
    padding-right: 0;
  }
  .c-pb-sm-0,
  .c-py-sm-0 {
    padding-bottom: 0;
  }
  .c-pl-sm-0,
  .c-px-sm-0 {
    padding-left: 0;
  }
  .c-p-sm-1 {
    padding: 0.25rem;
  }
  .c-pt-sm-1,
  .c-py-sm-1 {
    padding-top: 0.25rem;
  }
  .c-pr-sm-1,
  .c-px-sm-1 {
    padding-right: 0.25rem;
  }
  .c-pb-sm-1,
  .c-py-sm-1 {
    padding-bottom: 0.25rem;
  }
  .c-pl-sm-1,
  .c-px-sm-1 {
    padding-left: 0.25rem;
  }
  .c-p-sm-2 {
    padding: 0.5rem;
  }
  .c-pt-sm-2,
  .c-py-sm-2 {
    padding-top: 0.5rem;
  }
  .c-pr-sm-2,
  .c-px-sm-2 {
    padding-right: 0.5rem;
  }
  .c-pb-sm-2,
  .c-py-sm-2 {
    padding-bottom: 0.5rem;
  }
  .c-pl-sm-2,
  .c-px-sm-2 {
    padding-left: 0.5rem;
  }
  .c-p-sm-3 {
    padding: 1rem;
  }
  .c-pt-sm-3,
  .c-py-sm-3 {
    padding-top: 1rem;
  }
  .c-pr-sm-3,
  .c-px-sm-3 {
    padding-right: 1rem;
  }
  .c-pb-sm-3,
  .c-py-sm-3 {
    padding-bottom: 1rem;
  }
  .c-pl-sm-3,
  .c-px-sm-3 {
    padding-left: 1rem;
  }
  .c-p-sm-4 {
    padding: 1.5rem;
  }
  .c-pt-sm-4,
  .c-py-sm-4 {
    padding-top: 1.5rem;
  }
  .c-pr-sm-4,
  .c-px-sm-4 {
    padding-right: 1.5rem;
  }
  .c-pb-sm-4,
  .c-py-sm-4 {
    padding-bottom: 1.5rem;
  }
  .c-pl-sm-4,
  .c-px-sm-4 {
    padding-left: 1.5rem;
  }
  .c-p-sm-5 {
    padding: 3rem;
  }
  .c-pt-sm-5,
  .c-py-sm-5 {
    padding-top: 3rem;
  }
  .c-pr-sm-5,
  .c-px-sm-5 {
    padding-right: 3rem;
  }
  .c-pb-sm-5,
  .c-py-sm-5 {
    padding-bottom: 3rem;
  }
  .c-pl-sm-5,
  .c-px-sm-5 {
    padding-left: 3rem;
  }
  .c-p-sm-6 {
    padding: 4.5rem;
  }
  .c-pt-sm-6,
  .c-py-sm-6 {
    padding-top: 4.5rem;
  }
  .c-pr-sm-6,
  .c-px-sm-6 {
    padding-right: 4.5rem;
  }
  .c-pb-sm-6,
  .c-py-sm-6 {
    padding-bottom: 4.5rem;
  }
  .c-pl-sm-6,
  .c-px-sm-6 {
    padding-left: 4.5rem;
  }
  .c-p-sm-7 {
    padding: 6rem;
  }
  .c-pt-sm-7,
  .c-py-sm-7 {
    padding-top: 6rem;
  }
  .c-pr-sm-7,
  .c-px-sm-7 {
    padding-right: 6rem;
  }
  .c-pb-sm-7,
  .c-py-sm-7 {
    padding-bottom: 6rem;
  }
  .c-pl-sm-7,
  .c-px-sm-7 {
    padding-left: 6rem;
  }
  .c-p-sm-8 {
    padding: 7.5rem;
  }
  .c-pt-sm-8,
  .c-py-sm-8 {
    padding-top: 7.5rem;
  }
  .c-pr-sm-8,
  .c-px-sm-8 {
    padding-right: 7.5rem;
  }
  .c-pb-sm-8,
  .c-py-sm-8 {
    padding-bottom: 7.5rem;
  }
  .c-pl-sm-8,
  .c-px-sm-8 {
    padding-left: 7.5rem;
  }
  .c-m-sm-n1 {
    margin: -0.25rem;
  }
  .c-mt-sm-n1,
  .c-my-sm-n1 {
    margin-top: -0.25rem;
  }
  .c-mr-sm-n1,
  .c-mx-sm-n1 {
    margin-right: -0.25rem;
  }
  .c-mb-sm-n1,
  .c-my-sm-n1 {
    margin-bottom: -0.25rem;
  }
  .c-ml-sm-n1,
  .c-mx-sm-n1 {
    margin-left: -0.25rem;
  }
  .c-m-sm-n2 {
    margin: -0.5rem;
  }
  .c-mt-sm-n2,
  .c-my-sm-n2 {
    margin-top: -0.5rem;
  }
  .c-mr-sm-n2,
  .c-mx-sm-n2 {
    margin-right: -0.5rem;
  }
  .c-mb-sm-n2,
  .c-my-sm-n2 {
    margin-bottom: -0.5rem;
  }
  .c-ml-sm-n2,
  .c-mx-sm-n2 {
    margin-left: -0.5rem;
  }
  .c-m-sm-n3 {
    margin: -1rem;
  }
  .c-mt-sm-n3,
  .c-my-sm-n3 {
    margin-top: -1rem;
  }
  .c-mr-sm-n3,
  .c-mx-sm-n3 {
    margin-right: -1rem;
  }
  .c-mb-sm-n3,
  .c-my-sm-n3 {
    margin-bottom: -1rem;
  }
  .c-ml-sm-n3,
  .c-mx-sm-n3 {
    margin-left: -1rem;
  }
  .c-m-sm-n4 {
    margin: -1.5rem;
  }
  .c-mt-sm-n4,
  .c-my-sm-n4 {
    margin-top: -1.5rem;
  }
  .c-mr-sm-n4,
  .c-mx-sm-n4 {
    margin-right: -1.5rem;
  }
  .c-mb-sm-n4,
  .c-my-sm-n4 {
    margin-bottom: -1.5rem;
  }
  .c-ml-sm-n4,
  .c-mx-sm-n4 {
    margin-left: -1.5rem;
  }
  .c-m-sm-n5 {
    margin: -3rem;
  }
  .c-mt-sm-n5,
  .c-my-sm-n5 {
    margin-top: -3rem;
  }
  .c-mr-sm-n5,
  .c-mx-sm-n5 {
    margin-right: -3rem;
  }
  .c-mb-sm-n5,
  .c-my-sm-n5 {
    margin-bottom: -3rem;
  }
  .c-ml-sm-n5,
  .c-mx-sm-n5 {
    margin-left: -3rem;
  }
  .c-m-sm-n6 {
    margin: -4.5rem;
  }
  .c-mt-sm-n6,
  .c-my-sm-n6 {
    margin-top: -4.5rem;
  }
  .c-mr-sm-n6,
  .c-mx-sm-n6 {
    margin-right: -4.5rem;
  }
  .c-mb-sm-n6,
  .c-my-sm-n6 {
    margin-bottom: -4.5rem;
  }
  .c-ml-sm-n6,
  .c-mx-sm-n6 {
    margin-left: -4.5rem;
  }
  .c-m-sm-n7 {
    margin: -6rem;
  }
  .c-mt-sm-n7,
  .c-my-sm-n7 {
    margin-top: -6rem;
  }
  .c-mr-sm-n7,
  .c-mx-sm-n7 {
    margin-right: -6rem;
  }
  .c-mb-sm-n7,
  .c-my-sm-n7 {
    margin-bottom: -6rem;
  }
  .c-ml-sm-n7,
  .c-mx-sm-n7 {
    margin-left: -6rem;
  }
  .c-m-sm-n8 {
    margin: -7.5rem;
  }
  .c-mt-sm-n8,
  .c-my-sm-n8 {
    margin-top: -7.5rem;
  }
  .c-mr-sm-n8,
  .c-mx-sm-n8 {
    margin-right: -7.5rem;
  }
  .c-mb-sm-n8,
  .c-my-sm-n8 {
    margin-bottom: -7.5rem;
  }
  .c-ml-sm-n8,
  .c-mx-sm-n8 {
    margin-left: -7.5rem;
  }
  .c-m-sm-auto {
    margin: auto;
  }
  .c-mt-sm-auto,
  .c-my-sm-auto {
    margin-top: auto;
  }
  .c-mr-sm-auto,
  .c-mx-sm-auto {
    margin-right: auto;
  }
  .c-mb-sm-auto,
  .c-my-sm-auto {
    margin-bottom: auto;
  }
  .c-ml-sm-auto,
  .c-mx-sm-auto {
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .c-m-md-0 {
    margin: 0;
  }
  .c-mt-md-0,
  .c-my-md-0 {
    margin-top: 0;
  }
  .c-mr-md-0,
  .c-mx-md-0 {
    margin-right: 0;
  }
  .c-mb-md-0,
  .c-my-md-0 {
    margin-bottom: 0;
  }
  .c-ml-md-0,
  .c-mx-md-0 {
    margin-left: 0;
  }
  .c-m-md-1 {
    margin: 0.25rem;
  }
  .c-mt-md-1,
  .c-my-md-1 {
    margin-top: 0.25rem;
  }
  .c-mr-md-1,
  .c-mx-md-1 {
    margin-right: 0.25rem;
  }
  .c-mb-md-1,
  .c-my-md-1 {
    margin-bottom: 0.25rem;
  }
  .c-ml-md-1,
  .c-mx-md-1 {
    margin-left: 0.25rem;
  }
  .c-m-md-2 {
    margin: 0.5rem;
  }
  .c-mt-md-2,
  .c-my-md-2 {
    margin-top: 0.5rem;
  }
  .c-mr-md-2,
  .c-mx-md-2 {
    margin-right: 0.5rem;
  }
  .c-mb-md-2,
  .c-my-md-2 {
    margin-bottom: 0.5rem;
  }
  .c-ml-md-2,
  .c-mx-md-2 {
    margin-left: 0.5rem;
  }
  .c-m-md-3 {
    margin: 1rem;
  }
  .c-mt-md-3,
  .c-my-md-3 {
    margin-top: 1rem;
  }
  .c-mr-md-3,
  .c-mx-md-3 {
    margin-right: 1rem;
  }
  .c-mb-md-3,
  .c-my-md-3 {
    margin-bottom: 1rem;
  }
  .c-ml-md-3,
  .c-mx-md-3 {
    margin-left: 1rem;
  }
  .c-m-md-4 {
    margin: 1.5rem;
  }
  .c-mt-md-4,
  .c-my-md-4 {
    margin-top: 1.5rem;
  }
  .c-mr-md-4,
  .c-mx-md-4 {
    margin-right: 1.5rem;
  }
  .c-mb-md-4,
  .c-my-md-4 {
    margin-bottom: 1.5rem;
  }
  .c-ml-md-4,
  .c-mx-md-4 {
    margin-left: 1.5rem;
  }
  .c-m-md-5 {
    margin: 3rem;
  }
  .c-mt-md-5,
  .c-my-md-5 {
    margin-top: 3rem;
  }
  .c-mr-md-5,
  .c-mx-md-5 {
    margin-right: 3rem;
  }
  .c-mb-md-5,
  .c-my-md-5 {
    margin-bottom: 3rem;
  }
  .c-ml-md-5,
  .c-mx-md-5 {
    margin-left: 3rem;
  }
  .c-m-md-6 {
    margin: 4.5rem;
  }
  .c-mt-md-6,
  .c-my-md-6 {
    margin-top: 4.5rem;
  }
  .c-mr-md-6,
  .c-mx-md-6 {
    margin-right: 4.5rem;
  }
  .c-mb-md-6,
  .c-my-md-6 {
    margin-bottom: 4.5rem;
  }
  .c-ml-md-6,
  .c-mx-md-6 {
    margin-left: 4.5rem;
  }
  .c-m-md-7 {
    margin: 6rem;
  }
  .c-mt-md-7,
  .c-my-md-7 {
    margin-top: 6rem;
  }
  .c-mr-md-7,
  .c-mx-md-7 {
    margin-right: 6rem;
  }
  .c-mb-md-7,
  .c-my-md-7 {
    margin-bottom: 6rem;
  }
  .c-ml-md-7,
  .c-mx-md-7 {
    margin-left: 6rem;
  }
  .c-m-md-8 {
    margin: 7.5rem;
  }
  .c-mt-md-8,
  .c-my-md-8 {
    margin-top: 7.5rem;
  }
  .c-mr-md-8,
  .c-mx-md-8 {
    margin-right: 7.5rem;
  }
  .c-mb-md-8,
  .c-my-md-8 {
    margin-bottom: 7.5rem;
  }
  .c-ml-md-8,
  .c-mx-md-8 {
    margin-left: 7.5rem;
  }
  .c-p-md-0 {
    padding: 0;
  }
  .c-pt-md-0,
  .c-py-md-0 {
    padding-top: 0;
  }
  .c-pr-md-0,
  .c-px-md-0 {
    padding-right: 0;
  }
  .c-pb-md-0,
  .c-py-md-0 {
    padding-bottom: 0;
  }
  .c-pl-md-0,
  .c-px-md-0 {
    padding-left: 0;
  }
  .c-p-md-1 {
    padding: 0.25rem;
  }
  .c-pt-md-1,
  .c-py-md-1 {
    padding-top: 0.25rem;
  }
  .c-pr-md-1,
  .c-px-md-1 {
    padding-right: 0.25rem;
  }
  .c-pb-md-1,
  .c-py-md-1 {
    padding-bottom: 0.25rem;
  }
  .c-pl-md-1,
  .c-px-md-1 {
    padding-left: 0.25rem;
  }
  .c-p-md-2 {
    padding: 0.5rem;
  }
  .c-pt-md-2,
  .c-py-md-2 {
    padding-top: 0.5rem;
  }
  .c-pr-md-2,
  .c-px-md-2 {
    padding-right: 0.5rem;
  }
  .c-pb-md-2,
  .c-py-md-2 {
    padding-bottom: 0.5rem;
  }
  .c-pl-md-2,
  .c-px-md-2 {
    padding-left: 0.5rem;
  }
  .c-p-md-3 {
    padding: 1rem;
  }
  .c-pt-md-3,
  .c-py-md-3 {
    padding-top: 1rem;
  }
  .c-pr-md-3,
  .c-px-md-3 {
    padding-right: 1rem;
  }
  .c-pb-md-3,
  .c-py-md-3 {
    padding-bottom: 1rem;
  }
  .c-pl-md-3,
  .c-px-md-3 {
    padding-left: 1rem;
  }
  .c-p-md-4 {
    padding: 1.5rem;
  }
  .c-pt-md-4,
  .c-py-md-4 {
    padding-top: 1.5rem;
  }
  .c-pr-md-4,
  .c-px-md-4 {
    padding-right: 1.5rem;
  }
  .c-pb-md-4,
  .c-py-md-4 {
    padding-bottom: 1.5rem;
  }
  .c-pl-md-4,
  .c-px-md-4 {
    padding-left: 1.5rem;
  }
  .c-p-md-5 {
    padding: 3rem;
  }
  .c-pt-md-5,
  .c-py-md-5 {
    padding-top: 3rem;
  }
  .c-pr-md-5,
  .c-px-md-5 {
    padding-right: 3rem;
  }
  .c-pb-md-5,
  .c-py-md-5 {
    padding-bottom: 3rem;
  }
  .c-pl-md-5,
  .c-px-md-5 {
    padding-left: 3rem;
  }
  .c-p-md-6 {
    padding: 4.5rem;
  }
  .c-pt-md-6,
  .c-py-md-6 {
    padding-top: 4.5rem;
  }
  .c-pr-md-6,
  .c-px-md-6 {
    padding-right: 4.5rem;
  }
  .c-pb-md-6,
  .c-py-md-6 {
    padding-bottom: 4.5rem;
  }
  .c-pl-md-6,
  .c-px-md-6 {
    padding-left: 4.5rem;
  }
  .c-p-md-7 {
    padding: 6rem;
  }
  .c-pt-md-7,
  .c-py-md-7 {
    padding-top: 6rem;
  }
  .c-pr-md-7,
  .c-px-md-7 {
    padding-right: 6rem;
  }
  .c-pb-md-7,
  .c-py-md-7 {
    padding-bottom: 6rem;
  }
  .c-pl-md-7,
  .c-px-md-7 {
    padding-left: 6rem;
  }
  .c-p-md-8 {
    padding: 7.5rem;
  }
  .c-pt-md-8,
  .c-py-md-8 {
    padding-top: 7.5rem;
  }
  .c-pr-md-8,
  .c-px-md-8 {
    padding-right: 7.5rem;
  }
  .c-pb-md-8,
  .c-py-md-8 {
    padding-bottom: 7.5rem;
  }
  .c-pl-md-8,
  .c-px-md-8 {
    padding-left: 7.5rem;
  }
  .c-m-md-n1 {
    margin: -0.25rem;
  }
  .c-mt-md-n1,
  .c-my-md-n1 {
    margin-top: -0.25rem;
  }
  .c-mr-md-n1,
  .c-mx-md-n1 {
    margin-right: -0.25rem;
  }
  .c-mb-md-n1,
  .c-my-md-n1 {
    margin-bottom: -0.25rem;
  }
  .c-ml-md-n1,
  .c-mx-md-n1 {
    margin-left: -0.25rem;
  }
  .c-m-md-n2 {
    margin: -0.5rem;
  }
  .c-mt-md-n2,
  .c-my-md-n2 {
    margin-top: -0.5rem;
  }
  .c-mr-md-n2,
  .c-mx-md-n2 {
    margin-right: -0.5rem;
  }
  .c-mb-md-n2,
  .c-my-md-n2 {
    margin-bottom: -0.5rem;
  }
  .c-ml-md-n2,
  .c-mx-md-n2 {
    margin-left: -0.5rem;
  }
  .c-m-md-n3 {
    margin: -1rem;
  }
  .c-mt-md-n3,
  .c-my-md-n3 {
    margin-top: -1rem;
  }
  .c-mr-md-n3,
  .c-mx-md-n3 {
    margin-right: -1rem;
  }
  .c-mb-md-n3,
  .c-my-md-n3 {
    margin-bottom: -1rem;
  }
  .c-ml-md-n3,
  .c-mx-md-n3 {
    margin-left: -1rem;
  }
  .c-m-md-n4 {
    margin: -1.5rem;
  }
  .c-mt-md-n4,
  .c-my-md-n4 {
    margin-top: -1.5rem;
  }
  .c-mr-md-n4,
  .c-mx-md-n4 {
    margin-right: -1.5rem;
  }
  .c-mb-md-n4,
  .c-my-md-n4 {
    margin-bottom: -1.5rem;
  }
  .c-ml-md-n4,
  .c-mx-md-n4 {
    margin-left: -1.5rem;
  }
  .c-m-md-n5 {
    margin: -3rem;
  }
  .c-mt-md-n5,
  .c-my-md-n5 {
    margin-top: -3rem;
  }
  .c-mr-md-n5,
  .c-mx-md-n5 {
    margin-right: -3rem;
  }
  .c-mb-md-n5,
  .c-my-md-n5 {
    margin-bottom: -3rem;
  }
  .c-ml-md-n5,
  .c-mx-md-n5 {
    margin-left: -3rem;
  }
  .c-m-md-n6 {
    margin: -4.5rem;
  }
  .c-mt-md-n6,
  .c-my-md-n6 {
    margin-top: -4.5rem;
  }
  .c-mr-md-n6,
  .c-mx-md-n6 {
    margin-right: -4.5rem;
  }
  .c-mb-md-n6,
  .c-my-md-n6 {
    margin-bottom: -4.5rem;
  }
  .c-ml-md-n6,
  .c-mx-md-n6 {
    margin-left: -4.5rem;
  }
  .c-m-md-n7 {
    margin: -6rem;
  }
  .c-mt-md-n7,
  .c-my-md-n7 {
    margin-top: -6rem;
  }
  .c-mr-md-n7,
  .c-mx-md-n7 {
    margin-right: -6rem;
  }
  .c-mb-md-n7,
  .c-my-md-n7 {
    margin-bottom: -6rem;
  }
  .c-ml-md-n7,
  .c-mx-md-n7 {
    margin-left: -6rem;
  }
  .c-m-md-n8 {
    margin: -7.5rem;
  }
  .c-mt-md-n8,
  .c-my-md-n8 {
    margin-top: -7.5rem;
  }
  .c-mr-md-n8,
  .c-mx-md-n8 {
    margin-right: -7.5rem;
  }
  .c-mb-md-n8,
  .c-my-md-n8 {
    margin-bottom: -7.5rem;
  }
  .c-ml-md-n8,
  .c-mx-md-n8 {
    margin-left: -7.5rem;
  }
  .c-m-md-auto {
    margin: auto;
  }
  .c-mt-md-auto,
  .c-my-md-auto {
    margin-top: auto;
  }
  .c-mr-md-auto,
  .c-mx-md-auto {
    margin-right: auto;
  }
  .c-mb-md-auto,
  .c-my-md-auto {
    margin-bottom: auto;
  }
  .c-ml-md-auto,
  .c-mx-md-auto {
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .c-m-lg-0 {
    margin: 0;
  }
  .c-mt-lg-0,
  .c-my-lg-0 {
    margin-top: 0;
  }
  .c-mr-lg-0,
  .c-mx-lg-0 {
    margin-right: 0;
  }
  .c-mb-lg-0,
  .c-my-lg-0 {
    margin-bottom: 0;
  }
  .c-ml-lg-0,
  .c-mx-lg-0 {
    margin-left: 0;
  }
  .c-m-lg-1 {
    margin: 0.25rem;
  }
  .c-mt-lg-1,
  .c-my-lg-1 {
    margin-top: 0.25rem;
  }
  .c-mr-lg-1,
  .c-mx-lg-1 {
    margin-right: 0.25rem;
  }
  .c-mb-lg-1,
  .c-my-lg-1 {
    margin-bottom: 0.25rem;
  }
  .c-ml-lg-1,
  .c-mx-lg-1 {
    margin-left: 0.25rem;
  }
  .c-m-lg-2 {
    margin: 0.5rem;
  }
  .c-mt-lg-2,
  .c-my-lg-2 {
    margin-top: 0.5rem;
  }
  .c-mr-lg-2,
  .c-mx-lg-2 {
    margin-right: 0.5rem;
  }
  .c-mb-lg-2,
  .c-my-lg-2 {
    margin-bottom: 0.5rem;
  }
  .c-ml-lg-2,
  .c-mx-lg-2 {
    margin-left: 0.5rem;
  }
  .c-m-lg-3 {
    margin: 1rem;
  }
  .c-mt-lg-3,
  .c-my-lg-3 {
    margin-top: 1rem;
  }
  .c-mr-lg-3,
  .c-mx-lg-3 {
    margin-right: 1rem;
  }
  .c-mb-lg-3,
  .c-my-lg-3 {
    margin-bottom: 1rem;
  }
  .c-ml-lg-3,
  .c-mx-lg-3 {
    margin-left: 1rem;
  }
  .c-m-lg-4 {
    margin: 1.5rem;
  }
  .c-mt-lg-4,
  .c-my-lg-4 {
    margin-top: 1.5rem;
  }
  .c-mr-lg-4,
  .c-mx-lg-4 {
    margin-right: 1.5rem;
  }
  .c-mb-lg-4,
  .c-my-lg-4 {
    margin-bottom: 1.5rem;
  }
  .c-ml-lg-4,
  .c-mx-lg-4 {
    margin-left: 1.5rem;
  }
  .c-m-lg-5 {
    margin: 3rem;
  }
  .c-mt-lg-5,
  .c-my-lg-5 {
    margin-top: 3rem;
  }
  .c-mr-lg-5,
  .c-mx-lg-5 {
    margin-right: 3rem;
  }
  .c-mb-lg-5,
  .c-my-lg-5 {
    margin-bottom: 3rem;
  }
  .c-ml-lg-5,
  .c-mx-lg-5 {
    margin-left: 3rem;
  }
  .c-m-lg-6 {
    margin: 4.5rem;
  }
  .c-mt-lg-6,
  .c-my-lg-6 {
    margin-top: 4.5rem;
  }
  .c-mr-lg-6,
  .c-mx-lg-6 {
    margin-right: 4.5rem;
  }
  .c-mb-lg-6,
  .c-my-lg-6 {
    margin-bottom: 4.5rem;
  }
  .c-ml-lg-6,
  .c-mx-lg-6 {
    margin-left: 4.5rem;
  }
  .c-m-lg-7 {
    margin: 6rem;
  }
  .c-mt-lg-7,
  .c-my-lg-7 {
    margin-top: 6rem;
  }
  .c-mr-lg-7,
  .c-mx-lg-7 {
    margin-right: 6rem;
  }
  .c-mb-lg-7,
  .c-my-lg-7 {
    margin-bottom: 6rem;
  }
  .c-ml-lg-7,
  .c-mx-lg-7 {
    margin-left: 6rem;
  }
  .c-m-lg-8 {
    margin: 7.5rem;
  }
  .c-mt-lg-8,
  .c-my-lg-8 {
    margin-top: 7.5rem;
  }
  .c-mr-lg-8,
  .c-mx-lg-8 {
    margin-right: 7.5rem;
  }
  .c-mb-lg-8,
  .c-my-lg-8 {
    margin-bottom: 7.5rem;
  }
  .c-ml-lg-8,
  .c-mx-lg-8 {
    margin-left: 7.5rem;
  }
  .c-p-lg-0 {
    padding: 0;
  }
  .c-pt-lg-0,
  .c-py-lg-0 {
    padding-top: 0;
  }
  .c-pr-lg-0,
  .c-px-lg-0 {
    padding-right: 0;
  }
  .c-pb-lg-0,
  .c-py-lg-0 {
    padding-bottom: 0;
  }
  .c-pl-lg-0,
  .c-px-lg-0 {
    padding-left: 0;
  }
  .c-p-lg-1 {
    padding: 0.25rem;
  }
  .c-pt-lg-1,
  .c-py-lg-1 {
    padding-top: 0.25rem;
  }
  .c-pr-lg-1,
  .c-px-lg-1 {
    padding-right: 0.25rem;
  }
  .c-pb-lg-1,
  .c-py-lg-1 {
    padding-bottom: 0.25rem;
  }
  .c-pl-lg-1,
  .c-px-lg-1 {
    padding-left: 0.25rem;
  }
  .c-p-lg-2 {
    padding: 0.5rem;
  }
  .c-pt-lg-2,
  .c-py-lg-2 {
    padding-top: 0.5rem;
  }
  .c-pr-lg-2,
  .c-px-lg-2 {
    padding-right: 0.5rem;
  }
  .c-pb-lg-2,
  .c-py-lg-2 {
    padding-bottom: 0.5rem;
  }
  .c-pl-lg-2,
  .c-px-lg-2 {
    padding-left: 0.5rem;
  }
  .c-p-lg-3 {
    padding: 1rem;
  }
  .c-pt-lg-3,
  .c-py-lg-3 {
    padding-top: 1rem;
  }
  .c-pr-lg-3,
  .c-px-lg-3 {
    padding-right: 1rem;
  }
  .c-pb-lg-3,
  .c-py-lg-3 {
    padding-bottom: 1rem;
  }
  .c-pl-lg-3,
  .c-px-lg-3 {
    padding-left: 1rem;
  }
  .c-p-lg-4 {
    padding: 1.5rem;
  }
  .c-pt-lg-4,
  .c-py-lg-4 {
    padding-top: 1.5rem;
  }
  .c-pr-lg-4,
  .c-px-lg-4 {
    padding-right: 1.5rem;
  }
  .c-pb-lg-4,
  .c-py-lg-4 {
    padding-bottom: 1.5rem;
  }
  .c-pl-lg-4,
  .c-px-lg-4 {
    padding-left: 1.5rem;
  }
  .c-p-lg-5 {
    padding: 3rem;
  }
  .c-pt-lg-5,
  .c-py-lg-5 {
    padding-top: 3rem;
  }
  .c-pr-lg-5,
  .c-px-lg-5 {
    padding-right: 3rem;
  }
  .c-pb-lg-5,
  .c-py-lg-5 {
    padding-bottom: 3rem;
  }
  .c-pl-lg-5,
  .c-px-lg-5 {
    padding-left: 3rem;
  }
  .c-p-lg-6 {
    padding: 4.5rem;
  }
  .c-pt-lg-6,
  .c-py-lg-6 {
    padding-top: 4.5rem;
  }
  .c-pr-lg-6,
  .c-px-lg-6 {
    padding-right: 4.5rem;
  }
  .c-pb-lg-6,
  .c-py-lg-6 {
    padding-bottom: 4.5rem;
  }
  .c-pl-lg-6,
  .c-px-lg-6 {
    padding-left: 4.5rem;
  }
  .c-p-lg-7 {
    padding: 6rem;
  }
  .c-pt-lg-7,
  .c-py-lg-7 {
    padding-top: 6rem;
  }
  .c-pr-lg-7,
  .c-px-lg-7 {
    padding-right: 6rem;
  }
  .c-pb-lg-7,
  .c-py-lg-7 {
    padding-bottom: 6rem;
  }
  .c-pl-lg-7,
  .c-px-lg-7 {
    padding-left: 6rem;
  }
  .c-p-lg-8 {
    padding: 7.5rem;
  }
  .c-pt-lg-8,
  .c-py-lg-8 {
    padding-top: 7.5rem;
  }
  .c-pr-lg-8,
  .c-px-lg-8 {
    padding-right: 7.5rem;
  }
  .c-pb-lg-8,
  .c-py-lg-8 {
    padding-bottom: 7.5rem;
  }
  .c-pl-lg-8,
  .c-px-lg-8 {
    padding-left: 7.5rem;
  }
  .c-m-lg-n1 {
    margin: -0.25rem;
  }
  .c-mt-lg-n1,
  .c-my-lg-n1 {
    margin-top: -0.25rem;
  }
  .c-mr-lg-n1,
  .c-mx-lg-n1 {
    margin-right: -0.25rem;
  }
  .c-mb-lg-n1,
  .c-my-lg-n1 {
    margin-bottom: -0.25rem;
  }
  .c-ml-lg-n1,
  .c-mx-lg-n1 {
    margin-left: -0.25rem;
  }
  .c-m-lg-n2 {
    margin: -0.5rem;
  }
  .c-mt-lg-n2,
  .c-my-lg-n2 {
    margin-top: -0.5rem;
  }
  .c-mr-lg-n2,
  .c-mx-lg-n2 {
    margin-right: -0.5rem;
  }
  .c-mb-lg-n2,
  .c-my-lg-n2 {
    margin-bottom: -0.5rem;
  }
  .c-ml-lg-n2,
  .c-mx-lg-n2 {
    margin-left: -0.5rem;
  }
  .c-m-lg-n3 {
    margin: -1rem;
  }
  .c-mt-lg-n3,
  .c-my-lg-n3 {
    margin-top: -1rem;
  }
  .c-mr-lg-n3,
  .c-mx-lg-n3 {
    margin-right: -1rem;
  }
  .c-mb-lg-n3,
  .c-my-lg-n3 {
    margin-bottom: -1rem;
  }
  .c-ml-lg-n3,
  .c-mx-lg-n3 {
    margin-left: -1rem;
  }
  .c-m-lg-n4 {
    margin: -1.5rem;
  }
  .c-mt-lg-n4,
  .c-my-lg-n4 {
    margin-top: -1.5rem;
  }
  .c-mr-lg-n4,
  .c-mx-lg-n4 {
    margin-right: -1.5rem;
  }
  .c-mb-lg-n4,
  .c-my-lg-n4 {
    margin-bottom: -1.5rem;
  }
  .c-ml-lg-n4,
  .c-mx-lg-n4 {
    margin-left: -1.5rem;
  }
  .c-m-lg-n5 {
    margin: -3rem;
  }
  .c-mt-lg-n5,
  .c-my-lg-n5 {
    margin-top: -3rem;
  }
  .c-mr-lg-n5,
  .c-mx-lg-n5 {
    margin-right: -3rem;
  }
  .c-mb-lg-n5,
  .c-my-lg-n5 {
    margin-bottom: -3rem;
  }
  .c-ml-lg-n5,
  .c-mx-lg-n5 {
    margin-left: -3rem;
  }
  .c-m-lg-n6 {
    margin: -4.5rem;
  }
  .c-mt-lg-n6,
  .c-my-lg-n6 {
    margin-top: -4.5rem;
  }
  .c-mr-lg-n6,
  .c-mx-lg-n6 {
    margin-right: -4.5rem;
  }
  .c-mb-lg-n6,
  .c-my-lg-n6 {
    margin-bottom: -4.5rem;
  }
  .c-ml-lg-n6,
  .c-mx-lg-n6 {
    margin-left: -4.5rem;
  }
  .c-m-lg-n7 {
    margin: -6rem;
  }
  .c-mt-lg-n7,
  .c-my-lg-n7 {
    margin-top: -6rem;
  }
  .c-mr-lg-n7,
  .c-mx-lg-n7 {
    margin-right: -6rem;
  }
  .c-mb-lg-n7,
  .c-my-lg-n7 {
    margin-bottom: -6rem;
  }
  .c-ml-lg-n7,
  .c-mx-lg-n7 {
    margin-left: -6rem;
  }
  .c-m-lg-n8 {
    margin: -7.5rem;
  }
  .c-mt-lg-n8,
  .c-my-lg-n8 {
    margin-top: -7.5rem;
  }
  .c-mr-lg-n8,
  .c-mx-lg-n8 {
    margin-right: -7.5rem;
  }
  .c-mb-lg-n8,
  .c-my-lg-n8 {
    margin-bottom: -7.5rem;
  }
  .c-ml-lg-n8,
  .c-mx-lg-n8 {
    margin-left: -7.5rem;
  }
  .c-m-lg-auto {
    margin: auto;
  }
  .c-mt-lg-auto,
  .c-my-lg-auto {
    margin-top: auto;
  }
  .c-mr-lg-auto,
  .c-mx-lg-auto {
    margin-right: auto;
  }
  .c-mb-lg-auto,
  .c-my-lg-auto {
    margin-bottom: auto;
  }
  .c-ml-lg-auto,
  .c-mx-lg-auto {
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  .c-m-xl-0 {
    margin: 0;
  }
  .c-mt-xl-0,
  .c-my-xl-0 {
    margin-top: 0;
  }
  .c-mr-xl-0,
  .c-mx-xl-0 {
    margin-right: 0;
  }
  .c-mb-xl-0,
  .c-my-xl-0 {
    margin-bottom: 0;
  }
  .c-ml-xl-0,
  .c-mx-xl-0 {
    margin-left: 0;
  }
  .c-m-xl-1 {
    margin: 0.25rem;
  }
  .c-mt-xl-1,
  .c-my-xl-1 {
    margin-top: 0.25rem;
  }
  .c-mr-xl-1,
  .c-mx-xl-1 {
    margin-right: 0.25rem;
  }
  .c-mb-xl-1,
  .c-my-xl-1 {
    margin-bottom: 0.25rem;
  }
  .c-ml-xl-1,
  .c-mx-xl-1 {
    margin-left: 0.25rem;
  }
  .c-m-xl-2 {
    margin: 0.5rem;
  }
  .c-mt-xl-2,
  .c-my-xl-2 {
    margin-top: 0.5rem;
  }
  .c-mr-xl-2,
  .c-mx-xl-2 {
    margin-right: 0.5rem;
  }
  .c-mb-xl-2,
  .c-my-xl-2 {
    margin-bottom: 0.5rem;
  }
  .c-ml-xl-2,
  .c-mx-xl-2 {
    margin-left: 0.5rem;
  }
  .c-m-xl-3 {
    margin: 1rem;
  }
  .c-mt-xl-3,
  .c-my-xl-3 {
    margin-top: 1rem;
  }
  .c-mr-xl-3,
  .c-mx-xl-3 {
    margin-right: 1rem;
  }
  .c-mb-xl-3,
  .c-my-xl-3 {
    margin-bottom: 1rem;
  }
  .c-ml-xl-3,
  .c-mx-xl-3 {
    margin-left: 1rem;
  }
  .c-m-xl-4 {
    margin: 1.5rem;
  }
  .c-mt-xl-4,
  .c-my-xl-4 {
    margin-top: 1.5rem;
  }
  .c-mr-xl-4,
  .c-mx-xl-4 {
    margin-right: 1.5rem;
  }
  .c-mb-xl-4,
  .c-my-xl-4 {
    margin-bottom: 1.5rem;
  }
  .c-ml-xl-4,
  .c-mx-xl-4 {
    margin-left: 1.5rem;
  }
  .c-m-xl-5 {
    margin: 3rem;
  }
  .c-mt-xl-5,
  .c-my-xl-5 {
    margin-top: 3rem;
  }
  .c-mr-xl-5,
  .c-mx-xl-5 {
    margin-right: 3rem;
  }
  .c-mb-xl-5,
  .c-my-xl-5 {
    margin-bottom: 3rem;
  }
  .c-ml-xl-5,
  .c-mx-xl-5 {
    margin-left: 3rem;
  }
  .c-m-xl-6 {
    margin: 4.5rem;
  }
  .c-mt-xl-6,
  .c-my-xl-6 {
    margin-top: 4.5rem;
  }
  .c-mr-xl-6,
  .c-mx-xl-6 {
    margin-right: 4.5rem;
  }
  .c-mb-xl-6,
  .c-my-xl-6 {
    margin-bottom: 4.5rem;
  }
  .c-ml-xl-6,
  .c-mx-xl-6 {
    margin-left: 4.5rem;
  }
  .c-m-xl-7 {
    margin: 6rem;
  }
  .c-mt-xl-7,
  .c-my-xl-7 {
    margin-top: 6rem;
  }
  .c-mr-xl-7,
  .c-mx-xl-7 {
    margin-right: 6rem;
  }
  .c-mb-xl-7,
  .c-my-xl-7 {
    margin-bottom: 6rem;
  }
  .c-ml-xl-7,
  .c-mx-xl-7 {
    margin-left: 6rem;
  }
  .c-m-xl-8 {
    margin: 7.5rem;
  }
  .c-mt-xl-8,
  .c-my-xl-8 {
    margin-top: 7.5rem;
  }
  .c-mr-xl-8,
  .c-mx-xl-8 {
    margin-right: 7.5rem;
  }
  .c-mb-xl-8,
  .c-my-xl-8 {
    margin-bottom: 7.5rem;
  }
  .c-ml-xl-8,
  .c-mx-xl-8 {
    margin-left: 7.5rem;
  }
  .c-p-xl-0 {
    padding: 0;
  }
  .c-pt-xl-0,
  .c-py-xl-0 {
    padding-top: 0;
  }
  .c-pr-xl-0,
  .c-px-xl-0 {
    padding-right: 0;
  }
  .c-pb-xl-0,
  .c-py-xl-0 {
    padding-bottom: 0;
  }
  .c-pl-xl-0,
  .c-px-xl-0 {
    padding-left: 0;
  }
  .c-p-xl-1 {
    padding: 0.25rem;
  }
  .c-pt-xl-1,
  .c-py-xl-1 {
    padding-top: 0.25rem;
  }
  .c-pr-xl-1,
  .c-px-xl-1 {
    padding-right: 0.25rem;
  }
  .c-pb-xl-1,
  .c-py-xl-1 {
    padding-bottom: 0.25rem;
  }
  .c-pl-xl-1,
  .c-px-xl-1 {
    padding-left: 0.25rem;
  }
  .c-p-xl-2 {
    padding: 0.5rem;
  }
  .c-pt-xl-2,
  .c-py-xl-2 {
    padding-top: 0.5rem;
  }
  .c-pr-xl-2,
  .c-px-xl-2 {
    padding-right: 0.5rem;
  }
  .c-pb-xl-2,
  .c-py-xl-2 {
    padding-bottom: 0.5rem;
  }
  .c-pl-xl-2,
  .c-px-xl-2 {
    padding-left: 0.5rem;
  }
  .c-p-xl-3 {
    padding: 1rem;
  }
  .c-pt-xl-3,
  .c-py-xl-3 {
    padding-top: 1rem;
  }
  .c-pr-xl-3,
  .c-px-xl-3 {
    padding-right: 1rem;
  }
  .c-pb-xl-3,
  .c-py-xl-3 {
    padding-bottom: 1rem;
  }
  .c-pl-xl-3,
  .c-px-xl-3 {
    padding-left: 1rem;
  }
  .c-p-xl-4 {
    padding: 1.5rem;
  }
  .c-pt-xl-4,
  .c-py-xl-4 {
    padding-top: 1.5rem;
  }
  .c-pr-xl-4,
  .c-px-xl-4 {
    padding-right: 1.5rem;
  }
  .c-pb-xl-4,
  .c-py-xl-4 {
    padding-bottom: 1.5rem;
  }
  .c-pl-xl-4,
  .c-px-xl-4 {
    padding-left: 1.5rem;
  }
  .c-p-xl-5 {
    padding: 3rem;
  }
  .c-pt-xl-5,
  .c-py-xl-5 {
    padding-top: 3rem;
  }
  .c-pr-xl-5,
  .c-px-xl-5 {
    padding-right: 3rem;
  }
  .c-pb-xl-5,
  .c-py-xl-5 {
    padding-bottom: 3rem;
  }
  .c-pl-xl-5,
  .c-px-xl-5 {
    padding-left: 3rem;
  }
  .c-p-xl-6 {
    padding: 4.5rem;
  }
  .c-pt-xl-6,
  .c-py-xl-6 {
    padding-top: 4.5rem;
  }
  .c-pr-xl-6,
  .c-px-xl-6 {
    padding-right: 4.5rem;
  }
  .c-pb-xl-6,
  .c-py-xl-6 {
    padding-bottom: 4.5rem;
  }
  .c-pl-xl-6,
  .c-px-xl-6 {
    padding-left: 4.5rem;
  }
  .c-p-xl-7 {
    padding: 6rem;
  }
  .c-pt-xl-7,
  .c-py-xl-7 {
    padding-top: 6rem;
  }
  .c-pr-xl-7,
  .c-px-xl-7 {
    padding-right: 6rem;
  }
  .c-pb-xl-7,
  .c-py-xl-7 {
    padding-bottom: 6rem;
  }
  .c-pl-xl-7,
  .c-px-xl-7 {
    padding-left: 6rem;
  }
  .c-p-xl-8 {
    padding: 7.5rem;
  }
  .c-pt-xl-8,
  .c-py-xl-8 {
    padding-top: 7.5rem;
  }
  .c-pr-xl-8,
  .c-px-xl-8 {
    padding-right: 7.5rem;
  }
  .c-pb-xl-8,
  .c-py-xl-8 {
    padding-bottom: 7.5rem;
  }
  .c-pl-xl-8,
  .c-px-xl-8 {
    padding-left: 7.5rem;
  }
  .c-m-xl-n1 {
    margin: -0.25rem;
  }
  .c-mt-xl-n1,
  .c-my-xl-n1 {
    margin-top: -0.25rem;
  }
  .c-mr-xl-n1,
  .c-mx-xl-n1 {
    margin-right: -0.25rem;
  }
  .c-mb-xl-n1,
  .c-my-xl-n1 {
    margin-bottom: -0.25rem;
  }
  .c-ml-xl-n1,
  .c-mx-xl-n1 {
    margin-left: -0.25rem;
  }
  .c-m-xl-n2 {
    margin: -0.5rem;
  }
  .c-mt-xl-n2,
  .c-my-xl-n2 {
    margin-top: -0.5rem;
  }
  .c-mr-xl-n2,
  .c-mx-xl-n2 {
    margin-right: -0.5rem;
  }
  .c-mb-xl-n2,
  .c-my-xl-n2 {
    margin-bottom: -0.5rem;
  }
  .c-ml-xl-n2,
  .c-mx-xl-n2 {
    margin-left: -0.5rem;
  }
  .c-m-xl-n3 {
    margin: -1rem;
  }
  .c-mt-xl-n3,
  .c-my-xl-n3 {
    margin-top: -1rem;
  }
  .c-mr-xl-n3,
  .c-mx-xl-n3 {
    margin-right: -1rem;
  }
  .c-mb-xl-n3,
  .c-my-xl-n3 {
    margin-bottom: -1rem;
  }
  .c-ml-xl-n3,
  .c-mx-xl-n3 {
    margin-left: -1rem;
  }
  .c-m-xl-n4 {
    margin: -1.5rem;
  }
  .c-mt-xl-n4,
  .c-my-xl-n4 {
    margin-top: -1.5rem;
  }
  .c-mr-xl-n4,
  .c-mx-xl-n4 {
    margin-right: -1.5rem;
  }
  .c-mb-xl-n4,
  .c-my-xl-n4 {
    margin-bottom: -1.5rem;
  }
  .c-ml-xl-n4,
  .c-mx-xl-n4 {
    margin-left: -1.5rem;
  }
  .c-m-xl-n5 {
    margin: -3rem;
  }
  .c-mt-xl-n5,
  .c-my-xl-n5 {
    margin-top: -3rem;
  }
  .c-mr-xl-n5,
  .c-mx-xl-n5 {
    margin-right: -3rem;
  }
  .c-mb-xl-n5,
  .c-my-xl-n5 {
    margin-bottom: -3rem;
  }
  .c-ml-xl-n5,
  .c-mx-xl-n5 {
    margin-left: -3rem;
  }
  .c-m-xl-n6 {
    margin: -4.5rem;
  }
  .c-mt-xl-n6,
  .c-my-xl-n6 {
    margin-top: -4.5rem;
  }
  .c-mr-xl-n6,
  .c-mx-xl-n6 {
    margin-right: -4.5rem;
  }
  .c-mb-xl-n6,
  .c-my-xl-n6 {
    margin-bottom: -4.5rem;
  }
  .c-ml-xl-n6,
  .c-mx-xl-n6 {
    margin-left: -4.5rem;
  }
  .c-m-xl-n7 {
    margin: -6rem;
  }
  .c-mt-xl-n7,
  .c-my-xl-n7 {
    margin-top: -6rem;
  }
  .c-mr-xl-n7,
  .c-mx-xl-n7 {
    margin-right: -6rem;
  }
  .c-mb-xl-n7,
  .c-my-xl-n7 {
    margin-bottom: -6rem;
  }
  .c-ml-xl-n7,
  .c-mx-xl-n7 {
    margin-left: -6rem;
  }
  .c-m-xl-n8 {
    margin: -7.5rem;
  }
  .c-mt-xl-n8,
  .c-my-xl-n8 {
    margin-top: -7.5rem;
  }
  .c-mr-xl-n8,
  .c-mx-xl-n8 {
    margin-right: -7.5rem;
  }
  .c-mb-xl-n8,
  .c-my-xl-n8 {
    margin-bottom: -7.5rem;
  }
  .c-ml-xl-n8,
  .c-mx-xl-n8 {
    margin-left: -7.5rem;
  }
  .c-m-xl-auto {
    margin: auto;
  }
  .c-mt-xl-auto,
  .c-my-xl-auto {
    margin-top: auto;
  }
  .c-mr-xl-auto,
  .c-mx-xl-auto {
    margin-right: auto;
  }
  .c-mb-xl-auto,
  .c-my-xl-auto {
    margin-bottom: auto;
  }
  .c-ml-xl-auto,
  .c-mx-xl-auto {
    margin-left: auto;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-blue {
  background-color: #007bff !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #0062cc !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important;
}

.bg-red {
  background-color: #dc3545 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #bd2130 !important;
}

.bg-orange {
  background-color: #fd7e14 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important;
}

.bg-green {
  background-color: #28a745 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #1e7e34 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important;
}

.bg-primary {
  background-color: #0b5fff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #004ad7 !important;
}

.bg-secondary {
  background-color: #6b6c7e !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545462 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f7f8f9 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dadfe3 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 0.0625rem solid #dee2e6 !important;
}

.border-top {
  border-top: 0.0625rem solid #dee2e6 !important;
}

.border-right {
  border-right: 0.0625rem solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid #dee2e6 !important;
}

.border-left {
  border-left: 0.0625rem solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0b5fff !important;
}

.border-secondary {
  border-color: #6b6c7e !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f7f8f9 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 5000px !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0px !important;
}

.clearfix::after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-contents {
  display: contents !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-contents {
    display: contents !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-contents {
    display: contents !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-contents {
    display: contents !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-contents {
    display: contents !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
.hide {
  display: none !important;
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-contents {
    display: contents !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
/* SPDX-SnippetBegin
 * SPDX-License-Identifier: MIT
 * SPDX-SnippetCopyrightText: © 2012 Nicolas Gallagher <https://github.com/suitcss/components-flex-embed>
 */
.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.embed-responsive::before {
  content: "";
  display: block;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  border-width: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.embed-responsive-21by9::before {
  padding-top: calc(
				9 /
					21 *
					100%
			);
}

.embed-responsive-16by9::before {
  padding-top: calc(
				9 /
					16 *
					100%
			);
}

.embed-responsive-4by3::before {
  padding-top: calc(
				3 /
					4 *
					100%
			);
}

.embed-responsive-1by1::before {
  padding-top: calc(
				1 /
					1 *
					100%
			);
}

/* SPDX-SnippetEnd */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 7.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 7.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 7.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 7.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 7.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 7.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -6rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -6rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -6rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -6rem !important;
}

.m-n8 {
  margin: -7.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -7.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -7.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -7.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -7.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 7.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 7.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -6rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -6rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -6rem !important;
  }
  .m-sm-n8 {
    margin: -7.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -7.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 7.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 7.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 7.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 7.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 7.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 7.5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -6rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -6rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -6rem !important;
  }
  .m-md-n8 {
    margin: -7.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -7.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 7.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 7.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -6rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -6rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -6rem !important;
  }
  .m-lg-n8 {
    margin: -7.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -7.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 7.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 7.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -6rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -6rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -6rem !important;
  }
  .m-xl-n8 {
    margin: -7.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -7.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  bottom: 0;
  content: "";
  left: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-truncate-inline {
  display: inline-flex;
  max-width: 100%;
}
.text-truncate-inline .text-truncate {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  overflow-wrap: normal;
  word-wrap: normal;
}
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-lighter,
.text-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-light,
.text-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal,
.text-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semi-bold,
.text-weight-semi-bold {
  font-weight: 500 !important;
}

.font-weight-bold,
.text-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder,
.text-weight-bolder {
  font-weight: 900 !important;
}

.font-italic,
.text-italic {
  font-style: italic !important;
}

.font-monospace,
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-1 {
  font-size: 0.625rem;
}

.text-2 {
  font-size: 0.75rem;
}

.text-3 {
  font-size: 0.875rem;
}

.text-4 {
  font-size: 1rem;
}

.text-5 {
  font-size: 1.125rem;
}

.text-6 {
  font-size: 1.25rem;
}

.text-7 {
  font-size: 1.5rem;
}

.text-8 {
  font-size: 1.75rem;
}

.text-9 {
  font-size: 2rem;
}

.text-10 {
  font-size: 2.25rem;
}

.text-11 {
  font-size: 2.5rem;
}

.text-white {
  color: #fff !important;
}

.text-blue {
  color: #007bff !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #0056b3 !important;
}

.text-indigo {
  color: #6610f2 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #4709ac !important;
}

.text-purple {
  color: #6f42c1 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #4e2d89 !important;
}

.text-pink {
  color: #e83e8c !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important;
}

.text-red {
  color: #dc3545 !important;
}

a.text-red:hover, a.text-red:focus {
  color: #a71d2a !important;
}

.text-orange {
  color: #fd7e14 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #c35a02 !important;
}

.text-yellow {
  color: #ffc107 !important;
}

a.text-yellow:hover, a.text-yellow:focus {
  color: #ba8b00 !important;
}

.text-green {
  color: #28a745 !important;
}

a.text-green:hover, a.text-green:focus {
  color: #19692c !important;
}

.text-teal {
  color: #20c997 !important;
}

a.text-teal:hover, a.text-teal:focus {
  color: #158765 !important;
}

.text-cyan {
  color: #17a2b8 !important;
}

a.text-cyan:hover, a.text-cyan:focus {
  color: #0f6674 !important;
}

.text-primary {
  color: #0b5fff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0041be !important;
}

.text-secondary {
  color: #6b6c7e !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #484955 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f7f8f9 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd2d8 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #272833 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

.text-none, .text-decoration-none {
  text-decoration: none !important;
}
.text-underline, .text-decoration-underline {
  text-decoration: underline !important;
}
.text-break {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.c-prefers-expanded-text .breadcrumb-text-truncate, .c-prefers-expanded-text .form-control-plaintext, .c-prefers-expanded-text .form-control-select, .c-prefers-expanded-text .modal-title, .c-prefers-expanded-text .multi-step-title-center .multi-step-title, .c-prefers-expanded-text .multi-step-indicator .multi-step-indicator-label, .c-prefers-expanded-text .nav-text-truncate, .c-prefers-expanded-text .navbar-text-truncate, .c-prefers-expanded-text .text-truncate, .c-prefers-expanded-text .text-truncate-inline .text-truncate {
  overflow-wrap: break-word !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.c-prefers-link-underline [href]:not(.disabled):not([role=button]) {
  text-decoration: underline !important;
}
.c-prefers-link-underline .btn-monospaced[href]:not(.disabled):not([role=button]):not([role=tab])::after, .c-prefers-link-underline .nav-btn-monospaced[href]:not(.disabled):not([role=button]):not([role=tab])::after, .c-prefers-link-underline .nav-link-monospaced[href]:not(.disabled):not([role=button]):not([role=tab])::after {
  content: "   ";
  position: absolute;
  text-decoration: underline !important;
}
.c-prefers-link-underline .multi-step-item.active .multi-step-icon[href]:not([role=button])::after, .c-prefers-link-underline .multi-step-item.complete .multi-step-icon[href]:not([role=button])::after, .c-prefers-link-underline .multi-step-item.error .multi-step-icon[href]:not([role=button])::after, .c-prefers-link-underline .dropdown .multi-step-icon[href]:not([role=button])::after {
  content: "   ";
  position: absolute;
  text-decoration: underline !important;
}
.c-prefers-link-underline .multi-step-item.disabled .multi-step-icon[href]:not([role=button])::after {
  text-decoration: none !important;
}

.c-prefers-letter-spacing-1, .c-prefers-letter-spacing-1 * {
  letter-spacing: 1px !important;
}

.d-block-c-prefers-reduced-motion {
  display: none !important;
}

.d-inline-block-c-prefers-reduced-motion {
  display: none !important;
}

@media (prefers-reduced-motion: reduce) {
  .d-none-c-prefers-reduced-motion {
    display: none !important;
  }
  .d-block-c-prefers-reduced-motion {
    display: block !important;
  }
  .d-inline-block-c-prefers-reduced-motion {
    display: inline-block !important;
  }
}
.c-prefers-reduced-motion .d-none-c-prefers-reduced-motion {
  display: none !important;
}
.c-prefers-reduced-motion .d-block-c-prefers-reduced-motion {
  display: block !important;
}
.c-prefers-reduced-motion .d-inline-block-c-prefers-reduced-motion {
  display: inline-block !important;
}

.c-prefers-reduced-motion {
  scroll-behavior: auto;
  transition: none;
}

@keyframes loading-animation-circle {
  100% {
    transform: rotate(360deg);
  }
}
.loading-animation {
  display: block;
  height: 1em;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 1em;
}
.loading-animation::before {
  animation: loading-animation-circle 1s linear infinite;
  border-radius: 50%;
  box-shadow: -0.03125em -0.375em 0 0 currentColor;
  content: "";
  height: 0.25em;
  left: 50%;
  margin-left: -0.125em;
  margin-top: -0.125em;
  position: absolute;
  top: 50%;
  width: 0.25em;
}
@media (prefers-reduced-motion: reduce) {
  .loading-animation::before {
    animation: none;
  }
}
.c-prefers-reduced-motion .loading-animation::before {
  animation: none;
}

.loading-animation::after {
  animation: loading-animation-circle 1s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  content: "";
  display: block;
  height: 1em;
  -webkit-mask: conic-gradient(transparent 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  padding: 0.25em;
  width: 1em;
}
@media (prefers-reduced-motion: reduce) {
  .loading-animation::after {
    animation: none;
  }
}
.c-prefers-reduced-motion .loading-animation::after {
  animation: none;
}

@keyframes loading-animation-squares-box-1 {
  0% {
    left: 0;
    opacity: 0.4;
    top: 0;
    transform: scale(1);
  }
  25% {
    left: calc(100% - 1em);
    opacity: 0.4;
    top: 0;
    transform: scale(1);
  }
  50% {
    left: calc(100% - 1.5em);
    opacity: 1;
    top: calc(100% - 1.5em);
    transform: scale(2);
  }
  75% {
    left: 0.5em;
    opacity: 1;
    top: calc(100% - 1.5em);
    transform: scale(2);
  }
}
@keyframes loading-animation-squares-box-2 {
  0% {
    left: calc(100% - 1.5em);
    opacity: 1;
    top: calc(100% - 1.5em);
    transform: scale(2);
  }
  25% {
    left: 0.5em;
    opacity: 1;
    top: calc(100% - 1.5em);
    transform: scale(2);
  }
  50% {
    left: 0;
    opacity: 0.4;
    top: 0;
    transform: scale(1);
  }
  75% {
    left: calc(100% - 1em);
    opacity: 0.4;
    top: 0;
    transform: scale(1);
  }
}
.loading-animation-squares {
  display: block;
  height: 1em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: left;
  vertical-align: middle;
  width: 1em;
}
.loading-animation-squares::before {
  animation: loading-animation-squares-box-1 2.4s ease-in-out infinite;
  background-color: currentColor;
  border-radius: 0.2em;
  content: "";
  display: block;
  font-size: 0.3125em;
  height: 1em;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  transform: scale(1);
  width: 1em;
}
@media (prefers-reduced-motion: reduce) {
  .loading-animation-squares::before {
    animation: none;
  }
}
.c-prefers-reduced-motion .loading-animation-squares::before {
  animation: none;
}

.loading-animation-squares::after {
  animation: loading-animation-squares-box-2 2.4s ease-in-out infinite;
  background-color: currentColor;
  border-radius: 0.2em;
  content: "";
  display: block;
  font-size: 0.3125em;
  height: 1em;
  left: calc(100% - 1.5em);
  opacity: 1;
  position: absolute;
  top: calc(100% - 1.5em);
  transform: scale(2);
  width: 1em;
}
@media (prefers-reduced-motion: reduce) {
  .loading-animation-squares::after {
    animation: none;
  }
}
.c-prefers-reduced-motion .loading-animation-squares::after {
  animation: none;
}

.loading-animation-xs {
  font-size: 0.625rem;
}
.loading-animation-sm {
  font-size: 1rem;
}
.loading-animation-md {
  font-size: 2rem;
}
.loading-animation-lg {
  font-size: 4rem;
}
.loading-animation-primary {
  color: #0b5fff;
}
.loading-animation-secondary {
  color: #6b6c7e;
}
.loading-animation-light {
  color: #fff;
}
/* SPDX-SnippetBegin
 * SPDX-License-Identifier: MIT
 * SPDX-SnippetCopyrightText: © 2018 HTML5 Boilerplate <https://github.com/h5bp/main.css>
 */
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 0.0625rem solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
@media print {
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 0.0625rem solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    border-color: #dee2e6;
    color: inherit;
  }
}
/* SPDX-SnippetEnd */
.portlet-msg-progress {
  background-image: url(../images/aui/loading_indicator.gif);
  background-position: 6px 50%;
  background-repeat: no-repeat;
  padding-left: 30px;
}

.navbar .nav li > a {
  overflow-wrap: break-all;
  word-wrap: break-all;
}

.entry-status.approved {
  border-color: #28a745 !important;
}
.entry-status.scheduled {
  border-color: #17a2b8 !important;
}
.entry-status.pending {
  border-color: #17a2b8 !important;
}
.entry-status.denied {
  border-color: #dc3545 !important;
}
.entry-status.expired {
  border-color: #ffc107 !important;
}

.status.approved {
  color: #28a745;
}
.status.scheduled {
  color: #17a2b8;
}
.status.pending {
  color: #17a2b8;
}
.status.denied {
  color: #dc3545;
}
.status.expired {
  color: #ffc107;
}

/*# sourceMappingURL=clay.css.map */